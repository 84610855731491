import { Locale } from '@customer-portal/constants';
import Cookies from 'js-cookie';

import { AUTH0_DOMAIN } from '../constants/auth.constants';
import { DOMAIN_PREFIX_TO_CLOUD_COOKIE_NAME_SUFFIX } from '../constants/cloud.constants';
import type { DotComLanguage } from '../constants/dotcom.constants';
import {
  DOT_COM_LANGUAGE_TO_LOCALE,
  NODE_ENV_TO_DOT_COM_COOKIE_NAME_SUFFIX,
} from '../constants/dotcom.constants';
import { LANGUAGE_COOKIE_DOMAIN_NAME } from '../constants/localization.constants';
import {
  getNodeEnv,
  isLocalhost,
} from '../lib/env.utils';

export type LocalizedString = { textKey: string; fallbackText: string };

export const getLanguageCookieDomain = (): string => isLocalhost()
  ? 'localhost'
  : LANGUAGE_COOKIE_DOMAIN_NAME;

export const getCloudLanguageCookieName = (): string => {
  const domainPrefix = AUTH0_DOMAIN.split(LANGUAGE_COOKIE_DOMAIN_NAME)[0];

  const cookieNamePrefix = DOMAIN_PREFIX_TO_CLOUD_COOKIE_NAME_SUFFIX.get(
    isLocalhost()
      ? 'local'
      : DOMAIN_PREFIX_TO_CLOUD_COOKIE_NAME_SUFFIX.has(domainPrefix)
        ? domainPrefix
        : 'id-alpha'
  );

  return `language-${cookieNamePrefix}`;
};

export const getDotComLanguageCookieName = (): string => {
  const nodeEnv = getNodeEnv();

  const cookieNamePrefix = NODE_ENV_TO_DOT_COM_COOKIE_NAME_SUFFIX.get(
    NODE_ENV_TO_DOT_COM_COOKIE_NAME_SUFFIX.has(nodeEnv) ? nodeEnv : 'development'
  );

  return `locale-${cookieNamePrefix}`;
};

/**
 * Get the locale code of last localized page visited from UiPath.com
 */
export const getDotComLanguageFromCookie = (): Locale | undefined => {
  const cookieValue = Cookies
    .withAttributes({ domain: getLanguageCookieDomain() })
    .get(getDotComLanguageCookieName());

  if (cookieValue && DOT_COM_LANGUAGE_TO_LOCALE.hasOwnProperty(cookieValue)) {
    return DOT_COM_LANGUAGE_TO_LOCALE[cookieValue as DotComLanguage];
  }

  return undefined;
};

/**
 * Get the locale code of the selected language on the Cloud site, and the UiPath login pages
 */
export const getCloudLanguageFromCookie = (): Locale | undefined => {
  const cookieValue = Cookies
    .withAttributes({ domain: getLanguageCookieDomain() })
    .get(getCloudLanguageCookieName());

  for (const locale of Object.keys(Locale)) {
    if (cookieValue === locale.toLowerCase()) {
      return locale as Locale;
    }
  }

  return undefined;
};

export const setCloudLanguageCookie = (language: Locale): void => {
  Cookies.set(getCloudLanguageCookieName(), language, {
    domain: getLanguageCookieDomain(),
    expires: 365,
  });
};
