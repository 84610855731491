import React from 'react';

// Context
// Components
import Modal from '../Modal';
import GetUserInfoForm from './GetUserInfoForm';

const GetUserInfoModal = () => (
  <>
    <Modal
      modalTitle="Standard Modal"
      modalDescription="Get user info modal"
      innerClasses="GetUserInfoModal"
      handleClose={() => {}}
      open
      testId="GetUserInfoModal"
    >
      <div className="Modal-Fields-Container">
        <GetUserInfoForm />
      </div>
    </Modal>
  </>
);

export default GetUserInfoModal;
