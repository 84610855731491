export const messageByJob: Record<number, (params: any) => string> = {
  24: ({
    startDate, endDate,
  }: { startDate: string; endDate: string }) => {
    if (startDate && endDate) {
      const formattedStartDate = new Date(startDate).toDateString();
      const formattedEndDate = new Date(endDate).toDateString();
      return ` fetching coveo visits data from ${formattedStartDate} to ${formattedEndDate}`;
    }
    return '';
  },
};

export const getJobMessage = (jobNum: number, params: any) =>
  messageByJob[jobNum] ? messageByJob[jobNum](params) : '';

export const batchJobAllowedMapping: Record<number, (params: any) => { jobDisabled: boolean; jobValid: boolean }> = {
  24: ({
    startDate, endDate,
  }: { startDate: string; endDate: string }) => {
    const jobDisabled = !startDate || !endDate;
    return {
      jobDisabled,
      jobValid: !jobDisabled,
    };
  },
};

export const isBatchJobAllowed = (jobNum: number, params: any) =>
  batchJobAllowedMapping[jobNum] ? batchJobAllowedMapping[jobNum](params) : {
    jobDisabled: jobNum === 0,
    jobValid: jobNum !== 0,
  };
