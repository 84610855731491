import styled from 'styled-components';

import closeIcon from '../img/svg/browse/Error.svg';
import searchIcon from '../img/svg/browse/search_blue.svg';
import { ContentWrapper } from './CustomerPortalContentWrapper';

export const AnalyticsWrapper = styled.section`
  position: relative;
  background-color: #f5f5f6;
  min-height:800px;
  ${ContentWrapper}

  .flex-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .col {
    flex: 1;
    font-size: 0.6rem;
    line-height: 1.6rem;
    color: rgb(133, 143, 149);
  }
  .col-heading {
    margin: 10px;
    padding: 20px;

    flex: 1 0 auto;
    width: 200px;

    border: 1px solid #273139;
    border-radius: 8px;
    background: #273139;

    &--Money {
      background: ${p => p.theme.palette.green[700]};
      border: 1px solid ${p => p.theme.palette.green[700]};
    }

    p {
      color: #fff;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.8rem;

      &.Analytics__Data-Values {
        padding-top: 20px;
        font-size: 2.4rem;
      }
    }
  }

  .customer__name {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.8rem;
  }

  .customer__id {
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.4rem;
  }
  .user_roles {
    padding-left: 0px;
  }

  .user__stats {
    display: flex;
    align-items: center;
  }

  .user__statsItem {
    flex: 1 0 auto;
    width: 70px;
  }

  .marginBottomh3{
    margin-bottom:20px;
    margin-top:20px;
  }

  .Analytics__Data-Heading {
    color: ${p => p.theme.palette.ink[650]};
    font-size: 1.8rem;
    font-weight: normal;
    line-height: 1.33;
  }

  .Analytics__Container {
    margin-bottom: 50px;
  }

  .Header__Wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .AdminsSelection__adminsActions {
    display: flex;
    justify-content: space-between;
  }

  .AdminsSelection__adminsSearch {
    padding: ${p => p.theme.spacing(1.25)}px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #fff;
    border-radius: ${p => p.theme.spacing(1)}px;
    margin-right: ${p => p.theme.spacing(2)}px;
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }

  .AdminsSelection__adminsSearchIcon {
    position: relative;
    width: ${p => p.theme.spacing(2.5)}px;
    height: ${p => p.theme.spacing(2.5)}px;
    cursor: pointer;

    &:before {
      content: '';
      background: url(${searchIcon}) no-repeat;

      position: absolute;
      top: 5px;
      left: 4px;

      width: ${p => p.theme.spacing(2.5)}px;
      height: ${p => p.theme.spacing(2.5)}px;
    }

    &--close {
      &:before {
        top: 2px;
        background: url(${closeIcon}) no-repeat;
      }
    }
  }

  .AdminsSelection__adminsSearchInput {
    display: none;

    &--active {
      display: block;
    }

    input {
      width: auto;

      font-size: 1.4rem;

      outline: none;
      border: none;

      ${p => p.theme.breakpoints.up('sm')} {
        width: ${p => p.theme.spacing(25)}px;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #353c4a;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #353c4a;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #353c4a;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #353c4a;
      }
    }
  }

  .AddNewAdmin {
    font-weight: bold !important;
    font-size: 1.6rem !important;
    line-height: 2rem !important;
    border-radius: ${p => p.theme.spacing(1)}px;
    margin-bottom: ${p => p.theme.spacing(2)}px;
    float: right;
  }

  .TableCell__Clickable {
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  .action-pill {
    flex: 0 0 auto;
    width: 100%;
    color: #f5222d;
    background-color: #ffe6e7;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: center;
    cursor:pointer;

    ${p => p.theme.breakpoints.down('md')} {
      padding-left: ${p => p.theme.spacing(0.5)}px;
      padding-right: ${p => p.theme.spacing(0.5)}px;
    }
  }

  .inactivate {
    color: #f5222d;
    background-color: #ffe6e7;
  }

  .activate {
    color: ${p => p.theme.palette.success.main};
    background-color: #eeffe6;
  }

  .AdminForm_Form-Input-Outer {
      flex: calc(50% - 24px);
      max-width: 306px;
      ${p => p.theme.breakpoints.down('sm')} {
        max-width: 295px;
      }
      &:nth-child(1),&:nth-child(2) {
        ${p => p.theme.breakpoints.up('sm')} {
          margin-bottom: 8px;
        }
      }
      ${p => p.theme.breakpoints.down('xs')} {
        flex: 100%;
        max-width: 100%;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }

    .AdminForm_Input {
      width:100%;
      flex:1;
    }

    .Download-ButtonCSV {
      font-weight: bold !important;
      font-size: 1.6rem !important;
      line-height: 2rem !important;
      border-radius: 8px;
      padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(1.5)}px;

      ${p => p.theme.breakpoints.down('sm')} {
        margin-top: ${p => p.theme.spacing(2)}px;
      }
      float: right;
    }
  }

  .Header__CTAWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Header__Button {
      max-height: 46px;
    }
  }

`;

export const analyticsLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const FilterContainer = styled.div`
  max-width: 400px;
`;

export const CommandOption = styled.span`
  cursor: pointer;
  color: ${p => p.theme.palette.blue[500]}
`;
