import KB_ACTION from '../constants/kbActions.constants';
import type { IKBSearchState } from '../interfaces/knowledgeBase.interface';

// Reducer for getting top level KB
export const kbDataReducer = (state: any, action: { [key: string]: any }) => {
  switch (action.type) {
    case KB_ACTION.FETCH_INIT:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case KB_ACTION.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        data: action.data,
      };
    case KB_ACTION.FETCH_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      throw new Error('Unidentified reducer action type');
  }
};

export const kbSearchReducer = (state: any, action: { [key: string]: any }) => {
  if (!action) {
    throw new Error('Action must be defined');
  }

  switch (action.type) {
    case KB_ACTION.KEYWORD_UPDATED:
      return {
        ...state,
        keywordInputField: action.payload.keyword,
      };
    case KB_ACTION.KEYWORD_EMPTY:
      // Reset search state
      return {
        ...state,
        isSearching: false,
        keyword: '',
        keywordInputField: '',
        isCompleteSearching: false,
        filterCategories: [],
        kbDocuments: [],
        currentPage: 1,
        kbTotalDocs: 0,
        kbTotalPages: 0,
        skipResult: 0,
        filterParam: new Set<string>(),
        filterSubcategoriesParam: new Set<string>(),
      };
    case KB_ACTION.FETCH_INIT:
      // Return blank state with only those two new keywords
      return {
        ...state,
        filterSubcategoriesParam: state.filterSubcategoriesParam
          ? new Set<string>()
          : state.filterSubcategoriesParam,
        keyword: action.payload.keyword,
        keywordInputField: action.payload.keyword,
        isSearching: true,
        isCompleteSearching: false,
      };
    case KB_ACTION.FETCH_SUCCESS:
      return {
        ...state,
        isSearching: true,
        isCompleteSearching: true,
        kbTotalPages: action.payload.totalPages,
        kbTotalDocs: action.payload.totalDocs,
        kbDocuments: action.payload.value,
        filterCategories: action.payload.filterCategories
          ? action.payload.filterCategories
          : state.filterCategories,
      };
    case KB_ACTION.FETCH_FAIL:
      return {
        ...state,
        isSearching: true,
        isCompleteSearching: true,
        kbTotalDocs: 0,
        kbDocuments: [],
        filterCategories: [],
      };
    case KB_ACTION.SUBCATEGORY_UPDATED: {
      const requiredFieldsSubcategory = [ 'value' ];
      const givenFieldsSubcategory = Object.getOwnPropertyNames(action.payload);
      if (
        !requiredFieldsSubcategory.every(
          field => givenFieldsSubcategory.indexOf(field) > -1
        )
      ) {
        return { ...state };
      }

      const filterSubcategoriesParamCopy =
        state.filterSubcategoriesParam || new Set<string>(); // This should be a set

      // If it's in the set, then delete; else add
      if (filterSubcategoriesParamCopy.has(action.payload.value)) {
        filterSubcategoriesParamCopy.delete(action.payload.value);
      } else {
        filterSubcategoriesParamCopy.add(action.payload.value);
      }

      return {
        ...state,
        filterSubcategoriesParam: filterSubcategoriesParamCopy,
      };
    }
    case KB_ACTION.FILTER_UPDATED: {
      const requiredFields = [ 'value' ];
      const givenFields = Object.getOwnPropertyNames(action.payload);

      if (!requiredFields.every(field => givenFields.indexOf(field) > -1)) {
        return { ...state };
      }

      const filterParamCopy = state.filterParam || new Set<string>(); // This should be a set

      // If it's in the set, then delete; else add
      if (filterParamCopy.has(action.payload.value)) {
        filterParamCopy.delete(action.payload.value);
      } else {
        filterParamCopy.add(action.payload.value);
      }

      return {
        ...state,
        filterParam: filterParamCopy,
      };
    }
    case KB_ACTION.SORT_UPDATED:
      return {
        ...state,
        sortBy: action.payload.value,
        sortDirection: action.payload.sortDirection,
      };
    case KB_ACTION.PAGE_CLICKED:
      return {
        ...state,
        skipResult: action.payload.newSkip,
      };
    case KB_ACTION.DOCUMENT_UPLOADED: {
      // Update the array
      const newUploadDocumentsArr = state.kbDocuments;
      const newFilterCategoriesArr = state.filterCategories;
      if (Array.isArray(newFilterCategoriesArr)) {
        newFilterCategoriesArr.forEach((category: any) => {
          if (category.category_name === action.payload.subcategoryName) {
            category.count = category.count + 1;
          }
        });
      }
      if (Array.isArray(newUploadDocumentsArr)) {
        newUploadDocumentsArr.unshift({
          id: action.payload.id,
          name: action.payload.name.toLowerCase(),
          display_name: action.payload.name,
          keywords: action.payload.keywords,
          category: action.payload.category,
          subcategory: action.payload.subcategory,
          featured: action.payload.featured,
          is_marked_for_notification: action.payload.is_marked_for_notification,
          hyperlink: action.payload.hyperlink,
          description: action.payload.description,
          mime_type: action.payload.mimeType,
          size: action.payload.size,
          specs: action.payload.specs,
          languages: action.payload.languages,
        });
      }
      return {
        ...state,
        kbTotalPages: undefined,
        kbTotalDocs: state.kbTotalDocs + 1,
        kbDocuments: newUploadDocumentsArr,
        filterCategories: newFilterCategoriesArr,
      };
    }
    case KB_ACTION.DOCUMENT_DELETED: {
      let newDeleteDocsArr = state.kbDocuments;
      const newDeleteFilterCategoriesArr: IKBSearchState['kbDocuments'] = state.filterCategories;

      if (Array.isArray(newDeleteFilterCategoriesArr)) {
        newDeleteFilterCategoriesArr.forEach((category: any) => {
          if (category.category_name === action.payload.categoryName) {
            // Note: action.payload.categoryName can be category or subcategory
            // depending on where the user is deleting from
            category.count = category.count - 1;
          }
        });
      }
      if (Array.isArray(newDeleteDocsArr)) {
        newDeleteDocsArr = newDeleteDocsArr.filter((doc) => doc._id !== action.payload.id);
      }
      return {
        ...state,
        kbTotalPages: undefined,
        kbTotalDocs: state.kbTotalDocs - 1,
        kbDocuments: newDeleteDocsArr,
        filterCategories: newDeleteFilterCategoriesArr,
      };
    }
    default:
      return { ...state };
  }
};
