import './App/assets/css/global.css';
// import i18n for translation
import './i18n';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { apolloMaterialUiThemeLight as apolloMaterialUiTheme } from '@uipath/apollo-material-ui-theme';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from './App/contexts/auth';
import App from './App/index';
import { reactPlugin } from './App/lib/AppInsights/AppInsights';
import { isBrowserSupported } from './App/lib/browser.utils';
import {
  isDevelopment,
  isLocalhost,
} from './App/lib/env.utils';
import { getStringEnv } from './App/lib/index.util';
import BrowserNotSupported from './App/pages/BrowserNotSupported/BrowserNotSupported';
import { StoreProvider } from './App/store';
import {
  getCloudPrefixedPath,
  isCloudEnv,
} from './App/utils/cloud';

const MainApp = () => {
  // Return the browser not supported page
  if (!isBrowserSupported()) {
    return (
      <MuiThemeProvider theme={apolloMaterialUiTheme}>
        <ThemeProvider theme={apolloMaterialUiTheme}>
          <BrowserNotSupported />
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }

  // TEMPORARILY BLOCK CLOUD ACCESS, UNLESS LOCALHOST OR DEVELOPMENT
  // Remove this once we have implemented the move to Cloud
  if (!(isLocalhost() || isDevelopment()) && isCloudEnv()) {
    const classicUrl = getStringEnv(process.env.PUBLIC_URL);
    console.log(`Cloud mode disabled, redirecting to ${getStringEnv(process.env.PUBLIC_URL)}`);
    window.location.replace(classicUrl);
    return <></>;
  }

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <BrowserRouter basename={isCloudEnv() ? getCloudPrefixedPath() : undefined}>
        <AuthProvider>
          <StoreProvider>
            <MuiThemeProvider theme={apolloMaterialUiTheme}>
              <ThemeProvider theme={apolloMaterialUiTheme}>
                <App />
              </ThemeProvider>
            </MuiThemeProvider>
          </StoreProvider>
        </AuthProvider>
      </BrowserRouter>
    </AppInsightsContext.Provider>
  );
};

render(<MainApp />, document.getElementById('root'));
