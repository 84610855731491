import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from '../../../assets/css/Support/Case';
import type { DropDownItemData } from '../../../interfaces/sfdc.interface';
import type {
  CasePageProps,
  NewIFormDataKeys,
} from '../../../interfaces/support.interface';
import Modal from '../../Modal';
import SelectWithSearch from '../../Select-With-Search';
import TextLabel from '../../TextLabel';

const CreateCasePage = (props: CasePageProps) => {
  const { t } = useTranslation('common');
  const {
    formData,
    setFormData,
    showEmailDomainValidationModal,
    setShowEmailDomainValidationModal,
  } = props;
  const baseClass = 'SupportCaseBody';

  const handleSelectOptionChange = async (
    option: DropDownItemData,
    inputType:
    | 'country'
    | 'timezone'
  ) => {
    const newFormData = { ...formData };
    newFormData[inputType] = option;
    delete newFormData.errors[inputType];
    setFormData(newFormData);
  };

  const handleInputChange = async (event: any, inputType: NewIFormDataKeys) => {
    const value: string = event.target.value;
    const newFormData = { ...formData };
    newFormData[inputType] = value;
    delete newFormData.errors[inputType];
    if (inputType === 'phoneNumber') {
      if (/^[0-9 ]+$/.test(value) || value.length === 0) {
        setFormData(newFormData);
      }
    } else if (inputType === 'contactEmail') {
      if (value.length === value.trim().length) {
        setFormData(newFormData);
      }
    } else {
      setFormData(newFormData);
    }
  };

  return (
    <styles.SupportCaseBody data-testid='create-case-page'>
      <div className="NewSupportCaseBody">
        <h6 className={`${baseClass}__Case-Name`}>
          {t(
            'support_form_user_details_title',
            'Primary contact user details'
          )}
        </h6>
        <div className={`${baseClass}__Case-Fields`}>
          <FormControl className={`${baseClass}__Case-Field`}>
            <TextLabel label={t('support_form_name_label', 'Name')} />
            <TextField
              variant="outlined"
              value={formData.contactName}
              onChange={e => {
                handleInputChange(e, 'contactName');
              }}
              error={false}
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{
                maxLength: 100,
                'data-testid': 'contactName-input',
              }}
              className={`${baseClass}__Case-Field--Textfield`}
            />
            {formData.errors?.contactName && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.contactName}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>

          <FormControl className={`${baseClass}__Case-Field`}>
            <TextLabel label={t('support_form_email_label', 'Email')} />
            <TextField
              variant="outlined"
              value={formData.contactEmail}
              onChange={e => {
                handleInputChange(e, 'contactEmail');
              }}
              error={false}
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{
                maxLength: 100,
                'data-testid': 'contactEmail-input',
              }}
              className={`${baseClass}__Case-Field--Textfield`}
            />
            {formData.errors?.contactEmail && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.contactEmail}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>

          <FormControl
            className={`${baseClass}__Case-Field`}
          >
            <SelectWithSearch
              label={t('support_form_country_label')}
              searchable
              className="Custom-Select--White country-input"
              options={props.countries}
              onChange={(o: DropDownItemData) => {
                handleSelectOptionChange(o, 'country');
              }}
              value={formData.country}
              data-testid="country-input"
            />
            {formData.errors?.country && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.country}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>

          <FormControl className={`${baseClass}__Case-Field`}>
            <TextLabel label={t('support_form_phone_label')} />
            <TextField
              variant="outlined"
              value={formData.phoneNumber}
              onChange={e => {
                handleInputChange(e, 'phoneNumber');
              }}
              error={false}
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{
                maxLength: 20,
                'data-testid': 'phoneNumber-input',
              }}
              className={`${baseClass}__Case-Field--Textfield`}
            />
            {formData.errors?.phoneNumber && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.phoneNumber}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>

          <FormControl
            className={`${baseClass}__Case-Field`}
          >
            <SelectWithSearch
              label={t('support_form_time_zone_label')}
              searchable
              className="Custom-Select--White timezone-input"
              options={props.timezones}
              onChange={(o: DropDownItemData) => {
                handleSelectOptionChange(o, 'timezone');
              }}
              value={formData.timezone}
              data-testid="timezone-input"
            />
            {formData.errors?.timezone && (
              <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                {formData.errors.timezone}
              </styles.SupportCaseValidationErrorMessage>
            )}
          </FormControl>

          <br />
          <br />
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                'support_form_legal_acknowledgement',
                'I acknowledge that when submitting this request, I refrain from sending any protected health information, personal data, or sensitive categories of personal data as defined by the General Data Protection Regulation (GDPR) or by the Health Insurance Portability and Accountability Act (HIPAA). Please read more <a target="_blank" href="https://www.uipath.com/legal/trust-and-security/privacy-policy">here</a>.'
              ),
            }}
          />
          <br />
          <br />
        </div>
        {showEmailDomainValidationModal && (
          <Modal
            modalTitle={t('support_form_guest_user_email_validation_error_title')}
            modalDescription=''
            modalHeading={t('support_form_guest_user_email_validation_error_title')}
            innerClasses=''
            handleClose={() => {
              setShowEmailDomainValidationModal(false);
            }}
            open={showEmailDomainValidationModal}
          >
            <styles.EmailDomainValidationModalContainer>
              {t('support_form_guest_user_email_validation_error_body')}
              <br />
              <br />
              <br />
              <div className='EmailDomainValidationModalContainer__Buttons'>
                <Button
                  variant="contained"
                  disableElevation
                  color="secondary"
                  onClick={() => {
                    setShowEmailDomainValidationModal(false);
                  }}
                >
                  {t('support_form_guest_user_email_validation_error_close_btn')}
                </Button>
              </div>
            </styles.EmailDomainValidationModalContainer>
          </Modal>
        )}
      </div>
    </styles.SupportCaseBody>
  );
};

export default CreateCasePage;
