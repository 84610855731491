import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import Prism from 'react-syntax-highlighter';
import { defaultStyle } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import rehypeRaw from 'rehype-raw';
import remarkGFM from 'remark-gfm';

type Props = {
  children: string;
};

const MarkdownComponent = (props: Props) => (
  <ReactMarkdown
    className="markdown-body"
    children={props.children}
    remarkPlugins={[ remarkGFM ]}
    rehypePlugins={[ rehypeRaw ]}
    components={{
      code({
        node, inline, className = '', children, ...props
      }) {
        const match = /language-(\w+)/.exec(className || '');
        return !inline ? (
          <Prism
            {...props}
            children={String(children).replace(/\n$/, '')}
            style={defaultStyle}
            language={match?.[1] ?? 'markdown'}
          />
        ) : (
          <code
            {...props}
            className={className + ' inline-code-block'}>
            {children}
          </code>
        );
      },
    }}
  />
);

export default MarkdownComponent;
