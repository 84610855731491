import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AppLoader from '../../components/AppLoader';
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import { AuthScreenHint } from '../../constants/auth.constants';
import { CP_POST_AUTH_REDIRECT_URL_KEY } from '../../constants/state.constants';
import {
  LOGIN,
  REGISTER,
} from '../../constants/telemetry.constants';
import { useAuth0 } from '../../contexts/auth/auth0';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import { getUrlWithAllParams } from '../../utils/cloud';

interface Props {
  children?: ReactNode;
}

const Auth0AuthGuard = ({ children }: Props) => {
  const location = useLocation();
  const {
    isAuthenticated, isLoading, loginWithRedirect,
  } = useAuth0();

  const isUserAccessingInviteLink: boolean = ((location?.pathname ?? '') as string).startsWith('/register');
  const screenHint = isUserAccessingInviteLink ? AuthScreenHint.SignUp : AuthScreenHint.SignIn;

  const shouldLoginOrRegister: boolean = !isLoading && !isAuthenticated && !!loginWithRedirect;

  // Skip tracking the page view if the user is already authenticated
  // (when we don't meet the criteria to login or register)
  useTrackPageViewEvent(
    screenHint === AuthScreenHint.SignUp ? REGISTER : LOGIN,
    !shouldLoginOrRegister
  );

  useEffect(() => {
    const fn = async () => {
      if (shouldLoginOrRegister && loginWithRedirect) {
        const email: string = location.search?.startsWith('?email=') ? location.search.split('=')[1] : '';
        const fullURL = getUrlWithAllParams(location as unknown as Location);

        const loginHint = screenHint === AuthScreenHint.SignUp ? email : '';
        const targetUrl = screenHint === AuthScreenHint.SignUp ? '/' : fullURL;

        CustomerPortalGoogleAnalyticsPageView(screenHint === AuthScreenHint.SignUp ? 'Register' : 'Login');
        localStorage.setItem(CP_POST_AUTH_REDIRECT_URL_KEY, fullURL);

        await loginWithRedirect({
          authorizationParams: {
            screen_hint: screenHint,
            login_hint: loginHint,
            type: screenHint,
          },
          appState: { targetUrl },
        });
      }
    };

    fn();
  }, [ shouldLoginOrRegister, loginWithRedirect, location, screenHint ]);

  if (isLoading || !isAuthenticated) {
    return <AppLoader />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default Auth0AuthGuard;
