import {
  ModuleNames,
  ModulesMapping,
} from './contentful.constants';

const wrapperImage = (data: any) => ({
  altText: data?.fields?.altText,
  image: { file: { url: data?.fields?.image?.fields?.file?.url } },
  __typename: ModuleNames.WRAPPER_ASSET,
});

// ---------------------------------------

const getComponentType = (component: any) => ModulesMapping[component?.sys?.contentType?.sys?.id];

const ctaData = (data: any) => {
  const contentType = getComponentType(data);
  switch (contentType) {
    case ModuleNames.TOPIC_CTA:
      return formatTopicCTAData(data);
    case ModuleNames.TOPIC_INTERNAL_LINK:
      return formatTopicInternalLinkData(data);
    default:
      return null;
  }
};

const formatTopicInternalLinkData = (data: any) => ({
  ctaText: data?.fields?.ctaText,
  internalLink: data?.fields?.internalLink?.fields?.slug
    ? {
      slug: data.fields.internalLink.fields.slug,
      pageName: data.fields.internalLink.fields?.pageName || null,
      name: data.fields.internalLink.fields?.name || null,
    }
    : null,
  __typename: ModuleNames.TOPIC_INTERNAL_LINK,
});

const formatMainNavBannerData = (data: any) => ({
  __typename: ModuleNames.MAIN_NAV_BANNER,
  entryLocale: data?.fields?.entryLocale,
  useCase: data?.fields?.useCase,
  eyebrow: data?.fields?.eyebrow,
  title: data?.fields?.title,
  subtitle: data?.fields?.subtitle,
  description: data?.fields?.description,
  textAlignment: data?.fields?.textAlignment,
  icon: data?.fields?.icon ? wrapperImage(data.fields.icon) : null,
  image: data?.fields?.image ? wrapperImage(data.fields.image) : null,
  theme: data?.fields?.theme,
  cta: data?.fields?.cta ? ctaData(data.fields.cta) : null,
  displayCta: data?.fields?.displayCta,
  publishDate: data?.fields?.publishDate,
  unpublishDate: data?.fields?.unpublishDate,
  node_locale: data?.sys?.locale,
});

const formatMenuGroupData = (menuGroupData: any) => ({
  groupName: menuGroupData?.fields?.groupName,
  name: menuGroupData?.fields?.name,
  newGroupName: menuGroupData?.fields?.newGroupName,
  menuGroupDescription: menuGroupData?.fields?.menuGroupDescription,
  groupNameColor: menuGroupData?.fields?.groupNameColor,
  groupStyle: menuGroupData?.fields?.groupStyle,
  greyColumns: menuGroupData?.fields?.greyColumns,
  greyColumnAlignment: menuGroupData?.fields?.greyColumnAlignment,
  pages: menuGroupData?.fields?.pages?.map((page: any) => {
    const contentType = page?.sys?.contentType?.sys?.id;
    if (contentType === 'topicCta') {
      return formatTopicCTAData(page);
    } else if (contentType === 'menuGroup') {
      return formatMenuGroupData(page);
    } else if (contentType === 'topicIconLink') {
      return formatIconLinkData(page);
    } else if (contentType === 'componentMainNavBanner') {
      return formatMainNavBannerData(page);
    }
    return null;

  }),
  parentLink:
    menuGroupData?.fields?.parentLink &&
      menuGroupData.fields.parentLink?.sys?.contentType?.sys?.id === 'topicCta'
      ? formatTopicCTAData(menuGroupData.fields.parentLink)
      : null,
  highlightedLinks: menuGroupData?.fields?.highlightedLinks?.length
    ? menuGroupData.fields.highlightedLinks.map(formatTopicCTAData)
    : null,
  __typename: ModuleNames.MENU_GROUP,
});

const formatTopicCTAData = (data: any) => ({
  name: data?.fields?.name,
  externalUrl: data?.fields?.externalUrl,
  internalLink: data?.fields?.internalLink
    ? {
      slug: data?.fields?.internalLink?.fields?.slug,
      __typename:
        ModulesMapping[data?.internalLink?.sys?.contentType?.sys?.id],
      blogCategory: {
        seoMetadata: {
          pageCanonicalUrl:
            data?.fields?.internalLink?.fields?.blogCategory?.fields
              ?.seoMetadata?.fields?.pageCanonicalUrl,
        },
      },
    }
    : null,
  text: data?.fields?.text,
  description: data?.fields?.description,
  __typename: ModuleNames.TOPIC_CTA,
});

const formatComponentMicrocopySetData = (data: any) => ({
  key: data?.fields?.key,
  values: data?.fields?.values?.length
    ? data.fields.values.map((value: any) => ({
      key: value?.fields?.key,
      value: value?.fields?.value,
    }))
    : [],
});

const formatMenuNavData = (menuNavData: any) => ({
  name: menuNavData?.fields?.name,
  menuGroups: menuNavData?.fields?.menuGroups?.length
    ? menuNavData?.fields?.menuGroups?.map((menuGroup: any) => {
      if (menuGroup?.sys?.contentType?.sys?.id === 'menuGroup') {
        return formatMenuGroupData(menuGroup);
      } else if (menuGroup?.sys?.contentType?.sys?.id === 'topicCta') {
        return formatTopicCTAData(menuGroup);
      }
      return null;

    })
    : null,
});

const formatIconLinkData = (data: any) => ({
  __typename: ModuleNames.TOPIC_ICON_LINK,
  name: data?.fields?.name ? data.fields.name : null,
  ctaText: data?.fields?.ctaText ? data.fields.ctaText : null,
  url: data?.fields?.url ? data.fields.url : null,
  icon: data?.fields?.icon ? wrapperImage(data.fields.icon) : null,
  description: data?.fields?.description ? data?.fields?.description : null,
});

// High level function
export const formatFooterData = (footerData: any) => ({
  entryLocale: footerData?.fields?.entryLocale,
  location: footerData?.fields?.location,
  legalFooterOnly: footerData?.fields?.legalFooterOnly?.length
    ? footerData.fields.legalFooterOnly.map((item: any) =>
      formatTopicCTAData(item)
    )
    : null,
  node_locale: footerData?.sys?.locale,
  topHatLinks: footerData?.fields?.topHatLinks?.length
    ? footerData.fields.topHatLinks.map((topHatLink: any) => topHatLink.__typename === ModuleNames.TOPIC_CTA
      ? formatTopicCTAData(topHatLink)
      : formatIconLinkData(topHatLink))
    : null,
  socialMediaFooterOnly: footerData?.fields?.socialMediaFooterOnly?.length
    ? [
      formatComponentMicrocopySetData(
        footerData.fields.socialMediaFooterOnly[0]
      ),
    ]
    : null,
  ...formatMenuNavData(footerData),
});
