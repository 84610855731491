import styled from 'styled-components';

import { CPTableContainer } from '../Table';

// add top margin to the table
export const AuditHistoryTable = styled(CPTableContainer)`
  margin-top: ${props => props.theme.spacing(3)}px;
  max-height: 400px;
  overflow-y: auto;
`;
