import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ISubmitButtonsContainer {
  disabled: boolean;
  handleFormSubmit: Function;
  handleCancelButtonClick: Function;
}

const SubmitButtonsContainer = (props: ISubmitButtonsContainer) => {
  const { t } = useTranslation('common');

  return (
    <div className="AddHistoryOrder__SubmitContainer">
      <Button
        disabled={props.disabled}
        variant="contained"
        disableElevation
        color="secondary"
        onClick={e => {
          props.handleFormSubmit();
        }}
        data-testid="submit-button"
      >
        {t('support_form_button_submit', 'Submit')}
      </Button>
      <Button
        variant="outlined"
        disableElevation
        onClick={e => {
          props.handleCancelButtonClick();
        }}
        data-testid="cancel-button"
      >
        {t('support_form_button_cancel', 'Cancel')}
      </Button>
    </div>
  );
};

export default SubmitButtonsContainer;
