import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  Radio,
  Switch,
} from '@mui/material';
import type { DesktopDatePickerSlotsComponent } from '@mui/x-date-pickers';
import {
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import type { UncapitalizeObjectKeys } from '@mui/x-date-pickers/internals';
import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import type {
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import {
  DragDropContext,
  Droppable,
} from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import * as styled from '../../assets/css/Admin/HAPOManagementEntitlement';
import * as wrapperStyled from '../../assets/css/CustomerPortalContentWrapper';
import AddIcon from '../../assets/img/svg/action_icons/Plus.svg';
import {
  axiosGet,
  axiosPatch,
} from '../../client/axios';
import AddNewHapoGroupModal from '../../components/admin/hapoEntitlements/Hapo-AddNewGroupModal';
import type { addHapoLicenseType } from '../../components/admin/hapoEntitlements/Hapo-AddNewLicenseModal';
import AddNewHapoLicenseModal from '../../components/admin/hapoEntitlements/Hapo-AddNewLicenseModal';
import AuditHistoryListModal from '../../components/admin/hapoEntitlements/Hapo-AuditHistoryListModal';
import HapoGroupLicenseRow from '../../components/admin/hapoEntitlements/Hapo-GroupLicenseRow';
import HapoIndividualLicenseRow from '../../components/admin/hapoEntitlements/Hapo-IndividualLicenseRow';
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
import ConfirmationModal from '../../components/ConfirmationModal';
import Container from '../../components/CustomerPortal-New-Container';
import TextLabel from '../../components/TextLabel';
import {
  DEAL_TYPE_ELA,
  DEAL_TYPE_HAPO,
  DEAL_TYPE_UTO,
} from '../../constants/hapo.constants';
import {
  BACKEND_HOST_NAME,
  HAPO_LICENSES_URL,
} from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
import { hapoEntitlementsReducer } from '../../reducers/hapo_entitlements_reducer';
import { StoreContext } from '../../store';

const CustomerPortalPageHAPOManagement = (_props: any) => {
  const { search } = useLocation();
  const history = useHistory();
  const { dispatch } = useContext(StoreContext);
  const { getAccessToken } = useAuth();

  const query = React.useMemo(() => new URLSearchParams(search), [ search ]);
  enum ConfirmationActionType {
    disableHapoSync,
    enableHapoSync,
    disableUtoSync,
    enableUtoSync,
    submit,
    reset,
    none,
  }

  const emptyConfig = {
    summed_config: {
      source_contracts: [],
      source_configs: [],
      effective_start_date_str: moment().toISOString(),
      end_date_str: moment().toISOString(),
      config: {
        individual_skus: {},
        groups: {},
      },
    },
    deal_type: DEAL_TYPE_ELA,
    user: { name: 'Customer Portal' },
    contracts: [],
  };

  const [ accountInfo, setAccountInfo ] = useState<{
    id: string;
    name: string;
    utoEnabled: boolean;
    utoSyncEnabled: boolean;
    hapoEnabled: boolean;
    hapoSyncEnabled: boolean;
  }>({
    id: query.get('company') ?? '',
    name: '',
    utoEnabled: false,
    utoSyncEnabled: false,
    hapoEnabled: false,
    hapoSyncEnabled: false,
  });

  useEffect(() => {
    setDealType(
      accountInfo.utoEnabled ? DEAL_TYPE_UTO : DEAL_TYPE_ELA
    );
  }, [ accountInfo ]);

  const [ hapoConfig, dispatchHapoConfigChanges ] = useReducer(
    hapoEntitlementsReducer,
    emptyConfig.summed_config.config
  );

  const [ openAuditHistory, setOpenAuditHistory ] = useState<boolean>(false);
  const [ canSubmit, setCanSubmit ] = useState<boolean>(false);
  const [ lastUpdatedBy, setLastUpdatedBy ] = useState<any>('Customer Portal');
  const [ originalConfig, setOriginalConfig ] = useState<any>(emptyConfig);
  const [ hapoDates, setHapoDates ] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().toString(),
    endDate: moment().toString(),
  });
  const [ dealType, setDealType ] = useState('ela');

  const [ currentSkus, setCurrentSkus ] = useState<{
    [key: string]: { consumedQuantity: number; groupName?: string };
  }>({});
  const [ allAvailableSkus, setallAvailableSkus ] = useState<{
    [key: string]: { consumedQuantity: number; groupName?: string };
  }>({});
  const [ openConfirmationModal, setOpenConfirmationModal ] = useState<
  ConfirmationActionType
  >(ConfirmationActionType.none);
  const [ openAddNewLicense, setOpenAddNewLicense ] = useState<{
    open: boolean;
    groupName?: string;
  }>({ open: false });

  const [ openAddNewGroup, setOpenAddNewGroup ] = useState<{
    open: boolean;
    groupName?: string;
  }>({ open: false });
  const [ utoContracts, setUtoContracts ] = useState<string[]>(
    emptyConfig.contracts
  );
  const [ currentUtoContract, setCurrentUtoContract ] = useState<string>('');

  useEffect(() => {
    const getAccountInfo = async () => {
      // Fetch CompanyData
      const account: any = await axiosGet(
        `${BACKEND_HOST_NAME}/api/v1/hapo/status`,
        query.get('company') ?? '',
        await getAccessToken()
      );

      const hapoResult = await axiosGet(
        `${HAPO_LICENSES_URL}`,
        query.get('company') ?? '',
        await getAccessToken(),
        { params: { get_contracts: true } }
      );

      if (account.status === 200) {
        setAccountInfo({
          ...accountInfo,
          name: account.data.name,
          utoEnabled: account.data.uto_feature_enabled,
          utoSyncEnabled: account.data.uto_sync_enabled,
          hapoSyncEnabled: account.data.hapo_sync_enabled,
          hapoEnabled: account.data.hapo_feature_enabled,
        });
      }
      if (hapoResult.status === 200 && !_.isEmpty(hapoResult.data)) {
        updateConfig(hapoResult);
      }
    };
    getAccountInfo();
  }, []);

  // Update config after we fetch data from BE
  const updateConfig = (hapoRes: any) => {
    const config = hapoRes?.data ? hapoRes.data : emptyConfig;
    const summed_config = config.summed_config;
    setOriginalConfig(cloneDeep(config));
    dispatchHapoConfigChanges({
      type: 'FETCHED',
      payload: cloneDeep(summed_config.config),
    });

    if (
      config.deal_type === DEAL_TYPE_UTO &&
      Array.isArray(summed_config.source_contracts) &&
      summed_config.source_contracts.length > 0
    ) {
      setCurrentUtoContract(summed_config.source_contracts[0]);
    }

    if (
      [ DEAL_TYPE_ELA, DEAL_TYPE_HAPO, DEAL_TYPE_UTO ].includes(
        config?.deal_type ?? ''
      )
    ) {
      setDealType(config.deal_type);
    }
    setUtoContracts(config.contracts ?? []);
    setHapoDates({
      startDate:
        config.summed_config.effective_start_date ??
        emptyConfig.summed_config.effective_start_date_str,
      endDate:
        config.summed_config.end_date ?? emptyConfig.summed_config.end_date_str,
    });
    const allSkus = getSkusFromConfig(summed_config.config);
    setallAvailableSkus(allSkus);
    setLastUpdatedBy(config.last_updated_by ?? 'Customer Portal');
  };

  useEffect(() => {
    const allSkus = getSkusFromConfig();
    setCurrentSkus(allSkus);
  }, [ hapoConfig ]);

  const getSkusFromConfig = (config: any = hapoConfig) => {
    // flatten the object and get all the skus in the config
    const groups = config?.groups ?? {};
    const individualSkus = config?.individual_skus ?? {};
    const groupNames = Object.keys(groups);
    const allSkus: {
      [key: string]: { consumedQuantity: number; groupName?: string };
    } = {};
    const skus = Object.keys(individualSkus ?? {});
    skus.forEach((sku: string) => {
      allSkus[sku] = { consumedQuantity: individualSkus[sku].consumed_quantity };
    });

    groupNames.forEach((groupName: string) => {
      groups[groupName]?.skus.forEach((groupedSku: any) => {
        allSkus[groupedSku.product_code] = {
          consumedQuantity: groupedSku.consumed_quantity,
          groupName,
        };
      });
    });
    return allSkus;
  };

  const getConfirmationMessage = () => {
    let message = '';
    switch (openConfirmationModal) {
      case ConfirmationActionType.enableUtoSync:
      case ConfirmationActionType.enableHapoSync:
        message =
          'Re-enabling Sync of salesforce will remove all manually entered groups and SKUs.';
        break;
      case ConfirmationActionType.disableUtoSync:
      case ConfirmationActionType.disableHapoSync:
        message =
          'Disabling Sync with Salesforce will no longer pull new entitlements from salesforce for this account.';
        break;
      case ConfirmationActionType.submit:
        message = 'Are you sure you want to submit the changes?';
        break;
      case ConfirmationActionType.reset:
        message = 'Are you sure you want to cancel your changes and exit this page?';
        break;
    }
    return message;
  };

  const toggleUtoEnablement = async (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {

    if (checked) {
      const contractRes = await axiosGet(
        `${HAPO_LICENSES_URL}/getUTOContracts`,
        query.get('company') ?? '',
        await getAccessToken()
      );
      if (contractRes.status === 200) {
        setUtoContracts(contractRes.data);
        await updateSyncStatus(
          checked,
          currentUtoContract !== '',
          false,
          false
        );
      } else {
        showError('Failed to fetch UTO contracts');
      }
    } else {
      await updateSyncStatus(
        checked,
        checked,
        false,
        false,
      );
      setUtoContracts([]);
    }
  };

  const contractSelected = async (contract: string) => {
    const config = {
      params: {
        deal_type: 'uto',
        source_contract: contract,
        get_contracts: true,
      },
    };
    await updateSyncStatus(
      true,
      true,
      accountInfo.hapoEnabled,
      accountInfo.hapoSyncEnabled
    );
    await fetchConfigData(config);
  };

  const toggleHapoEnablement = async (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    await updateSyncStatus(
      false,
      false,
      checked,
      checked
    );
    if (checked) {
      const config = {
        params: {
          deal_type: 'ela/hapo',
          get_contracts: false,
        },
      };
      await fetchConfigData(config);
    }
  };

  const fetchConfigData = async (config: { [key: string]: any }) => {
    const hapoResult = await axiosGet(
      `${HAPO_LICENSES_URL}/hapoDealsForAccount`,
      query.get('company') ?? '',
      await getAccessToken(),
      config
    );

    if (hapoResult.status === 200) {
      updateConfig(hapoResult);
    }
  };

  const updateSyncStatus = async (
    uto_feature_enabled: boolean,
    uto_sync_enabled: boolean,
    hapo_feature_enabled: boolean,
    hapo_sync_enabled: boolean
  ) => {
    const requestBody = {
      updateObject: {
        uto_feature_enabled,
        uto_sync_enabled,
        hapo_feature_enabled,
        hapo_sync_enabled,
      },
    };

    try {
      const res = await axiosPatch(
        `${BACKEND_HOST_NAME}/api/v1/companies`,
        accountInfo.id,
        await getAccessToken(),
        requestBody
      );
      // if successfully updated
      if (res.status === 201) {
        setAccountInfo({
          ...accountInfo,
          hapoEnabled: hapo_feature_enabled,
          hapoSyncEnabled: hapo_sync_enabled,
          utoEnabled: uto_feature_enabled,
          utoSyncEnabled: uto_sync_enabled,
        });
      }
    } catch (e) {
      showError(`Something went wrong ${e}`);
    }
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(ConfirmationActionType.none);
  };

  const handleConfirmModal = async () => {
    switch (openConfirmationModal) {
      case ConfirmationActionType.enableHapoSync:
        await toggleHapoSync(true);
        break;
      case ConfirmationActionType.disableHapoSync:
        await toggleHapoSync(false);
        break;
      case ConfirmationActionType.enableUtoSync:
        await toggleUtoSync(true);
        break;
      case ConfirmationActionType.disableUtoSync:
        await toggleUtoSync(false);
        break;
      case ConfirmationActionType.submit:
        handleFormSubmit();
        break;
      case ConfirmationActionType.reset:
        handleReset();
        break;
    }
    setOpenConfirmationModal(ConfirmationActionType.none);
  };

  const toggleHapoSync = async (checked: boolean) => {
    if (openConfirmationModal === ConfirmationActionType.none) {
      const actionType = checked
        ? ConfirmationActionType.enableHapoSync
        : ConfirmationActionType.disableHapoSync;
      setOpenConfirmationModal(actionType);
      return;
    }
    await updateSyncStatus(
      accountInfo.utoEnabled,
      accountInfo.utoSyncEnabled,
      accountInfo.hapoEnabled,
      checked
    );
  };

  const toggleUtoSync = async (checked: boolean) => {
    if (openConfirmationModal === ConfirmationActionType.none) {
      const actionType = checked
        ? ConfirmationActionType.enableUtoSync
        : ConfirmationActionType.disableUtoSync;
      setOpenConfirmationModal(actionType);
      return;
    }
    await updateSyncStatus(
      accountInfo.utoEnabled,
      checked,
      accountInfo.hapoEnabled,
      accountInfo.hapoSyncEnabled
    );
  };

  const getForm = () => (
    <styled.FormWrapper>
      <div>
        <FormControl>
          <InputLabel htmlFor="hapo-enablement">Enable UTO</InputLabel>
          <Switch
            checked={accountInfo.utoEnabled}
            onChange={toggleUtoEnablement}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={accountInfo.hapoEnabled}
            data-testid="uto-enablement-switch"
          />
          <FormHelperText>
              When this switch is on, UTO is enabled for the account
          </FormHelperText>
        </FormControl>
        <br />
        <br />
        {accountInfo.utoEnabled && utoContracts.length > 0 && (
          <FormControl>
            <InputLabel htmlFor="select-uto-contract">
                Select UTO Contract
            </InputLabel>
            {utoContracts.map((contract: string) => (
              <FormControlLabel
                className="Contract-Radio-Buttons"
                value={contract}
                control={
                  <Radio
                    onChange={() => {
                      contractSelected(contract);
                    }}
                    checked={currentUtoContract === contract}
                  />
                }
                label={contract}
              />
            ))}
            <FormHelperText>
                Currently we do not support Multiple UTO Contracts.
            </FormHelperText>
          </FormControl>
        )}
        <br />
        <br />
        <FormControl>
          <InputLabel htmlFor="hapo-sync-enablement">
              Sync UTO with Salesforce
          </InputLabel>
          <Switch
            checked={accountInfo.utoSyncEnabled}
            onChange={(e, checked) => {
              toggleUtoSync(checked);
            }}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={accountInfo.hapoEnabled || !accountInfo.utoEnabled}
            data-testid="uto-sync-enablement-switch"
          />
          <FormHelperText>
              When this switch is on, data will be retrieved from Salesforce
          </FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl>
          <InputLabel htmlFor="hapo-enablement">Enable HAPO/ELA</InputLabel>
          <Switch
            checked={accountInfo.hapoEnabled}
            onChange={toggleHapoEnablement}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={accountInfo.utoEnabled}
            data-testid="hapo-enablement-switch"
          />
          <FormHelperText>
              When this switch is on, HAPO is enabled for the account
          </FormHelperText>
        </FormControl>
        <br />
        <br />
        <br />
        <br />
        <FormControl>
          <InputLabel htmlFor="hapo-sync-enablement">
              Sync HAPO/ELA with Salesforce
          </InputLabel>
          <Switch
            checked={accountInfo.hapoSyncEnabled}
            onChange={(e, checked) => {
              toggleHapoSync(checked);
            }}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={accountInfo.utoEnabled || !accountInfo.hapoEnabled}
            data-testid="hapo-sync-enablement-switch"
          />
          <FormHelperText>
              When this switch is on, data will be retrieved from Salesforce
          </FormHelperText>
        </FormControl>
      </div>
    </styled.FormWrapper>
  );

  const getGroupLicenses = () => {
    const keys = Object.keys(hapoConfig.groups);

    return keys.map((key: string, index: number) => {
      const group = hapoConfig.groups[key];
      return (
        <HapoGroupLicenseRow
          key={key}
          index={index}
          name={key}
          max_allowed_quantity={group.max_allowed_quantity}
          consumed_quantity={group.consumed_quantity}
          available_quantity={group.available_quantity}
          childLicenses={group.skus ?? []}
          handleDeleteClick={handleOnDeleteGroupLicense}
          handleAddChildLicenseClick={(groupName: string) => {
            setOpenAddNewLicense({
              open: true,
              groupName,
            });
          }}
          handleEditGroupNameClick={(groupName: string) => {
            setOpenAddNewGroup({
              open: true,
              groupName,
            });
          }}
          handleDeleteChildLicenseClick={handleOnDeleteGroupChildLicense}
          handleMaxQuantityChange={handleOnGroupMaxQuantityChange}
        />
      );
    });
  };

  const getIndividualLicenses = () => {
    const keys = Object.keys(hapoConfig.individual_skus);

    return keys.map((key: string, index: number) => {
      const individualLicense = hapoConfig.individual_skus[key];
      return (
        <HapoIndividualLicenseRow
          key={key}
          index={index}
          productCode={key}
          name={individualLicense.display_name}
          max_allowed_quantity={individualLicense.max_allowed_quantity}
          consumed_quantity={individualLicense.consumed_quantity}
          available_quantity={individualLicense.available_quantity}
          handleDeleteClick={handleOnDeleteIndividualLicense}
          handleMaxQuantityChange={handleOnIndividualLicenseMaxQuantityChange}
        />
      );
    });
  };

  const handleReset = () => {
    if (openConfirmationModal === ConfirmationActionType.none) {
      setOpenConfirmationModal(ConfirmationActionType.reset);
      return;
    }

    dispatchHapoConfigChanges({
      type: 'RESET',
      payload: cloneDeep(originalConfig.summed_config),
    });
    setHapoDates({
      startDate: originalConfig.effective_start_date_str,
      endDate: originalConfig.end_date_str,
    });
    return history.go(-1);
  };

  const handleOnIndividualLicenseMaxQuantityChange = (
    productCode: string,
    maxQuantity: number
  ) => {
    dispatchHapoConfigChanges({
      type: 'SKU_QUANTITY_CHANGED',
      payload: {
        productCode,
        maxQuantity,
      },
    });
  };

  const handleOnGroupMaxQuantityChange = (
    groupName: string,
    maxQuantity: number
  ) => {
    dispatchHapoConfigChanges({
      type: 'GROUP_QUANTITY_CHANGED',
      payload: {
        groupName,
        maxQuantity,
      },
    });
  };

  const handleOnDragEnd = (result: DropResult) => {
    const {
      destination, source, type,
    } = result;
    if (!destination) {
      return;
    }
    // Back to where it started
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    dispatchHapoConfigChanges({
      type: 'MOVED',
      payload: {
        destination,
        source,
        type,
      },
    });
  };

  const handleOnCloseAddLicenseModal = () => {
    setOpenAddNewLicense({ open: false });
  };

  const handleOnLicenseAdd = (
    license: addHapoLicenseType,
    groupName?: string
  ) => {
    // Check if already added and throw an error if it is
    if (currentSkus[license.product_code]) {
      // throw an error
      const errorMessage = currentSkus[license.product_code].groupName
        ? `This SKU is already added under group: ${
          currentSkus[license.product_code].groupName
        } `
        : 'This SKU is already added as an Individual SKU';
      showError(errorMessage);
      return;
    }

    const consumedQuantity =
      allAvailableSkus[license.product_code]?.consumedQuantity ?? 0;

    if (groupName) {
      dispatchHapoConfigChanges({
        type: 'SKU_ADDED_TO_GROUP',
        payload: {
          groupName,
          license,
          consumedQuantity,
        },
      });
    } else {
      dispatchHapoConfigChanges({
        type: 'SKU_ADDED',
        payload: {
          license,
          consumedQuantity,
        },
      });
    }
    handleOnCloseAddLicenseModal();
  };

  const handleOnEditGroup = (oldGroupName: string, newGroupName: string) => {
    dispatchHapoConfigChanges({
      type: 'GROUP_NAME_CHANGED',
      payload: {
        oldGroupName,
        newGroupName,
      },
    });

    handleOnCloseAddorEditGroupModal();
  };

  const handleOnGroupAdd = (groupName: string) => {
    dispatchHapoConfigChanges({
      type: 'GROUP_ADDED',
      payload: { groupName },
    });
    handleOnCloseAddorEditGroupModal();
  };

  const handleOnCloseAddorEditGroupModal = () => {
    setOpenAddNewGroup({ open: false });
  };

  const handleOnDeleteIndividualLicense = (productCode: string) => {
    dispatchHapoConfigChanges({
      type: 'INDIVIDUAL_SKU_REMOVED',
      payload: { productCode },
    });
  };

  const handleOnDeleteGroupLicense = (groupName: string) => {
    dispatchHapoConfigChanges({
      type: 'GROUP_REMOVED',
      payload: { groupName },
    });
  };

  const handleOnDeleteGroupChildLicense = (
    groupName: string,
    licenseName: string
  ) => {
    dispatchHapoConfigChanges({
      type: 'GROUP_SKU_REMOVED',
      payload: {
        groupName,
        licenseName,
      },
    });
  };

  const showError = (errorMessage: string) => {
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: errorMessage,
    });
  };

  const formHasValidQuantities = () => {
    if (_.isObject(hapoConfig.groups)) {
      const groupNames = Object.keys(hapoConfig.groups);

      // Total Group Quantity Check
      const invalidGroups = groupNames.filter(groupName => hapoConfig.groups[groupName].max_allowed_quantity <= 0);
      if (invalidGroups.length > 0) {
        showError(
          `Total quantity should be greater than 0 for Group/s: ${invalidGroups} `
        );
        return false;
      }

      // Total Group Empty Check
      const emptyGroups = groupNames.filter(groupName => hapoConfig.groups[groupName].skus?.length <= 0 ?? true);
      if (emptyGroups.length > 0) {
        showError(`Group/s: "${emptyGroups}" cannot be empty`);
        return false;
      }
    }

    if (_.isObject(hapoConfig.individual_skus)) {
      const individualSkus = Object.keys(hapoConfig.individual_skus);
      const invalidSkus = individualSkus
        .filter(sku => hapoConfig.individual_skus[sku].max_allowed_quantity <= 0)
        .map(sku => hapoConfig.individual_skus[sku].display_name);
      if (invalidSkus.length > 0) {
        showError(`Total Quantity should be greater that 0 for SKU/s: ${invalidSkus} `);
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    setCanSubmit(
      (!_.isEmpty(hapoConfig.groups) ||
        !_.isEmpty(hapoConfig.individual_skus)) &&
        !(
          _.isEqual(hapoConfig, originalConfig.summed_config.config) &&
          dealType === originalConfig.deal_type &&
          originalConfig.summed_config.end_date_str === hapoDates.endDate &&
          originalConfig.summed_config.effective_start_date_str ===
            hapoDates.startDate
        )
    );
  }, [ hapoConfig, hapoDates, dealType ]);

  const handleFormSubmit = async () => {
    // Date check
    if (moment(hapoDates.endDate).isSameOrBefore(hapoDates.startDate)) {
      showError('End Date cannot be same as or before the Start Date ');
      return;
    }

    // validity check
    if (!formHasValidQuantities()) {
      return;
    }

    if (openConfirmationModal === ConfirmationActionType.none) {
      setOpenConfirmationModal(ConfirmationActionType.submit);
      return;
    }

    // format data
    const config = cloneDeep(originalConfig);
    config.deal_type = dealType;
    config.summed_config.config = hapoConfig;
    config.summed_config.end_date_str = hapoDates.endDate;
    config.summed_config.effective_start_date_str = hapoDates.startDate;
    const body = {
      action: 'change_sync',
      sync_with_sfdc: false,
      config,
      old_config: originalConfig,
    };

    try {
      await axiosPatch(
        `${HAPO_LICENSES_URL}`,
        query.get('company') ?? '',
        await getAccessToken(),
        body
      );
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: 'Entitlements changed successfully!',
      });
    } catch (e) {
      showError('Could not submit the Entitlements ' + JSON.stringify(e));
      return;
    }
    return history.go(-1);
  };

  // disable keyboard for date picker
  const handleKeypress = (event: Event) => {
    event.preventDefault();
    return false;
  };

  const handleDateChange = (date: Date, type: string) => {
    switch (type) {
      case 'startDate':
        setHapoDates({
          ...hapoDates,
          startDate: date.toISOString(),
        });
        break;
      case 'endDate':
        setHapoDates({
          ...hapoDates,
          endDate: date.toISOString(),
        });
        break;
    }
  };

  const canDoManualEdits = () => (
    (!accountInfo.hapoSyncEnabled && accountInfo.hapoEnabled) ||
      (!accountInfo.utoSyncEnabled && accountInfo.utoEnabled)
  );

  const canShowGroups = () => canDoManualEdits() && !_.isEmpty(hapoConfig.groups);

  const canShowIndividualSkus = () => canDoManualEdits() && !_.isEmpty(hapoConfig.individual_skus);

  return (
    <>
      <Helmet>
        <title>Customer Portal | Manage HAPO/ELA/UTO Entitlement</title>
      </Helmet>

      <wrapperStyled.SectionContentWrapper>
        <BasicHero
          title={
            accountInfo.name
              ? `Change of Entitlement - ${accountInfo.name}`
              : 'Change of Entitlement'
          }
          subtitle="This form is to add or change the entitlements of a HAPO/ELA/UTO configuration. Every effort should be made to edit the Salesforce subscription record(s) before using this form. "
        />

        <Container>{getForm()}</Container>
        <ConfirmationModal
          modalHeading="Warning"
          primaryButtonText="Confirm"
          secondaryButtontext="Cancel"
          message={getConfirmationMessage()}
          open={openConfirmationModal !== ConfirmationActionType.none}
          handleConfirm={handleConfirmModal}
          handleClose={handleCloseConfirmationModal}
        />
        <AddNewHapoLicenseModal
          open={openAddNewLicense.open}
          groupName={openAddNewLicense.groupName}
          handleAddLicense={handleOnLicenseAdd}
          handleClose={handleOnCloseAddLicenseModal}
        />
        <AddNewHapoGroupModal
          open={openAddNewGroup.open}
          groupName={openAddNewGroup.groupName}
          existingGroups={Object.keys(hapoConfig?.groups ?? {})}
          handleAddGroup={handleOnGroupAdd}
          handleEditGroup={handleOnEditGroup}
          handleClose={handleOnCloseAddorEditGroupModal}
        />
        {openAuditHistory && (
          <AuditHistoryListModal
            open={openAuditHistory}
            companyId={query.get('company') ?? ''}
            handleClose={() => setOpenAuditHistory(false)}
          />
        )}

        <Container>
          {canDoManualEdits() && hapoConfig && (
            <styled.SkuForm>
              <div className="Date-Pickers-View">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {Object.entries(hapoDates).map(dateEntry => (
                    <>
                      <TextLabel
                        label={dateEntry[0] === 'startDate'
                          ? 'Start Date'
                          : 'End Date'}
                        helpText=''
                      />
                      <DesktopDatePicker
                        key={dateEntry[0]}
                        view='day'
                        views={[ 'day' ]}
                        openTo='day'
                        slots={{
                          openPickerButton: (props) => (
                            <IconButton
                              {...props}
                              data-testid={`${dateEntry[0]}Picker`} />
                          ),
                          openPickerIcon: () => (
                            <Icon className='material-icons-outlined'>
                                  calendar_today
                            </Icon>
                          ),
                        } as UncapitalizeObjectKeys<DesktopDatePickerSlotsComponent<Date>>}
                        slotProps={{
                          textField: {
                            id: 'date-picker-inline',
                            className: '',
                            InputProps: {
                              'aria-label': `change ${dateEntry[0]}`,
                              className: 'Tall',
                            },
                            'aria-label': `change ${dateEntry[0]}`,
                          },
                          field: { className: 'Date-Picker' },
                          toolbar: { hidden: true },
                        } as any}
                        format="MM/dd/yyyy"
                        className="Date-Picker"
                        value={moment(dateEntry[1]).toDate()}
                        onChange={(date: any) => {
                          handleDateChange(date, dateEntry[0]);
                        }}
                      />
                    </>
                  ))}
                </LocalizationProvider>
              </div>
              <div
                className="Add-User-Opener"
                data-testid="add-new-group-button"
                onClick={() => {
                  setOpenAddNewGroup({ open: true });
                }}
              >
                <img
                  src={AddIcon}
                  alt="Plus Icon"
                  className="Add-User-Icon"
                />
                <h6 className="Bold">Add new Group</h6>
              </div>

              {canShowGroups() && (
                <div>
                  <styled.TableHeader>
                    <p className="Small">Group Name</p>
                    <p className="Small">Total Quantity</p>
                    <p className="Small">Consumed Quantity</p>
                    <p className="Small">Available Quantity</p>
                    <span />
                  </styled.TableHeader>

                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable
                      droppableId="droppable-licenses"
                      type="GROUP">
                      {(provided: DroppableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          data-testid='droppable-area'
                        >
                          {getGroupLicenses()}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              )}

              <div
                className="Add-User-Opener"
                onClick={() => {
                  setOpenAddNewLicense({ open: true });
                }}
                data-testid="add-individual-SKU"
              >
                <img
                  src={AddIcon}
                  alt="Plus Icon"
                  className="Add-User-Icon"
                />
                <h6 className="Bold">Add new individual SKU</h6>
              </div>
              {canShowIndividualSkus() && (
                <div>
                  <styled.TableHeader>
                    <p className="Small">SKU Name</p>
                    <p className="Small">Total Quantity</p>
                    <p className="Small">Consumed Quantity</p>
                    <p className="Small">Available Quantity</p>
                    <span />
                  </styled.TableHeader>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable
                      droppableId="droppable-licenses"
                      type="INDIVIDUAL"
                    >
                      {(provided: DroppableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {getIndividualLicenses()}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              )}
              <div className="Hapo-Submit">
                <Button
                  variant="contained"
                  disableElevation
                  color="secondary"
                  disabled={!canSubmit}
                  onClick={handleFormSubmit}
                  data-testid="submit-button"
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  color="secondary"
                  onClick={() => setOpenAuditHistory(true)}
                  data-testid="history-button"
                >
                  History
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  onClick={handleReset}
                  data-testid="cancel-button"
                >
                  Cancel
                </Button>
                <p data-testid="last-updated-text">
Last Updated By:
                  {lastUpdatedBy}
                </p>
              </div>
            </styled.SkuForm>
          )}
        </Container>
      </wrapperStyled.SectionContentWrapper>
    </>
  );
};

export default CustomerPortalPageHAPOManagement;
