import { Button } from '@mui/material';
import DOMPurify from 'dompurify';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { CloudMigrationContainer } from '../../assets/css/CloudMigration';
import {
  CLOUD_DOMAIN,
  CLOUD_SERVICE_NAME,
} from '../../constants/auth.constants';
import {
  CLOUD_CONNECT_CANCEL_AND_RETURN_TO_CP,
  CLOUD_CONNECT_CONFIRM,
  CLOUD_CONNECT_FIRST_PAGE_VIEW,
  CLOUD_CONNECT_RETURN_TO_CP,
  CLOUD_CONNECT_SECOND_PAGE_VIEW,
  CLOUD_CONNECT_SIGN_OUT_AND_GO_TO_DIFFERENT_ORG,
  CLOUD_MIGRATION,
} from '../../constants/telemetry.constants';
import {
  useTrackPageViewEvent,
  useTriggerTrackEventWithStateData,
} from '../../lib/AppInsights/AppInsights';
import { StoreContext } from '../../store';
import { getCloudOrgNameFromUrl } from '../../utils/cloud';

const CustomerPortalToCloudMigration = (props: any) => {
  const { t } = useTranslation('common');
  const { state } = useContext(StoreContext);
  const [ cloudName, setCloudName ] = useState<any>('');
  const [ showErrorPage, setShowErrorPage ] = useState(false);
  const [ showConfirmationPage, setShowConfirmationPage ] = useState(false);
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();
  useTrackPageViewEvent(CLOUD_MIGRATION);
  const baseClass = 'CloudMigration';

  useEffect(() => {
    // if user is cloud admin
    setCloudName(getCloudOrgNameFromUrl());
    triggerTrackEventWithStateData(CLOUD_CONNECT_FIRST_PAGE_VIEW);
    // Else if user is NOT cloud admin, show error page
    // setShowErrorPage(true);
    // triggerTrackEventWithStateData(CLOUD_CONNECT_NOT_ALLOWED_PAGE_VIEW);
  }, []);

  const continueToNextStep = () => {
    setShowConfirmationPage(true);
    triggerTrackEventWithStateData(CLOUD_CONNECT_SECOND_PAGE_VIEW);
  };

  const onConfirm = () => {
    // complete migration to cloud
    triggerTrackEventWithStateData(CLOUD_CONNECT_CONFIRM);
  };

  const onSignOutAndGoToDifferentOrg = () => {
    triggerTrackEventWithStateData(CLOUD_CONNECT_SIGN_OUT_AND_GO_TO_DIFFERENT_ORG);
  };

  const onCancel = (fromErrorPage: boolean) => {
    triggerTrackEventWithStateData(fromErrorPage ? CLOUD_CONNECT_RETURN_TO_CP : CLOUD_CONNECT_CANCEL_AND_RETURN_TO_CP);
    // return to Customer Portal
    window.location.href = '/';
  };

  const connectToCloudPromptMessage = () => (
    <div>
      <div className={`${baseClass}__Heading-Text`}>
        {t('cloud_migration_heading_message', 'Connect your UiPath Automation Cloud Account to the Customer Portal')}
      </div>
      <div
        className={`${baseClass}__Text`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(t('cloud_migration_description_message',
            'You are trying to connect <b>{{cpAccountName}}</b> to your Cloud Organization <b>{{cloudName}}</b>.',
            {
              cpAccountName: state.companyName,
              cloudName,
            }
          )),
        }}
      />
      <br />

    </div>
  );

  return (
    <CloudMigrationContainer>
      <Helmet>
        <title>
          {t(
            'cloud_migration_page_title',
            'Cloud Migration | Customer Portal'
          )}
        </title>
      </Helmet>
      {showErrorPage ? (
        <div>
          {connectToCloudPromptMessage()}
          <div className={`${baseClass}__Text`}>
            {t('cloud_migration_description_error_message', 'This step must be completed by a Cloud Admin. As a user, you are unable to complete the process of connecting your Automation Cloud organization to the Customer Portal.')}
          </div>
          <div className={`${baseClass}__Bolded-Text`}>
            {t('cloud_migration_action_to_take', 'Action to take:')}
          </div>
          <div className={`${baseClass}__Text`}>
            <ul>
              <li>{t('cloud_migration_action_to_take_step_1', 'Add a Cloud Admin to your Customer Portal account.')}</li>
              <li>{t('cloud_migration_action_to_take_step_2', 'Request that the Cloud Admin log in to Customer Portal to connect the accounts.')}</li>
            </ul>
          </div>
          <div className={`${baseClass}__Button-Container`}>
            <Button
              variant='contained'
              color='secondary'
              className={`${baseClass}__Button`}
              onClick={onSignOutAndGoToDifferentOrg}
            >
              {t('cloud_migration_sign_out_and_go_to_different_org_button', 'Sign out and connect to a different Automation Cloud Organization')}
            </Button>
            <Button
              variant='contained'
              color='secondary'
              className={`${baseClass}__Button`}
              onClick={() => onCancel(true)}
            >
              {t('cloud_migration_return_to_customer_portal_button', 'Return to Customer Portal')}
            </Button>
          </div>
        </div>
      ) : showConfirmationPage ? (
        <div>
          <div className={`${baseClass}__Heading-Text`}>
            {t('cloud_migration_complete_the_process_message', 'Complete the process to connect your Automation Cloud to the Customer Portal')}
          </div>
          <div className={`${baseClass}__Text`}>
            {t('cloud_migration_list_of_users_message', 'The users listed below will be added to the \'Everyone\' group in Automation Cloud and receive an invite:')}
          </div>
          {/* list of users */}
          <div className={`${baseClass}__Bolded-Text`}>
            {t('cloud_migration_important_note', 'Important Note:')}
          </div>
          <div className={`${baseClass}__Text`}>
            <ul>
              <li>{t('cloud_migration_list_of_users_important_note_1', 'Confirm the list of users who will be added to your Automation Cloud organization. If necessary, cancel the process and first deactivate users in the Customer Portal.')}</li>
              <li>{t('cloud_migration_list_of_users_important_note_2', 'All pending or deactivated users will be removed from your Customer Portal account and will not be added to your Automation Cloud organization.')}</li>
            </ul>
          </div>
          <div className={`${baseClass}__Button-Container`}>
            <Button
              variant='contained'
              color='primary'
              className={`${baseClass}__Button`}
              onClick={onConfirm}
            >
              {t('cloud_migration_confirm_button', 'Confirm')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              className={`${baseClass}__Button`}
              onClick={() => onCancel(false)}
            >
              {t('cloud_migration_cancel_and_return_to_customer_portal_button', 'Cancel and return to Customer Portal')}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {connectToCloudPromptMessage()}
          <div className={`${baseClass}__Bolded-Text`}>
            {t('cloud_migration_important_note', 'Important Note:')}
          </div>
          <div className={`${baseClass}__Text`}>
            <ul>
              <li>{t('cloud_migration_important_note_text', 'If needed, sign out and log in to a different cloud organization.')}</li>
            </ul>
          </div>
          <div className={`${baseClass}__Bolded-Text`}>
            {t('cloud_migration_outcome', 'Migration Outcome:')}
          </div>
          <div className={`${baseClass}__Text`}>
            <ul>
              <li>{t('cloud_migration_outcome_text_1', 'After completing the migration, this Customer Portal account will only be accessible through UiPath Automation Cloud.')}</li>
              <li>{t('cloud_migration_outcome_text_2', 'All active users from your existing Customer Portal account will be imported to Automation Cloud and will receive an email invitation to join it.')}</li>
              <li dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(t('cloud_migration_outcome_text_3', 'The URLs for the Customer Portal will change to <b>{{cloudURL}}</b>',
                  { cloudURL: `${CLOUD_DOMAIN}/${cloudName}/${CLOUD_SERVICE_NAME}` })),
              }} />
              <li>{t('cloud_migration_outcome_text_4', 'All existing URLs will redirect to your Automation Cloud account.')}</li>
            </ul>
          </div>
          <div className={`${baseClass}__Button-Container`}>
            <Button
              variant='contained'
              color='secondary'
              className={`${baseClass}__Button`}
              onClick={continueToNextStep}>
              {t('cloud_migration_continue_button', 'Continue')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              className={`${baseClass}__Button`}
              onClick={() => onCancel(false)}
            >
              {t('cloud_migration_cancel_and_return_to_customer_portal_button', 'Cancel and return to Customer Portal')}
            </Button>
          </div>
        </div>
      )}
    </CloudMigrationContainer>
  );
};

export default CustomerPortalToCloudMigration;
