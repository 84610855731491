import Alert from '@mui/lab/Alert';
// Images
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import * as styles from '../assets/css/NotificationBanner';
// Store
import { StoreContext } from '../store';
import ApolloIcon from './ApolloIcon';
import NotificationBannerActions from './Notification-BannerActions';

interface NotificationBannerProps {
  message: string;
  type: string;
  actions?: Array<{
    name: string;
    url: string;
  }>;
  autoHide?: boolean;
  isCloseEnabled?: boolean;
}

const NotificationBanner = (props: NotificationBannerProps) => {
  const [ open, setOpen ] = useState(false);
  const autoHideBanner: number | null = props.autoHide ? 4000 : null;
  const { dispatch } = useContext(StoreContext);

  useEffect(() => {
    if (props.message) {
      setOpen(true);
    }
  }, [ props.message ]);

  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: 'setBannerMsg',
      payload: '',
    });
  };

  const generateAlert = () => {
    if (props.type === 'success') {
      return (
        <styles.SuccessOrErrorAlert>
          <Alert
            icon={<ApolloIcon
              icon='check_circle'
              fontSize='inherit'
              className='icon'
            />}
            severity="success"
            {...(props.isCloseEnabled !== false && { onClose: handleClose })}
            className='alert'
          >
            {props.message}
          </Alert>
        </styles.SuccessOrErrorAlert>
      );
    } else if (props.type === 'error') {
      return (
        <styles.SuccessOrErrorAlert>
          <Alert
            icon={<ApolloIcon
              icon='error'
              fontSize='inherit'
              className='icon'
            />}
            severity="error"
            {...(props.isCloseEnabled !== false && { onClose: handleClose })}
            className='alert'
          >
            {props.message}
          </Alert>
        </styles.SuccessOrErrorAlert>
      );
    } else if (props.type === 'withActions') {
      return (
        <styles.CustomAlert
          className="AlertWithActions"
          icon={<ApolloIcon
            icon='check_circle'
            fontSize='inherit'
            className='icon'
          />}
          severity="success"
          action={
            <NotificationBannerActions
              actions={props.actions ? props.actions : undefined}
              {...(props.isCloseEnabled !== false && { onClose: handleClose })}
            />
          }
        >
          {props.message}
        </styles.CustomAlert>
      );
    }
    return (
      <styles.SuccessOrErrorAlert>
        <Alert
          icon={<ApolloIcon
            icon='info'
            fontSize='inherit'
            className='icon'
          />}
          severity="info"
          {...(props.isCloseEnabled !== false && { onClose: handleClose })}
          className='alert'
        >
          {props.message}
        </Alert>
      </styles.SuccessOrErrorAlert>
    );
  };

  return (
    <styles.CustomSnackbar
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...(props.isCloseEnabled !== false && { onClose: handleClose })}
      autoHideDuration={autoHideBanner}
    >
      {generateAlert()}
    </styles.CustomSnackbar>
  );
};

export default NotificationBanner;
