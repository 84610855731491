import TextField from '@mui/material/TextField';
import React, {
  useContext,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../../assets/css/RequestForQuote/BrowseProducts/RequestForQuoteLicenseDetailPopUp';
import type { GlobalStoreState } from '../../../interfaces/contextStore';
// Helpers
import { getRelevantSkusFromProductType } from '../../../lib/requestForQuote.utils';
import { StoreContext } from '../../../store/index';

const RequestForQuoteLicenseDetailPopUp = ({
  popUpData,
  handleClosePopUp,
  showPopUp = false,
  handleAddLicenseToCart,
  handleQuantityUpdate,
  handleRemoveLicenseFromCart,
  cartProducts,
  lastSelectedOrchestrator,
  selectedCategory,
  isAddToCartAllowed,
  requiresAutomationCloudBundle,
  requiresAutomationSuiteBundle,
}: {
  popUpData: {
    _id: string;
    name: string;
    description: string;
    short_description: string;
    quantity: number;
    min_quantity: number;
    product_code: string;
  };
  isAddToCartAllowed: boolean;
  requiresAutomationCloudBundle: boolean;
  requiresAutomationSuiteBundle: boolean;
  handleClosePopUp: (event: React.MouseEvent<unknown>) => void;
  showPopUp: boolean;
  handleAddLicenseToCart: (
    license: {
      [key: string]: any;
      _id: string;
      sku_name: string;
      product_code: string;
      min_quantity: number;
    },
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
  ) => void;
  handleQuantityUpdate: (
    licenseId: string,
    newQuantity: number,
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
  ) => void;
  handleRemoveLicenseFromCart: (
    licenseId: string,
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
  ) => void;
  cartProducts: GlobalStoreState['rfqCart']['products'];
  lastSelectedOrchestrator: GlobalStoreState['rfqCart']['lastSelectedOrchestrator'];
  selectedCategory: GlobalStoreState['rfqCart']['selectedCategory'];
}) => {
  // Translate method
  const { t } = useTranslation('common');
  // State
  const { dispatch } = useContext(StoreContext);
  const [ addToCart, setAddToCart ] = useState(Boolean(popUpData.quantity));

  // Contextual search
  const getQuantityFromCart = (licenseId: string) => {
    const foundOrch = cartProducts?.find(
      stateSku => stateSku._id === lastSelectedOrchestrator?._id
    );
    const quantityInCart =
      getRelevantSkusFromProductType(
        selectedCategory,
        cartProducts,
        foundOrch,
        requiresAutomationCloudBundle,
        requiresAutomationSuiteBundle
      ).find(sku => sku.sku_id === licenseId)?.quantity || 0;

    return quantityInCart.toString();
  };

  // Methods
  const handleAddToCart = (e: any) => {
    handleAddLicenseToCart(
      {
        _id: popUpData._id,
        sku_name: popUpData.name,
        min_quantity: popUpData.min_quantity,
        product_code: popUpData.product_code,
      },
      selectedCategory,
      dispatch,
      lastSelectedOrchestrator
    );
    setAddToCart(!addToCart);
  };

  const handleShowQuantityDropdown = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const quantityStr = e.target.value;

    handleQuantityUpdate(
      popUpData._id,
      parseInt(quantityStr) || 0,
      selectedCategory,
      dispatch,
      lastSelectedOrchestrator
    );
  };

  const handleRemoveFromCart = () => {
    handleRemoveLicenseFromCart(
      popUpData._id,
      selectedCategory,
      dispatch,
      lastSelectedOrchestrator
    );
    setAddToCart(false);
  };

  return (
    <styles.RequestForQuoteLicenseDetailPopUp
      className={`RequestForQuote__BrowseProducts__licenseDetailPopUp ${
        showPopUp
          ? 'RequestForQuote__BrowseProducts__licenseDetailPopUp--active'
          : ''
      }`}
      data-testid="RequestForQuote__BrowseProducts__licenseDetailPopUp"
    >
      <div className="RequestForQuote__BrowseProducts__licenseDetailContainer">
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="RequestForQuote__BrowseProducts__licenseDetailClosePopUp"
          onClick={handleClosePopUp}
        >
          <path
            d="M9.66683 1.27337L8.72683 0.333374L5.00016 4.06004L1.2735 0.333374L0.333496 1.27337L4.06016 5.00004L0.333496 8.72671L1.2735 9.66671L5.00016 5.94004L8.72683 9.66671L9.66683 8.72671L5.94016 5.00004L9.66683 1.27337Z"
            fill="#8A97A0"
          />
        </svg>
        <h4 className="RequestForQuote__BrowseProducts__licenseDetailTitle">
          {popUpData.name}
        </h4>
        <p className="RequestForQuote__BrowseProducts__licenseDetailFullDesc">
          {popUpData.description}
        </p>
        {popUpData.short_description && (
          <div className="RequestForQuote__BrowseProducts__licenseDetailShortDesc">
            <styles.ShortDescriptionHeader>
              {t(
                'rfq_license_details_pop_up_grants_to_use',
                'Grants the Right to Use'
              )}
            </styles.ShortDescriptionHeader>
            <styles.ShortDescription
              dangerouslySetInnerHTML={{ __html: popUpData.short_description }}
            />
          </div>
        )}

        <div className="RequestForQuote__BrowseProducts__licenseDetailFooter">
          <div
            className={`RequestForQuote__BrowseProducts__licenseDetailAddToCart ${
              !isAddToCartAllowed
                ? 'RequestForQuote__BrowseProducts__licenseDetailAddToCart--disabled'
                : ''
            }`}
          >
            {!addToCart && (
              <>
                <svg
                  className="RequestForQuote__BrowseProducts__licenseDetailAddToCartIcon"
                  data-testid="RequestForQuote__BrowseProducts__licenseDetailAddToCartIcon"
                  width="11"
                  height="10"
                  viewBox="0 0 11 10"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={(e: any) => {
                    if (isAddToCartAllowed) {
                      handleAddToCart(e);
                    }
                  }}
                >
                  <path d="M10.1668 5.66659H6.16683V9.66659H4.8335V5.66659H0.833496V4.33325H4.8335V0.333252H6.16683V4.33325H10.1668V5.66659Z" />
                </svg>
                <p
                  onClick={(e: any) => {
                    if (isAddToCartAllowed) {
                      handleAddToCart(e);
                    }
                  }}
                >
                  {t('rfq_license_details_pop_up_add_to_cart', 'Add to Cart')}
                </p>
              </>
            )}

            {addToCart && (
              <div className="RequestForQuote__BrowseProducts__licenseDetailQuantity">
                <span className="RequestForQuote__BrowseProducts__QuantityLabel">
                  {t('quantity', 'Qty')}
                </span>

                <TextField
                  aria-label="Quantity"
                  className="RequestForQuote__BrowseProducts__QuantityInput"
                  variant={'outlined' as any}
                  value={getQuantityFromCart(popUpData._id)}
                  onChange={handleShowQuantityDropdown}
                  onClick={e => e.stopPropagation()}
                  onFocus={e => e.target.select()}
                  error={false}
                  required={false}
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  id="quantity"
                />

                <span
                  aria-label="Remove from cart"
                  onClick={handleRemoveFromCart}
                >
                  {t('rfq_license_details_pop_up_remove', 'Remove')}
                </span>
              </div>
            )}
          </div>

          <button
            className="RequestForQuote__BrowseProducts__licenseDetailClose"
            onClick={handleClosePopUp}
          >
            {t('rfq_license_details_pop_up_close', 'Close')}
          </button>
        </div>
      </div>
    </styles.RequestForQuoteLicenseDetailPopUp>
  );
};

export default RequestForQuoteLicenseDetailPopUp;
