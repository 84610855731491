import React, { useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styling
import * as styles from '../../../assets/css/Knowledge/SearchResults';
// Image assets
import DownArrow from '../../../assets/img/svg/kb/Arrow_Down.svg';
// Interfaces
import type { IDataObject } from '../../../interfaces/dataObject.interface';
import type { IKBSearchState } from '../../../interfaces/knowledgeBase.interface';
import CustomerPortalLoader from '../../CustomerPortal-Loader';
// Layout
import Container from '../../CustomerPortal-New-Container';
// Components
import CustomerPortalPagination from '../../CustomerPortal-Pagination';
import CustomerPortalSearchInput from '../../CustomerPortal-SearchInput';
import KBSearchNoResults from '../../knowledge/KB-NoResults';
import SelectWithSearch from '../../Select-With-Search';
import SupportKBDocument from '../document/SupportKBDocument';
import SupportKBLinkOut from '../link_out/SupportKBLinkOut';

type KBSearchResultsProps = {
  handleCategoryFilterClick: (name: string) => void;
  handleSearchSortClick: (
    filter: { path: string; direction: number } | null,
    element?: HTMLElement
  ) => void;
  searchPaginationPage: number;
  searchDocsPerPage: number;
  searchNumPagesBeforeEllipses: number;
  handleSearchPaginateClick: (event: any) => void;
  searchState: IKBSearchState;
  isDisplayMiniSearchForm?: boolean;
  handleMiniSearchFormSubmit?: (keywords: string) => void;
  isDisplaySearchResultCount?: boolean;
  availableSubcategoriesList?: any[];
  isProductResults?: boolean;
  [key: string]: any;
  screenName: string;
};

/**
 *
 * Container that holds the KB search results
 *
 */
const SupportKBSearchResults = ({
  handleCategoryFilterClick,
  handleSearchSortClick,
  searchPaginationPage,
  searchDocsPerPage,
  searchNumPagesBeforeEllipses,
  handleSearchPaginateClick,
  searchState,
  isDisplaySearchResultCount = true,
  isDisplayMiniSearchForm = false,
  handleMiniSearchFormSubmit,
  isProductResults = false,
  screenName,
}: KBSearchResultsProps) => {
  // Translate method
  const { t } = useTranslation('common');
  // Grab from props
  searchState = searchState || {
    keyword: null,
    keywordInputField: '',
    isSearching: false,
    isCompleteSearching: false,
    filterCategories: [],
    kbDocuments: [],
    currentPage: 1,
    kbTotalDocs: 0,
    kbTotalPages: 0,
    sortBy: null,
    sortDirection: -1,
    skipResult: 0,
    filterParam: null,
    filterSubcategoriesParam: null,
  };

  /* State */
  const [ isFilterActive, setFilterActive ] = useState(false);
  const [ isSortActive, setSortActive ] = useState(false);
  const [ miniSearchText, setMiniSearchText ] = useState('');

  /* Events */
  // Event when filter select is clicked on mobile
  const handleFilterActiveClick = () => {
    if (
      (!isFilterActive && !isSortActive) ||
      (isFilterActive && !isSortActive)
    ) {
      setFilterActive(!isFilterActive);
      return;
    }

    setFilterActive(!isFilterActive);
    setSortActive(!isSortActive);
  };

  // Event when sort select is clicked on mobile
  const handleSortActiveClick = () => {
    if (
      (!isSortActive && !isFilterActive) ||
      (isSortActive && !isFilterActive)
    ) {
      setSortActive(!isSortActive);
      return;
    }
    setFilterActive(!isFilterActive);
    setSortActive(!isSortActive);
  };

  // Desktop sort option
  const handleSortOptionClick = async (selectedOption: any) => {
    let type;
    if (isProductResults) {
      switch (selectedOption.value) {
        case 'recently_updated':
          type = 'recently_updated';
          break;
        case 'recently_created':
          type = 'recently_created';
          break;
        case 'most_viewed':
        default:
          type = 'views';
      }
    } else {
      switch (selectedOption.value) {
        case 'name':
          type = 'title';
          break;
        case 'date':
        default:
          type = 'created_on';
      }
    }

    const direction = selectedOption.value;

    handleSearchSortClick({
      path: type,
      direction,
    });
  };

  const filterOptions = () => {
    if (isProductResults) {
      return [
        {
          label: 'Most Viewed',
          value: 'most_viewed',
          direction: -1,
        },
        {
          label: 'Recently Updated',
          value: 'recently_updated',
          direction: -1,
        },
        {
          label: 'Recently Created',
          value: 'recently_created',
          direction: -1,
        },
      ];
    }
    return [
      {
        label: 'Name',
        value: 'name',
        direction: 1,
      },
      {
        label: 'Date',
        value: 'date',
        direction: -1,
      },
    ];

  };

  const filterPlaceholder = () => {
    let sortInner;
    // switch (searchState.sortBy) {
    //   case 'title':
    //     sortInner = 'Name';
    //     break;
    //   case 'views':
    //     sortInner = 'Most Viewed';
    //     break;
    //   case 'recently_updated':
    //     sortInner = 'Recently Updated';
    //     break;
    //   case 'recently_created':
    //     sortInner = 'Recently Created';
    //     break;
    //   case 'created_on':
    //   default:
    //     sortInner = 'Date';
    // }

    if (isProductResults) {
      switch (searchState.sortBy) {
        case 'recently_updated':
          sortInner = 'Recently Updated';
          break;
        case 'recently_created':
          sortInner = 'Recently Created';
          break;
        case 'views':
        default:
          sortInner = 'Most Viewed';
      }
    } else {
      switch (searchState.sortBy) {
        case 'title':
          sortInner = 'Name';
          break;
        case 'created_on':
        default:
          sortInner = 'Date';
      }
    }

    return (
      <div data-testid="sort-by">
        {t('sort_by', 'Sort by')}
:
        {' '}
        <span className="Custom-Select__Placeholder-Value">{sortInner}</span>
      </div>
    );
  };

  const handleMiniSearchInput = (keyword: string) => {
    setMiniSearchText(keyword);
  };

  const handleMiniFormSubmit = () => {
    if (typeof handleMiniSearchFormSubmit !== 'function') {
      return;
    }
    handleMiniSearchFormSubmit(miniSearchText);
  };

  const renderSearchResults = () => {
    // If this is global search and no results, simply display No Results component since global search bar exists
    if (!isProductResults && searchState.kbDocuments.length === 0) {
      return (
        <styles.Wrapper>
          <KBSearchNoResults />
          <SupportKBLinkOut screenName={screenName} />
        </styles.Wrapper>
      );
    }

    // Otherwise, render normally. If this is category search and no results, we still show the mini search bar and sort buttons
    return (
      <styles.Wrapper>
        <styles.SearchWrapper
          className={
            isProductResults
              ? 'CustomerPortalKBSearchResults__isProductResults'
              : undefined
          }
          data-testid="SearchResult"
        >
          {!isProductResults && (
            <>
              <styles.CategoriesContainer
                className={`CustomerPortalKBSearchResults__CategoriesContainer ${isFilterActive
                  ? 'CustomerPortalKBSearchResults__CategoriesContainer--Active'
                  : ''
                }`}
                data-testid="CategoriesContainer"
              >
                <styles.FilterNameText>
                  {t(
                    'support_kb_search_component_filter_by_label',
                    'Filter by category'
                  )}
                </styles.FilterNameText>
                <styles.FilterContainer>
                  {searchState.filterCategories.map((elem: IDataObject) => (
                    <styles.CategoriesFilterItem
                      key={elem._id}
                      className={`CustomerPortalKBSearchResults__CategoryFilterItem ${((searchState.filterParam &&
                          searchState.filterParam.has(elem._id)) ||
                          (searchState.filterSubcategoriesParam &&
                            searchState.filterSubcategoriesParam.has(
                              elem._id
                            )) ||
                          (searchState.filterSubcategoriesParam &&
                            searchState.filterSubcategoriesParam.has(
                              elem._id
                            ))) &&
                          'CustomerPortalKBSearchResults__CategoryFilterItem--Active'}`}
                      onClick={() => {
                        handleCategoryFilterClick(elem._id);
                        return;
                      }}
                      data-testid="CategoriesFilterItem"
                    >
                      <styles.CategoryFilterItemCount
                        className="CategoryFilterItem__Count"
                        data-testid="CategoryFilterItem__Count"
                      >
                        {elem.count}
                      </styles.CategoryFilterItemCount>
                      <styles.CategoryFilterItemSubcategoryName
                        className="CategoryFilterItem__SubCategoryName"
                        data-testid="CategoryFilterItem__SubCategoryName"
                      >
                        {elem._id}
                      </styles.CategoryFilterItemSubcategoryName>
                    </styles.CategoriesFilterItem>
                  ))}
                </styles.FilterContainer>
              </styles.CategoriesContainer>
            </>
          )}
          <styles.SearchResultsInner data-testid="SearchResultsInner">
            <div className="SearchResultsInner__Heading">
              {!isProductResults && (
                <styles.SearchResultsTitle data-testid="SearchResultsTitle">
                  {isDisplaySearchResultCount &&
                    searchState.kbTotalDocs === 1 &&
                    t(
                      'support_kb_search_component_one_result_title',
                      `We found ${searchState.kbTotalDocs} result in multiple Categories`,
                      { kbTotalDocs: searchState.kbTotalDocs }
                    )}
                  {isDisplaySearchResultCount &&
                    searchState.kbTotalDocs > 1 &&
                    t(
                      'support_kb_search_component_more_results_title',
                      `We found ${searchState.kbTotalDocs} results in multiple Categories`,
                      { kbTotalDocs: searchState.kbTotalDocs }
                    )}
                </styles.SearchResultsTitle>
              )}
              <styles.SearchResultsDesktopActions>
                {isProductResults && (
                  <div
                    className="CustomerPortalPageKnowledgeBase__SearchContainer CustomerPortalPageKnowledgeBase__SearchContainer--Product"
                    data-testid="SearchContainer--Product"
                  >
                    <CustomerPortalSearchInput
                      placeholderText={t(
                        'support_kb_page_search_placeholder',
                        'Search here...'
                      )}
                      handleSubmit={handleMiniFormSubmit}
                      keyword={miniSearchText}
                      handleOnChange={handleMiniSearchInput}
                      cssClass="CustomerPortalKBSearchResults__Product-Search"
                    />
                  </div>
                )}
                <SelectWithSearch
                  value={searchState.sortBy}
                  className="Custom-Select--White CustomerPortalClientsSelection__clientsSort"
                  onChange={handleSortOptionClick}
                  options={filterOptions()}
                  searchable={false}
                  placeholder={filterPlaceholder()}
                />
                {isDisplayMiniSearchForm && (
                  <>
                    <div
                      className="CustomerPortalClientsSelection__clientsSearch"
                      data-testid="clientsSearch"
                    />
                  </>
                )}
              </styles.SearchResultsDesktopActions>
            </div>
            {!isProductResults && (
              <styles.MobileFilters>
                <div
                  className={`CustomerPortalKBSearchResults__FiltersHeader${isFilterActive
                    ? ' CustomerPortalKBSearchResults__FiltersHeader--Active'
                    : ''
                  }`}
                  onClick={handleFilterActiveClick}
                  data-testid="MobileFilters"
                >
                  <span>
                    {t(
                      'knowledge_base_search_filter_sidebar_title_mobile',
                      'Filter'
                    )}
                  </span>
                  <img
                    src={DownArrow}
                    alt="Downard arrow"
                    className="CustomerPortalPageKnowledgeBase__DownwardArrow"
                  />
                </div>

                <div
                  className={`CustomerPortalKBSearchResults__FilterContainer${isFilterActive
                    ? ' CustomerPortalKBSearchResults__FilterContainer--Active'
                    : ''
                  }`}
                >
                  {searchState.filterCategories.map((elem: IDataObject) => (
                    <div
                      key={elem._id}
                      className={`CustomerPortalKBSearchResults__SortItem CustomerPortalKBSearchResults__FilterItem--Mobile ${((searchState.filterParam &&
                          searchState.filterParam.has(elem._id)) ||
                          (searchState.filterSubcategoriesParam &&
                            searchState.filterSubcategoriesParam.has(
                              elem._id
                            ))) &&
                          ' CustomerPortalKBSearchResults__SortItem--Active'}`}
                      onClick={() => {
                        handleCategoryFilterClick(elem._id);
                      }}
                      data-testid="CustomerPortalKBSearchResults__FilterItem"
                    >
                      <span className="CategoryFilterItem__Count">
                        {`${elem._id} ${elem.count}`}
                      </span>
                    </div>
                  ))}
                </div>

                <div
                  className={`CustomerPortalKBSearchResults__FiltersHeader${isSortActive
                    ? ' CustomerPortalKBSearchResults__FiltersHeader--Active'
                    : ''
                  }`}
                  onClick={handleSortActiveClick}
                  data-testid="MobileSort"
                >
                  <span>
                    {t('knowledge_base_search_sort_by_label_mobile', 'Sort')}
                  </span>
                  <img
                    src={DownArrow}
                    alt="Downward arrow"
                    className="CustomerPortalPageKnowledgeBase__DownwardArrow"
                  />
                </div>
                <div
                  className={`CustomerPortalKBSearchResults__SortContainer${isSortActive
                    ? ' CustomerPortalKBSearchResults__SortContainer--Active'
                    : ''
                  }`}
                >
                  <div
                    className={`CustomerPortalKBSearchResults__SortItem${searchState.sortBy === 'name' &&
                      searchState.sortDirection === -1
                      ? ' CustomerPortalKBSearchResults__SortItem--Active'
                      : ''
                    }`}
                    onClick={e => {
                      handleSearchSortClick({
                        path: 'name',
                        direction: -1,
                      });
                    }}
                    data-testid="MobileSortByName"
                  >
                    <p className="CustomerPortalKBSearchResults__SortText">
                      {t('knowledge_base_search_sort_by_name', 'Name')}
                    </p>
                  </div>

                  <div
                    className={`CustomerPortalKBSearchResults__SortItem${searchState.sortBy === 'Date' &&
                      searchState.sortDirection === 1
                      ? ' CustomerPortalKBSearchResults__SortItem--Active'
                      : ''
                    }`}
                    onClick={e => {
                      handleSearchSortClick({
                        path: 'created_on',
                        direction: 1,
                      });
                    }}
                  >
                    <p className="CustomerPortalKBSearchResults__SortText">
                      {t('knowledge_base_search_sort_by_date', 'Date')}
                    </p>
                  </div>
                </div>
              </styles.MobileFilters>
            )}
            {searchState.kbDocuments.length > 0 ? (
              <>
                <styles.SearchResultsContainer>
                  {searchState.kbDocuments.map((kbDocument: any) => (
                    <SupportKBDocument
                      key={kbDocument.id}
                      productComponentTitle={kbDocument.category}
                      documentTitleText={kbDocument.title}
                      articleId={kbDocument.id}
                      articleViews={kbDocument.totalViewCount}
                      lastUpdated={kbDocument.lastModifiedDate}
                      isProductResults={isProductResults}
                      articleNumber={kbDocument.articleNumber}
                    />
                  ))}
                </styles.SearchResultsContainer>
                <CustomerPortalPagination
                  className="KBPagination"
                  activePage={searchPaginationPage}
                  numResults={searchState.kbTotalDocs}
                  numResultsPerPage={searchDocsPerPage}
                  numPagesBeforeEllipses={searchNumPagesBeforeEllipses}
                  handleClick={handleSearchPaginateClick}
                />
              </>
            ) : <KBSearchNoResults />}
          </styles.SearchResultsInner>
        </styles.SearchWrapper>
        <SupportKBLinkOut screenName={screenName} />
      </styles.Wrapper>
    );
  };
  return (
    <Container>
      {searchState.isSearching && !searchState.isCompleteSearching && (
        <CustomerPortalLoader />
      )}

      {searchState.isSearching &&
        searchState.isCompleteSearching &&
        renderSearchResults()}
    </Container>
  );
};

export default SupportKBSearchResults;
