import type { MutableRefObject } from 'react';
import React, {
  useEffect,
  useRef,
} from 'react';

import { KeyboardEventKey } from '../../../constants/accessibility.constants';

interface Props {
  children: React.ReactNode;
  contentElementRef: MutableRefObject<HTMLVideoElement | HTMLIFrameElement | null>;
}

const VideoFocusTrap = (props: Props) => {
  const {
    children, contentElementRef,
  } = props;
  const boundaryContainer = useRef<HTMLDivElement | null>(null);
  const firstFocusableElement = useRef<Element | undefined>();
  const lastFocusableElement = useRef<Element | undefined>();

  useEffect(() => {

    const setFirstAndLastElements = () => {
      const focusableElementsSelector =
        ':scope button:not([disabled]), [href], input:not([type=\'hidden\']), select, [tabindex]:not([tabindex=\'-1\']), iframe, video';
      const focusableElements = boundaryContainer.current?.querySelectorAll(
        focusableElementsSelector
      );

      if (!focusableElements) {
        return;
      }

      firstFocusableElement.current = focusableElements[0];
      lastFocusableElement.current =
        focusableElements[focusableElements.length - 1];

      const createPlaceHolderElement = (
        el: Element | undefined,
        insertAfter: boolean = false
      ) => {
        if (el) {
          const elToInsertBefore = insertAfter ? el.nextSibling : el;
          const tabPlaceholderElement = document.createElement('div');
          tabPlaceholderElement.setAttribute('tabindex', '0');
          el.parentNode?.insertBefore(tabPlaceholderElement, elToInsertBefore);

          return tabPlaceholderElement;
        }

        return undefined;
      };

      if (
        firstFocusableElement.current &&
        firstFocusableElement.current === contentElementRef.current
      ) {
        firstFocusableElement.current = createPlaceHolderElement(
          firstFocusableElement.current
        );
      }

      if (
        lastFocusableElement.current &&
        lastFocusableElement.current === contentElementRef.current
      ) {
        lastFocusableElement.current = createPlaceHolderElement(
          lastFocusableElement.current,
          true
        );
      }
    };

    setFirstAndLastElements();
  }, []);

  const handleTabbing = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const currFocusedElement = document.activeElement;

    if (e.key === KeyboardEventKey.TAB) {
      const elToCheck = e.shiftKey
        ? firstFocusableElement.current
        : lastFocusableElement.current;
      const elToFocus = e.shiftKey
        ? lastFocusableElement.current
        : firstFocusableElement.current;

      if (currFocusedElement === elToCheck) {
        e.preventDefault();
        elToFocus instanceof HTMLElement && elToFocus.focus();
      }
    }
  };

  return (
    <div
      ref={boundaryContainer}
      onKeyDown={handleTabbing}>
      {children}
    </div>
  );
};

export default VideoFocusTrap;
