import type { Result } from '@coveo/headless';

export const SEARCH_SOURCE: string = 'cp';
export const SEARCH_HUB_NAME: string = 'CustomerPortal-Main';
export const DEFAULT_SUGGESTIONS_COUNT: number = 3;
export const DEFAULT_RESULTS_COUNT: number = 10;
export const SEARCH_MINIMUM_CHARACTER_LENGTH: number = 3;
export const SEARCH_MAXIMUM_CHARACTER_LENGTH: number = 4000;
export const DEFAULT_FACET_VALUES_COUNT: number = 5;
export const SEARCH_SUGGESTIONS_DELAY_MS: number = 500;
export const QUERY_TEXT_URL_KEY = 'q';
export const DOCS_COMBINED_VERSION_NAME = 'Cloud';
export const DOCS_VERSION_SKIP_TAG_NAME = 'SKIP';

export enum SearchResultSource {
  TECHNICAL_SOLUTIONS = 'Technical Solution Articles',
  ACADEMY = 'UiPath Academy',
  DOCS = 'UiPath Documentation Portal',
  FORUMS = 'UiPath Forums',
  KNOWLEDGE_BASE = 'UiPath Knowledge Base',
  YOUTUBE = 'UiPath Youtube',
}

export const SearchResultSourceLabelMap: Record<string, { localText: string; fallbackText: string }> = {
  [SearchResultSource.TECHNICAL_SOLUTIONS]: {
    localText: 'search_content_type_technical_solutions_label',
    fallbackText: 'Technical solution articles',
  },
  [SearchResultSource.DOCS]: {
    localText: 'search_content_type_docs_label',
    fallbackText: 'Product documentation',
  },
  [SearchResultSource.FORUMS]: {
    localText: 'search_content_type_forums_label',
    fallbackText: 'User communities',
  },
  [SearchResultSource.KNOWLEDGE_BASE]: {
    localText: 'search_content_type_knowledge_base_label',
    fallbackText: 'Knowledge base',
  },
  [SearchResultSource.YOUTUBE]: {
    localText: 'search_content_type_youtube_label',
    fallbackText: 'Videos',
  },
};

export enum SearchAnalyticsActions {
  DOCUMENT_OPEN = 'DOCUMENT_OPEN',
}

export interface AnalyticsActionsPayload {
  [SearchAnalyticsActions.DOCUMENT_OPEN]: Result;
}
