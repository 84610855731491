import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { HapoAddLicenseModalWrapper } from '../../../assets/css/Admin/AddNewHapoLicenseModal';
import { CPTableContainer } from '../../../assets/css/Table';
import { axiosGet } from '../../../client/axios';
import { RFQ_PRODUCTS_URL } from '../../../constants/network.constants';
import { useAuth } from '../../../contexts/auth';
import { StoreContext } from '../../../store';
import CustomerPortalPagination from '../../CustomerPortal-Pagination';
import CustomerPortalSearchInput from '../../CustomerPortal-SearchInput';
import Modal from '../../Modal';
import Loader from './../../CustomerPortal-Loader';

export type addHapoLicenseType = {
  sku_name: string;
  product_code: string;
  id: string;
};

type AddNewHapoLicenseModalProps = {
  open: boolean;
  groupName?: string;
  handleAddLicense: (license: addHapoLicenseType, groupName?: string) => void;
  handleClose: () => void;
};

const AddNewHapoLicenseModal = ({
  open,
  groupName,
  handleAddLicense,
  handleClose,
}: AddNewHapoLicenseModalProps) => {
  // Constants
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const limit = 5;
  const [ licenses, setLicenses ] = useState<addHapoLicenseType[]>([]);
  const [ searchPhrase, setSearchPhrase ] = useState<string | undefined>('');
  const [ searchPhraseChanged, setSearchPhraseChanged ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ currentPage, setCurrentPage ] = useState(1);

  const start = (currentPage - 1) * limit;
  const end = currentPage * limit;

  const handleSearchOnChange = (val: string) => {
    setSearchPhrase(val);
  };

  const handleOnLicenseSelect = (license: addHapoLicenseType) => {
    handleAddLicense(license, groupName);
  };

  const handlePaginationClick = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (open) {
      fetchLicenses();
    }
  }, [ open ]);

  useEffect(() => {
    if (searchPhraseChanged) {
      if (searchPhrase) {
        fetchLicenses();
      } else {
        setLicenses([]);
      }
      setSearchPhraseChanged(false);
    }
  }, [ searchPhraseChanged ]);

  const fetchLicenses = async () => {
    // Fetch CompanyData
    try {
      const licensesRes: any = await axiosGet(
        `${RFQ_PRODUCTS_URL}?selection=active`,
        state.companyId,
        await getAccessToken(),
      );

      setLoading(false);
      if (licensesRes.status === 200) {
        const activeLicenses = licensesRes.data.data.map((license: any) => ({
          product_code: license.product_code,
          sku_name: license.sku_name,
          id: license._id,
        }));
        setLicenses(activeLicenses);
      }
    } catch (e) {
      setLoading(false);
      showError(`Something went wrong ${e}`);
    }
  };

  const filteredSkus = licenses.filter(license => {
    const phrase = searchPhrase?.toString();
    if (!phrase) {
      return true;
    }
    return (
      new RegExp(phrase, 'gi').test(license.sku_name) ||
      new RegExp(phrase, 'gi').test(license.id) ||
      new RegExp(phrase, 'gi').test(license.product_code)
    );
  });

  const showError = (errorMessage: string) => {
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: errorMessage,
    });
  };

  const getLicensesTable = () => (
    <HapoAddLicenseModalWrapper>
      <CPTableContainer>
        <Table
          size="medium"
          aria-label="SKUs Table"
          data-testid="skus-table">
          <TableHead>
            <TableRow>
              <TableCell>Product Code</TableCell>
              <TableCell>SKU Id</TableCell>
              <TableCell>SKU Name</TableCell>
            </TableRow>
          </TableHead>
          {filteredSkus.length > 0 && (
            <TableBody>
              {filteredSkus
                .slice(start, end)
                .map((license: addHapoLicenseType) => (
                  <TableRow
                    className="Table-Row"
                    key={license.product_code}
                    data-testid={license.product_code}
                    onClick={() => handleOnLicenseSelect(license)}
                  >
                    <TableCell>{license.product_code}</TableCell>
                    <TableCell>{license.id}</TableCell>
                    <TableCell>{license.sku_name}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
        {filteredSkus.length == 0 && (
          <p
            className="No-Licenses-Text"
            data-testid="EmptyState">
            No SKUs found!
          </p>
        )}
        {filteredSkus.length > limit && (
          <CustomerPortalPagination
            activePage={currentPage}
            handleClick={handlePaginationClick}
            numPagesBeforeEllipses={5}
            numResultsPerPage={limit}
            numResults={filteredSkus.length}
          />
        )}
      </CPTableContainer>
    </HapoAddLicenseModalWrapper>
  );

  return (
    <Modal
      modalTitle="Add new SKU"
      modalHeading="Select a SKU"
      modalDescription="Modal for selecting an sku"
      open={open}
      handleClose={handleClose}
      testId="AddNewHapoLicenseModal"
    >
      <HapoAddLicenseModalWrapper data-testid="HapoAddLicenseModalWrapper">
        <CustomerPortalSearchInput
          handleOnChange={handleSearchOnChange}
          handleSubmit={() => {}}
          placeholderText="Search by Name, Id or Product Code"
          keyword={searchPhrase || ''}
        />

        {licenses.length > 0 && getLicensesTable()}
        {loading && (
          <div className="HapoManagement__Loader">
            <Loader />
          </div>
        )}
      </HapoAddLicenseModalWrapper>
    </Modal>
  );
};

export default AddNewHapoLicenseModal;
