export enum FileSizes {
  OneMegabyte = 1024 * 1024,
  FourMegabytes = 4 * OneMegabyte,
  TwentyFiveMegabytes = 25 * OneMegabyte,
  OneGigabyte = 1024 * 1024 * 1024,
  TwoGigabytes = 2 * OneGigabyte,
}

export enum TimeUnits {
  OneMinuteInMillis = 60 * 1000,
}
