import React from 'react';

// Styles
import { ModalContentFormWrapper } from '../../../assets/css/Knowledge/CategoryManager-Table';
import Button from '../../Button/Button';
import Modal from '../../Modal';

type DeleteModalProps = {
  oldCategoryName: string;
  oldSubcategoryName?: string;
  isCategoryDeleteModalOpen: boolean;
  handleFormSubmit: (oldCategoryName: string, oldSubcategoryName?: string) => void;
  handleClose: () => void;
};

const DeleteModal = (props: DeleteModalProps) => {

  /* Event */
  const handleFormSubmit = (e: any) => {
    if (!e) {
      return;
    }
    e.preventDefault();
    props.handleFormSubmit(props.oldCategoryName, props.oldSubcategoryName);
    handleModalClose();
  };

  const handleModalClose = () => {
    props.handleClose();
  };

  return (
    <Modal
      modalTitle="Delete Category or Subcategory"
      modalHeading={`Are you sure you want to delete "${props.oldSubcategoryName ?
        props.oldSubcategoryName : props.oldCategoryName}"?`}
      modalDescription="Delete Selected"
      open={props.isCategoryDeleteModalOpen}
      handleClose={handleModalClose}
    >
      <ModalContentFormWrapper
        onSubmit={handleFormSubmit}
      >
        <Button
          className="KnowledgeCategories__SubmitButton"
          onClick={() => {}}
          text="Submit"
        />
      </ModalContentFormWrapper>
    </Modal>

  );
};

export default DeleteModal;
