import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import {
  FooterColumn,
  FooterInner,
  FooterLegal,
  FooterOuter,
  FooterSitemap,
  FooterSocial,
  FooterTopHat,
} from '../../../../assets/css/Footer/NewFooter';
// Logos
import {
  Facebook_Logo,
  Instagram_logo,
  Linkedin_Logo,
  Twitter_Logo,
  UIPath_Logo,
  Youtube_Logo,
} from '../../../../assets/img/svg/footer/new_footer/logos';
// Constants
import { ModuleNames } from '../contentful.constants';
// Utils
import { internalLinkHandler } from '../link.utils';
import Typography from '../Typography';

const NewFooter = ({ footerData }: any) => {
  const menuGroups = footerData?.allContentfulNavMenu?.nodes?.[0]?.menuGroups;
  const legalLinks = footerData?.allContentfulNavMenu?.nodes?.[0]?.legalFooterOnly;

  const LINKEDIN = 'linkedin';
  const FACEBOOK = 'facebook';
  const TWITTER = 'twitter';
  const YOUTUBE = 'youtube';
  const INSTAGRAM = 'instagram';
  const FOOTER_SOCIAL_INTRO = 'socials.footer.intro.text';
  const socialMicrocopySet =
    footerData?.allContentfulNavMenu?.nodes?.[0]?.socialMediaFooterOnly[0]
      ?.values;

  const socialData: any = {};
  socialMicrocopySet?.forEach((social: any) => {
    if (social.key.startsWith(LINKEDIN)) {
      socialData.LINKEDIN = social.value;
    } else if (social.key.startsWith(FACEBOOK)) {
      socialData.FACEBOOK = social.value;
    } else if (social.key.startsWith(TWITTER)) {
      socialData.TWITTER = social.value;
    } else if (social.key.startsWith(YOUTUBE)) {
      socialData.YOUTUBE = social.value;
    } else if (social.key.startsWith(INSTAGRAM)) {
      socialData.INSTAGRAM = social.value;
    } else if (social.key === FOOTER_SOCIAL_INTRO) {
      socialData.FOOTER_SOCIAL_INTRO = social.value;
    }
  });

  const buildColumn = (start: number, end: number, menuItems: any) => {
    const column: React.JSX.Element[] = [];
    for (let i = start; i < end; i++) {
      const heading = menuItems[i]?.newGroupName;
      if (heading) {
        column.push(<h6>{heading}</h6>);
      }

      let index = 0;
      const listItems: React.JSX.Element[] = [];

      if (!menuItems[i]?.pages) {
        continue;
      }

      for (const link of menuItems[i].pages) {
        const className =
          link?.__typename === ModuleNames.TOPIC_ICON_LINK
            ? { className: 'Footer__Text--Bold' }
            : {};

        listItems.push(
          <li key={index}>
            <a
              {...className}
              href={link?.url || link?.externalUrl}
              target="_blank"
              rel="noreferrer">
              {link?.text || link?.ctaText}
            </a>
          </li>
        );
        index++;
      }

      column.push(<ul>{listItems}</ul>);
    }

    return <div>{column}</div>;
  };

  const renderFooterLinks = () => {
    /*
      Only considering for desktop (12 column layout), responsive will be handled in styles,
      a menu list spans two columns width on desktop, hence two columns is 4.
    */
    const TWO_COLUMNS = 4;
    const ONE_COLUMN = 2;
    const COLUMN_END = 13;

    let columnStart = 1,
      index = 1,
      finalMarkup: React.JSX.Element[] = [];

    for (const menuGroup of menuGroups) {
      const pages = menuGroup?.pages || [];
      let columns = ONE_COLUMN;

      // only split list into two columns if more than >= 4 menu groups
      if (pages.length >= 4) {
        columns = TWO_COLUMNS;
      }

      const markup: React.JSX.Element[] = [];
      if (columns === TWO_COLUMNS) {
        const mid = Math.ceil(pages.length / 2);
        markup.push(
          buildColumn(0, mid, pages),
          buildColumn(mid, pages.length, pages)
        );
      } else {
        markup.push(buildColumn(0, pages.length, pages));
      }

      columnStart =
        columnStart > COLUMN_END || columnStart + columns > COLUMN_END
          ? 1
          : columnStart;

      finalMarkup.push(
        <FooterColumn
          columns={columns}
          columnStart={columnStart.toString()}
          columnEnd={columnStart + columns.toString()}
          menuIndex={index}
        >
          <div className="Footer__Group-Title">
            <p>{menuGroup.newGroupName}</p>
            <span />
          </div>
          {markup}
        </FooterColumn>
      );

      columnStart += columns;
      index++;
    }
    return finalMarkup;
  };

  return (
    <FooterOuter>
      <FooterInner>
        {/* Footer Top Hat */}
        <FooterTopHat>
          <Link
            to="/support/add-case"
            className="Footer__UIPathLogo">
            {UIPath_Logo}
          </Link>
        </FooterTopHat>
        {/* Footer Site Map */}
        <FooterSitemap>{renderFooterLinks()}</FooterSitemap>
        {/* Footer Social */}
        {Object.keys(socialData)?.length > 0 && (
          <FooterSocial>
            <div className="Footer__SocialNetwork">
              <div>
                {socialData.FOOTER_SOCIAL_INTRO && (
                  <Typography
                    className="Footer__SocialNetwork-IntroText"
                    type="body"
                  >
                    {socialData.FOOTER_SOCIAL_INTRO}
                  </Typography>
                )}
                <ul>
                  <li>
                    <a
                      href={socialData.FACEBOOK}
                      target="_blank"
                      rel="noreferrer">
                      {Facebook_Logo}
                    </a>
                  </li>
                  <li>
                    <a
                      href={socialData.LINKEDIN}
                      target="_blank"
                      rel="noreferrer">
                      {Linkedin_Logo}
                    </a>
                  </li>
                  <li>
                    <a
                      href={socialData.TWITTER}
                      target="_blank"
                      rel="noreferrer">
                      {Twitter_Logo}
                    </a>
                  </li>
                  <li>
                    <a
                      href={socialData.YOUTUBE}
                      target="_blank"
                      rel="noreferrer">
                      {Youtube_Logo}
                    </a>
                  </li>
                  <li>
                    <a
                      href={socialData.INSTAGRAM}
                      target="_blank"
                      rel="noreferrer">
                      {Instagram_logo}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </FooterSocial>
        )}
        {/* Footer Legal */}
        {legalLinks?.length && (
          <FooterLegal>
            <ul>
              {legalLinks.map((item: any, index: number) => {
                const link = item?.internalLink ?? item?.externalUrl;
                return (
                  <li key={link}>
                    <a
                      href={
                        item?.internalLink
                          ? internalLinkHandler(item?.internalLink)
                          : item?.externalUrl
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item?.text}
                    </a>
                  </li>
                );
              })}
              <li>
                <a
                  id="ot-sdk-btn"
                  className="ot-sdk-show-settings"
                  rel="noopener"
                  target="_blank"
                >
                  View cookie settings
                </a>
              </li>
            </ul>
            <p>{`© 2005-${new Date().getFullYear()} UiPath. All rights reserved.`}</p>
          </FooterLegal>
        )}
      </FooterInner>
    </FooterOuter>
  );
};

export default NewFooter;
