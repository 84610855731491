import type {
  RoleWithMetadata,
  UserCompanyAssociationType,
  UserPermission,
  UserRole,
} from '@customer-portal/constants';
import { DEFAULT_PERMISSIONS } from '@customer-portal/constants';

/**
 * Returns the list of roles sorted alphabetically
 */
export const getSortedRoles = (roles: UserRole[]): UserRole[] => (
  Array.from(new Set(roles)).sort((a, b) => a.localeCompare(b))
);

/**
 * Returns the list of permissions sorted alphabetically
 */
export const getSortedPermissions = (permissions: UserPermission[]): UserPermission[] => (
  Array.from(new Set<UserPermission>(permissions))
    .sort((a, b) => a.localeCompare(b))
);

/**
 * Returns the list of roles with metadata, sorted alphabetically by name
 */
export const getSortedRoleWithMetadatas = (roles: RoleWithMetadata[]): RoleWithMetadata[] => {
  const set = new Set<UserRole>();

  return roles
    .reduce((arr, role) => {
      if (!set.has(role.name)) {
        set.add(role.name);
        arr.push(role);
      }
      return arr;
    }, [] as RoleWithMetadata[])
    .sort((a, b) => a.name.localeCompare(b.name));
};

/**
 * Returns the list of association types sorted alphabetically
 */
export const getSortedAssociationTypes = (associationTypes: UserCompanyAssociationType[]): UserCompanyAssociationType[] => (
  Array.from(new Set<UserCompanyAssociationType>(associationTypes))
    .sort((a, b) => a.localeCompare(b))
);

/**
 * Returns a filtered list based on specific criteria for each role type (can include duplicates)
 */
export const getValidRoles = (roles: RoleWithMetadata[]): RoleWithMetadata[] => (
  roles.filter((r) => (
    r.isActive !== false &&
    (!r.expirationDate || new Date(r.expirationDate) > new Date())
  ))
);

/**
 * Returns the list of all permissions across all roles (can include duplicates)
 */
export const getMergedPermissions = (roles: RoleWithMetadata[]): UserPermission[] => (
  roles.flatMap((r) => ([
    ...(DEFAULT_PERMISSIONS[r.name] ?? []),
    ...(r.extraPermissions ?? []),
  ]))
);

/**
 * Returns if the user has the list of roles
 */
export const doesUserHaveRoles = ({
  adminUserRoles = [],
  userCompanyRoles = [],
  roles,
  match = 'all',
}: {
  adminUserRoles?: RoleWithMetadata[];
  userCompanyRoles?: RoleWithMetadata[];
  roles: UserRole[];
  match?: 'any' | 'all';
}): boolean => {
  if (!roles.length) {
    return true;
  }

  const allValidRoles: RoleWithMetadata[] = [
    ...getValidRoles(adminUserRoles),
    ...getValidRoles(userCompanyRoles),
  ];
  const allRolesForUser: Set<UserRole> = new Set(
    allValidRoles.map((r) => r.name)
  );

  return match === 'all'
    ? roles.every((r) => allRolesForUser.has(r))
    : roles.some((r) => allRolesForUser.has(r));
};

/**
 * Returns if the user has the list of permissions
 */
export const doesUserHavePermissions = ({
  adminUserRoles = [],
  userCompanyRoles = [],
  permissions,
  match = 'all',
}: {
  adminUserRoles?: RoleWithMetadata[];
  userCompanyRoles?: RoleWithMetadata[];
  permissions: UserPermission[];
  match?: 'any' | 'all';
}): boolean => {
  if (!permissions.length) {
    return true;
  }

  const allValidRoles: RoleWithMetadata[] = [
    ...getValidRoles(adminUserRoles),
    ...getValidRoles(userCompanyRoles),
  ];

  const allPermissionsForUser: Set<UserPermission> = new Set(
    getMergedPermissions(allValidRoles)
  );

  return match === 'all'
    ? permissions.every((p) => allPermissionsForUser.has(p))
    : permissions.some((p) => allPermissionsForUser.has(p));
};

/**
 * Returns a single string representing the user's roles. Used for telemetry (AppInsights)
 */
export const getTelemetryRoleString = ({
  adminUserRoles = [],
  userCompanyRoles = [],
}: {
  adminUserRoles?: RoleWithMetadata[];
  userCompanyRoles?: RoleWithMetadata[];
}): string => {
  const allValidRoles: RoleWithMetadata[] = [
    ...getValidRoles(adminUserRoles),
    ...getValidRoles(userCompanyRoles),
  ];

  return Array.from(new Set(allValidRoles.map((r) => r.name)))
    .sort((a, b) => a.localeCompare(b))
    .join(',');
};
