import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressBarWrapper } from '../../../assets/css/Support/ProgressBar';

export type progressBarProps = {
  sections: any[];
  currentPage: number;
};

const ProgressBar = (props: progressBarProps) => {
  const {
    sections, currentPage,
  } = props;
  const { t } = useTranslation('common');

  const getSection = (index: number) => {
    switch (index) {
      case 0:
        return 'firstSection';
      case sections.length - 1:
        return 'lastSection';
      default:
        return 'middleSection';
    }
  };

  const getProgress = (index: number) => {
    switch (true) {
      case index < currentPage:
        return 'completed';
      case index === currentPage:
        return 'inprogress';
      default:
        return 'incomplete';
    }
  };

  return (
    <ProgressBarWrapper data-testid='progress-bar'>
      {sections.map((section, index) => (
        <div
          className={getSection(index)}
          key={index}
        >
          <div className={getProgress(index)}>
            { index > currentPage && (
              index + 1
            )}
          </div>
          <div className="title">
            {t(section.textKey, section.fallbackTect)}
          </div>
        </div>
      ))}
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
