import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Styles
import * as styles from '../../assets/css/UserCard';
// Images
import DotsIcon from '../../assets/img/Dots.svg';
import NoUserIcon from '../../assets/img/No_User_Profile.svg';
import type { IDataObject } from '../../interfaces/dataObject.interface';
// Components
import Label from '../Label';

type UserCardProps = {
  avatarUrl?: string;
  name?: string;
  role?: string;
  email?: string;
  labelText?: string;
  labelType?: string;
  isAdmin?: boolean;
  isMenu?: boolean;
  menuItems?: Array<{
    label: string;
    onClick: Function;
  }>;
  key?: number;
  loadingCard?: boolean;
  showRole?: boolean; // default true
  addedBy?: string;
  joinDate?: string;
  isSelfRegistered?: boolean;
  isAutoInvited?: boolean;
};

const UserCardMenu = styled(Menu)`
  position: relative;
  .MuiMenu-paper {
    border-radius: 8px;
    margin-top: 12px;
    -webkit-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
    -moz-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
  }
  .UserCard__Menu-Option {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #353c4a;
    padding: 8px 24px;
  }
`;

const UserCard = (props: UserCardProps) => {
  const { t } = useTranslation('common');
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const showRole = props.showRole ?? true;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openerClasses = () => {
    let base = 'UserCard__Menu-Opener';

    if (anchorEl) {
      base += ' UserCard__Menu-Opener--Open';
    }

    return base;
  };

  const getRole = () => {
    let role = '';
    if (showRole) {
      role = props.role ? props.role : '.....';
    }
    return role;
  };

  const getAddedBy = () => {
    const formattedDate = moment(props.joinDate).format('MM/DD/YYYY');

    if (props.addedBy) {
      return t('company_team_users_added_by_user', {
        name: props.addedBy,
        date: formattedDate,
      });
    }

    if (props.isSelfRegistered) {
      return t('company_team_users_added_by_self_registration', { date: formattedDate });
    }

    return t('company_team_users_added_by_uipath', { date: formattedDate });
  };

  return (
    <styles.UserCard
      className={props.loadingCard ? 'UserCard__Loading' : ''}
      data-testid="UserCard"
    >
      <div className="UserCard__Avatar">
        {!props.loadingCard && (
          <div className="UserCard__Avatar-Img">
            {props.avatarUrl ? (
              <img
                src={props.avatarUrl}
                alt="User avatar"
                data-testid="UserCard__Avatar"
              />
            ) : (
              <img
                src={NoUserIcon}
                alt="User avatar"
                data-testid="NoUser_UserCard__Avatar"
              />
            )}
          </div>
        )}
        {props.isAdmin && (
          <div
            className="UserCard__Avatar-Admin"
            data-testid="Admin_UserCard__Avatar"
          >
            <p className="Bold">Admin</p>
          </div>
        )}
      </div>
      <div className="UserCard__Attributes">
        {props.name && (
          <div
            className="UserCard__Info"
            data-testid="UserCard__Info">
            <h6 className="UserCard__Heading">{`${props.name}`}</h6>
            <p className="UserCard__Subheading">{getRole()}</p>
          </div>
        )}
        {props.email && (
          <div
            className="UserCard__Email"
            data-testid="UserCard__Email">
            <a
              href={`mailto:${props.email}`}
              className="Small">
              {props.email}
            </a>
          </div>
        )}
        <div
          className="UserCard__AddedBy"
          data-testid="UserCard__AddedBy">
          {props.joinDate && (
            <div className="Small">
              {t('company_team_users_added_by_title', { text: getAddedBy() })}
            </div>
          )}
        </div>
        {props.labelText && props.labelType && (
          <div
            className="UserCard__Label"
            data-testid="UserCard__Label">
            <Label
              text={props.labelText}
              type={props.labelType} />
          </div>
        )}
      </div>
      {props.isMenu && props.menuItems && (
        <div
          className="UserCard__Menu"
          data-testid="UserCard__Menu">
          <img
            src={DotsIcon}
            alt="Menu Opener"
            className={openerClasses()}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            data-testid="MenuIcon"
          />
          <UserCardMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
            className="Select__Outer"
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: -30,
              horizontal: 'center',
            }}
          >
            {props.menuItems?.length &&
              props.menuItems.map((item: IDataObject, i: number) => (
                <MenuItem
                  onClick={item.onClick}
                  className="UserCard__Menu-Option Select__Option"
                  key={i}
                  data-testid="MenuItem"
                >
                  {item.label}
                </MenuItem>
              ))}
          </UserCardMenu>
        </div>
      )}
    </styles.UserCard>
  );
};

UserCard.propTypes = {
  avatarUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  role: PropTypes.string,
  email: PropTypes.string,
  labelText: PropTypes.string,
  labelType: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default UserCard;
