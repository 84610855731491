import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styling
import * as styles from '../../assets/css/Knowledge/SearchResults';
// Image assets
import SadRobotIcon from '../../assets/img/svg/kb/SadRobot.svg';
import { getCloudPrefixedPath } from '../../utils/cloud';

const KBSearchNoResults = () => {
  // Translate method
  const { t } = useTranslation('common');

  return (
    <styles.NoResultsWrapper data-testid="EmptySearchResult">
      <styles.NoResultsInner>
        <img
          src={SadRobotIcon}
          alt="Sad Robot"
          className="CustomerPortalPageKnowledgeBase__SadRobot"
        />
        <styles.NoResultsTitle data-testid="NoResultsTitle">
          {t(
            'knowledge_base_search_no_results_title',
            'Your query did not match any documents from the Knowledge Base.'
          )}
        </styles.NoResultsTitle >
        <h6 className="CustomerPortalKBSearchResults--NoResults__SearchResultsCopy">
          {t('knowledge_base_search_no_results_subTitle', 'Suggestions')}
        </h6>
        <ul
          className="CustomerPortalKBSearchResults--NoResults__SuggestionList"
          data-testid="NoResults__SuggestionList">
          <li>
            {t(
              'knowledge_base_search_no_results_list_item_1',
              'Make sure your search term is at least 3 characters long'
            )}
          </li>
          <li>
            {t(
              'knowledge_base_search_no_results_list_item_2',
              'Make sure that all words are spelled correctly'
            )}
          </li>
          <li>
            {t(
              'knowledge_base_search_no_results_list_item_3',
              'Try similar keywords or broader ones'
            )}
          </li>
          <li>
            {t(
              'knowledge_base_search_no_results_list_item_4',
              'Reach out to your UiPath Contacts regarding your specific search'
            )}
          </li>
          <li>
            {t(
              'knowledge_base_search_no_results_list_item_5_1',
              'Get in touch with'
            )}
            {' '}
            <a href={getCloudPrefixedPath('/company?section=contacts')}>
              {t(
                'knowledge_base_search_no_results_list_item_5_2',
                'your UiPath team'
              )}
            </a>
            {' '}
            {t(
              'knowledge_base_search_no_results_list_item_5_3',
              'regarding your inquiry'
            )}
          </li>
        </ul>
      </styles.NoResultsInner>
    </styles.NoResultsWrapper>
  );
};

export default KBSearchNoResults;
