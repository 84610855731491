import { Header } from '@customer-portal/constants';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

// Styles
import * as styled from '../../assets/css/CustomerPortalNotifications';
// Images
import noUserProfileImg from '../../assets/img/No_User_Profile.svg';
import logoRed from '../../assets/img/uipath-logo-red.png';
// Clients
import {
  axiosGet,
  axiosPost,
} from '../../client/axios';
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
// Components
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
import NotificationsModal from '../../components/CustomerPortal-NotificationsModal';
import CustomerPortalPagination from '../../components/CustomerPortal-Pagination';
import { NOTIFICATIONS } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import type {
  INotification,
  IRelatedDocType,
} from '../../interfaces/notifications.interface';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import downloadDocument from '../../lib/customerPortalDownload.utils';
// Utils
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import Container from './../../components/CustomerPortal-New-Container';
// Constants
import {
  BACKEND_HOST_NAME,
  COLLAB_ASSETS_URL,
  KB_ASSETS_URL,
  USER_PICTURE_BY_AVATARID_URL,
} from './../../constants/network.constants';
// Store
import { StoreContext } from './../../store';

const DOC_TYPE_TO_ASSET_URL: { [key in IRelatedDocType]: string } = {
  kb_documents: KB_ASSETS_URL,
  collab_documents: COLLAB_ASSETS_URL,
};

const CustomerPortalPageNoifications = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  // Local state
  const [ res, setRes ] = useState<INotification[]>([]);
  const [ noResults, setNoResults ] = useState(false);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ open, setOpen ] = useState(false);

  /* Default constants */
  const docsPerPage = 10;
  const canEditNotificationSettings = UserPermissionsHelper.isEditEmailNotificationAllowed();
  const start = (currentPage - 1) * docsPerPage;
  const end = currentPage * docsPerPage;

  // Methods
  const handleClose = () => {
    setOpen(false);
  };

  const handleError = () => {
    setOpen(false);
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'notifications_page_notifications_settings_error',
        'There was an error in saving your notification settings. Please try again.'
      ),
    });
  };

  const handleSuccess = () => {
    dispatch({
      type: 'setBannerType',
      payload: 'success',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'notifications_page_notifications_settings_success',
        'Your notification settings were successfully saved.'
      ),
    });
  };

  const handlePaginationClick = (page: number) => {
    setCurrentPage(page);
  };

  const showNotificationModal = async () => {
    setOpen(true);
  };

  const getNotificationData = async () => {
    try {
      const notificationResults = await axiosGet(
        `${BACKEND_HOST_NAME}/api/v1/notifications/getNotifications`,
        state.companyId,
        await getAccessToken(),
        {
          params: {
            skip: 0,
            limit: 10,
          },
        }
      );

      if (notificationResults?.data && notificationResults.data.length === 0) {
        setNoResults(true);
      }

      setRes(notificationResults.data);
    } catch (err) {
      console.log(err);
    }
  };

  useTrackPageViewEvent(NOTIFICATIONS);

  /* Lifecycle */
  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Notifications');
    getNotificationData();
  }, []);

  const handleNotifClick = async (notification: INotification) => {
    // Only try to download if there's a related doc and it's a URL type
    if (notification.relatedDoc && !notification.relatedDoc.hyperlink) {
      try {
        await downloadDocument(
          `${DOC_TYPE_TO_ASSET_URL[notification.relatedDocType as IRelatedDocType]}/${notification.relatedDoc._id}`,
          { company_id: state.companyId },
          {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            },
          }
        );
      } catch (e) {
        const errorMessage =
          e.response?.data?.data
            ? `Error going to hyperlink: ${e.response.data.data}`
            : `Error going to hyperlink: ${e.toString()}`;

        dispatch({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: errorMessage,
        });
      }
    }

    if (notification.seen) {
      return;
    }

    try {
      await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/notifications/markNotificationAsSeen`,
        state.companyId,
        await getAccessToken(),
        { notifId: notification._id },
      );
    } catch (err) {
      console.log(err);
    }
  };

  const renderNotificationLink = (notification: INotification) => {
    if (notification.title.indexOf('removed') !== -1) {
      return null;
    }

    // If there's no related doc found, link to notification's linkHref
    if (!notification.relatedDoc) {
      return (
        <Link
          to={notification.linkHref}
          className="NotificationLink"
          target="_blank"
          rel='noopener noreferrer'
          onClick={() => handleNotifClick(notification)}
        >
          {notification.linkName}
        </Link>
      );
    }

    // If the found related doc is of URL type, link to that
    if (notification.relatedDoc.hyperlink) {
      return (
        <a
          href={notification.relatedDoc.hyperlink}
          className="NotificationLink"
          target="_blank"
          rel='noopener noreferrer'
          onClick={() => handleNotifClick(notification)}
        >
          {notification.relatedDoc.display_name}
        </a>
      );
    }

    // Expect the found related doc to be a file type
    return (
      <div
        className="NotificationLink"
        onClick={() => handleNotifClick(notification)}
      >
        {notification.relatedDoc.display_name}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {t('notifications_page_title', 'Notifications | Customer Portal')}
        </title>
      </Helmet>

      <styled.PageWrapper data-testid="CustomerPortalNotificationsPage__wrapper">
        <styled.HomeSection>
          <BasicHero
            title={t('notifications_page_headline', 'Notifications')}
          />

          <Container cssClass="CustomerPortalPageHome__container">
            {canEditNotificationSettings && (
              <>
                <styled.notificationSettingsLink data-testid="CustomerPortalNotificationsPage__notificationSettings">
                  <a onClick={showNotificationModal}>
                    {t(
                      'notifications_page_notifications_settings_cta',
                      'Notification Settings'
                    )}
                  </a>
                </styled.notificationSettingsLink>
                <p>
                  <br />
                </p>
              </>
            )}

            {res.length > 0 && (
              <>
                <ul
                  className="Notifications__Cards-List"
                  data-testid="CustomerPortalNotificationsPage__notificationList"
                >
                  {res.slice(start, end).map((notification: INotification) => (
                    <li
                      key={notification._id}
                      className="CustomerPortalClientsSelection__clientsListItem"
                      data-testid="CustomerPortalNotificationsPage__notificationItem"
                    >
                      {notification.isGlobal ? (
                        <styled.notificationAvatarImg>
                          <img
                            src={logoRed}
                            className="Notifications__Avatar"
                            alt="user_image"
                          />
                        </styled.notificationAvatarImg>
                      ) : (
                        <styled.notificationAvatarImg>
                          <img
                            src={
                              notification.avatarURL === ''
                                ? noUserProfileImg
                                : `${USER_PICTURE_BY_AVATARID_URL}/${notification.avatarURL}`
                            }
                            className="Notifications__Avatar"
                            alt="user_image"
                          />
                        </styled.notificationAvatarImg>
                      )}
                      <styled.notificationTitle>
                        {notification.title}
                      </styled.notificationTitle>
                      <styled.notificationLink>
                        {renderNotificationLink(notification)}
                      </styled.notificationLink>
                      <styled.notificationDate>
                        <Moment fromNow>{notification.created_on}</Moment>
                      </styled.notificationDate>
                    </li>
                  ))}
                </ul>
                <CustomerPortalPagination
                  activePage={currentPage}
                  handleClick={handlePaginationClick}
                  numPagesBeforeEllipses={5}
                  numResultsPerPage={docsPerPage}
                  numResults={res.length}
                />
              </>
            )}

            {res.length === 0 && !noResults && (
              <styled.notificationsLoader data-testid="CustomerPortalNotificationsPage__loader">
                <div>
                  <CustomerPortalLoader />
                </div>
              </styled.notificationsLoader>
            )}

            {noResults && (
              <styled.noResultsTest data-testid="CustomerPortalNotificationsPage__noResults">
                {t(
                  'notifications_page_no_results',
                  'You Currently Have No Notifications.'
                )}
              </styled.noResultsTest>
            )}
          </Container>

          <NotificationsModal
            onClose={handleClose}
            onSuccess={handleSuccess}
            onError={handleError}
            open={open}
          />
        </styled.HomeSection>
      </styled.PageWrapper>
    </>
  ); // End of return
};

export default CustomerPortalPageNoifications;
