import Button from '@mui/material/Button';
import styled from 'styled-components';

export default styled(Button)`
  && {
    outline: none;
    border: none;
    ${props => `
    background-color: white;
    color: ${props.theme.palette.ink[550]};
    font-weight: normal;
    margin: 6px;
    border-radius: ${props.theme.spacing(1)}px;
    padding: 10px 16px;
  `};
  }
`;
