import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { _401 } from '../../constants/telemetry.constants';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';

const CustomerPortalPage401 = (props: any) => {
  const { t } = useTranslation('common');

  useTrackPageViewEvent(_401);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('401');
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('401_page_title', 'Unauthorized | Customer Portal')}</title>
      </Helmet>

      <ErrorPage
        errorType='401'
        header={t('page_401_hero_text', 'Unauthorized')}
        description={t(
          'page_401_description',
          'Please contact your administrator to verify the permissions on your account.'
        )}
      >
        <Link
          data-testid='ErrorPage-401__CTA'
          className='link'
          to='/'
        >
          {t('page_401_cta', 'Go to homepage')}
        </Link>
      </ErrorPage>
    </>
  );
};

export default CustomerPortalPage401;
