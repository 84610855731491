import {
  Button,
  CircularProgress,
  FormControl,
} from '@mui/material';
import React, {
  useContext,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CustomActionPanel } from '../../../assets/css/Support/CaseActionPanel';
import { axiosPost } from '../../../client/axios';
import { ActionType } from '../../../constants/caseActions.constants';
import { CREATE_REOPEN_CASE_URL } from '../../../constants/network.constants';
import { useAuth } from '../../../contexts/auth';
import type { DropDownItemData } from '../../../interfaces/sfdc.interface';
import { encodeUTF8ToBase64 } from '../../../lib/encodings.utils';
import { StoreContext } from '../../../store';
import SelectWithSearch from '../../Select-With-Search';
import TextAreaWithCharCount from '../../TextAreaWithCharCount';
import TextLabel from '../../TextLabel';

export type ReopenCaseProps = {
  id: string;
  caseNumber: string;
  reopenReasons: DropDownItemData[];
  setActionType: (value: string) => void;
};

type ReopenCaseData = {
  reason: string;
  additionalDetails: string;
};

const ReopenCase = (props: ReopenCaseProps) => {
  const {
    id, caseNumber, reopenReasons, setActionType,
  } = props;
  const baseClass = 'CaseActions';
  const { t } = useTranslation('common');
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<ReopenCaseData>({
    mode: 'onChange',
    defaultValues: {
      reason: '',
      additionalDetails: '',
    },
  });

  const reason = watch('reason');
  const additionalDetails = watch('additionalDetails');
  const [ loading, setLoading ] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const additionalDetailsInput = additionalDetails.trim();
    if (additionalDetailsInput.length < 3) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_incident_case_action_invalid_input',
          'Invalid input. Please provide a valid input with at least 3 printable characters.'
        ),
      });
      setLoading(false);
    } else {
      const body: any = {
        caseId: id,
        reasonForReopen: reason,
        additionalDetails: encodeUTF8ToBase64(additionalDetailsInput),
      };

      try {
        const result = await axiosPost(
          CREATE_REOPEN_CASE_URL,
          state.companyId,
          await getAccessToken(),
          body
        );

        if (result.status === 201) {
          dispatch({
            type: 'toggleActionPanel',
            payload: false,
          });
          setActionType(ActionType.None);
          dispatch({
            type: 'setBannerType',
            payload: 'success',
          });
          dispatch({
            type: 'setBannerMsg',
            payload: t(
              'support_incident_cases_reopen_case_success',
              'Your case has been opened and assigned to a product support engineer.'
            ),
          });
          setLoading(false);
          window.location.reload();
        }
      } catch (err) {
        dispatch({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: t(
            'support_incident_cases_reopen_case_fail',
            'We\'re sorry, we cannot reopen the case at this time; please try after some time or create a new case.'
          ),
        });
        setLoading(false);
        console.error('Something went wrong while reopening a case!', err);
      }
    }
  };

  const onCancel = () => {
    reset();
    dispatch({
      type: 'toggleActionPanel',
      payload: false,
    });
    setActionType(ActionType.None);
  };

  return (
    <CustomActionPanel data-testid="ReopenCaseSupport">
      <div className='form-container'>
        <p className='title'>
          {t(
            'support_incident_cases_reopen_case_number',
            `Reopen Case #${caseNumber}`,
            { caseNumber }
          )}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={`${baseClass}__Case-Field`}>
            <SelectWithSearch
              placeholder={reason || t('support_incident_cases_case_actions_reason_placeholder', 'Select a reason...')}
              label={t('support_incident_cases_case_actions_reason', 'Reason')}
              required
              searchable
              options={reopenReasons}
              onChange={(option: any) => {
                setValue('reason', option.label);
              }}
              className='Custom-Select_Reason'
              value={reason}
              data-testid="reason-input"
            />
          </FormControl>
          <FormControl className={`${baseClass}__Case-Field businessJustification`}>
            <TextLabel
              label={t('support_incident_cases_follow_up_case_additional_details', 'Additional Details')}
              required
            />
            <TextAreaWithCharCount
              value={additionalDetails}
              register={{ ...register('additionalDetails') }}
              aria-label="empty textarea"
              rowsMin={10}
              maxLength={1000}
              label="additionalDetails"
            />
          </FormControl>
        </form>
      </div>
      <div className='button-container'>
        <div className='button'>
          <Button
            variant='outlined'
            onClick={onCancel}
            disabled={loading}
            className='cancelBtn'
          >
            {t('support_incident_cases_cancel', 'Cancel')}
          </Button>
          {
            !loading ? (
              <Button
                variant='contained'
                color='secondary'
                onClick={handleSubmit(onSubmit)}
                disabled={!reason || !additionalDetails}
                className='submitBtn'
                data-testid='submit-button'
              >
                {t('support_incident_cases_submit', 'Submit')}
              </Button>
            ) : (
              <Button
                variant='contained'
                color='secondary'
                className='loaderBtn'
              >
                <CircularProgress
                  size={24}
                  className='loader'
                />
              </Button>
            )
          }
        </div>
      </div>
    </CustomActionPanel>
  );
};

export default ReopenCase;
