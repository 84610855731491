import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const ThumbDownFill = () => (
  <SvgIcon
    width="16px"
    height="14px"
    viewBox="0 0 16 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g
      id="00-Feedback"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="0x.1-—-Feedback"
        transform="translate(-717.000000, -577.000000)">
        <g
          id="Group-7"
          transform="translate(697.000000, 495.000000)">
          <g
            id="Group-4"
            transform="translate(0.000000, 80.000000)">
            <g
              id="thumb_down-24px-(1)"
              transform="translate(20.000000, 0.000000)">
              <polygon
                id="Path"
                points="0 0 16 0 16 16 0 16" />
              <path
                d="M10,2 L4,2 C3.44666667,2 2.97333333,2.33333333 2.77333333,2.81333333 L0.76,7.51333333 C0.7,7.66666667 0.666666667,7.82666667 0.666666667,8 L0.666666667,9.33333333 C0.666666667,10.0666667 1.26666667,10.6666667 2,10.6666667 L6.20666667,10.6666667 L5.57333333,13.7133333 L5.55333333,13.9266667 C5.55333333,14.2 5.66666667,14.4533333 5.84666667,14.6333333 L6.55333333,15.3333333 L10.9466667,10.94 C11.1866667,10.7 11.3333333,10.3666667 11.3333333,10 L11.3333333,3.33333333 C11.3333333,2.6 10.7333333,2 10,2 Z M12.6666667,2 L12.6666667,10 L15.3333333,10 L15.3333333,2 L12.6666667,2 Z"
                id="Shape"
                fill="#0067DF"
                fillRule="nonzero" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ThumbDownFill;
