import Menu from '@mui/material/Menu';
import styled from 'styled-components';

import { transitionStandard } from '../js_mixins/transitions';

export const UserCard = styled.div`
  width: 100%;
  border-radius: ${p => p.theme.spacing(1)}px;
  background: white;
  padding: ${p => p.theme.spacing(2)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${p => p.theme.spacing(2)}px;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 0;
  }

  &.UserCard__Loading {
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      height: 80px;
      position: absolute;
      width: 500px;
      display: block;
      background: linear-gradient(to right, rgba(230,230,230,0) 0%,rgba(221,221,221,0.4) 42%,rgba(221,221,221,0.4) 62%,rgba(230,230,230,0) 100%);
      animation: load 2.5s ease-out infinite;
    }
  }

  @keyframes load {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(200%);
    }
  }

  .UserCard__Attributes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 2;
    ${p => p.theme.breakpoints.down('sm')} {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .UserCard__Avatar-Img {
    width: 48px;
    height: 48px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: ${p => p.theme.spacing(3)}px;
    margin-right: ${p => p.theme.spacing(2)}px;
    position: relative;
    img {
      width: auto;
      height: 100%;
    }
  }
  .UserCard__Heading,.UserCard__Subheading {
    color: #353C4A;
  }
  .UserCard__Avatar {
    position: relative;
  }
  .UserCard__Avatar-Admin {
    position: absolute;
    bottom: 0;
    background: black;
    text-align: center;
    border-radius: ${p => p.theme.spacing(1)}px;
    width: 45px;
    height: 16px;
    p {
      color: white;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 16px;
      font-size: 1.0rem;
    }
  }
  .UserCard__Info {
    flex: 1.5;
    .UserCard__Info-Name {
      color: #353C4A;
    }
  }
  .UserCard__Email {
    flex: 2;
    a {
      color: #858F95;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.5px;
    }
  }
  .UserCard__AddedBy {
    flex: 1.5;
  }
  .UserCard__Menu {
    .UserCard__Menu-Opener {
      padding: 0 ${p => p.theme.spacing(1)}px;
      cursor: pointer;
      ${transitionStandard('opacity')};
      &--Open {
        opacity: 0.3;
      }
    }
  }
  .UserCard__Label {
    flex: 1;
    .Label {
      margin: 0 auto;
      ${p => p.theme.breakpoints.down('sm')} {
        margin: 10px auto 0 auto;
      }
    }
  }
`;

export const UserCardMenu = styled(Menu)`
  position: relative;
  .MuiMenu-paper {
    border-radius: 8px;
    margin-top: 12px;
    background: red !important;
    -webkit-box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
    -moz-box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
    box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
  }
`;
