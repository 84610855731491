import styled from 'styled-components';

export const RichTextLabel = styled.label`
  display: block;
  margin: ${p => p.theme.spacing(1)}px 0;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${p => p.theme.palette.ink['550']};
`;

export const RichTextWrapper = styled.div`
  min-height: 131px;
  border: 1px solid ${p => p.theme.palette.ink['400']};
  background-color: white;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 1.4rem;
  color: ${p => p.theme.palette.ink['550']};

  &:hover {
    border: 1px solid ${p => p.theme.palette.ink['900']};
  }

  &:focus, &:focus-within {
    border: 2px solid ${p => p.theme.palette.blue['500']};
  }

  .DraftEditor-root {
    padding: 16px 8px;
    line-height: 1.7;
  }
`;

export const RichTextFormatButtonWrapper = styled.div`
  border-bottom: 1px solid ${p => p.theme.palette.ink['400']};
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;

export const RichTextFormatButton = styled.button`
  padding: 4px;
  background-color: white;
  cursor: pointer;
  margin-right: ${p => p.theme.spacing(3)}px;
  border: none;
`;
