import MuiContainer from '@mui/material/Container';
import React from 'react';
import styled from 'styled-components';

const Container = styled(MuiContainer)`
  width: 100%;
  margin: 0 auto;
`;

type ContainerProps = {
  className?: string;
};

const CustomerPortalContainer: React.FunctionComponent<ContainerProps> = ({
  className, children,
}) => (
  <Container className={`CustomerPortalContainer ${className}`}>
    {children}
  </Container>
);

export default CustomerPortalContainer;
