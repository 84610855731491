// Components
import MenuItem from '@mui/material/MenuItem';
import React from 'react';

import * as styles from '../assets/css/Select';
// Helpers
import type { IDataObject } from '../interfaces/dataObject.interface';

const CustomizedSelects = (props: any) => {
  const base = 'Select';

  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const handleClick = (event: any) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e?: any) => {
    if (e.target.id === props.id) {
      props.onChange(e);
      handleClose();
    } else {
      if (props.onClose) {
        props.onClose(props.id);
      }
    }
  };

  const renderOptions = () => {
    if (props.options) {
      const options = props.options.map((option: IDataObject, i: number) => {
        let activeClass;
        if (props.selected && option.value === props.selected.value) {
          activeClass = `${base}__Option--Active`;
        }
        return (
          <styles.SelectOption
            onClick={handleChange}
            className={`${base}__Option ${activeClass}`}
            value={option.value}
            key={i}
            id={props.id}
            data-testid="Select__options"
          >
            <div className="Select__Option-Container">
              <p className={`${base}__Option-Label`}>{option.label}</p>
              {option.description && (
                <p className={`${base}__Option-Description`}>
                  {option.description}
                </p>
              )}
            </div>
          </styles.SelectOption>
        );
      });
      return options;
    }
  };

  const generatePlaceholder = () => {
    if (props.isFilter) {
      return (
        <>
          <p className={`${base}__Trigger-Placeholder-Title`}>Sort by: </p>
          <p className={`${base}__Trigger-Placeholder-Value`}>Newest</p>
        </>
      );
    }
    const selectValue =
        props.selected?.value ? (
          <MenuItem
            className={`${base}__Trigger-Placeholder-Title Selected`}
            value={props.selected.value}
            data-testid="Select__selectedValue"
          >
            {props.selected.label}
          </MenuItem>
        ) : (
          <p className={`${base}__Trigger-Placeholder-Title`}>
            {props.placeholderText}
          </p>
        );
    return <>{selectValue}</>;

  };

  return (
    <styles.SelectContainer
      className={`Select ${props.className ? props.className : ''}`}
      data-testid={props.testid}
    >
      <styles.SelectTrigger
        aria-owns={anchorEl ? `${base}__Menu` : undefined}
        aria-haspopup="true"
        className="Select__Trigger"
        onClick={handleClick}
        data-testid="Select__trigger"
      >
        <div className={`${base}__Trigger-Text`}>{generatePlaceholder()}</div>
        <div className={`${base}__Trigger-Arrow`} />
      </styles.SelectTrigger>
      {props.helperText && (
        <div className={`${base}__Helper-Text`}>{props.helperText}</div>
      )}

      <styles.SelectOuter
        id={`${base}__Menu`}
        className={`${base}__Outer ${props.className}`}
        open={Boolean(anchorEl)}
        elevation={0}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'center',
        }}
        onClose={handleClose}
        onClick={handleChange}
        disableRestoreFocus
      >
        <div className={`${base}__Inner`}>{renderOptions()}</div>
      </styles.SelectOuter>
    </styles.SelectContainer>
  );
};

export default CustomizedSelects;
