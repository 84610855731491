import styled from 'styled-components';

import { transitionStandard } from '../../js_mixins/transitions';

export const CompanyHeader = styled.div`
  width: 100%;
  position: relative;
  .CompanyHeader__Bg {
    width: 100%;
    height: 400px;
    ${p => p.theme.breakpoints.down('sm')} {
      height: 276px;
    }
  }
  .CompanyHeader__Bg-Default {
    background-color: #AAAAAB;
  }
  .CompanyHeader__Bg-Image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .CompanyHeader__Grid {
    height: 160px;
    position: absolute;
    width: 100%;
    bottom: 0;
    ${p => p.theme.breakpoints.down('sm')} {
      height: 140px;
    }
  }
  .CompanyHeader__Edit-Screen {
    ${transitionStandard('all')};
    background: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.Active {
      background: ${p => p.theme.palette.blue[500]};
      opacity: 0.9;
    }
    .CompanyInfoBox__Update-Icon {
      width: 45px;
      height: auto;
    }
  }
`;
