import styled from 'styled-components';

export const ResultContainer = styled.div`
  margin-top: ${p => p.theme.spacing(4)}px;
  overflow: auto;
  max-height: 80vh;
`;

export const ActionButtonDiv = styled.div`
  overflow-y: auto;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: end;
  padding-right: ${p => p.theme.spacing(4)}px;
  top: 0;
  bottom: 0;  
  align-items: center;
  min-height: 72px;  
  background-color: white;
  .MuiButtonBase-root:first-of-type {
    margin-right: ${p => p.theme.spacing(2)}px;
  }
`;

export const EmptyResultContainer = styled.div`
  padding-bottom: ${p => p.theme.spacing(3.5)}px;
  padding-right: ${p => p.theme.spacing(4)}px;
`;

export const EmptyResultLink = styled.a`
  color: ${p => p.theme.palette.blue[500]};
  font-size: 1.6rem;
  font-weight: 700;
`;

export const EmptyResultDescription = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-top: ${p => p.theme.spacing(1)}px;
  margin-bottom: ${p => p.theme.spacing(1)}px;
`;

export const LoaderContainer = styled.div`
  margin-left: 40%;
  margin-top: 25%;
  display: block;
  height: 40vh;
`;
