import { Locale } from '@customer-portal/constants';
import React, {
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

import { axiosPublicGet } from '../../../client/axios';
import { PUBLIC_FOOTER_DATA_URL } from '../../../constants/network.constants';
import type { OptionalPublicSupportProps } from '../../../interfaces/support.interface';
import { LOCALE_TO_CONTENTFUL_LANGUAGE } from './contentful.constants';
import { formatFooterData } from './contentful.utils';
import NewFooter from './NewFooter';
import formattedFooterDataMock from './NewFooter/mocks/formatted_footer_data.json';
import OldFooter from './OldFooter';

const CustomerPortalPublicFooter = (props: OptionalPublicSupportProps) => {
  const {
    setIsCsrfTokenSet, setIsRateLimited,
  } = props;
  const { i18n } = useTranslation('common');
  const [ footerData, setFooterData ] = useState<any>(null);

  const getFooterData = async () => {
    const language = i18n.language as Locale ?? Locale.en;

    try {
      const response = await axiosPublicGet(
        PUBLIC_FOOTER_DATA_URL,
        { params: { language } }
      );

      setFooterData({
        allContentfulNavMenu: {
          nodes: [
            formatFooterData(response?.data?.items?.[0]),
          ],
        },
      });
    } catch (e) {
      if (e.response?.status === 401 && setIsCsrfTokenSet) {
        setIsCsrfTokenSet(false);
        return;
      }
      if (e.response?.status === 429 && setIsRateLimited) {
        setIsRateLimited(true);
        return;
      }
      console.error(`Something went wrong trying to get footer data: `, e);
    }
  };

  useEffect(() => {
    if (!footerData) {
      getFooterData();
    }
  }, [ i18n.language, footerData ]);

  const navDataList = (footerData ? footerData : formattedFooterDataMock)
    .allContentfulNavMenu.nodes;

  const oldFooterData = navDataList.filter(
    (item: any) => !item?.location?.startsWith('New ')
  );

  const newFooterData = navDataList.filter((item: any) =>
    item?.location?.startsWith('New ')
  );

  const footer = { allContentfulNavMenu: { nodes: newFooterData.length ? newFooterData : oldFooterData } };

  // New prefix takes precendence
  return newFooterData.length ? (
    <div data-testid="PublicFooter__NewFooter">
      <NewFooter
        footerData={footer}
      />
    </div>
  ) : (
    <div data-testid="PublicFooter__OldFooter">
      <OldFooter
        footerData={footer}
        language={LOCALE_TO_CONTENTFUL_LANGUAGE[
          i18n.language as keyof typeof Locale
        ]}
      />
    </div>
  );
};

export default CustomerPortalPublicFooter;
