import type { IconProps } from '@mui/material';
import {
  Icon,
  Typography,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface HiddenSrTextProps {
  children: any;
  variant: any;
}

export const HiddenSrText = styled(({
  children, ...props
}: HiddenSrTextProps) => <Typography {...props}>{children}</Typography>)(
  () => `
    && {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
      left: 0;
      top: 0;
      margin: 0;
    }
  `
);

type Props = {
  icon: string;
  className?: string;
  srText?: string;
  outlined?: boolean;
};

const ApolloIcon = (props: Props & IconProps<any>) => {
  const {
    icon, className = '', srText, outlined, ...iconProps
  } = props;

  return (
    <>
      <Icon
        className={`${outlined ? 'material-icons-outlined' : ''} ${className}`}
        {...iconProps}
      >
        {icon}
      </Icon>
      {srText ? <HiddenSrText variant='srOnly'>{srText}</HiddenSrText> : null}
    </>
  );
};

export default ApolloIcon;
