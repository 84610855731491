import styled from 'styled-components';

export const ContactModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 80vh;
  overflow: auto;
  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
    height: 500px;
    flex-wrap: nowrap;
  }
  .PremiumSupportContactModal__Number-Container {
    width: 50%;
    margin-bottom: 20px;
    ${p => p.theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }
  .PremiumSupportContactModal__Number--Country {
    color: ${p => p.theme.palette.ink[650]}
  }
  .PremiumSupportContactModal__Number-Attribute-Container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0;
    .PremiumSupportContactModal__Number-Attribute {
      font-size: 1.2rem;
      line-height: 2.0rem;
      color: ${p => p.theme.palette.ink[650]}
    }
    .PremiumSupportContactModal__Number-Attribute--Heading {
      margin-right: 18px;
      width: 60px;
    }
    .PremiumSupportContactModal__Number-Attribute--Phone {
      font-size: 1.4rem;
      line-height: 2.0rem;
      color: ${p => p.theme.palette.blue[500]}
    }
  }
`;
