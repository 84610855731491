const premiumSupportIncidentTypes = [
  {
    id: 'production_environment_install',
    section: 'infrastructure_assistance',
    name: {
      keyText:
        'support_premium_care_form_section_item_incident_installation_assistance_title',
      fallbackText: 'Installation assistance',
    },
    description: {
      keyText:
        'support_premium_care_form_section_item_incident_installation_assistance_desc',
      fallbackText:
        'Join a screen share to guide the client through building the core UiPath platform. Included are the following: Installation of Orchestrator, License generation and application in Orchestrator (assumes all robots get their license from Orchestrator), Creation of one tenant (in case of multitenancy), Installation of one robot, Robot connection to Orchestrator, Confirmation that the platform is fully functional in terms of infrastructure, Provision of one robot with Studio, connect the Robot and test deployment of a single package. Installation/validation of HA/DR environment. Assist with technical issues which may arise during the installation.',
    },
  },
  {
    id: 'production_environment_upgrade',
    section: 'infrastructure_assistance',
    name: {
      keyText:
        'support_premium_care_form_section_item_incident_upgrade_assistance_title',
      fallbackText: 'Upgrade assistance',
    },
    description: {
      keyText:
        'support_premium_care_form_section_item_incident_upgrade_assistance_desc',
      fallbackText:
        'Join a screen share to guide the client through upgrading the core UiPath platform. Included are the following: Technical assessment of client infrastructure for the upgrade planning process, Advise on backup (MS SQL DB, Orchestrator directory, Elasticsearch indices), Upgrade Orchestrator version, Upgrade Robot version, Upgrade Studio version, Verify proper upgrade by checking assets, jobs, packages, processes, robots. Includes upgrade/migration of HA, DR and/or cloud environments.',
    },
  },
  {
    id: 'infrastructure_review',
    section: 'infrastructure_assistance',
    name: {
      keyText:
        'support_premium_care_form_section_item_incident_infrastructure_review_title',
      fallbackText: 'Infrastructure review',
    },
    description: {
      keyText:
        'support_premium_care_form_section_item_incident_infrastructure_review_desc',
      fallbackText:
        'Assess infrastructure (planned or implemented) and provide recommendations and high-level architecture options.',
    },
  },
  {
    id: 'contact_advisor',
    section: 'contact_advisor',
    name: {
      keyText:
        'support_premium_care_form_section_item_incident_contact_technical_advisor_title',
      fallbackText: 'Contact your technical advisor',
    },
    description: {
      keyText:
        'support_premium_care_form_section_item_incident_contact_technical_advisor_desc',
      fallbackText:
        'Intended for general questions including: assistance on an existing case, how-To information, troubleshooting guidance, technical advisory or support service issues.',
    },
  },
];

export default premiumSupportIncidentTypes;
