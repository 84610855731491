const tollFreeNumbers = [
  {
    name: 'Australia',
    language: 'English',
    timing: '24*7',
    phone: '+61 (1800) 934234',
    flag_pic: 'australia.png',
  },
  {
    name: 'France',
    language: 'French',
    timing: 'Mon to Fri ( 6:00 to 15:00 GMT )',
    phone: '+33 (800) 945645',
    flag_pic: 'france.png',
  },
  {
    name: 'Singapore',
    language: 'English',
    timing: '24*7',
    phone: '+65 18003211202',
    flag_pic: 'singapore.png',
  },
  {
    name: 'Spain',
    language: 'Spanish',
    timing: 'Mon to Fri ( 6:00 - 15:00 GMT )',
    phone: '+34 (900) 838904',
    flag_pic: 'spain.png',
  },
  {
    name: 'China',
    language: 'Chinese',
    timing: 'Mon to Fri ( 1:00 - 10:00 GMT )',
    phone: '+86 400 1200848',
    flag_pic: 'china.png',
  },
  {
    name: 'Germany',
    language: 'German',
    timing: 'Mon to Fri ( 6:00 to 15:00 GMT )',
    phone: '+49 (800) 7234780',
    flag_pic: 'germany.png',
  },
  {
    name: 'Russia',
    language: 'Russian',
    timing: 'Mon to Fri ( 5:00 to 14:00 GMT )',
    phone: '+7 (800) 1009601',
    flag_pic: 'russia.png',
  },
  {
    name: 'South Korea',
    language: 'Korean',
    timing: 'Mon to Fri ( 2:00 - 11:00 GMT )',
    phone: '+82 3083210234',
    flag_pic: 'southkorea.png',
  },
  {
    name: 'Thailand',
    language: 'Thai',
    timing: 'Mon to Fri ( 1:00 - 10:00 GMT )',
    phone: '+66 180 0012663',
    flag_pic: 'thailand.png',
  },
  // TODO need country flags
  {
    name: 'US',
    language: 'English',
    timing: '24*7',
    phone: '+1 (888) 621 4999',
    flag_pic: '',
  },
  {
    name: 'UK',
    language: 'English',
    timing: '24*7',
    phone: '+44 (800) 3689854',
    flag_pic: '',
  },
  {
    name: 'India',
    language: 'English',
    timing: '24*7',
    phone: '000 (800) 9190254',
    flag_pic: '',
  },
];

export default tollFreeNumbers;
