export const enterpriseSuccessIncidentTypes = [
  {
    id: 'infrastructure_review',
    section: 'infrastructure_assistance',
    name: {
      keyText:
        'enterprise_form_section_item_advisory_on_installation_of_an_environment_title',
      fallbackText: 'Advisory on Installation of an Environment',
    },
    description: {
      keyText:
        'enterprise_form_section_item_advisory_on_installation_of_an_environment_desc',
      fallbackText:
        'Join a screen share to guide the client through building the core UiPath platform. Included are the following: Installation of Orchestrator, License generation and application in Orchestrator (assumes all robots get their license from Orchestrator), Creation of one tenant (in case of multitenancy), Installation of one robot, Robot connection to Orchestrator, Confirmation that the platform is fully functional in terms of infrastructure, Provision of one robot with Studio, connect the Robot and test deployment of a single package. Installation/validation of HA/DR environment. Assist with technical issues which may arise during the installation.',
    },
  },
  {
    id: 'infrastructure_scaling',
    section: 'infrastructure_assistance',
    name: {
      keyText:
        'enterprise_form_section_item_advisory_on_upgrade_of_an_environment_title',
      fallbackText: 'Advisory on Upgrade of an Environment',
    },
    description: {
      keyText:
        'enterprise_form_section_item_advisory_on_upgrade_of_an_environment_desc',
      fallbackText:
        'Join a screen share to guide the client through upgrading the core UiPath platform. Included are the following: Technical assessment of client infrastructure for the upgrade planning process, Advise on backup (MS SQL DB, Orchestrator directory, Elasticsearch indices), Upgrade Orchestrator version, Upgrade Robot version, Upgrade Studio version, Verify proper upgrade by checking assets, jobs, packages, processes, robots. Includes upgrade/migration of HA, DR and/or cloud environments.',
    },
  },
  {
    id: 'infrastructure_scaling',
    section: 'infrastructure_assistance',
    name: {
      keyText:
        'enterprise_form_section_item_advisory_on_migration_of_an_environment_title',
      fallbackText: 'Advisory on Migration of an Environment',
    },
    description: {
      keyText:
        'enterprise_form_section_item_advisory_on_migration_of_an_environment_desc',
      fallbackText:
        'Join a screen share to guide the client through the stages of migrating the UiPath platform from one deployment environment to another.  Typical advisory involves helping with planning on migration from on premise versions of the platform to UiPath’s Automation Cloud.',
    },
  },
  {
    id: 'implementation_review',
    section: 'technical_assistance',
    name: {
      keyText:
        'enterprise_form_section_item_code_design_advisory_title',
      fallbackText: 'Code Design Advisory',
    },
    description: {
      keyText:
        'enterprise_form_section_item_code_design_advisory_desc',
      fallbackText:
        'Review workflow/process code and recommend design best practices & patterns especially around establishment of development standards and the use of reusable components and libraries across automations.',
    },
  },
  {
    id: 'best_coding_practices',
    section: 'technical_assistance',
    name: {
      keyText: 'enterprise_form_section_item_code_review_title',
      fallbackText: 'Code Review',
    },
    description: {
      keyText: 'enterprise_form_section_item_code_review_desc',
      fallbackText:
        'Review workflow/process code and recommend coding best practices including performance optimization and exception management.',
    },
  },
  {
    id: 'platform_configuration_advisory',
    section: 'technical_assistance',
    name: {
      keyText:
        'enterprise_form_section_item_platform_configuration_advisory_title',
      fallbackText: 'Platform Configuration Advisory',
    },
    description: {
      keyText:
        'enterprise_form_section_item_platform_configuration_advisory_desc',
      fallbackText:
        'Advise on implementation and integration of UiPath Platform products. Advise on best-practice in product configuration including but not limited to folder structure and implementation, role-based security, license distribution, user accounts, password rotation, etc.​',
    },
  },
  {
    id: 'new_release_walkthrough',
    section: 'technical_assistance',
    name: {
      keyText: 'enterprise_form_section_item_new_release_walkthrough_title',
      fallbackText: 'New Release Technical Capabilities Walkthrough',
    },
    description: {
      keyText: 'enterprise_form_section_item_new_release_walkthrough_desc',
      fallbackText:
        'Provide technical information about key product capabilities and bug fixes in a new release.',
    },
  },
  {
    id: 'process_go_live_assistance',
    section: 'technical_assistance',
    name: {
      keyText: 'enterprise_form_section_item_process_go_live_assistance_title',
      fallbackText: 'Process go-live Assistance',
    },
    description: {
      keyText: 'enterprise_form_section_item_process_go_live_assistance_desc',
      fallbackText:
        'Join a screen share to assist with technical issues with UiPath components which may arise during the deployment of a process in a production environment.',
    },
  },
];

export default enterpriseSuccessIncidentTypes;

