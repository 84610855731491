import type { Locale } from '@customer-portal/constants';
import {
  PUBLIC_SUPPORT_CSRF_COOKIE_NAME,
  PUBLIC_SUPPORT_FORM_CSRF_POLLING_INTERVAL_MS,
  PUBLIC_SUPPORT_FORM_RATE_LIMIT_RETRY_AFTER_MS,
} from '@customer-portal/constants';
import Cookies from 'js-cookie';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPublicGet } from '../../client/axios';
import CustomerPortalPublicHeader from '../../components/cp_header/CustomerPortal-Public-Header';
import CustomerPortalLanding from '../../components/CustomerPortal-Landing';
import CustomerPortalPublicFooter from '../../components/Footer/PublicFooter';
import NotificationBanner from '../../components/Notification-Banner';
import StickyMessageBanner from '../../components/StickyMessageBanner';
import { PUBLIC_GET_CSRF_TOKEN_URL } from '../../constants/network.constants';
import { publicLandingPage } from '../../constants/support.constants';
import { LocaleToAzureTranslatorLocale } from '../../constants/webchat.constants';
import { StoreContext } from '../../store';
import { getUrlWithAllParams } from '../../utils/cloud';
import { featureFlag } from '../../utils/featureFlag';
import { webchatClient } from '../../utils/webchatClient';
import CustomerPortalPageAddCase from '../AddCase';

const CustomerPortalPublicLandingPage = () => {
  const [ isCsrfTokenSet, setIsCsrfTokenSet ] = useState(false);
  const [ showSupportForm, setShowSupportForm ] = useState(false);
  const [ isRateLimited, setIsRateLimited ] = useState(false);
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const {
    i18n, t,
  } = useTranslation('common');

  const isNavigationDisabled = !isCsrfTokenSet || isRateLimited;

  const closeStickyMessage = (i: number) => {
    dispatch({
      type: 'removeNotificationBannerMessage',
      payload: i,
    });
  };

  const getCsrfToken = useCallback(async () => {
    try {
      const csrfResult = await axiosPublicGet(PUBLIC_GET_CSRF_TOKEN_URL);
      if (csrfResult.status === 200) {
        setIsCsrfTokenSet(true);
      }
    } catch (e) {
      if (e.response?.status === 429) {
        setIsRateLimited(true);
        return;
      }
      console.error(e);
    }
  }, []);

  const initChatbot = useCallback(async () => {
    // Check if chatbot is enabled and initialize accordingly
    if (await featureFlag.isChatbotEnabled()) {
      const language = i18n.language as Locale;
      try {
        // Call deinitialize (may have no effect) to ensure that the chatbot is re-initialized with the correct language
        await webchatClient.deinitialize();
        await webchatClient.initialize({
          language,
          settings: {
            disableHtmlContentSanitization: false,
            enableUnreadMessageTitleIndicator: false,
            startBehavior: 'injection',
            getStartedData: {
              email: '',
              language: LocaleToAzureTranslatorLocale.get(language),
              isGuestUser: true,
              url: `${window.location.origin}${getUrlWithAllParams()}`,
            },
          },
        });
        webchatClient.show();
      } catch (e) {
        console.error('Error initializing chatbot', e);
      }
    }
  }, [ i18n.language ]);

  const showRateLimitErrorMessage = useCallback(() => {
    dispatch({
      type: 'setBannerIsCloseEnabled',
      payload: false,
    });
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'support_form_rate_limited_message',
        'You\'ve made too many requests in a short period of time. Please wait one minute before trying again.'
      ),
    });
    dispatch({
      type: 'setBannerAutoHide',
      payload: false,
    });
  }, [ t, dispatch ]);

  const hideRateLimitErrorMessage = useCallback(() => {
    dispatch({
      type: 'setBannerAutoHide',
      payload: true,
    });
    dispatch({
      type: 'setBannerMsg',
      payload: '',
    });
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerIsCloseEnabled',
      payload: true,
    });
  }, [ dispatch ]);

  // Clear the rate limit error message when page loads
  useEffect(() => {
    hideRateLimitErrorMessage();
  }, [ hideRateLimitErrorMessage ]);

  // Show rate limit error message if rate
  // limited and hide after 1 minute
  useEffect(() => {
    if (isRateLimited) {
      showRateLimitErrorMessage();
      setTimeout(() => {
        setIsRateLimited(false);
        hideRateLimitErrorMessage();
      }, PUBLIC_SUPPORT_FORM_RATE_LIMIT_RETRY_AFTER_MS);
    }
  }, [ isRateLimited, showRateLimitErrorMessage, hideRateLimitErrorMessage ]);

  // Set listener to check every 5 seconds if CSRF
  // token is set in cookies to re-fetch if needed
  useEffect(() => {
    const interval = setInterval(() => {
      if (!Cookies.get(PUBLIC_SUPPORT_CSRF_COOKIE_NAME)) {
        setIsCsrfTokenSet(false);
      }
    }, PUBLIC_SUPPORT_FORM_CSRF_POLLING_INTERVAL_MS);
    return () => clearInterval(interval);
  }, []);

  // Fetch CSRF token if not set and only when not rate limited
  useEffect(() => {
    if (!isCsrfTokenSet && !isRateLimited) {
      getCsrfToken();
    }
  }, [ isCsrfTokenSet, isRateLimited, getCsrfToken ]);

  useEffect(() => {
    if (isCsrfTokenSet) {
      initChatbot();
    }
  }, [ isCsrfTokenSet, initChatbot ]);

  return (
    <div>
      <CustomerPortalPublicHeader />
      {state.bannerMsg?.length > 0 && (
        <NotificationBanner
          message={state.bannerMsg}
          type={state.bannerType}
          isCloseEnabled={state.bannerIsCloseEnabled}
        />
      )}
      <StickyMessageBanner
        messages={state.notificationBannerMessages ?? []}
        handleCloseMessage={closeStickyMessage}
      />
      {showSupportForm ?
        <CustomerPortalPageAddCase
          isPublic
          isCsrfTokenSet={isCsrfTokenSet}
          setIsCsrfTokenSet={setIsCsrfTokenSet}
          isRateLimited={isRateLimited}
          setIsRateLimited={setIsRateLimited} />
        :
        <CustomerPortalLanding
          text={publicLandingPage}
          isPublicLandingPage
          isNavigationDisabled={isNavigationDisabled}
          setShowSupportForm={setShowSupportForm}
        />}
      <CustomerPortalPublicFooter
        setIsCsrfTokenSet={setIsCsrfTokenSet}
        setIsRateLimited={setIsRateLimited}
      />
    </div>
  );
};

export default CustomerPortalPublicLandingPage;
