import {
  CaseRecordType,
  Header,
} from '@customer-portal/constants';
import axios from 'axios';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Redirect } from 'react-router-dom';

import NoCompanyId from '../../components/CustomerPortal-NoCompanyId';
import { isBasicCaseType } from '../../constants/account.constants';
// Constants
import {
  SFDC_PREMIUM_DASHBOARD_URL,
  SFDC_URL,
} from '../../constants/network.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
// Interfaces
import type { ISupportList } from '../../interfaces/supportTickets.interface';
// Utils
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
import { featureFlag } from '../../utils/featureFlag';
import CustomerPortalSupportDashboard from './CustomerPortalSupportDashboard';
// Components
import CustomerPortalSupportIncident from './SupportIncident/CustomerPortalSupportIncident';
import CustomerPortalSupportPremium from './SupportPremium/CustomerPortalSupportPremium';

const CustomerPortalSupportFactory = (props: any) => {
  const sectionParam = new URLSearchParams(props.location.search).get(
    'section'
  );
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ premiumList, setPremiumList ] = useState<ISupportList[]>([]);
  const [ incidentList, setIncidentList ] = useState<ISupportList[]>([]);
  const [ dashboardData, setDashboardData ] = useState({});
  const [ isEntityFiltersEnabled, setIsEntityFiltersEnabled ] = useState(false);

  const canViewPremium = UserPermissionsHelper.isViewPremiumSupportAllowed();
  const canViewIncident = UserPermissionsHelper.isViewSupportAllowed();
  const canUserSupportRestrictedAccount = UserPermissionsHelper.canUserSupportRestrictedAccount();

  const fetchSFDCData = async () => {
    if (typeof window !== 'undefined') {
      if (state.companyId !== '') {
        try {
          const sfdcResults = await axios.get(`${SFDC_URL}/cases`, {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              [Header.SELECTED_ACCOUNT_ID]: state.companyId,
              [Header.AUTH_TYPE]: getAuthType(),
            },
          });

          if (
            sfdcResults?.data?.results &&
            sfdcResults.data.results.length > 0
          ) {
            setIncidentList(
              sfdcResults.data.results.filter((el: any) => isBasicCaseType(el.RecordTypeId))
            );
            setPremiumList(
              sfdcResults.data.results.filter((el: any) => el.RecordTypeId === CaseRecordType.PREMIUM)
            );
          }

          const isEntityManagementEnabled = await featureFlag.isEntityManagementEnabled(
            state.companyId,
            await getAccessToken()
          );
          setIsEntityFiltersEnabled(isEntityManagementEnabled);
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const fetchDashboardData = async () => {
    if (state.companyId !== '') {
      try {
        const dashboardResults = await axios.get(
          `${SFDC_PREMIUM_DASHBOARD_URL}/cases/proactiveCare/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              [Header.SELECTED_ACCOUNT_ID]: state.companyId,
              [Header.AUTH_TYPE]: getAuthType(),
            },
          }
        );

        if (
          dashboardResults?.data?.dashboardData
        ) {
          setDashboardData(dashboardResults.data.dashboardData);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  /* Lifecycle */
  useEffect(() => {
    // hooks go here!!
    fetchSFDCData();
    fetchDashboardData();

    return () => {
      setIncidentList([]);
      setPremiumList([]);
      setDashboardData({});
    };
  }, [ props.location.pathname ]);

  // Early return if no company id
  if (!state.companyId) {
    return <NoCompanyId />;
  }

  if (!canUserSupportRestrictedAccount) {
    return <Redirect to="/support-restricted" />;
  }

  switch (sectionParam) {
    case 'cases-premium':
      if (canViewPremium) {
        return (
          <CustomerPortalSupportPremium
            incidentsList={premiumList}
            isLoading={isLoading}
            isEntityFiltersEnabled={isEntityFiltersEnabled}
          />
        );
      }
      // Go to Incident
      return <Redirect to="/unauthorized" />;

    case 'tickets-dashboard':
      if (canViewPremium) {
        return <CustomerPortalSupportDashboard data={dashboardData} />;
      }
      return <Redirect to="/unauthorized" />;

    case 'tickets-incident':
      if (canViewIncident) {
        return (
          <CustomerPortalSupportIncident
            incidentsList={incidentList}
            isLoading={isLoading}
            isEntityFiltersEnabled={isEntityFiltersEnabled}
          />
        );
      }
      return <Redirect to="/unauthorized" />;

    default:
      if (canViewIncident) {
        return (
          <CustomerPortalSupportIncident
            incidentsList={incidentList}
            isLoading={isLoading}
            isEntityFiltersEnabled={isEntityFiltersEnabled}
          />
        );
      } else if (canViewPremium) {
        return (
          <CustomerPortalSupportPremium
            incidentsList={premiumList}
            isLoading={isLoading}
            isEntityFiltersEnabled={isEntityFiltersEnabled}
          />
        );
      }
      return <Redirect to="/unauthorized" />;
  }
};

export default CustomerPortalSupportFactory;
