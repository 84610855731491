import React from 'react';
import styled from 'styled-components';

// Images and Icons
import SearchIconSrc from '../assets/img/svg/kb/Search2.svg';

const CustomerPortalSearchInputComponent = styled.div`
  ${(props): any => `
    margin-bottom: ${props.theme.spacing(2)}px;
  `};
  width: 100%;
  max-width: 588px;

  .CustomerPortalSearchInput__Container {
    position: relative;
  }
`;

const Input = styled.input`
  ${(props): any => `
    color: ${props.theme.palette.ink[700]};
    border: 1px solid ${props.theme.palette.grey[300]};
    padding: ${props.theme.spacing(1)}px ${props.theme.spacing(7)}px ${props.theme.spacing(1)}px ${props.theme.spacing(3)}px;
  `};
  font-size: 1.6rem !important;
  line-height: 1.25;
  border-radius: 8px;
  width: 100%;
  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  min-height: 56px;

  &::placeholder {
    color: #858f95; // TODO: Replace when design system
  }

  &:hover {
    border: 1px solid ${props => props.theme.palette.blue[200]};
  }

  &:focus {
    border: 1px solid ${props => props.theme.palette.blue[500]};
  }
`;

const SubmitButton = styled.button`
  position: absolute;
  border: none;
  right: ${props => `${props.theme.spacing(1)}px`};
  top: ${props => `${props.theme.spacing(1)}px`};
  width: 40px;
  height: 40px;
  background: ${props => props.theme.palette.blue[500]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    background: url('${SearchIconSrc}') no-repeat;
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
  }

  &:hover {
    outline: none;
  }
`;

type SearchInputProps = {
  handleOnChange: (val: string) => void;
  handleSubmit: () => void;
  keyword?: string;
  placeholderText?: string;
  cssClass?: string;
  children?: any;
};

/* This is a pure react component */
const CustomerPortalSearchInput = (props: SearchInputProps) => {
  const defaultPlaceholdertext = props.placeholderText || '';
  const searchKeyword = props.keyword || '';

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!e) {
      return;
    }
    e.preventDefault();

    props.handleSubmit();
  };

  return (
    <CustomerPortalSearchInputComponent
      className={props.cssClass ? props.cssClass : undefined}
    >
      <form
        className="CustomerPortalSearchInput__Container"
        onSubmit={handleSubmit}
      >
        <Input
          type="text"
          name="keyword"
          placeholder={defaultPlaceholdertext}
          value={searchKeyword}
          onChange={(e: any) => {
            props.handleOnChange(e.target.value);
          }}
          className={props.cssClass}
          data-testid='search-input'
        />
        <SubmitButton
          type="submit"
          data-testid='submit-button' />
      </form>
    </CustomerPortalSearchInputComponent>
  );
};

export default CustomerPortalSearchInput;
