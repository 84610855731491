import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { SelectAccountModalWrapper } from '../../../assets/css/Admin/SelectAccountModal';
import { CPTableContainer } from '../../../assets/css/Table';
import { axiosGet } from '../../../client/axios';
import { BROWSE_CUSTOMERS_URL } from '../../../constants/network.constants';
import { useAuth } from '../../../contexts/auth';
import { StoreContext } from '../../../store';
import CustomerPortalSearchInput from '../../CustomerPortal-SearchInput';
import Modal from '../../Modal';
import Loader from './../../CustomerPortal-Loader';

type AddNewHapoAccountModalProps = {
  handleEnablementForAccount: (companyId: string) => void;
  handleClose: () => void;
};

const AddNewHapoAccountModal = ({
  handleEnablementForAccount,
  handleClose,
}: AddNewHapoAccountModalProps) => {
  type accountType = {
    companyId: string;
    companyName: string;
  };

  // Constants
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const limit = 5;
  const [ accounts, setAccounts ] = useState<accountType[]>([]);
  const [ searchPhrase, setSearchPhrase ] = useState<string | undefined>('');
  const [ searchPhraseChanged, setSearchPhraseChanged ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const shouldShowEmptyState = () => !loading && searchPhrase && accounts.length === 0;

  const [ debouncedCallApi ] = useState(() =>
    _.debounce(() => {
      setSearchPhraseChanged(true);
    }, 400)
  );

  const handleSearchOnChange = (val: string) => {
    setSearchPhrase(val);
    setLoading(true);
    debouncedCallApi();
  };

  const handleOnAccountSelect = (companyId: string) => {
    handleEnablementForAccount(companyId);
  };

  useEffect(() => {
    if (searchPhraseChanged) {
      if (searchPhrase) {
        fetchAccounts();
      } else {
        setAccounts([]);
        setLoading(false);
      }
      setSearchPhraseChanged(false);
    }
  }, [ searchPhraseChanged ]);

  const fetchAccounts = async () => {
    // Fetch CompanyData
    try {
      const accountsRes: any = await axiosGet(
        BROWSE_CUSTOMERS_URL,
        state.companyId,
        await getAccessToken(),
        {
          params: {
            action: 'search',
            sort_by: 'name',
            limit,
            keywords: searchPhrase,
            onlyNonHapoCustomers: true,
          },
        }
      );

      setLoading(false);
      if (accountsRes.status === 200) {
        const newAccounts = accountsRes.data.data.customers.map((account: any) => ({
          companyId: account.id,
          companyName: account.name,
        }));
        setAccounts(newAccounts);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const getAccountsTable = () => (
    <SelectAccountModalWrapper>
      <CPTableContainer data-testid="AddAccountTableContainer">
        <Table
          size="small"
          aria-label="Accounts Table">
          <TableHead>
            <TableRow>
              <TableCell>Company ID</TableCell>
              <TableCell>Company Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="AddAccountTableBody">
            {accounts.map((account: accountType) => (
              <TableRow
                className="Table-Row"
                key={account.companyId}
                data-testid={account.companyId}
                onClick={() => handleOnAccountSelect(account.companyId)}
              >
                <TableCell>{account.companyId}</TableCell>
                <TableCell>{account.companyName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CPTableContainer>
    </SelectAccountModalWrapper>
  );

  return (
    <Modal
      modalTitle="Enable New Account"
      modalHeading="Select an Account"
      modalDescription="Modal for selecting an account to enable HAPO"
      open
      handleClose={handleClose}
      testId="AddNewHapoAccountModal"
    >
      <SelectAccountModalWrapper data-testid='HapoAddAccountModalWrapper'>
        <CustomerPortalSearchInput
          handleOnChange={handleSearchOnChange}
          handleSubmit={() => {}}
          placeholderText="Search for Company Name or Id"
          keyword={searchPhrase || ''}
        />

        {!loading && accounts.length > 0 && getAccountsTable()}
        {loading && (
          <div className="HapoManagement__Loader">
            <Loader />
          </div>
        )}
        {shouldShowEmptyState() && (
          <p
            className="No-Licenses-Text"
            data-testid="EmptyState">
            No Account found!!
          </p>
        )}
      </SelectAccountModalWrapper>
    </Modal>
  );
};

export default AddNewHapoAccountModal;
