import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
// charts
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Surface,
  Symbols,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// Styles
import * as styles from '../../assets/css/CustomerPortalSupportDashboard';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import Loader from '../../components/CustomerPortal-Loader';
import Container from '../../components/CustomerPortal-New-Container';
// components
import CustomerPortalSupportHeader from '../../components/support/CustomerPortal-Support-Header';
// Constants
import {
  priorityColors,
  productColors,
  statusColors,
} from '../../constants/supportDashboard.constants';
import { SUPPORT_DASHBOARD } from '../../constants/telemetry.constants';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';

const PremiumSupportDashboard = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  const dashboardData = props.data;

  // Hero
  const heroTitle = t('support_dashboard_hero_title', 'Dashboard');
  const heroModifiers = 'FontBold RightPadding';

  const EmptyChart = () => (
    <h1 className="CustomerPortalSupportDashboard__chartNoData">
      {t('support_dashboard_no_results', 'No Data Available')}
    </h1>
  );

  const LoadingChart = () => (
    <div className="CustomerPortalSupportDashboard__chartLoader">
      <Loader />
    </div>
  );

  type DashboardBarChartProps = {
    data: any;
    colors: any[];
    isStacked?: boolean;
    barSize?: number;
    isForMultipleMonths: boolean;
  };

  type DashboardPieChartProps = {
    data: any;
    colors: any[];
  };

  const DashboardPieChart = (props: DashboardPieChartProps) => {
    const data: any[] = props.data;
    const colors = props.colors;
    let allLegendTypes: any = [];

    if (data[0]?.allTypes?.length > 0) {
      allLegendTypes = data[0].allTypes;
    } else {
      return <EmptyChart />;
    }
    // convert it into piechart data [{name, value}]
    const pieData = allLegendTypes
      .map((el: any, index: number) => {
        const tempObj: any = {};
        tempObj.value = data[0][el] ? data[0][el] : 0;
        tempObj.name = el;
        return tempObj;
      })
      .filter((el: any) => el.value > 0);

    const chartMargins = {
      top: 20,
      right: 5,
      left: 5,
      bottom: 10,
    };

    const legendWrapper = {
      display: 'block',
      width: `45%`,
      height: '100%',
      maxWidth: '361px',
    };

    const wrapperStyle = {
      display: 'block',
      width: `100%`,
      height: '300px',
      maxWidth: '500px',
    };

    return (
      <div
        className="CustomerPortalSupportDashboard__PieChart"
        style={wrapperStyle}
      >
        <ResponsiveContainer>
          <PieChart margin={chartMargins}>
            <Legend
              layout="vertical"
              align="left"
              verticalAlign="top"
              iconSize={12}
              iconType="circle"
              content={renderLegend}
              wrapperStyle={{
                ...legendWrapper,
                float: 'left',
              }}
            />
            <Tooltip />
            <Pie
              data={pieData}
              dataKey="value"
              nameKey="name"
              innerRadius={55}
              outerRadius={90}
              fill="#8884d8"
            >
              {pieData.map((el: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getColorForType(el.name, colors, index)}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const renderLegend = (props: any) => {
    const baseClass = 'PieChartLegend';
    const {
      payload, iconSize,
    } = props;
    const SIZE = 32;
    const halfSize = SIZE / 2;
    const viewBox = {
      x: 0,
      y: 0,
      width: SIZE,
      height: SIZE,
    };
    const totalCasesArray = payload.map((entry: any) => entry.payload.value);
    const totalCases = totalCasesArray.reduce(
      (a: number, b: number) => a + b,
      0
    );
    const svgStyle = {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: 4,
    };
    return (
      <div>
        <div className={`${baseClass}__TotalsView`}>
          <p className={`${baseClass}__TotalsView__Total`}>{totalCases}</p>
          <p className={`${baseClass}__TotalsView_Cases`}>
            {t('support_dashboard_cases_text', 'Cases')}
          </p>
        </div>
        <table className={`${baseClass}__Table`}>
          {payload.map((entry: any, index: number) => (
            <tbody key={index}>
              <tr>
                <td>
                  <Surface
                    width={iconSize}
                    height={iconSize}
                    viewBox={viewBox}
                    style={svgStyle}
                  >
                    <Symbols
                      fill={entry.color}
                      cx={halfSize}
                      cy={halfSize}
                      size={SIZE}
                      sizeType="diameter"
                      type="circle"
                    />
                  </Surface>
                </td>
                <td>
                  <span className={`${baseClass}__Table__ItemName`}>
                    {entry.value}
                  </span>
                </td>
                <td className={`${baseClass}__Table__ItemCount_TableData`}>
                  <span className={`${baseClass}__Table__ItemCount`}>
                    {entry.payload.value}
                  </span>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    );
  };

  const DashboardBarChart = (props: DashboardBarChartProps) => {
    const data = props.data;
    let allLegendTypes: any = [];
    const isStacked = props.isStacked ?? false;
    const barSize = props.barSize ?? 20;
    if (data[0]?.allTypes?.length > 0) {
      allLegendTypes = data[0].allTypes;
    } else {
      return <EmptyChart />;
    }

    const colors = allLegendTypes.map((x: string, index: number) =>
      getColorForType(x, props.colors, index)
    );

    const barData = props.isForMultipleMonths
      ? data
      : allLegendTypes.map((el: any, index: number) => {
        const tempObj: any = {};
        tempObj.value = data[0][el];
        tempObj.name = el;
        return tempObj;
      });

    const wrapperStyle = {
      display: 'block',
      marginRight: '20px',
      height: '300px',
      maxWidth: '500px',
    };

    return (
      <div
        className="CustomerPortalSupportDashboard__BarChart"
        style={wrapperStyle}
      >
        <ResponsiveContainer className="ChartWrap">
          <BarChart data={barData}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="name"
              type="category" />
            <YAxis />
            <Tooltip />
            <Legend
              align="left"
              verticalAlign="top"
              iconSize={8}
              iconType="circle"
              payload={allLegendTypes.map((type: string, index: number) => ({
                type: 'circle',
                color: `${colors[index]}`,
                value: `${type}`,
              }))}
            />
            {props.isForMultipleMonths
              ? getBarForLastThreeMonth(barData, barSize, colors, isStacked)
              : getBarForLastMonth(barData, barSize, colors, isStacked)}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const getColorForType = (type: string, colors: any[], index: number) => {
    let color: any = undefined;
    colors.forEach((el: any, i: number) => {
      if (el.name.toLowerCase() === type.toLowerCase()) {
        color = el.value;
      }
    });
    return color === undefined ? colors[index % colors.length].value : color;
  };

  const getBarForLastMonth = (
    data: any,
    barSize: number,
    colors: any[],
    isStacked: boolean
  ) => (
    <Bar
      dataKey="value"
      maxBarSize={barSize}>
      {data.map((el: any, index: number) => (
        <Cell
          key={`cell-${index}`}
          fill={colors[index % colors.length]} />
      ))}
    </Bar>
  );

  const getBarForLastThreeMonth = (
    data: any,
    barSize: number,
    colors: string[],
    isStacked: boolean
  ) => {
    let allLegendTypes = [];
    allLegendTypes = data[0].allTypes;
    return allLegendTypes.map((el: any, j: any) => (
      <Bar
        key={j}
        dataKey={el}
        maxBarSize={barSize}
        fill={colors[j]}
        name={el}
        stackId={isStacked ? 'a' : undefined}
      />
    ));
  };

  useTrackPageViewEvent(SUPPORT_DASHBOARD);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Support Dashboard');
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t('dashboard_page_title', 'Dashboard | Customer Portal')}
        </title>
      </Helmet>
      <styles.SupportDashboard>
        <CustomerPortalSupportHeader
          title={heroTitle}
          cssClass={heroModifiers}
          showStickerText
        />

        <Container>
          {/* Weekly Data */}
          <div className="CustomerPortalSupportDashboard__weeklyDataContainer">
            <div
              className="CustomerPortalSupportDashboard__weeklyData"
              data-testid="weekly-data-container"
            >
              {dashboardData.thisWeek?.data && (
                <div>
                  <h2 className="CustomerPortalSupportDashboard__weeklyData-Heading">
                    {t('support_dashboard_this_week_title', 'This week')}
                  </h2>
                  {(dashboardData.thisWeek.data[0].maxValue ?? 0) === 1 && (
                    <span className="CustomerPortalSupportDashboard__weeklyData-Subheading">
                      <span className="CustomerPortalSupportDashboard__weeklyData-SubheadingCount">
                        {dashboardData.thisWeek.data[0].maxValue ?? 0}
                        {' '}
                      </span>
                      {t(
                        'support_dashboard_this_week_description',
                        'New Support Case created this week'
                      )}
                    </span>
                  )}
                  {(dashboardData.thisWeek.data[0].maxValue ?? 0) !== 1 && (
                    <span className="CustomerPortalSupportDashboard__weeklyData-Subheading">
                      <span className="CustomerPortalSupportDashboard__weeklyData-SubheadingCount">
                        {dashboardData.thisWeek.data[0].maxValue ?? 0}
                        {' '}
                      </span>
                      {t(
                        'support_dashboard_this_week_description',
                        'New Support Case created this week'
                      )}
                    </span>
                  )}
                </div>
              )}
              {!dashboardData.thisWeek && <LoadingChart />}
            </div>
          </div>

          {/* This month Cases */}
          <div
            className="CustomerPortalSupportDashboard__thisMonthContainer"
            data-testid="monthly-data-container"
          >
            <h2 className="CustomerPortalSupportDashboard__categoryTitle">
              {t(
                'support_dashboard_this_month_title',
                'This month\'s Support Cases'
              )}
            </h2>

            {/* 3X GRID Charts */}
            <div className="CustomerPortalSupportDashboard__3XGridCharts">
              {/* Chart 1 */}
              <div className="CustomerPortalSupportDashboard__chart">
                <h3 className="CustomerPortalSupportDashboard__chartTitle CustomerPortalSupportDashboard__chartTitle--smallChart">
                  {t('support_dashboard_chart_title_by_status', 'By Status')}
                </h3>
                {dashboardData.thisMonth?.byStatus &&
                  dashboardData.thisMonth.byStatus.length === 0 && (
                  <EmptyChart />
                )}

                {!dashboardData.thisMonth && <LoadingChart />}

                {dashboardData.thisMonth?.byStatus &&
                  dashboardData.thisMonth.byStatus.length > 0 && (
                  <DashboardBarChart
                    data={dashboardData.thisMonth.byStatus}
                    colors={statusColors}
                    barSize={49}
                    isForMultipleMonths={false}
                  />
                )}
              </div>
              {/* Chart 2 */}
              <div className="CustomerPortalSupportDashboard__chart">
                <h3 className="CustomerPortalSupportDashboard__chartTitle CustomerPortalSupportDashboard__chartTitle--smallChart">
                  {t('support_dashboard_chart_title_by_product', 'By Product')}
                </h3>
                {dashboardData.thisMonth?.byProduct &&
                  dashboardData.thisMonth.byProduct.length === 0 && (
                  <EmptyChart />
                )}

                {!dashboardData.thisMonth && <LoadingChart />}

                {dashboardData.thisMonth?.byProduct &&
                  dashboardData.thisMonth.byProduct.length > 0 && (
                  <DashboardPieChart
                    data={dashboardData.thisMonth.byProduct}
                    colors={productColors}
                  />
                )}
              </div>
              {/* Chart 3 */}
              <div className="CustomerPortalSupportDashboard__chart">
                <h3 className="CustomerPortalSupportDashboard__chartTitle CustomerPortalSupportDashboard__chartTitle--smallChart">
                  {t(
                    'support_dashboard_chart_title_by_priority',
                    'By Priority'
                  )}
                </h3>

                {dashboardData.thisMonth?.byPriority &&
                  dashboardData.thisMonth.byPriority.length === 0 && (
                  <EmptyChart />
                )}

                {!dashboardData.thisMonth && <LoadingChart />}

                {dashboardData.thisMonth?.byPriority &&
                  dashboardData.thisMonth.byPriority.length > 0 && (
                  <ResponsiveContainer
                    className="ChartWrap"
                    width="99%"
                    aspect={2}
                  >
                    <DashboardBarChart
                      data={dashboardData.thisMonth.byPriority}
                      colors={priorityColors}
                      barSize={49}
                      isForMultipleMonths={false}
                    />
                  </ResponsiveContainer>
                )}
              </div>
            </div>
          </div>

          {/* Last Three Month Cases */}
          <div
            className="CustomerPortalSupportDashboard__thisMonthContainer"
            data-testid="last-three-months-data-container"
          >
            <h2 className="CustomerPortalSupportDashboard__categoryTitle">
              {t(
                'support_dashboard_last_3_months_title',
                'Last Three Months Support Cases'
              )}
            </h2>

            {/* 3X GRID Charts */}
            <div className="CustomerPortalSupportDashboard__3XGridCharts">
              {/* Chart 1 */}
              <div className="CustomerPortalSupportDashboard__chart">
                <h3 className="CustomerPortalSupportDashboard__chartTitle CustomerPortalSupportDashboard__chartTitle--smallChart">
                  {t('support_dashboard_chart_title_by_status', 'By Status')}
                </h3>

                {dashboardData.lastThreeMonths?.byStatus &&
                  dashboardData.lastThreeMonths.byStatus.length === 0 && (
                  <EmptyChart />
                )}

                {!dashboardData.lastThreeMonths && <LoadingChart />}

                {dashboardData.lastThreeMonths?.byStatus &&
                  dashboardData.lastThreeMonths.byStatus.length > 0 && (
                  <DashboardBarChart
                    data={dashboardData.lastThreeMonths.byStatus}
                    colors={statusColors}
                    isForMultipleMonths
                  />
                )}
              </div>
              {/* Chart 2 */}
              <div className="CustomerPortalSupportDashboard__chart">
                <h3 className="CustomerPortalSupportDashboard__chartTitle CustomerPortalSupportDashboard__chartTitle--smallChart">
                  {t('support_dashboard_chart_title_by_product', 'By Product')}
                </h3>

                {dashboardData.lastThreeMonths?.byProduct &&
                  dashboardData.lastThreeMonths.byProduct.length === 0 && (
                  <EmptyChart />
                )}

                {!dashboardData.lastThreeMonths && <LoadingChart />}

                {dashboardData.lastThreeMonths?.byProduct &&
                  dashboardData.lastThreeMonths.byProduct.length > 0 && (
                  <DashboardBarChart
                    data={dashboardData.lastThreeMonths.byProduct}
                    colors={productColors}
                    isStacked
                    isForMultipleMonths
                  />
                )}
              </div>
              {/* Chart 3 */}
              <div className="CustomerPortalSupportDashboard__chart">
                <h3 className="CustomerPortalSupportDashboard__chartTitle CustomerPortalSupportDashboard__chartTitle--smallChart">
                  {t(
                    'support_dashboard_chart_title_by_priority',
                    'By Priority'
                  )}
                </h3>

                {dashboardData.lastThreeMonths?.byPriority &&
                  dashboardData.lastThreeMonths.byPriority.length === 0 && (
                  <EmptyChart />
                )}

                {!dashboardData.lastThreeMonths && <LoadingChart />}

                {dashboardData.lastThreeMonths?.byPriority &&
                  dashboardData.lastThreeMonths.byPriority.length > 0 && (
                  <DashboardBarChart
                    data={dashboardData.lastThreeMonths.byPriority}
                    colors={priorityColors}
                    isForMultipleMonths
                  />
                )}
              </div>
            </div>
          </div>
        </Container>
      </styles.SupportDashboard>
    </>
  ); // End of return
};

export default PremiumSupportDashboard;
