import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

// Components

type ButtonProps = {
  className?: string;
  text?: string;
  href?: any;
  onClick?: (e?: any) => void;
  target?: string;
  external?: boolean;
  isValid?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const CustomButton = styled.button`
  cursor: pointer;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 2px;
  border: none;
  outline: none;
  background: ${p => p.theme.palette.blue[500]};
  min-width: 160px;
  color: #ffffff;

  &:active {
    transform: translateY(1px);
  }

  &.Is-Invalid {
    background: ${p => p.theme.palette.grey[300]};
    color: ${p => p.theme.palette.ink[450]};
    pointer-events: none;

    .Button__Inner-Link {
      color: ${p => p.theme.palette.ink[450]};
    }
  }

  &.Secondary {
    background: ${p => p.theme.palette.grey[300]};
    color: ${p => p.theme.palette.ink[450]};

    .Button__Inner-Link {
      color: ${p => p.theme.palette.ink[450]};
    }
  }

  &.Is-Loading {
    /* Make room for the spinner */
    min-width: 185px;
    position: relative;
    background: ${p => p.theme.palette.grey[300]};
    color: ${p => p.theme.palette.ink[450]};
    outline: none;
    cursor: not-allowed;

    box-shadow: inset 0px 0px 5px ${p => p.theme.palette.grey[300]};

    &:active {
      transform: translateY(0);
    }

    &:before {
      content: '';

      position: absolute;
      top: inherit;
      left: 10px;

      border: 2px solid #f3f3f3;
      border-top: 2px solid #414244;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      animation: ${spin} 2s linear infinite;
      -webkit-animation: ${spin} 2s linear infinite;
    }
  }

  ${p =>
    !p.onClick
      ? `
    .Button__Inner-Link {
      color: #ffffff;
      padding: ${p.theme.spacing(1.5)}px ${p.theme.spacing(3)}px;
      display: block;
    }
  `
      : `
    padding: ${p.theme.spacing(1.5)}px ${p.theme.spacing(3)}px;
  `}
`;

const Button = (props: ButtonProps) => {
  const baseClass = 'Button';
  const classes = props.className
    ? `${baseClass} ${props.className}`
    : baseClass;

  // If there's an onClick and it's an external link, then open the
  // link at the prop.starget and run the props.onClick simultaneously
  // (for triggering custom analytics tracking)
  if (props.onClick && props.external) {
    const validClasses =
      props.isValid !== undefined && !props.isValid ? 'Is-Invalid' : '';
    const isLoadingClass = props.isLoading ? 'Is-Loading' : '';
    return (
      <a
        className="Button__Inner-Link"
        href={props.href}
        target={props.target}
      >
        <CustomButton
          className={`${classes} ${validClasses}`}
          disabled={props.disabled || props.isLoading}
          data-testid={(
            classes +
            ' ' +
            (validClasses ? validClasses + ' ' : '') +
            isLoadingClass
          ).trim()}
          onClick={props.onClick}
        >
          {props.text}
        </CustomButton>
      </a>
    );
  }

  if (props.external) {
    return (
      <CustomButton
        className={classes}
        disabled={props.disabled}
        data-testid="ExternalButton"
      >
        <a
          href={props.href}
          target={props.target}
          className="Button__Inner-Link"
        >
          {props.text}
        </a>
      </CustomButton>
    );
  }

  if (props.onClick) {
    const validClasses =
      props.isValid !== undefined && !props.isValid ? 'Is-Invalid' : '';
    const isLoadindClass = props.isLoading ? 'Is-Loading' : '';
    return (
      <CustomButton
        className={classes + ' ' + validClasses + ' ' + isLoadindClass}
        disabled={props.disabled || props.isLoading}
        onClick={props.onClick}
        data-testid={(
          classes +
          ' ' +
          (validClasses ? validClasses + ' ' : '') +
          isLoadindClass
        ).trim()}
      >
        {props.text}
      </CustomButton>
    );
  }
  const isDisabled = props.disabled !== undefined && props.disabled;
  const validClasses = isDisabled ? 'Is-Invalid' : '';
  return (
    <CustomButton
      className={`${classes} ${validClasses}`}
      disabled={props.disabled}
      data-testid={`${classes} ${validClasses}`.trim()}
    >
      <Link
        className="Button__Inner-Link"
        to={props.href}
        target={props.target}
      >
        {props.text}
      </Link>
    </CustomButton>
  );

};

export default Button;
