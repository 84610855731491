import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CustomActionPanel } from '../../../assets/css/Support/CaseActionPanel';
import {
  axiosPost,
  RequestType,
  useAxios,
} from '../../../client/axios';
import { ActionType } from '../../../constants/caseActions.constants';
import {
  CREATE_ESCALATE_CASE_URL,
  SFDC_URL,
} from '../../../constants/network.constants';
import { useAuth } from '../../../contexts/auth';
import type { DropDownItemData } from '../../../interfaces/sfdc.interface';
import { encodeUTF8ToBase64 } from '../../../lib/encodings.utils';
import { StoreContext } from '../../../store';
import SelectWithSearch from '../../Select-With-Search';
import TextAreaWithCharCount from '../../TextAreaWithCharCount';
import TextLabel from '../../TextLabel';

export type EscalateCaseProps = {
  id: string;
  caseNumber: string;
  timezone: string;
  contactNumber: string;
  setIsEscalated: (value: boolean) => void;
  setActionType: (value: string) => void;
  escalationReasons: DropDownItemData[];
};

type EscalateCaseData = {
  reason: string;
  businessJustification: string;
  preferredContactNumber: string;
  preferredTimezone: DropDownItemData;
};

const EscalateCaseSupport = (props: EscalateCaseProps) => {
  const {
    id, caseNumber, timezone, contactNumber, setIsEscalated, setActionType, escalationReasons,
  } = props;
  const baseClass = 'CaseActions';
  const { t } = useTranslation('common');
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<EscalateCaseData>({
    mode: 'onChange',
    defaultValues: {
      reason: '',
      businessJustification: '',
      preferredContactNumber: contactNumber,
      preferredTimezone: {
        label: timezone,
        value: timezone,
      },
    },
  });
  const reason = watch('reason');
  const businessJustification = watch('businessJustification');
  const preferredContactNumber = watch('preferredContactNumber');
  const preferredTimezone = watch('preferredTimezone');
  const [ loading, setLoading ] = useState(false);
  const [ timezones, setTimezones ] = useState([]);

  const [ sfdcResults ] = useAxios({
    url: `${SFDC_URL}/cases/support/supportCaseForm`,
    requestType: RequestType.GET,
  });

  useEffect(() => {
    setTimezones(sfdcResults?.data?.data?.timezones);
  }, [ sfdcResults ]);

  const onSubmit = async () => {
    setLoading(true);
    const businessJustificationInput = businessJustification.trim();
    if (businessJustificationInput.length < 3) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_incident_case_action_invalid_input',
          'Invalid input. Please provide a valid input with at least 3 printable characters.'
        ),
      });
      setLoading(false);
    } else {
      const body: any = {
        caseId: id,
        reason,
        contactNumber: preferredContactNumber,
        preferredTimezone: preferredTimezone.value,
        businessJustification: encodeUTF8ToBase64(businessJustificationInput),
      };

      try {
        const result = await axiosPost(
          CREATE_ESCALATE_CASE_URL,
          state.companyId,
          await getAccessToken(),
          body
        );

        if (result.status === 201) {
          dispatch({
            type: 'toggleActionPanel',
            payload: false,
          });
          setActionType(ActionType.None);
          setIsEscalated(true);
          dispatch({
            type: 'setBannerType',
            payload: 'success',
          });
          dispatch({
            type: 'setBannerMsg',
            payload: t(
              'support_incident_cases_escalate_case_success',
              'The case has been escalated. An Escalation Manager will review this request and get back to you shortly.'
            ),
          });
          setLoading(false);
          window.location.reload();
        }
      } catch (err) {
        dispatch({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: t(
            'support_incident_cases_escalate_case_fail',
            'We\'re sorry, we cannot escalate the case at this time; please add a comment to the case.'
          ),
        });
        setLoading(false);
        console.error('Something went wrong while escalating a case!', err);
      }
    }
  };

  const onCancel = () => {
    reset();
    dispatch({
      type: 'toggleActionPanel',
      payload: false,
    });
    setActionType(ActionType.None);
  };

  return (
    <CustomActionPanel data-testid="EscalateCaseSupport">
      <div className='form-container'>
        <p className='title'>
          {t(
            'support_incident_cases_escalate_case_number',
            `Escalate Case #${caseNumber}`,
            { caseNumber }
          )}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={`${baseClass}__Case-Field`}>
            <SelectWithSearch
              placeholder={reason || t('support_incident_cases_case_actions_reason_placeholder', 'Select a reason...')}
              label={t('support_incident_cases_case_actions_reason', 'Reason')}
              required
              searchable
              options={escalationReasons}
              onChange={(option: any) => {
                setValue('reason', option.label);
              }}
              className='Custom-Select_Reason'
              value={reason}
              data-testid="reason-input"
            />
          </FormControl>
          <FormControl className={`${baseClass}__Case-Field`}>
            <TextLabel
              label={t('support_incident_cases_escalate_case_contact_number', 'Contact Number')}
              required
            />
            <TextField
              variant="outlined"
              value={preferredContactNumber}
              onChange={e => {
                const value = e.target.value;
                if (/^[0-9 ]+$/.test(value) || value.length === 0) {
                  setValue('preferredContactNumber', value);
                }
              }}
              error={false}
              required
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{
                maxLength: 20,
                'data-testid': 'contactNumber-input',
              }}
            />
          </FormControl>
          <FormControl className={`${baseClass}__Case-Field`}>
            <SelectWithSearch
              label={t('support_incident_cases_escalate_case_preferred_timezone', 'Preferred Time Zone')}
              required
              searchable
              className='Custom-Select_Reason'
              options={timezones}
              onChange={(o: DropDownItemData) => {
                setValue('preferredTimezone', o);
              }}
              value={preferredTimezone}
              data-testid="timezone-input"
            />
          </FormControl>
          <FormControl className={`${baseClass}__Case-Field businessJustification`}>
            <TextLabel
              label={t('support_incident_cases_escalate_case_business_justification', 'Business Justification')}
              required
            />
            <TextAreaWithCharCount
              placeholder={t(
                'support_incident_cases_escalate_case_business_justification_placeholder',
                'Please provide us with a description of the impact of the current issue:\n - What type of impact are you experiencing?\n - Are there acceptable workarounds?\n - Are there any milestone dates upcoming (Go live, UAT)? If yes, please provide the dates.'
              )}
              value={businessJustification}
              register={{ ...register('businessJustification') }}
              aria-label="empty textarea"
              rowsMin={7}
              maxLength={255}
              label="businessJustification"
            />
          </FormControl>
        </form>
      </div>
      <div className='button-container'>
        <div className='button'>
          <Button
            variant='outlined'
            onClick={onCancel}
            disabled={loading}
            className='cancelBtn'
          >
            {t('support_incident_cases_cancel', 'Cancel')}
          </Button>
          {
            !loading ? (
              <Button
                variant='contained'
                color='secondary'
                onClick={handleSubmit(onSubmit)}
                disabled={!reason || !businessJustification || !preferredTimezone || !preferredContactNumber}
                className='submitBtn'
                data-testid='submit-button'
              >
                {t('support_incident_cases_submit', 'Submit')}
              </Button>
            ) : (
              <Button
                variant='contained'
                color='secondary'
                className='loaderBtn'
              >
                <CircularProgress
                  size={24}
                  className='loader'
                />
              </Button>
            )
          }
        </div>
      </div>
    </CustomActionPanel>
  );
};

export default EscalateCaseSupport;
