import type {
  CaseAssistEngine,
  DocumentSuggestionResponse,
} from '@coveo/headless/case-assist';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FacetText,
  FacetValuesContainer,
  ResultContainer,
  ResultDescription,
  ResultLink,
} from '../../../assets/css/Support/CaseAssist/SupportCaseDeflectionListItem';
import { CaseAssistAnalyticsActions } from '../../../constants/caseAssist.constants';
import { SearchResultSourceLabelMap } from '../../../constants/search.constants';
import { FacetFilterTypes } from '../../../pages/Search/Facets/types';
import { logCaseAssistAnalyticsEvent } from '../../../utils/caseAssist';
import { processFacetValue } from '../../../utils/search';

type SupportCaseDeflectionListItemProps = {
  engine: CaseAssistEngine;
  suggestion: DocumentSuggestionResponse;
  index: number;
};

const SupportCaseDeflectionListItem = ({
  engine,
  suggestion,
  index,
}: SupportCaseDeflectionListItemProps) => {
  const { t } = useTranslation('common');
  const generateFacetValues = (rawQueryValues: any) => {
    const facets: Array<{ facetLabel: string; facetType: string }> = [];
    for (const facetType of [
      FacetFilterTypes.CONTENT_TYPE,
      FacetFilterTypes.PRODUCT,
      FacetFilterTypes.VERSION,
    ]) {
      const facetValue = rawQueryValues[facetType];

      if (Array.isArray(facetValue)) {
        const processedFacetValues: Array<{ facetLabel: string; facetType: string }> =
        facetValue.map((currVal) => ({
          facetLabel: processFacetValue(currVal, facetType, rawQueryValues),
          facetType,
        }));
        facets.push(...processedFacetValues);
      } else if (typeof facetValue === 'string') {
        facets.push({
          facetLabel: processFacetValue(facetValue, facetType, rawQueryValues),
          facetType,
        });
      }
    }

    return (
      <FacetValuesContainer>
        {facets.map(({
          facetLabel, facetType,
        }, idx) => (
          // For content type labels, apply custom rename and localization
          facetType === FacetFilterTypes.CONTENT_TYPE && SearchResultSourceLabelMap[facetLabel] ?
            <FacetText key={`${facetLabel}-${idx}`}>
              {t(
                SearchResultSourceLabelMap[facetLabel].localText,
                SearchResultSourceLabelMap[facetLabel].fallbackText
              )}
            </FacetText>
            : <FacetText key={`${facetLabel}-${idx}`}>{facetLabel}</FacetText>
        ))}
      </FacetValuesContainer>
    );
  };

  return (
    <ResultContainer key={index}>
      <ResultLink
        href={suggestion.clickUri}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          logCaseAssistAnalyticsEvent(
            engine,
            CaseAssistAnalyticsActions.CASE_COVEO_SUGGESTION_OPEN,
            suggestion.uniqueId
          );
        }}
      >
        {suggestion.title}
      </ResultLink>
      <ResultDescription>{suggestion.excerpt}</ResultDescription>
      {generateFacetValues(suggestion.fields)}
    </ResultContainer>
  );
};

export default SupportCaseDeflectionListItem;
