export const HAPO_RESET_CART = 'HAPO_RESET_CART';
export const HAPO_CHANGE_CATEGORY = 'HAPO_CHANGE_CATEGORY';
export const HAPO_SELECT_EXISTING_ORCHESTRATOR_OPTION = 'HAPO_SELECT_EXISTING_ORCHESTRATOR_OPTION';
export const HAPO_CHANGE_ONPREM_SELECTION = 'HAPO_CHANGE_ONPREM_SELECTION';
export const HAPO_ADD_1ST_LEVEL_LICENSE = 'HAPO_ADD_1ST_LEVEL_LICENSE';
export const HAPO_ADD_2ND_LEVEL_LICENSE = 'HAPO_ADD_2ND_LEVEL_LICENSE';
export const HAPO_REMOVE_1ST_LEVEL_LICENSE = 'HAPO_REMOVE_1ST_LEVEL_LICENSE';
export const HAPO_REMOVE_2ND_LEVEL_LICENSE = 'HAPO_REMOVE_2ND_LEVEL_LICENSE';
export const HAPO_UPDATE_1ST_LEVEL_LICENSE_QUANTITY = 'HAPO_UPDATE_1ST_LEVEL_LICENSE';
export const HAPO_UPDATE_2ND_LEVEL_LICENSE_QUANTITY = 'HAPO_UPDATE_2ND_LEVEL_LICENSE';
export const HAPO_CLICK_LICENSE_IN_CART_VIEW = 'HAPO_CLICK_LICENSE_IN_CART_VIEW';
export const HAPO_CONTINUE_TO_CART_SUCCESS = 'HAPO_CONTINUE_TO_CART_SUCCESS';
export const HAPO_CONTINUE_SHOPPING = 'HAPO_CONTINUE_SHOPPING';
export const HAPO_LICENSE_NICKNAMES_LOADED = 'HAPO_LICENSE_NICKNAMES_LOADED';
export const HAPO_DISPLAY_ANNOUNCEMENT_MODAL = 'HAPO_DISPLAY_ANNOUNCEMENT_MODAL';
