import styled from 'styled-components';

export const CustomFileUpload = styled.div`
  .FileUpload_Box {
    color: ${p => p.theme.palette.grey[600]};
    border: 1px dashed black;
    border-radius: 2px;
    padding: 8px;
    cursor: pointer
  }

  .FileUpload_Placeholder {
    display: flex;
    justify-content: space-between;

    &-Icon {
      margin: auto 0px auto 10px;
    }
  }

  .FileUpload_Files {
    margin-top: 10px;
  }

  .FileUpload_File {
    display: flex;
    color: ${p => p.theme.palette.blue[600]};
    margin-bottom: 12px;

    &-Close {
      margin-right: 6px;
      cursor: pointer;
    }

    &-Name {
      font-weight: 500;
    }

    &-Loader {
      margin-right: 6px;
      border: 3px solid #f3f3f3;
      border-radius: 50%;
      border-top: 3px solid #3498db;
      min-width: 16px;
      height: 16px;
      animation: spin 2s linear infinite;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    &-Success {
      margin-right: 6px;
      color: ${p => p.theme.palette.green[700]};
    }

    &-Fail {
      margin-right: 6px;
      color: ${p => p.theme.palette.red[700]};
      transform: rotate(45deg);
      cursor: pointer;
    }
  }

  .FileUpload_Note {
    color: ${p => p.theme.palette.grey[600]};
    margin-top: 4px;
    font-size: 1.2rem;
  }

  .FileUpload_Error {
    color: ${p => p.theme.palette.red[500]};
    margin-top: 4px;
    font-size: 1.2rem;
  }
`;
