import styled from 'styled-components';

export const Header = styled.div`
  margin-bottom: ${p => p.theme.spacing(5)}px;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
`;

export const Container = styled.div`
  margin: ${p => p.theme.spacing(2.5)}px auto;
  width: 80%;
  
  .GetUserInfoForm__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: ${p => p.theme.spacing(2.5)}px;
  }

  .GetUserInfoForm__column {
    display: flex;
    flex-direction: column;
    align-items: left;
    flex: 1 1 auto;

    &.GetUserInfoForm__GeneralTermsOfUseConsent, 
    &.GetUserInfoForm__B2CTermsAndConditionsConsent {
      padding: 0 ${p => p.theme.spacing(1)}px;
      .MuiIconButton-root.MuiIconButton-root {
        transform: scale(1.5);

        &:hover {
          background-color: transparent;
        }
      }
    }

    .MuiIconButton-root.MuiIconButton-root.b2cTermsAndConditionsCheckbox {
      padding-top: 0;
      padding-bottom: 0;
    }

    .MuiIconButton-root.MuiIconButton-root.generalTermsOfUseCheckbox {
      align-self: flex-start;

      &+span {
        padding-top: ${p => p.theme.spacing(0.75)}px;
      }
    }
  }

  .GetUserInfoForm__field {
    width: 250px;

    .GetUserInfoForm__Select .Custom-Select__control {
      padding: 1px 0;
      background: white;
      border: 1px solid ${p => p.theme.palette.ink[400]};
      border-radius: ${p => p.theme.spacing(0.5)}px;

      &:hover {
        border: 1px solid black;
      }
    }

    .GetUserInfoForm__Select .Custom-Select__control.Custom-Select__control--is-focused {
      border: ${p => p.theme.spacing(0.25)}px solid ${p => p.theme.palette.blue[500]};
    }

    button {
      display: block;
      margin-top: ${p => p.theme.spacing(2)}px;

      width: 100%;
      height: ${p => p.theme.spacing(6)}px;

      ${p => p.theme.breakpoints.down('xs')} {
       width: 250px;
      }
    }
  }
`;

export const Spinner = styled.div`
  margin: ${p => p.theme.spacing(5)}px 0;

  svg {
    display: block;
    margin: 0 auto;
    width: ${p => p.theme.spacing(12.5)}px;
    height: ${p => p.theme.spacing(12.5)}px;
  }
`;
