import styled from 'styled-components';

export const IndividualLicense = styled.div`
  width: 100%;
  min-height: 80px;
  border-radius: ${p => p.theme.spacing(1)}px;
  background: white;
  padding: ${p => p.theme.spacing(2)}px 40px ${p => p.theme.spacing(2)}px ;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${p => p.theme.spacing(2)}px;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: ${p => p.theme.palette.ink[650]};
    font-size: 1.4rem;
    line-height: 20px;
  }
  .IndividualLicense__Name {
    flex: 5;
  }

  .IndividualLicense__Progress {
    flex: 2;
  }

  .IndividualLicense__Stats {
    flex: 1;
    text-align: center;
  }
`;
