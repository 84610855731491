import { Locale } from '@customer-portal/constants';

export const LANGUAGE_COOKIE_DOMAIN_NAME = '.uipath.com';

export const localeToLanguage: Map<Locale, string> = new Map([
  [ Locale.en, 'English' ],
  [ Locale.ja, '日本語' ],
  [ Locale.de, 'Deutsch' ],
  [ Locale.es, 'Español' ],
  [ Locale['es-MX'], 'Español (México)' ],
  [ Locale.fr, 'Français' ],
  [ Locale.ko, '한국어' ],
  [ Locale.pt, 'Português' ],
  [ Locale['pt-BR'], 'Português (Brasil)' ],
  [ Locale.ru, 'Pусский' ],
  [ Locale.tr, 'Türkçe' ],
  [ Locale['zh-CN'], '中文 (简体)' ],
  [ Locale['zh-TW'], '中文 (繁體)' ],
]);

export const languageToLocale: Map<string, Locale> = Array.from(
  localeToLanguage.keys()
).reduce((map, key) => map.set(localeToLanguage.get(key), key), new Map());

export const getAllLanguageOptions = () =>
  Array.from(localeToLanguage.values());

export const getValidLanguage = (currentLanguage: string | null): Locale => {
  if (currentLanguage && currentLanguage in Locale) {
    return currentLanguage as Locale;
  }
  return Locale.en;
};
