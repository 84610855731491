import { TextField } from '@mui/material';
import React from 'react';

import {
  CustomInput,
  CustomSelect,
  CustomSelectManual,
} from '../assets/css/SelectWithManualSearch';
import { ReactComponent as ArrowDown } from '../assets/img/svg/arrows/Arrow_Down_18.svg';
import { ReactComponent as ArrowUp } from '../assets/img/svg/arrows/Arrow_Up_18.svg';
import TextLabel from './TextLabel';

export type SelectWithManualSearchProps = {
  className?: string;
  id?: string;
  options: string[];
  disabled?: boolean;
  placeholder?: any;
  isOpen?: boolean;
  onOpen?: any;
  onClose?: any;
  onChange?: any;
  onKeyDown?: any;
  classNamePrefix?: any;
  searchable?: boolean;
  name?: string;
  label?: string;
  required?: boolean;
  error?: string;
  helpText?: string;
  value: any;
  dataTestId: string;
};

const SelectWithManualSearch = (props: SelectWithManualSearchProps) => (
  <CustomSelect>
    {props.label && (
      <TextLabel
        disabled={props.disabled}
        label={props.label}
        required={props.required}
        helpText={props.helpText}
      />
    )}
    <CustomSelectManual
      disablePortal
      freeSolo
      disabled={props.disabled}
      options={props.options}
      onOpen={props.onOpen}
      onClose={props.onClose}
      onChange={props.onChange}
      value={props.value}
      filterOptions={(options: any) => options}
      renderInput={(params: any) => (
        <CustomInput>
          <TextField
            {...params}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
          />
          {props.isOpen ? (
            <ArrowUp className='arrow arrowUp' />
          ) : (
            <ArrowDown className={props.disabled ? 'arrow arrowDisabled' : 'arrow'} />
          )}
        </CustomInput>
      )}
      data-testid={props.dataTestId}
    />
  </CustomSelect>
);
export default SelectWithManualSearch;
