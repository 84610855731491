import { getTelemetryRoleString } from '@customer-portal/utils';
import {
  ReactPlugin,
  useAppInsightsContext,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import {
  useContext,
  useEffect,
} from 'react';

import {
  APPLICATION_PAGEVIEW,
  CUSTOMER_PORTAL,
} from '../../constants/telemetry.constants';
import type { GlobalStoreState } from '../../interfaces/contextStore';
import { StoreContext } from '../../store/index';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    // Fake connectionString is for Jest tests
    connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING ?? 'InstrumentationKey=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx;IngestionEndpoint=xxxxx',
    extensions: [ reactPlugin ],
    disableAjaxTracking: true,
    disableFetchTracking: true,
    extensionConfig: { [reactPlugin.identifier]: { history: browserHistory } },
  },
});

appInsights.loadAppInsights();

// Call when we have user info
const setAppInsAuthenticatedUser = (userId: string) => {
  const escapedUserId = userId.replace(/[,;=| ]+/g, '_');
  appInsights.setAuthenticatedUserContext(escapedUserId, undefined, true);
};

// Call when user logs out
const unInitAppIns = () => {
  appInsights.flush();
  appInsights.clearAuthenticatedUserContext();
};

const getDefaultGlobalTelemetryValues = (state: Partial<GlobalStoreState>) => ({
  ApplicationName: CUSTOMER_PORTAL,
  UserId: state.userId,
  UserAuthId: state.userAuthId,
  CloudUserId: state.cloudUserId,
  CompanyId: state.companyId,
  Role: getTelemetryRoleString({
    adminUserRoles: state.currentAdmin?.roles,
    userCompanyRoles: state.currentUserCompany?.userCompany?.roles,
  }),
});

/**
 * Triggers an AppInsights track event with the given event name and data, and with user data from the provided state.
 * If calling this beneath StoreProvider, prefer useTriggerTrackEventWithStateData instead, which automatically uses the current state.
 */
const triggerTrackEvent = (eventName: string, eventData = {}, state: Partial<GlobalStoreState>) => {
  if (appInsights.appInsights.isInitialized()) {
    reactPlugin.trackEvent(
      { name: eventName },
      {
        ...getDefaultGlobalTelemetryValues(state),
        ...eventData,
      }
    );
  }
};

/**
 * Returns a function that triggers an AppInsights track event with user data from the current state.
 */
const useTriggerTrackEventWithStateData = () => {
  const { state } = useContext(StoreContext);
  return (eventName: string, eventData = {}) => {
    triggerTrackEvent(eventName, eventData, state);
  };
};

// We add the skip to avoid the hook to be called on the Auth0 auth guard since it's always rendered
// but only forces the user to login/register when not authenticated
const useTrackPageViewEvent = (pageName: string, skip: boolean = false) => {
  const { state } = useContext(StoreContext);

  const appInsContext = useAppInsightsContext();

  useEffect(() => {
    if (skip) {
      return;
    }

    if (
      pageName &&
      state.isRegistered &&
      appInsContext &&
      typeof appInsContext.trackEvent === 'function'
    ) {
      appInsContext.trackEvent(
        { name: APPLICATION_PAGEVIEW },
        {
          ...getDefaultGlobalTelemetryValues({
            userId: state.userId,
            cloudUserId: state.cloudUserId,
            companyId: state.companyId,
            currentAdmin: state.currentAdmin,
            currentUserCompany: state.currentUserCompany,
          }),
          PageName: pageName,
        }
      );
    }
  }, [ appInsContext, pageName, state.isRegistered, state.userId, state.companyId, state.cloudUserId, skip ]);
};

const logTrace = (message: string) => {
  appInsights.trackTrace({ message });
};

const logException = (errorMessage: string) => {
  appInsights.trackException({ exception: new Error(errorMessage) });
};

export {
  reactPlugin,
  appInsights,
  useTrackPageViewEvent,
  setAppInsAuthenticatedUser,
  unInitAppIns,
  triggerTrackEvent,
  useTriggerTrackEventWithStateData,
  logTrace,
  logException,
};
