import React from 'react';

// Styles
import * as styles from '../assets/css/CustomerPortalMinimalHero';
// Assets
import defaultHeroBg from './../assets/img/grid_svg_black.svg';
// Components
import Container from './CustomerPortal-New-Container';

type BasicHeroProps = {
  cssClass?: string;
  heroBg?: string;
  backButton?: boolean;
  handleBackButtonClick: Function;
  backButtonHref?: string;
};

const CustomerPortalBasicHero = (props: BasicHeroProps) => {
  const cssClass = props.cssClass ? props.cssClass : '';
  const heroBg = props.heroBg ? props.heroBg : defaultHeroBg;
  const backButton = props.backButton ? props.backButton : false;

  // Events
  const handleBackButtonClickLocal = () => {
    props.handleBackButtonClick();
  };

  return (
    <styles.Hero
      className={cssClass}
      heroBg={heroBg}>
      <Container cssClass="BackButtonContainer">
        {backButton &&
            <div
              className="heroBackButton"
              onClick={handleBackButtonClickLocal}>
Go Back
            </div>}
      </Container>
      <div className="HeroBackgroundContainer" />
    </styles.Hero>
  );
};

export default CustomerPortalBasicHero;
