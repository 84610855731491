import { ACCOUNT_LICENSES_URL } from '../constants/network.constants';
import { logException } from '../lib/AppInsights/AppInsights';
import { axiosGet } from './axios';

export const fetchCompanyLicenses = async (
  companyId: string,
  accessToken: any,
  userIdDebug?: string,
  stateDispatcher?: ({
    type, payload,
  }: { type: string; payload: any }) => void) => {
  const result: { licenses: any[]; boonLicense: any } = {
    licenses: [],
    boonLicense: {},
  };

  async function fetchLicenses(page: number) {
    return await axiosGet(ACCOUNT_LICENSES_URL, companyId, accessToken, { params: { page } });
  }

  let page = 0;
  try {
    while (page >= 0) {
      const nextResult = await fetchLicenses(page);
      const {
        nextPage = -1, boonLicense = {},
      } = nextResult.data;
      const { licenses = [] } = boonLicense;

      if (nextPage === page || !Array.isArray(licenses) || licenses.length === 0) {
        break;
      }

      result.licenses = nextResult.data.licenses;
      result.boonLicense = {
        ...result.boonLicense,
        ...boonLicense,
        licenses: [ ...(result.boonLicense.licenses || []), ...licenses ],
      };
      if (stateDispatcher) {
        stateDispatcher({
          type: 'setCompanyLicenses',
          payload: result,
        });
      }
      page = nextPage;
    }
  } catch (e) {
    logException(`fetchCompanyLicenses: Error on request to ${ACCOUNT_LICENSES_URL} for companyId ${companyId} and userId: ${userIdDebug}. Error: ${e}`);
  }

  if (stateDispatcher) {
    stateDispatcher({
      type: 'setCompanyLicenses',
      payload: result,
    });
  }
  return result;
};
