import {
  FormControl,
  TextField,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
// Styles
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useState } from 'react';

// Styles
import { ModalContentFormWrapper } from '../../../assets/css/Knowledge/CategoryManager-Table';
import type { SkuTypeTransformedAdmin } from '../../../types/requestForQuote.types';
import Button from '../../Button/Button';
import Modal from '../../Modal';
import RichTextEditor from '../../RichTextEditor';
import TextLabel from '../../TextLabel';

type AddModalProps = {
  submitting: boolean;
  handleFormSubmit: (sku: SkuTypeTransformedAdmin) => void;
  handleClose: () => void;
  [key: string]: any;
};

const AddModal = ({
  sku, submitting, handleFormSubmit, handleClose,
}: AddModalProps) => {
  const [ id, setId ] = useState('');
  const [ productCode, setProductCode ] = useState('');
  const [ name, setName ] = useState('');
  const [ feature, setFeature ] = useState('');
  const [ longDescription, setLongDescription ] = useState('');
  const [ minQuantity, setMinQuantity ] = useState('');
  const [ maxQuantity, setMaxQuantity ] = useState('');
  const [ idHelperText, setIdHelperText ] = useState('');
  const [ productCodeHelperText, setProductCodeHelperText ] = useState('');
  const [ nameHelperText, setNameHelperText ] = useState('');
  const [ featureHelperText, setFeatureHelperText ] = useState('');
  const [ minQuantityHelpText, setMinQuantityHelpText ] = useState('');
  const [ maxQuantityHelpText, setMaxQuantityHelpText ] = useState('');
  const [ shortDescription, setShortDescription ] = useState('');
  const [ showInRFQ, setShowInRFQ ] = useState(false);
  const [ showInHAPO, setShowInHAPO ] = useState(false);
  const [ showHapo, setShowHapo ] = useState({
    Basic: false,
    Standard: false,
    NonProduction: false,
    'No Orchestrator': false,
    'CRPA Orchestrator': false,
    Cloud: false,
    ProcessMining: false,
    'Automation Suite': false,
    'Dedicated Automation Cloud': false,
  });
  const [ showRfq, setShowRfq ] = useState({
    Basic: false,
    Standard: false,
    NonProduction: false,
    'No Orchestrator': false,
    'CRPA Orchestrator': false,
    Cloud: false,
    'Automation Suite': false,
  });
  const [ formError, setFormError ] = useState('');

  const rfqDisplaySettings = [
    {
      checkedValue: showRfq.Basic,
      name: 'Basic',
      label: 'Show in Basic Orchestrator',
    },
    {
      checkedValue: showRfq.Standard,
      name: 'Standard',
      label: 'Show in Standard Orchestrator',
    },
    {
      checkedValue: showRfq['CRPA Orchestrator'],
      name: 'CRPA Orchestrator',
      label: 'Show in CRPA Orchestrator',
    },
    {
      checkedValue: showRfq.NonProduction,
      name: 'NonProduction',
      label: 'Show in Non Production Orchestrator',
    },
    {
      checkedValue: showRfq['No Orchestrator'],
      name: 'No Orchestrator',
      label: 'Show in Standalone Orchestrator',
    },
    {
      checkedValue: showRfq.Cloud,
      name: 'Cloud',
      label: 'Show in Cloud',
    },
    {
      checkedValue: showRfq['Automation Suite'],
      name: 'Automation Suite',
      label: 'Show in Automation Suite',
    },
  ];

  const hapoDisplaySettings = [
    {
      checkedValue: showHapo.Basic,
      name: 'Basic',
      label: 'Show in Basic Orchestrator',
    },
    {
      checkedValue: showHapo.Standard,
      name: 'Standard',
      label: 'Show in Standard Orchestrator',
    },
    {
      checkedValue: showHapo.NonProduction,
      name: 'NonProduction',
      label: 'Show in Non Production Orchestrator',
    },
    {
      checkedValue: showHapo['CRPA Orchestrator'],
      name: 'CRPA Orchestrator',
      label: 'Show in CRPA Orchestrator',
    },
    {
      checkedValue: showHapo['No Orchestrator'],
      name: 'No Orchestrator',
      label: 'Show in Standalone Orchestrator',
    },
    {
      checkedValue: showHapo.Cloud,
      name: 'Cloud',
      label: 'Show in Cloud',
    },
    {
      checkedValue: showHapo.ProcessMining,
      name: 'ProcessMining',
      label: 'Show in Process Mining',
    },
    {
      checkedValue: showHapo['Automation Suite'],
      name: 'Automation Suite',
      label: 'Show in Automation Suite',
    },
    {
      checkedValue: showHapo['Dedicated Automation Cloud'],
      name: 'Automation Cloud - Dedicated',
      label: 'Show in Dedicated Automation Cloud',
    },
  ];

  /* Event */

  const handleQuantityChange = (
    dispatchState: React.Dispatch<any>,
    dispatchHelperText: React.Dispatch<any>
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === '') {
      dispatchState('');
      dispatchHelperText('');
      return;
    }

    const num = Number(e.target.value);

    if (!Number.isInteger(num) || num < 0) {
      dispatchHelperText(
        'Enter a positive whole number, 0, or leave the field empty'
      );
      return;
    }

    dispatchState(num);
    dispatchHelperText('');
  };

  const handleIdChange = (
    dispatchState: React.Dispatch<any>,
    dispatchHelperText: React.Dispatch<any>
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === '') {
      dispatchState('');
      dispatchHelperText('SKU name is required!');
      return;
    }

    dispatchState(e.target.value);
    dispatchHelperText('');
  };

  const handleProductCodeChange = (
    dispatchState: React.Dispatch<any>,
    dispatchHelperText: React.Dispatch<any>
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === '') {
      dispatchState('');
      dispatchHelperText('SKU product code is required!');
      return;
    }

    dispatchState(e.target.value);
    dispatchHelperText('');
  };

  const handleNameChange = (
    dispatchState: React.Dispatch<any>,
    dispatchHelperText: React.Dispatch<any>
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === '') {
      dispatchState('');
      dispatchHelperText('SKU name is required!');
      return;
    }

    dispatchState(e.target.value);
    dispatchHelperText('');
  };

  const handleFeatureChange = (
    dispatchState: React.Dispatch<any>,
    dispatchHelperText: React.Dispatch<any>
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const allowedFeatureValues = [
      'Orchestrator',
      'Robots',
      'Multiuser',
      'Users',
      'Platform Capabilities',
    ];
    if (e.target.value === '') {
      dispatchState('');
      dispatchHelperText('Feature is required!');
      return;
    }

    if (!allowedFeatureValues.includes(e.target.value)) {
      dispatchState(e.target.value);
      dispatchHelperText(
        `Please enter one of this values: ${allowedFeatureValues.join(', ')}`
      );
      return;
    }

    dispatchState(e.target.value);
    dispatchHelperText('');
  };

  const handleLongDescriptionChange = (e: any) => {
    if (e.target.value === '') {
      setLongDescription('');
      return;
    }
    setLongDescription(e.target.value);
  };

  const handleShortDescriptionChange = (html: string, isEmpty: boolean) => {
    if (isEmpty) {
      setShortDescription('');
      return;
    }
    setShortDescription(html);
  };

  const handleSubmitClick = (event: React.FormEvent<HTMLFormElement>) => {
    if (
      id === '' ||
      productCode === '' ||
      name === '' ||
      longDescription === '' ||
      feature === '' ||
      minQuantity === '' ||
      maxQuantity === ''
    ) {
      event.preventDefault();
      setFormError('Please fill the required fields, marked with * !');
      return;
    }

    // Min Max Validation
    if (
      minQuantity &&
      maxQuantity &&
      Number(minQuantity) > Number(maxQuantity)
    ) {
      event.preventDefault();
      setFormError('Minimum Quantity must be less than Maximum Quantity');
      return;
    } else if (
      minQuantity &&
      maxQuantity &&
      Number(maxQuantity) < Number(minQuantity)
    ) {
      event.preventDefault();
      setFormError('Maximum Quantity must be greater than Maximum Quantity');
      return;
    }

    setFormError('');
  };

  const handleHapoDisplayChange = (displayKeyName: string, value: boolean) => {
    setShowHapo({
      ...showHapo,
      [displayKeyName]: value,
    });
  };

  const handleRfqDisplayChange = (displayKeyName: string, value: boolean) => {
    setShowRfq({
      ...showRfq,
      [displayKeyName]: value,
    });
  };

  const handleFormSubmitLocal = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Convert to min and max to numbers

    const temp: SkuTypeTransformedAdmin = {
      ...sku,
      _id: id,
      product_code: productCode,
      sku_name: name,
      long_description: longDescription,
      short_description: shortDescription,
      tag: feature as any,
      min_quantity: Number.isNaN(parseInt(minQuantity, 10))
        ? undefined
        : parseInt(minQuantity, 10),
      max_quantity: Number.isNaN(parseInt(maxQuantity, 10))
        ? undefined
        : parseInt(maxQuantity, 10),
      showRfq,
      show_in: {
        rfq: showInRFQ,
        hapo: showInHAPO,
      },
      showHapo,
    };

    handleFormSubmit(temp);
  };

  return (
    <Modal
      modalTitle="Add"
      modalHeading="Add new SKU"
      modalDescription="Modal for adding sku"
      open
      handleClose={handleClose}
      testId="AdminAddModal"
    >
      <ModalContentFormWrapper
        className="CustomerPortalProductCatalog__formWrapper"
        onSubmit={formError === '' ? handleFormSubmitLocal : () => { }}
        data-testid="AdminAddModalForm"
      >
        <p style={{
          color: '#526069',
          fontSize: '14px',
        }}>
          <b>Display Settings for RFQ:</b>
        </p>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={showInRFQ}
                onChange={_event => setShowInRFQ(!showInRFQ)}
                name="rfq"
              />
            }
            label="Available in Request For Quote"
          />
        </div>

        {rfqDisplaySettings.map(
          ({
            checkedValue, name: settingName, label,
          }) => (
            <FormControlLabel
              key={`RfqDisplay_${settingName}`}
              control={
                <Checkbox
                  checked={checkedValue}
                  onChange={(_e, checked) =>
                    handleRfqDisplayChange(settingName, checked)}
                  name={settingName}
                />
              }
              label={label}
            />
          )
        )}

        <br />

        <p style={{
          color: '#526069',
          fontSize: '14px',
        }}>
          <b>Display Settings for Enterprise Agreement:</b>
        </p>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={showInHAPO}
                onChange={_event => setShowInHAPO(!showInHAPO)}
                name="Hapo"
              />
            }
            label="Available in HAPO"
          />
        </div>

        {hapoDisplaySettings.map(
          ({
            checkedValue, name: settingName, label,
          }) => (
            <FormControlLabel
              key={`HapoDisplay_${settingName}`}
              control={
                <Checkbox
                  checked={checkedValue}
                  onChange={(_e, checked) =>
                    handleHapoDisplayChange(settingName, checked)}
                  name={settingName}
                />
              }
              label={label}
            />
          )
        )}

        <div
          style={{
            marginTop: '8px',
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControl style={{
            width: '50%',
            marginRight: '24px',
          }}>
            <TextLabel
              label="SKU ID"
              required
            />
            <TextField
              id="SKUId"
              type="string"
              placeholder="Enter a SKU id"
              value={id}
              onChange={handleIdChange(setId, setIdHelperText)}
              helperText={idHelperText}
              aria-label="sku-id"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{ maxLength: 320 }}
              data-testid="SKUId__TextField"
            />
          </FormControl>

          <FormControl style={{ width: '50%' }}>
            <TextLabel
              label="SKU Product Code"
              required
            />
            <TextField
              id="SKUProductCode"
              type="string"
              placeholder="Enter a SKU product code"
              value={productCode}
              onChange={handleProductCodeChange(
                setProductCode,
                setProductCodeHelperText
              )}
              helperText={productCodeHelperText}
              aria-label="sku-productCode"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{ maxLength: 320 }}
              data-testid="ProductCode__TextField"
            />
          </FormControl>
        </div>

        <div
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControl style={{
            width: '50%',
            marginRight: '24px',
          }}>
            <TextLabel
              label="SKU Name"
              required
            />
            <TextField
              id="SKUName"
              type="string"
              placeholder="Enter a SKU name"
              value={name}
              onChange={handleNameChange(setName, setNameHelperText)}
              helperText={nameHelperText}
              aria-label="sku-name"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{ maxLength: 320 }}
              data-testid="SKUName__TextField"
            />
          </FormControl>

          <FormControl style={{ width: '50%' }}>
            <TextLabel
              label="Feature"
              required
            />
            <TextField
              id="feature"
              type="string"
              placeholder="Enter a feature name"
              value={feature}
              onChange={handleFeatureChange(setFeature, setFeatureHelperText)}
              helperText={featureHelperText}
              aria-label="feature"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{ maxLength: 320 }}
              data-testid="FeatureName__TextField"
            />
          </FormControl>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControl style={{
            width: '50%',
            marginRight: '24px',
          }}>
            <TextLabel
              label="Long description"
              required
            />
            <textarea
              value={longDescription}
              style={{
                resize: 'none',
                fontSize: '14px',
              }}
              onChange={handleLongDescriptionChange}
              placeholder="Enter a long description"
              rows={6}
              cols={42}
              data-testid="LongDescription__TextField"
            />
          </FormControl>

          <div style={{ width: '50%' }}>
            <RichTextEditor
              handleOnChange={handleShortDescriptionChange}
              label="Short Description"
              placeholder="Enter a short description"
              defaultValue={shortDescription}
              stripPastedStyles
            />
          </div>
        </div>

        <div
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControl style={{
            width: '50%',
            marginRight: '24px',
          }}>
            <TextLabel
              label="Minimum Quantity"
              required
            />
            <TextField
              id="MinQuantity"
              type="number"
              value={minQuantity}
              onChange={handleQuantityChange(
                setMinQuantity,
                setMinQuantityHelpText
              )}
              placeholder="Enter a minimum quantity"
              helperText={minQuantityHelpText}
              aria-label="min-quantity"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{ maxLength: 320 }}
              data-testid="MinQuantity__TextField"
            />
          </FormControl>

          <FormControl style={{ width: '50%' }}>
            <TextLabel
              label="Maximum Quantity"
              required
            />
            <TextField
              id="MaxQuantity"
              type="number"
              value={maxQuantity}
              onChange={handleQuantityChange(
                setMaxQuantity,
                setMaxQuantityHelpText
              )}
              placeholder="Enter a maximum quantity"
              helperText={maxQuantityHelpText}
              aria-label="max-quantity"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              inputProps={{ maxLength: 320 }}
              data-testid="MaxQuantity__TextField"
            />
          </FormControl>
        </div>

        <p className="KnowledgeCategories__FormError">
          {formError}
        </p>
        <Button
          className="KnowledgeCategories__SubmitButton"
          onClick={handleSubmitClick}
          isLoading={submitting}
          text="Submit"
        />
      </ModalContentFormWrapper>
    </Modal>
  );
};

export default AddModal;
