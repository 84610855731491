import { SEARCH_DATA_REDUCER_ACTION_TYPE } from '../constants/productDownloadsSearchActions.constants';

export const productDownloadsSearchReducer = (state: any, action: { [key: string]: any }) => {
  if (!action) {
    throw new Error('Action must be defined');
  }

  switch (action.type) {
    case SEARCH_DATA_REDUCER_ACTION_TYPE.KEYWORD_UPDATED:
      return {
        ...state,
        keywordInputField: action.payload.keyword,
      };
    case SEARCH_DATA_REDUCER_ACTION_TYPE.KEYWORD_EMPTY:
      // Reset search state
      return {
        ...state,
        isSearching: false,
        keyword: '',
        keywordInputField: '',
        isCompleteSearching: false,
        products: [],
      };
    case SEARCH_DATA_REDUCER_ACTION_TYPE.FETCH_INIT:
      // Return blank state with only those two new keywords
      return {
        ...state,
        keyword: action.payload.keyword,
        keywordInputField: action.payload.keyword,
        isSearching: true,
        isCompleteSearching: false,
      };
    case SEARCH_DATA_REDUCER_ACTION_TYPE.FETCH_SUCCESS:
      return {
        ...state,
        isSearching: true,
        isCompleteSearching: true,
        products: action.payload.products,
      };
    case SEARCH_DATA_REDUCER_ACTION_TYPE.FETCH_FAIL:
      return {
        ...state,
        isSearching: true,
        isCompleteSearching: true,
        products: [],
      };
    default:
      return { ...state };
  }
};
