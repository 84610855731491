import { AdminUserChangeType } from '@customer-portal/constants';

export enum ChangeSnapshotState {
  OLD = 0,
  NEW = 1,
}

export const AdminUserChangeTypeToLabel: Record<AdminUserChangeType, string> = {
  [AdminUserChangeType.UNDEFINED]: 'Undefined',
  [AdminUserChangeType.ADD_NEW]: 'Admin was added',
  [AdminUserChangeType.EDIT_ROLES]: 'Admin roles were edited',
  [AdminUserChangeType.EDIT_STATUS]: 'Admin status was edited',
};
