import TableContainer from '@mui/material/TableContainer';
import styled from 'styled-components';

export const CPTableContainer = styled(TableContainer)`
  font-family: ${p => p.theme.typography.fontFamily};
  background: white;
  -webkit-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1) !important;
  -moz-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1) !important;
  box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1) !important;
  .MuiTableCell-body,
  .MuiTableCell-head {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7;
  }
  .MuiTableCell-head {
    color: #858f95;
  }
  .MuiTableCell-body {
    color: ${props => props.theme.palette.ink[600]};
  }
  .MuiTableCell-root {
    border-color: ${props => props.theme.palette.grey[100]};
  }
`;
