import styled from 'styled-components';

import ContactsIcon from '../../img/svg/company/Contacts.svg';
import ContactsIconHover from '../../img/svg/company/Contacts_Active.svg';
import EntitiesIcon from '../../img/svg/company/Entities.svg';
import EntitiesIconHover from '../../img/svg/company/Entities_Active.svg';
import LicensesIcon from '../../img/svg/company/Licenses.svg';
import LicensesIconHover from '../../img/svg/company/Licenses_Active.svg';
import UsersIcon from '../../img/svg/company/Users.svg';
import UsersIconHover from '../../img/svg/company/Users_Active.svg';
import { transitionStandard } from '../../js_mixins/transitions';

const generateNavIcons = () => {
  const links = [
    {
      label: 'contacts',
      icon: ContactsIcon,
      iconHover: ContactsIconHover,
    },
    {
      label: 'users',
      icon: UsersIcon,
      iconHover: UsersIconHover,
    },
    {
      label: 'licenses',
      icon: LicensesIcon,
      iconHover: LicensesIconHover,
    },
    // TODO: Update Icons
    {
      label: 'hapo',
      icon: LicensesIcon,
      iconHover: LicensesIconHover,
    },
    {
      label: 'entities',
      icon: EntitiesIcon,
      iconHover: EntitiesIconHover,
    },
    {
      label: 'requestforquote',
      icon: LicensesIcon,
      iconHover: LicensesIconHover,
    },
  ];
  return links.map((link, i) => (
    `
      &--${link.label} {
        &:before {
          ${transitionStandard('background-image')};
          background-image: url('${link.icon}');
        }
        &:hover,&.CompanyNavigation__Item--Active {
          &:before {
            background-image: url('${link.iconHover}');
          }
        }
      }
    `));
};

export const NavigationContainer = styled.div`
  background: white;
  border-radius: ${p => p.theme.spacing(1)}px;
  padding: ${p => p.theme.spacing(2)}px 0 !important;
  ${p => p.theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    background: ${p => p.theme.palette.grey[50]}};
    padding: ${p => p.theme.spacing(2)}px;
    box-sizing: border-box;
    #CompanyNavigation__Item--UiPath-Contacts-Tab {
      display: none;
    }
  }
  .CompanyNavigation__Item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 16px 16px 56px;
    ${transitionStandard('all')};
    cursor: pointer;
    position: relative;
    ${p => p.theme.breakpoints.down('sm')} {
      padding: 8px 0 8px 34px;
    }
    &:before {
      content: "";
      position: absolute;
      left: ${p => p.theme.spacing(2)}px;
      width: 24px;
      height: 24px;
      top: 50%;
      transform: translateY(-50%);
      background-size: 24px 24px;
      background-repeat: no-repeat;
      background-position: center;
      ${p => p.theme.breakpoints.down('sm')} {
        left: 0;
      }
    }
    // Defines nav items to loop through below and call the nav-icons mixin.
    ${generateNavIcons()}

    p {
      color: #353C4A;
    }

    &:hover {
      p {
        color: ${p => p.theme.palette.blue[500]};
      }
    }

    &--Active {
      p {
        color: ${p => p.theme.palette.blue[500]};
      }
    }
  }
  .CompanyNavigation__Item-Tab-Indicator {
    background: white;
    width: 2px;
    height: 32px;
    position: absolute;
    left: 0;
    border-radius: 0 2px 2px 0;
    top: 50%;
    transform: translateY(-50%);
    ${transitionStandard('background')};
    ${p => p.theme.breakpoints.down('sm')} {
      width: 100%;
      height: 2px;
      top: unset;
      bottom: -4px;
      left: 50%;
      border-radius: 0 0 2px 2px;
      transform: translateX(-50%);
      background: ${p => p.theme.palette.grey[50]}};
    }
    &.CompanyNavigation__Item-Tab-Indicator--Active {
      background: ${p => p.theme.palette.blue[500]};
    }
    &--Active {
      background: ${p => p.theme.palette.blue[500]};
    }
  }
`;
