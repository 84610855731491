import React, {
  useEffect,
  useRef,
} from 'react';

import { KeyboardEventKey } from '../constants/accessibility.constants';

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  keyboardTrap?: boolean;
  focusOnLoad?: boolean | keyof HTMLElementTagNameMap;
  onCloseFunction?: Function;
};

const AccessibilityComponent = ({
  children,
  keyboardTrap,
  focusOnLoad,
  onCloseFunction,
  ...elementProps
}: Props) => {
  const elementRef: React.MutableRefObject<null | HTMLDivElement> = useRef(
    null
  );

  const FOCUSABLE_ELEMENTS_SELECTOR =
    ':scope button:not([disabled]), [href], input:not([type=\'hidden\']), select, [tabindex]:not([tabindex=\'-1\'])';

  const focusElement = (focusOnLoad: PropType<Props, 'focusOnLoad'>) => {
    if (!focusOnLoad) {
      return;
    }

    let elementToFocus: Element | null | undefined = undefined;

    if (typeof focusOnLoad !== 'boolean') {
      // Focus on the element with the given tag name
      const focusableElements = getFocusableElements(elementRef.current);
      if (focusableElements) {
        elementToFocus = Array.from(focusableElements).find(
          (element) => element.tagName.toLowerCase() === focusOnLoad
        );
      }
    }

    if (!elementToFocus) {
      // Focus on the first element if no element is found
      elementToFocus = elementRef.current?.querySelector(
        FOCUSABLE_ELEMENTS_SELECTOR
      );
    }

    // Check if element can be focused
    if (elementToFocus && elementToFocus instanceof HTMLElement) {
      elementToFocus.focus();
    }
  };

  useEffect(() => {
    focusElement(focusOnLoad);
  }, [ focusOnLoad ]);

  const getFocusableElements = (
    containerElement?: HTMLElement | null
  ): NodeListOf<Element> | undefined => containerElement?.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR);

  const maintainFocusInContainer = (
    e: React.KeyboardEvent<HTMLElement>,
    containerElement?: HTMLElement | null
  ) => {
    if (!containerElement) {
      return;
    }

    const focusableElements = getFocusableElements(containerElement);
    const firstFocusableElement = focusableElements?.[0];
    const lastFocusableElement =
      focusableElements?.[focusableElements.length - 1];

    if (e.shiftKey) {
      // shift + tab key clicked together
      if (
        lastFocusableElement &&
        document.activeElement === firstFocusableElement
      ) {
        (lastFocusableElement as HTMLElement)?.focus();
        e.preventDefault();
      }
    } else {
      // tab key is pressed
      if (
        firstFocusableElement &&
        document.activeElement === lastFocusableElement
      ) {
        (firstFocusableElement as HTMLElement)?.focus();
        e.preventDefault();
      }
    }
  };

  const triggerAccessibilityActions = (e: React.KeyboardEvent<HTMLElement>) => {
    if (
      keyboardTrap &&
      (e.key === KeyboardEventKey.TAB)
    ) {
      maintainFocusInContainer(e, elementRef?.current ?? undefined);
    }

    if (
      !!onCloseFunction &&
      (e.key === KeyboardEventKey.ESCAPE)
    ) {
      onCloseFunction();
    }
  };

  return (
    <div
      onKeyDown={triggerAccessibilityActions}
      ref={elementRef}
      {...elementProps}
    >
      {children}
    </div>
  );
};

export default AccessibilityComponent;
