import styled from 'styled-components';

import NavIcon from '../../../img/svg/arrows/Nav_Icon_ArrowRightWhite_CircleBlue.svg';
import { ContentWrapper } from '../../CustomerPortalContentWrapper';

export const PageWrapper = styled.section`
  ${ContentWrapper}

  .CustomerPortalPage__container {
    min-height: 700px;
  }

    ${p => p.theme.breakpoints.up('sm')} {
      padding-left: ${p => p.theme.spacing(1)}px;
    }
  }

  .supportDetailsInfo {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    > :last-child {
        margin-bottom: ${p => p.theme.spacing(3)}px;
      }
  }

  .resolution {
      margin-top: 16px;

      img {
        max-width:80%;
        vertical-align: middle;
        width: auto;
        height: auto;
      }
  }

  .question,
  .resolution {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #273139;
  }

  .heroBackButton {
    font-size: 1.2rem;
    color: #353c4a;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    cursor:pointer;
    margin-top:24px;
    margin-bottom:32px;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      background:url(${NavIcon}) no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .fullWidthHero {
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    width: 100vw;
    max-height:96px;
    z-index:1;
  }
`;

export const HomeSection = styled.section`
  position: relative;
  background-color: ${p => p.theme.palette.grey[100]};
`;

export const notificationsLoader = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const noResultsTest = styled.h1`
  color: rgb(53,60,74);
  font-size: 2.2rem;
  font-weight: bold;
  height: 32px;
  -webkit-letter-spacing: 0.5px;
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  letter-spacing: 0.5px;
  line-height: 16px;
  width: 100%;
  text-align: center;
  padding: 40px 0;
  margin-bottom: ${p => p.theme.spacing(8)}px;
`;

export const supportDetailsBanner = styled.div`
  min-height: 64px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  box-sizing: border-box;
  position: relative;
  margin: 8px;
  padding: 16px;
  font-size: 1.5rem;
  margin-bottom: 16px;

  ${p => p.theme.breakpoints.down('sm')} {
    height: unset;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }

  > :span {
      margin 8px;
  }
`;

export const supportDetailStatsDiv = styled.div`
  min-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  margin: 8px;
  font-size: 1.5rem;

  ${p => p.theme.breakpoints.down('sm')} {
    height: unset;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 16px;
  }
`;

export const supportDetailStatsLabel = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #526069;
`;

export const supportDetailStats = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #273139;
`;

export const supportDetailsInfoDiv = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 24px;
`;

export const supportDetailsInfoLabel = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #526069;
  margin-right: ${p => p.theme.spacing(1)}px;
`;

export const supportDetailsInfo = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #273139;
`;

export const supportDetailsBannerLabel = styled.span`
  font-weight: bold;
  color: black;
  margin-right: ${p => p.theme.spacing(0.5)}px;
`;

export const supportDetailsBannerDateCase = styled.div`
  /* width: 20%; */
  padding-right: 56px;
  color: ${p => p.theme.palette.ink[650]};
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.5px;
  line-height: 16px;
  box-sizing: border-box;
  ${p => p.theme.breakpoints.down('sm')} {
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }
`;

export const supportTicketDescriptionWrapper = styled.div`
`;

export const heroTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${p => p.theme.spacing(2)}px;
  align-items: flex-start;

  ${p => p.theme.breakpoints.down('xs')} {
    flex-direction: column;

    > :nth-child(2) {
      margin-top: ${p => p.theme.spacing(2)}px;
    }
  }
`;

export const heroTitle = styled.h1`
  color: rgb(0, 0, 0);
  width:100%;
  font-size: 3.6rem;
  font-weight: 800;
  letter-spacing: 0px;
  line-height: 44px;
`;
