import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { CPProgressBar } from '../assets/css/CustomerPortal-ProgressBar';

const LinearProgressWithLabel = (props: any) => {
  const classes = useStyles();

  const percentage =
    isNaN(props.value) || !isFinite(props.value) || props.value < 0
      ? 'N/A'
      : `${props.value.toFixed(1)}%`;

  return (
    <Box
      display="flex"
      alignItems="center">
      <Box
        width="100%"
        mr={1}>
        <CPProgressBar
          variant="determinate"
          {...props}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary">
          {percentage}
        </Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: { width: '100%' },
  colorPrimary: { backgroundColor: '#BADAFF !important' },
  barColorPrimary: { backgroundColor: '#0067DF' },
});

const CPLinearProgressBar = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={(props.progress * 100) / props.total} />
    </div>
  );
};

CPLinearProgressBar.propTypes = {
  /**
   * The value of the progress indicator for the determinate variants.
   */
  progress: PropTypes.number.isRequired,

  /**
   * Total out of which we want to calculate progress percentage
   */
  total: PropTypes.number.isRequired,
};

export default CPLinearProgressBar;
