import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';

const CompanyBodyUnauthorized = () => {
  // Translate method
  const { t } = useTranslation('common');
  const baseClass = 'MyCompanyBody';

  return (
    <div
      className={`${baseClass}__Users`}
      data-testid="CompanyBodyUnauthorized"
    >
      <h3 className={`${baseClass}__Body-Heading Regular`}>
        {t('company_licenses_unauthorized_title', 'Unauthorized')}
      </h3>
      <div className={`${baseClass}__Heading-Actions-Text`}>
        <h5 className={`${baseClass}__Body-Subheading Regular`}>
          {t(
            'company_licenses_unauthorized_description',
            'Please contact your administrator to verify the permissions on your account.'
          )}
        </h5>
      </div>
    </div>
  );
};

export default CompanyBodyUnauthorized;
