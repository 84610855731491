import { Link } from 'react-router-dom';
import styled from 'styled-components';

import NavIcon from '../../img/svg/arrows/Nav_Icon_ArrowRightWhite_CircleBlue.svg';
import { ContentWrapper } from '../CustomerPortalContentWrapper';

export const KnownIssuesContainer = styled.div`
  background-color: #ffffff;
  padding: ${p => p.theme.spacing(4)}px ${p => p.theme.spacing(2)}px
    ${p => p.theme.spacing(3)}px ${p => p.theme.spacing(2)}px;
  z-index: 0;
  ${ContentWrapper}

  ${p => p.theme.breakpoints.up('md')} {
    padding: ${p => p.theme.spacing(8)}px ${p => p.theme.spacing(4)}px
      ${p => p.theme.spacing(9)}px ${p => p.theme.spacing(4)}px;
  }

  &.detailsPage {
    padding-top: 32px;
  }

  .heroBackButton {
    font-size: 1.2rem;
    color: #353c4a;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    cursor:pointer;
    margin-top:24px;
    margin-bottom:32px;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      background:url(${NavIcon}) no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .statusColumn {
    width: 12%;
  }

  .issueIdentifierColumn {
    width: 12%;
  }

  .summaryColumn {
    width: 22%;
  }
  
  .productColumn {
    width: 12%;
  }

  .affectsVersionsColumn {
    width: 16%;
  }

  .fixVersionsColumn {
    width: 14%;
  }

  .dateCreatedColumn {
    width: 12%;
  }
}
`;

export const Title = styled.h1<{ smallFont?: boolean }>`
  color: black;
  font-family: ${p => p.theme.typography.fontFamily};
  font-size: 3.2rem;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1.14;
  white-space: pre-wrap;

  ${p => p.theme.breakpoints.up('lg')} {
    font-size: 5.6rem;
  }
`;

export const Subtitle = styled.p`
  width: 100%;
  padding: ${p => p.theme.spacing(3)}px 0 ${p => p.theme.spacing(5)}px 0;
  color: ${p => p.theme.palette.ink[650]};
  font-family: ${p => p.theme.typography.fontFamily};
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.33;

  ${p => p.theme.breakpoints.up('sm')} {
    width: 50%;
  }

  ${p => p.theme.breakpoints.up('lg')} {
    width: 40%;
  }
`;

export const DetailSectionContainer = styled.div`
  border: 1px solid ${p => p.theme.palette.grey[500]};
  border-radius: 8px;
  padding: 4px 14px 12px;
  margin: 8px 0px;
`;

export const DateSectionContainer = styled.div`
  display: flex;
  margin: 16px 0px;
`;

export const SectionContainer = styled.div`
  margin-top: 24px;
`;

export const HeroTitle = styled.h1`
  color: rgb(0, 0, 0);
  width:100%;
  font-size: 3.6rem;
  font-weight: 800;
  letter-spacing: 0px;
  line-height: 44px;
`;

export const KnownIssueDetailsInfoDiv = styled.div`
  display: flex;
  margin-right: 24px;
  &.affected-product {
    margin-bottom: 8px;
  }
`;

export const KnownIssueDetailsInfoLabel = styled.p`
  color: ${p => p.theme.palette.grey[700]};
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  margin-top: 12px;
  display: flex;
  margin-right: 4px;
`;

export const KnownIssueDetailsInfo = styled.p`
  color: black;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  margin-top: 12px;
  display: flex;
`;

export const SectionTitle = styled.h2`
  color: black;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 12px;
`;

export const SectionContent = styled.h3`
  color: black;
  font-size: 1.4rem;
  font-weight: 400;

  .hardBreak {
    height: 8px;
    width: 100%;
  }

  .codeBlock {
    background-color: ${p => p.theme.palette.grey[200]};
    padding: 8px 12px;
    margin-bottom: 10px;
    margin-top: 12px;
    border-radius: 4px;
  }

  .code {
    background-color: ${p => p.theme.palette.grey[200]};
    border-radius: 4px;
    width: fit-content;
    padding: 0px 2px;
    margin: 0px 4px;
  }
  
  .paragraphBlock {
    display: flex;
    flex-wrap: wrap;
  }

  .heading {
    font-weight: 600;
    margin-top: 12px;
  }

  .em {
    font-style: italic;
  }

  .strike {
    text-decoration: line-through;
  }
  
  .underline {
    text-decoration: underline;
  }

  .strong {
    font-weight: 600;
  }

  .sub {
    font-size: 0.75em;
    margin-top: 0.6em;
  }

  .sup {
    font-size: 0.75em;
    vertical-align: super;
  }

  a {
    color: ${p => p.theme.palette.blue[500]};
  }
`;

export const ListTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ListTag = styled.div`
  background-color: ${p => p.theme.palette.grey[200]};
  border-radius: 50px;
  margin: 0px 4px 8px 4px !important;
  padding: 2px 12px !important;
  width: fit-content;
  font-size: 1.3rem;
  text-align: center;

  &.yellow {
    background-color: ${p => p.theme.palette.yellow[100]};
    color: ${p => p.theme.palette.yellow[700]};
    font-weight: 600;
  }

  &.green {
    background-color: ${p => p.theme.palette.green[100]};
    color: ${p => p.theme.palette.green[700]};
    font-weight: 600;
  }

  &.red {
    background-color: ${p => p.theme.palette.red[100]};
    color: ${p => p.theme.palette.red[700]};
    font-weight: 600;
  }

  &.additional {
    color: ${p => p.theme.palette.ink[700]};
    font-weight: 600;

    &:hover {
      color: ${p => p.theme.palette.blue[500]};
      cursor: pointer;
    }
  }
`;

export const DetailsLink = styled(Link)`
  color: ${p => p.theme.palette.blue[500]};
  text-decoration: none;
  font-weight: 500;
  padding: 6px;
  border-radius: 4px;

  &:hover {
    background-color: ${p => p.theme.palette.blue[100]};
  }

  &.summary {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    height: auto;
    word-wrap: break-word;
  }
`;

export const Popup = styled.div`
  display: none;           
  position: absolute;       
  background-color: white; 
  padding: 10px;             
  z-index: 100;            
  white-space: normal;    
  max-width: 450px;
  transform: translateY(-10%) translateX(-20%);
  border-radius: 4px;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);

  &.versions {
    max-width: 140px;
    transform: none;
  }

  &.summary {
    font-weight: 500;
  }
`;

export const LoaderContainer = styled.div`
  margin-top: 70px;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
