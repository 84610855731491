/**
 * Shared file for Boon related licenses
 * - My Company
 * - RFQ
 */
import {
  licenseBundlesMapping,
  productFeaturesBundlesMapping,
  subscriptionMapping,
} from '../constants/license.constants';

export const getSubscriptionName = (subscriptionCode: string) => subscriptionMapping[subscriptionCode]?.commercialName;

export const getBundleDisplayName = (
  boonLicenseCode: string,
  customInfoArr: any[] | undefined
) => {
  const bundle = customInfoArr?.find(
    license => license.boon_license_cd === boonLicenseCode
  );
  return bundle?.display_name || '';
};

export const getBundleName = (
  bundleCode: string,
  subscriptionName:
  | 'Production'
  | 'NonProduction'
  | 'Production Basic'
  | 'default'
) => {
  let bundleName = '-';
  if (licenseBundlesMapping[bundleCode]) {
    bundleName =
      licenseBundlesMapping[bundleCode]['commercialName']['default'][
        subscriptionName
      ] ||
      licenseBundlesMapping[bundleCode]['commercialName']['default'][
        'default'
      ] ||
      bundleName;
  } else if (productFeaturesBundlesMapping[bundleCode]) {
    bundleName =
      productFeaturesBundlesMapping[bundleCode]['commercialName']['default'][
        subscriptionName
      ] ||
      productFeaturesBundlesMapping[bundleCode]['commercialName']['default'][
        'default'
      ] ||
      bundleName;
  }

  return bundleName;
};
