// Constant file for action types
export default {
  FETCH_INIT: 'FETCH_INIT',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAIL: 'FETCH_FAIL',
  SUBCATEGORY_PROCESSED: 'SUBCATEGORY_PROCESSED',
  PAGE_CLICKED: 'PAGE_CLICKED',
  FILTER_UPDATED: 'FILTER_UPDATED',
  KEYWORD_UPDATED: 'KEYWORD_UPDATED',
  SORT_UPDATED: 'SORT_UPDATED',
  SUBCATEGORY_UPDATED: 'SUBCATEGORY_UPDATED',
  KEYWORD_EMPTY: 'KEYWORD_EMPTY',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  DOCUMENT_DELETED: 'DOCUMENT_DELETED',
};
