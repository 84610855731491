export enum KBCategoryType {
  CATEGORY = 1,
  SUBCATEGORY = 2,
}

export enum KBDocumentType {
  FILE = 'file',
  LINK = 'link',
  EMBEDDED_VIDEO = 'embedded-video',
}

export enum KBEmbeddedVideoType {
  VIMEO = 'vimeo',
  UNKNOWN = 'unknown',
}

export const MAIL_TO_LINK_REGEX = /^mailto:[^\s@]+@[^\s@]+\.[^\s@]+(\?.*)?$/;
