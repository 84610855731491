import type { ReactNode } from 'react';
import React from 'react';

import AuthUserUtil from '../../lib/auth.util';
import ClassicShell from './ClassicShell';
import CloudShell from './CloudShell';

interface Props {
  children?: ReactNode;
}

const Header = ({ children }: Props) => {
  const ShellComponent = AuthUserUtil.isCloudUser() ? CloudShell : ClassicShell;

  return (
    <ShellComponent>
      {children}
    </ShellComponent>
  );
};

export default Header;
