import styled from 'styled-components';

export const ResultContainer = styled.div`
  padding-bottom: ${p => p.theme.spacing(3.5)}px;
  padding-right: ${p => p.theme.spacing(4)}px;
`;

export const ResultLink = styled.a`
  color: ${p => p.theme.palette.blue[500]};
  font-size: 1.6rem;
  font-weight: 700 !important;
`;

export const ResultDescription = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-top: ${p => p.theme.spacing(1)}px;
  margin-bottom: ${p => p.theme.spacing(1)}px;
`;

export const FacetValuesContainer = styled.div`
  display: flex;
`;

export const FacetText = styled.div`
  position: relative;
  color: ${p => p.theme.palette.grey[600]};
  font-weight: 500;
  font-size: 1.2rem;
  padding-right: 20px;
  :not(:last-child)&::after {
    position: absolute;
    top: 40%;
    right: ${p => p.theme.spacing(1.25)}px;
    display: block;
    content: '';
    height: ${p => p.theme.spacing(0.25)}px;
    width: ${p => p.theme.spacing(0.25)}px;
    background: ${p => p.theme.palette.grey[600]};
    border-radius: ${p => p.theme.spacing(0.25)}px;
  }
`;
