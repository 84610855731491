import { Locale } from '@customer-portal/constants';
import { ApShell } from '@uipath/portal-shell-react';
import type { ReactNode } from 'react';
import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import useLogout from '../../hooks/useLogout';
import { isLocalhost } from '../../lib/env.utils';
import { StoreContext } from '../../store';
import AppLoader from '../AppLoader';
import CustomerPortalHeader from '../cp_header/CustomerPortal-Header';

const Container = styled.div`
  display: flex;
  flex: 1;
`;

interface Props {
  children?: ReactNode;
}

const CloudShell = ({ children }: Props) => {
  const { i18n } = useTranslation('common');
  const { state } = useContext(StoreContext);
  const history = useHistory();
  const logout = useLogout();

  const onPortalShellLoaded = useCallback(() => {
    window.PortalShell.initialize({ devProperties: { mode: isLocalhost() ? 'dev-cloud' : 'auto' } });
  }, []);
  const onLogoClick = useCallback(() => {
    history.push('/');
  }, []);
  const onLanguageChanged = useCallback(async (event: any) => {
    const language: string = event?.detail?.selectedLanguageId ?? '';
    const locale: string = language
      .split('-')
      .map((w, i): string => i === 0 ? w : w.toUpperCase())
      .join('-');
    if (locale && locale in Locale) {
      i18n.changeLanguage(locale);
    }
  }, []);

  useEffect(() => {
    // Initialize Portal-Shell
    if (window.PortalShell) {
      onPortalShellLoaded();
    } else {
      document.addEventListener('portalShellLoaded', onPortalShellLoaded);
    }

    // Add global event listeners
    document.addEventListener('logout', logout);
    document.addEventListener('logoClicked', onLogoClick);
    document.addEventListener('languageChanged', onLanguageChanged);

    return () => {
      document.removeEventListener('portalShellLoaded', onPortalShellLoaded);
      document.removeEventListener('logout', logout);
      document.removeEventListener('logoClicked', onLogoClick);
      document.removeEventListener('languageChanged', onLanguageChanged);
    };
  }, []);

  // Show the loader until the `checkUser` action is dispatched and completed inside the children component for the first time.
  // If user was checked previously and being re-checked, show the children to prevent loading-screen when navigating between pages
  const showLoader = !state.finishedInitialUserCheck;

  return (
    <>
      {showLoader && (<AppLoader />)}
      <ApShell
        id='navbar'
        productName='Customer Portal'
        feature={' '}
        showHelpMenu={false}
        hideLanguages={false}
        themeOverride='light'
        showProfileMenu
        hideTenantPicker
        showPreferences
        showSlotEndDivider
        hidden={showLoader}
      >
        <p slot='slot-mid'>
          <Container>
            <CustomerPortalHeader />
          </Container>
        </p>
        {children}
      </ApShell>
    </>
  );
};

export default CloudShell;
