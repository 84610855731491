import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { _404 } from '../../constants/telemetry.constants';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';

const CustomerPortalPage404 = (props: any) => {
  const { t } = useTranslation('common');

  useTrackPageViewEvent(_404);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('404');
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('404_page_title', 'Not Found | Customer Portal')}</title>
      </Helmet>

      <ErrorPage
        errorType='404'
        header={t('page_404_hero_text', 'Something went wrong :-(')}
        description={t(
          'page_404_description',
          'This page was not found. You may have mistyped the address or the page may have moved.'
        )}
      >
        <Link
          data-testid='ErrorPage-404__CTA'
          className='link'
          to='/'
        >
          {t('page_404_cta', 'Go to homepage')}
        </Link>
      </ErrorPage>
    </>
  );
};

export default CustomerPortalPage404;
