import React, {
  useContext,
  useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// styles
import * as styles from '../../assets/css/CustomerPortalHome';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import ScrollToTop from '../../components/CustomerPortal-ScrollToTop';
import FeedbackForm from '../../components/home/Feedback-Form';
import SearchBar from '../../components/search/SearchBar/SearchBar';
import { HOME } from '../../constants/telemetry.constants';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import { headlessSearchEngine } from '../../utils/search';
// Components
import Container from './../../components/CustomerPortal-New-Container';
import { UserPermissionsHelper } from './../../lib/UserPermissions';
import { StoreContext } from './../../store';

const Home = () => {
  const { state } = useContext(StoreContext);
  const history = useHistory();
  const { t } = useTranslation('common');

  const isAllowedViewCollab = UserPermissionsHelper.isViewCollabAllowed();
  const isAllowedViewSupport = UserPermissionsHelper.isViewSupportAllowed();
  const isAllowedViewLicense = state.companyId !== '' && UserPermissionsHelper.isViewLicenseAllowed();
  const doesHaveAnyRole = (state.currentUserCompany?.userCompany?.roles.length ?? 0) + (state.currentAdmin?.roles.length ?? 0) > 0;

  const handleDisabledClick = (e: any) => {
    e.preventDefault();
  };

  const handleRedirectToBrowsePage = () => {
    if (state.isUiPath && !state.companyId) {
      history.push('/browse');
    }
  };

  useTrackPageViewEvent(HOME);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Home');
    handleRedirectToBrowsePage();
  }, []);

  return (
    <>
      <ScrollToTop />

      <Helmet>
        <title>{t('home_page_title', 'Home | Customer Portal')}</title>
      </Helmet>

      <styles.PageWrapper
        isHomePage
        data-testid="CustomerPortalHomePage__pageWrapper">
        {doesHaveAnyRole && <FeedbackForm />}
        <styles.HomeSection>
          <styles.Hero />
          <Container cssClass="CustomerPortalHome__heroContent">
            <styles.CustomerPortalPageHomeMainTitle>
              {t('homepage_hero_title', 'Welcome to the Customer Portal for')}
            </styles.CustomerPortalPageHomeMainTitle>
            <styles.CustomerPortalPageHomeTitle>
              {state.companyName}
            </styles.CustomerPortalPageHomeTitle>
          </Container>
          <Container>
            <SearchBar engine={headlessSearchEngine} />
          </Container>
          <Container cssClass="CustomerPortalHome__Trio-Two-Outer">
            <styles.HomeTrioTwoItem data-testid="CustomerPortalHomePage__seeLicensesBtn">
              <div className="CustomerPoralHome__Trio-Text">
                <h6>{t('homepage_card_1_title', 'Licenses')}</h6>
                <p>
                  {t(
                    'homepage_card_1_description',
                    'View all your UiPath licenses and contact your UiPath team for any inquiries.'
                  )}
                </p>
              </div>
              <styles.CardLink
                disabled={!isAllowedViewLicense}
                to="./company?section=licenses"
                data-testid="CustomerPortalHomePage__licensesCard"
              >
                {t('homepage_card_1_link', 'See licenses')}
              </styles.CardLink>
            </styles.HomeTrioTwoItem>
            <styles.HomeTrioTwoItem data-testid="CustomerPortalHomePage__seeLicensesBtn">
              <div className="CustomerPoralHome__Trio-Text">
                <h6>{t('homepage_card_2_title', 'Collaboration Space')}</h6>
                <p>
                  {t(
                    'homepage_card_2_description',
                    'A secure space where you can upload, share, and organize documents to be shared between your team and your dedicated UiPath contacts.'
                  )}
                </p>
              </div>
              <styles.CardLink
                disabled={!isAllowedViewCollab}
                to="./collab-space"
                onClick={!isAllowedViewCollab ? handleDisabledClick : undefined}
                data-testid="CustomerPortalHomePage__collabCard"
              >
                {t('homepage_card_2_link', 'Access collaboration space')}
              </styles.CardLink>
            </styles.HomeTrioTwoItem>
            <styles.HomeTrioTwoItem data-testid="CustomerPortalHomePage__seeLicensesBtn">
              <div className="CustomerPoralHome__Trio-Text">
                <h6>{t('homepage_card_3_title', 'Support')}</h6>
                <p>
                  {t(
                    'homepage_card_3_description',
                    'View and manage available cases or raise a new one and your dedicated UiPath team will get right to it.'
                  )}
                </p>
              </div>
              <styles.CardLink
                disabled={!isAllowedViewSupport}
                to="./support"
                onClick={
                  !isAllowedViewSupport ? handleDisabledClick : undefined
                }
                data-testid="CustomerPortalHomePage__supportCard"
              >
                {t('homepage_card_3_link', 'See cases')}
              </styles.CardLink>
            </styles.HomeTrioTwoItem>
          </Container>
        </styles.HomeSection>
      </styles.PageWrapper>
    </>
  );
};

export default Home;
