import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BundleCard,
  BundleCardContainer,
  BundleParent,
  BundleParentContainer,
  ProductContainer,
  ProductQty,
  ProductsMainWrapper,
} from '../../../assets/css/Admin/Admin-RFQModal';
import * as styles from '../../../assets/css/Category/KB-Upload-Modal';
import { ModalContentFormWrapper } from '../../../assets/css/Knowledge/CategoryManager-Table';
import Modal from '../../Modal';
import TextLabel from '../../TextLabel';

type AdminHapoModalProps = {
  dataTestId?: string;
  isModalOpen: boolean;
  quoteData: { [key: string]: any };
  handleModalClose: Function;
  children?: any;
};

const AdminHapoModal = (props: AdminHapoModalProps) => {
  const {
    isModalOpen,
    handleModalClose,
    quoteData,
    dataTestId,
  } = props;

  const { t } = useTranslation('common');

  const generateProducts = () => {
    if (!Array.isArray(quoteData.bundles)) {
      return undefined;
    }

    return (
      <BundleCardContainer data-testid={dataTestId || ''}>
        {quoteData.bundles.map((product: any, index: number) => {
          const {
            sku_name,
            existing_license_name,
            license_code,
            type,
            quantity,
            _id,
            products,
          } = product;

          const skuName = existing_license_name ?? sku_name ?? '';
          const bundleType = license_code ?? type ?? '';
          return (
            <BundleCard key={index}>
              <BundleParentContainer>
                <BundleParent>
                  <span>
                    <strong>{skuName}</strong>
                  </span>
                  <br />
                  <span>{bundleType}</span>
                </BundleParent>
                <ProductQty>{quantity}</ProductQty>
              </BundleParentContainer>
              {Array.isArray(products) && products.length > 0 && (
                <ProductContainer>
                  {products.map((childProduct: any, childIndex: number) => (
                    <BundleParentContainer key={childIndex}>
                      <BundleParent>{childProduct.sku_name}</BundleParent>
                      <ProductQty>{childProduct.quantity}</ProductQty>
                    </BundleParentContainer>
                  ))}
                </ProductContainer>
              )}
            </BundleCard>
          );
        })}
      </BundleCardContainer>
    );
  };

  return (
    <Modal
      modalTitle="Order Details"
      modalHeading="Order Details"
      modalDescription="Modal for order details"
      open={isModalOpen}
      handleClose={(e: any) => handleModalClose()}
    >
      <ModalContentFormWrapper>
        {/* Never editable */}
        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_order_details_order_id',
              'Order ID:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={quoteData._id}
            placeholder="Order ID"
            disabled
          />
        </styles.FormControlWrapper>

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_order_details_order_status',
              'Order Status:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={quoteData.status}
            placeholder="Order Status"
            disabled
          />
        </styles.FormControlWrapper>

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_customer_id_long',
              'Customer / ID Long:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={quoteData.companyId}
            placeholder="Customer ID"
            disabled
          />
        </styles.FormControlWrapper>

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_customer_name',
              'Customer Name:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={quoteData.company?.name ?? ''}
            placeholder="Customer Name"
            disabled
          />
        </styles.FormControlWrapper>

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_products',
              'Products:'
            )}
          />
          <ProductsMainWrapper>{generateProducts()}</ProductsMainWrapper>
        </styles.FormControlWrapper>

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_version',
              'Version:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={quoteData.version}
            placeholder="Version"
            disabled
          />
        </styles.FormControlWrapper>

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_created_by',
              'Created By:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={quoteData.created_by}
            placeholder="Created By Name"
            disabled
          />
        </styles.FormControlWrapper>

        {quoteData.note && (
          <styles.FormControlWrapper className="UploadForm__FormControl UploadForm__DescriptionArea">
            <TextLabel
              label={t(
                'rfq_analytics_cart_details_note',
                'Note:'
              )}
            />
            <styles.TextInput
              variant={'outlined' as any}
              className="UploadForm__Textarea"
              value={quoteData.note}
              rows={2}
              multiline
              placeholder="Note from customer"
              disabled
            />
          </styles.FormControlWrapper>
        )}
      </ModalContentFormWrapper>
    </Modal>
  );
};

export default AdminHapoModal;
