import styled from 'styled-components';

import { ContentWrapper } from './CustomerPortalContentWrapper';

export const SupportDashboard = styled.section`
  position: relative;
  background-color: #f5f5f6;
  margin-bottom: ${p => p.theme.spacing(8)}px;
  padding-bottom: 50px;
  ${ContentWrapper}

  ${p => p.theme.breakpoints.up('md')} {
    margin-bottom: ${p => p.theme.spacing(8)}px;
  }

  .CustomerPortalSupportDashboard__weeklyDataContainer {
    text-align: center;
    display: flex;
    justify-content: center;
    height: 76px;

    .CustomerPortalSupportDashboard__weeklyData {
      position: relative;
      width: 30%;
      min-width: 282px;
      padding: ${p => p.theme.spacing(2)}px;

      background-color: #fff;
      border-radius: ${p => p.theme.spacing(1)}px;

      .CustomerPortalSupportDashboard__chartLoader,
      .CustomerPortalSupportDashboard__chartNoData {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .CustomerPortalSupportDashboard__chartNoData {
        color: #e3e3e3;
        font-weight: bold;
        font-size: 5  .4rem;
      }

      ${p => p.theme.breakpoints.up('md')} {
        padding-top: ${p => p.theme.spacing(2)}px;
        padding-bottom: ${p => p.theme.spacing(2)}px;
        padding-left: ${p => p.theme.spacing(2)}px;
        padding-rigth: ${p => p.theme.spacing(2)}px;
      }

      .CustomerPortalSupportDashboard__weeklyData-Heading {
        margin-bottom: 4px;
        font-size: 20px;
      }

      .CustomerPortalSupportDashboard__weeklyData-Heading,
      .CustomerPortalSupportDashboard__weeklyData-SubheadingCount {
        font-weight: bold;
        color: black;
        font-size: 20px;
      }

      .CustomerPortalSupportDashboard__weeklyData-Subheading {
        font-size: 14px;
        color: #273139;
      }
    }
  }

  .CustomerPortalSupportDashboard__chartTitle {
    position: absolute;
    top: ${p => p.theme.spacing(0.5)}px;
    left: ${p => p.theme.spacing(2)}px;

    font-size: 1.4rem;
    font-weight: 600;
    line-height: ${p => p.theme.spacing(2.5)}px;

    color: #273139;

    &--smallChart {
      left: ${p => p.theme.spacing(2.5)}px;
    }

    ${p => p.theme.breakpoints.up('md')} {
      top: ${p => p.theme.spacing(1.5)}px;
      left: ${p => p.theme.spacing(2.5)}px;
    }
  }

  .CustomerPortalSupportDashboard__3XGridCharts {
    min-height: 282px;

    ${p => p.theme.breakpoints.up('md')} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .CustomerPortalSupportDashboard__chart {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    position: relative;
    width: 100%;
    height: 300px;
    margin-bottom: ${p => p.theme.spacing(3)}px;

    background-color: #fff;
    border-radius: ${p => p.theme.spacing(1)}px;

    .CustomerPortalSupportDashboard__chartLoader,
    .CustomerPortalSupportDashboard__chartNoData {
      width: 100%;
      height: 100%;
      min-height: 282px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .CustomerPortalSupportDashboard__chartNoData {
      color: #e3e3e3;
      font-weight: bold;
      font-size: 2.4rem;
    }

    .recharts-legend-wrapper {
      margin-left: ${p => p.theme.spacing(2)}px;
    }

    .recharts-default-legend {
      margin-bottom: ${p => p.theme.spacing(0)}px !important;
      margin-top: ${p => p.theme.spacing(3)}px !important;
    }


    ${p => p.theme.breakpoints.up('md')} {
      margin-bottom: 0;

      flex: 0 0 auto;
      width: calc(33.3% - ${p => p.theme.spacing(2.25) * 2 / 3}px);

      &:not(:nth-child(odd)){
        margin-left: 0;
      }

      &:not(:nth-child(3n+1)){
          margin-left: ${p => p.theme.spacing(2.25)}px;
      }

      .recharts-wrapper {
        margin-top: ${p => p.theme.spacing(2)}px;
      }

      .recharts-legend-wrapper {
        margin-left: ${p => p.theme.spacing(2)}px;
      }

      .recharts-default-legend {
        margin-bottom: ${p => p.theme.spacing(2)}px !important;
      }
    }

    .CustomerPortalSupportDashboard__PieChart {
      .recharts-surface {
        float: right;
        width: 80%;
        margin-right: 10px;
      }
    }

    // NOTE: this is a work around to set custom font size, color for all legends
    .ChartWrap {
      &>div>div>ul>li>span {
        font-size: 12px;
        color: #9A9B9F;
      }
    }
  }

  .CustomerPortalSupportDashboard__categoryTitle {
    margin: ${p => p.theme.spacing(3)}px 0;

    font-size: 2rem;
    font-weight: bold;
    line-height: 20px;
  }

  .CustomerPortalClientsSelection__container {
    display: flex;
    flex-direction: column;

    ${p => p.theme.breakpoints.up('sm')} {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .LegendTitle {
    font-size: 12px;
    line-height: 148%;
    color: #9A9B9F;
  }
  .PieChartLegend__TotalsView {
    margin-top:5px;

    ${p => p.theme.breakpoints.down('sm')} {
      margin-top:15px;
    }
  }
  .PieChartLegend__TotalsView__Total {
    font-size: 3.5rem;
    line-height: 34px;
    color: black;
  }

  .PieChartLegends__TotalsView__Cases {
    font-size: 14px;
    line-height: 22px;
    color: #58595B;
  }

  .PieChartLegend__TableContainer {
    overflow: auto;
    max-height: 282px;
    padding-right: 20px;
    overflow-y: auto;
  }

  .PieChartLegend_Table {
    padding-left: 0px;
    margin-top: 13px;
    justify-content: space-between;
    width: 100%;
    table-layout: auto;
  }

  .PieChartLegend__Table__ItemName {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9A9B9F;
    line-height: 148%;
    text-transform: capitalize;
  }

  .PieChartLegend__Table__ItemCount_TableData {
    text-align: end;
  }

  .PieChartLegend__Table__ItemCount {
    font-weight: bold;
    font-style: normal;
    font-size: 12px;
    color: #58595B;
    text-align: right;
    line-height: 148%;
  }
`;
