import type { User as Auth0SPAUser } from '@auth0/auth0-spa-js';
import { AuthType } from '@customer-portal/constants';
import {
  getAccessToken,
  getUser,
  useSignoutRedirect,
} from '@uipath/auth-react';
import type { User as OIDCUser } from 'oidc-client-ts';
import type { ReactNode } from 'react';
import React, {
  createContext,
  useContext,
} from 'react';

import { getStringEnv } from '../../lib/index.util';
import { isCloudEnv } from '../../utils/cloud';
import {
  Auth0Provider,
  useAuth0,
} from './auth0';
import { IdentityProvider } from './identity';

export type Auth0User = Auth0SPAUser;
export type IdentityUser = OIDCUser;

export interface IAuthContext {
  user: Auth0User | IdentityUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  getAccessToken: () => Promise<string>;
  logout: () => Promise<void>;
}

export const getAuthType = (): AuthType => isCloudEnv() ? AuthType.Cloud : AuthType.Classic;

export const AuthContext = createContext({} as IAuthContext);
export const useAuth = () => useContext(AuthContext);

const AuthContextProvider = ({ children }: { children?: ReactNode }) => {
  const authType = getAuthType();
  const auth0 = useAuth0();
  const signoutRedirect = useSignoutRedirect();

  const contextValue: IAuthContext = authType === AuthType.Cloud ? {
    user: getUser(),
    isAuthenticated: true,
    isLoading: false,
    getAccessToken: async () => getAccessToken() ?? '',
    logout: () => signoutRedirect({ post_logout_redirect_uri: getStringEnv(process.env.REACT_APP_LOGOUT_URL) }),
  } : {
    user: auth0.user ?? null,
    isAuthenticated: auth0.isAuthenticated,
    isLoading: auth0.isLoading,
    getAccessToken: async () => (auth0?.getTokenSilently && await auth0.getTokenSilently()) ?? '',
    logout: async () => {
      auth0.logout && await auth0.logout({ logoutParams: { returnTo: getStringEnv(process.env.REACT_APP_LOGOUT_URL) } });
    },
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

interface Props {
  children?: ReactNode;
}

export const AuthProvider = (props: Props) => (
  <Auth0Provider>
    <IdentityProvider>
      <AuthContextProvider>
        {props.children}
      </AuthContextProvider>
    </IdentityProvider>
  </Auth0Provider>
);
