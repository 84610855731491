import React, {
  useEffect,
  useState,
} from 'react';

// Styles
import * as style from './../assets/css/components/CustomerPortal-Pagination';

type PaginationProps = {
  activePage: number;
  handleClick: (val: number) => void;
  numPagesBeforeEllipses: number;
  numPages?: number;
  numResultsPerPage?: number;
  numResults?: number;
  className?: string;
  dataTestIdRightButton?: string;
};

/**
 * Takes in total number of pages to display
 * or uses total number of elements and divide by
 * number of elements to display per page.
 * Also takes in number of pages to display preceding the ellipses.
 *
 */
const CustomerPortalPagination = ({
  activePage,
  handleClick,
  numPages,
  numPagesBeforeEllipses = 5,
  numResults,
  numResultsPerPage,
  className,
  dataTestIdRightButton = 'PaginationRightButton',
}: PaginationProps) => {
  // Get total pages
  let totalPages = 1;
  if (numPages) {
    totalPages = numPages;
  } else if (numResults && numResultsPerPage) {
    totalPages = Math.ceil(numResults / numResultsPerPage);
  }

  // Get number of pages before ellipses
  const pagesBeforeEllipses = numPagesBeforeEllipses || totalPages;

  const [ paginationButtons, setPaginationButtons ] = useState<Array<{ pageNum: number; isActive: boolean } | null>>([ {
    pageNum: activePage,
    isActive: true,
  } ]);

  useEffect(() => {
    // If `totalPages` is less than `pagesBeforeEllipses`
    if (totalPages < pagesBeforeEllipses) {
      setPaginationButtons(new Array(totalPages).fill(null)
        .map((obj, i) => ({
          pageNum: i + 1,
          isActive: i + 1 === activePage,
        })));
      return;
    }

    const newPaginationButtons: Array<{ pageNum: number; isActive: boolean } | null> = [ {
      pageNum: activePage,
      isActive: true,
    } ];
    let left = activePage - 1;
    let right = activePage + 1;

    // Make sure `totalPages` was set and left and right aren't out of bounds
    while (totalPages !== undefined && (left > 1 || right < totalPages)) {
      // Shows -> [2 prev pages, ..., first 2 pages]
      if (left - pagesBeforeEllipses > 1) {
        newPaginationButtons.unshift(
          ...new Array(2).fill(null)
            .map((obj) => ({
              pageNum: left--,
              isActive: false,
            }))
            .reverse()
        );
        newPaginationButtons.unshift(null);
        newPaginationButtons.unshift(
          ...new Array(2).fill(null)
            .map((obj, i) => ({
              pageNum: i + 1,
              isActive: false,
            }))
        );
        left = 1;
        // Shows -> [first `pagesBeforeEllipses` pages]
      } else {
        newPaginationButtons.unshift(
          ...new Array(left).fill(null)
            .map((obj) => ({
              pageNum: activePage - left--,
              isActive: false,
            }))
        );
      }

      // Shows -> [2 next pages, ..., last 2 pages]
      if (right + pagesBeforeEllipses < totalPages) {
        newPaginationButtons.push(
          ...new Array(2).fill(null)
            .map((obj) => ({
              pageNum: right++,
              isActive: false,
            }))
        );
        newPaginationButtons.push(null);
        newPaginationButtons.push(
          ...new Array(2).fill(null)
            .map((obj, i) => ({
              pageNum: totalPages - (1 - i),
              isActive: false,
            }))
        );
        right = totalPages;
        // Shows -> [last `pagesBeforeEllipses` pages]
      } else {
        newPaginationButtons.push(
          ...new Array(totalPages - (right - 1)).fill(null)
            .map((obj) => ({
              pageNum: right++,
              isActive: false,
            }))
        );
      }
    }
    setPaginationButtons(newPaginationButtons);
  }, [ activePage, numPages, totalPages ]);

  // Event Listener
  const handleButtonClick = (val: number) => {
    if (val < 1 || val > totalPages || val === activePage) {
      return;
    }

    if (typeof handleClick === 'function') {
      handleClick(val);
    }
  };

  return (
    <style.PaginationWrapper
      className={className}
      data-testid='Pagination'>
      <button
        id="LeftButton"
        disabled={activePage === 1}
        onClick={() => handleButtonClick(activePage - 1)}
      />

      <div
        className="CustomerPortalPagination__NumberedButtonContainer"
        data-testid='Pagination__NumberedButtonContainer'
      >
        {paginationButtons.map((page, i) => {
          const classes =
            page?.pageNum === activePage
              ? 'CustomerPortalPagination__NumberedButton CustomerPortalPagination__NumberedButton--Active'
              : 'CustomerPortalPagination__NumberedButton';
          return !page
            ? (
              <span
                className='CustomerPortalPagination__Ellipses'
                key={`Ellipses-${i}`}
              >
                ...
              </span>
            ) : (
              <button
                key={`page-${page.pageNum}`}
                className={classes}
                onClick={() => handleButtonClick(page.pageNum)}
              >
                {page.pageNum}
              </button>
            );
        })}
      </div>

      <button
        id="RightButton"
        data-testid={dataTestIdRightButton}
        disabled={activePage === totalPages}
        onClick={() => handleButtonClick(activePage + 1)}
      />
    </style.PaginationWrapper>
  );
};

export default CustomerPortalPagination;
