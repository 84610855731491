import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';

import {
  GROUP,
  INDIVIDUAL,
  PM_DEVELOPER,
} from '../../../constants/hapo.constants';
import {
  AUTOMATION_SUITE,
  CLOUD,
  EXISTING_AUTOMATION_SUITE,
  EXISTING_CLOUD,
  EXISTING_DEDICATED_AUTOMATION_CLOUD,
  EXISTING_ORCHESTRATOR,
  EXISTING_PROCESS_MINING,
  NEW_ORCHESTRATOR,
  NEW_PROCESS_MINING,
  STANDALONE,
} from '../../../constants/requestForQuote.constants';
import {
  calculateGroupBundlesAvailableQuantities,
  getAvailableQuatity,
  groupBundlesByGroupName,
} from './../../../lib/hapo.utils';
import ProductName from './ProductNameTooltip';

const OrderBundles = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');

  const formatedBundles: any[] = [];
  const bundles: any[] = props.bundles;
  const hapoConfig = props.hapoRef?.summed_config?.config;

  function renderIndividualLicenses(bundles: any[]) {
    return (
      <>
        <div
          className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestName"
          data-testid="HapoOrderBundles_nameWrapper"
        >
          <span className="RequestHistoryListItemDesc__mobile">
            {t('past_requests_hapo_column_name', 'Name')}
            {' '}
/
            {' '}
            {t('past_requests_hapo_column_quantity', 'Quantity')}
            {' '}
/
            {' '}
            {t('past_requests_hapo_column_remaining', 'Remaning')}
:
          </span>
          {bundles.map((item: any, index: number) => (
            <div
              key={`individual - ${index}`}
              className="RequestHistoryListItem__nameQuantityRemaining"
            >
              <ProductName
                licenseName={item.licenseName}
                licenseCode={item.licenseCode}
                productName={item.name}
                consumedAgainst={item.consumedAgainst}
              />
              <span className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestQuantityRequested">
                {item.quantity}
              </span>
              <span className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestRemaining">
                {item.availableQuantity}
              </span>
            </div>
          ))}
        </div>
      </>
    );
  }

  function renderGroupedLicenses(
    bundles: any[],
    hasIndividualLicenses: boolean
  ) {
    return (
      <>
        <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestName">
          <span className="RequestHistoryListItemDesc__mobile">
            {t('past_requests_hapo_column_name', 'Name')}
:
          </span>
          {Object.entries(groupBundlesByGroupName(bundles)).map(
            (item: any, index: number) => (
              <React.Fragment key={`header - ${index}`}>
                <span
                  className="RequestHistoryListItemTitle RequestHistoryListItemTitle--grouped"
                  data-testid="HapoOrderBundles_groupedText"
                >
                  {t(
                    'past_requests_hapo_grouped_licenses',
                    'Grouped Licenses'
                  )}
                  {' '}
                    - [
                  {item[0]}
]
                </span>
                <div className="RequestHistoryListItemGrouped">
                  {Array.isArray(item[1]) &&
                      item[1].map((product: any, index: number) => (
                        <div
                          key={`groupProduct - ${index}`}
                          className="RequestHistoryListItem__nameQuantityRemaining"
                        >
                          <ProductName
                            licenseName={product.licenseName}
                            licenseCode={product.licenseCode}
                            productName={product.name}
                            consumedAgainst={item.consumedAgainst}
                          />
                          <span className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestQuantityRequested">
                            <span>{product.quantity}</span>
                          </span>
                          <span className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestRemaining">
                            <span>
                              {calculateGroupBundlesAvailableQuantities(
                                groupBundlesByGroupName(bundles),
                                item[0]
                              )}
                            </span>
                          </span>
                        </div>
                      ))}
                </div>
              </React.Fragment>
            )
          )}
          {hasIndividualLicenses && (
            <>
              <span
                className="RequestHistoryListItemTitle RequestHistoryListItemTitle--nonGrouped"
                data-testid="HapoOrderBundles_nonGroupedText"
              >
                {t(
                  'past_requests_hapo_non_grouped_licenses',
                  'Non-Grouped Licenses'
                )}
              </span>
              <div className="RequestHistoryListItemNonGrouped">
                {bundles.map((item: any, index: number) => {
                  if (
                    item.consumedAgainst === INDIVIDUAL ||
                    item.consumedAgainst === PM_DEVELOPER
                  ) {
                    return (
                      <div
                        key={`nonGrouped - ${index}`}
                        className="RequestHistoryListItem__nameQuantityRemaining"
                      >
                        <ProductName
                          licenseName={item.licenseName}
                          licenseCode={item.licenseCode}
                          productName={item.name}
                          consumedAgainst={item.consumedAgainst}
                        />
                        <span className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestQuantityRequested">
                          {item.quantity}
                        </span>
                        <span className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestRemaining">
                          {item.availableQuantity}
                        </span>
                      </div>
                    );
                  }
                  return undefined;
                })}
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  // a product can be a bundle or individual product
  function formatedProducts(
    product: any,
    licenseName?: string,
    licenseCode?: string
  ) {
    return {
      productCode: product.product_code,
      name: product.sku_name,
      quantity: product.quantity,
      availableQuantity:
        product.consumed_against === GROUP
          ? getAvailableQuatity(product.product_code, hapoConfig)
            .availableQuantityGroup
          : getAvailableQuatity(product.product_code, hapoConfig)
            .availableQuantityIndividual,
      consumedAgainst: product.consumed_against,
      consumedAgainstGroupName: product.consumed_against_group_name,
      licenseCode: licenseCode ? licenseCode : product.license_code,
      licenseName: licenseName
        ? licenseName
        : product.existing_license_name
          ? product.existing_license_name
          : product.sku_name,
    };
  }

  function generateOrderBundles() {
    let isGrouped: boolean = false;
    let hasIndividualLicenses: boolean = false;

    for (const bundle of bundles) {
      const isExistingBundle =
        bundle.type === EXISTING_ORCHESTRATOR ||
        bundle.type === EXISTING_PROCESS_MINING ||
        bundle.type === EXISTING_CLOUD ||
        bundle.type === EXISTING_AUTOMATION_SUITE ||
        bundle.type === EXISTING_DEDICATED_AUTOMATION_CLOUD;
      const isNewBundle =
        bundle.type === NEW_ORCHESTRATOR ||
        bundle.type === NEW_PROCESS_MINING ||
        bundle.type === AUTOMATION_SUITE ||
        bundle.type === CLOUD;

      // First check Standalone licences, this doesn't have any products attached
      if (bundle.type === STANDALONE) {
        if (bundle.consumed_against === GROUP) {
          isGrouped = true;
        } else {
          hasIndividualLicenses = true;
        }

        formatedBundles.push(formatedProducts(bundle));
      } else if (isExistingBundle) {
        // Add the products
        for (const product of bundle.products) {
          if (product.consumed_against === GROUP) {
            isGrouped = true;
          } else {
            hasIndividualLicenses = true;
          }

          formatedBundles.push(
            formatedProducts(
              product,
              bundle.existing_license_name,
              bundle.license_code
            )
          );
        }
      } else if (isNewBundle) {
        if (bundle.consumed_against === GROUP) {
          isGrouped = true;
        } else {
          hasIndividualLicenses = true;
        }

        // Add the orcherstrators
        formatedBundles.push(formatedProducts(bundle));

        // Now add the products
        for (const product of bundle.products) {
          if (product.consumed_against === GROUP) {
            isGrouped = true;
          } else {
            hasIndividualLicenses = true;
          }

          formatedBundles.push(
            formatedProducts(product, bundle.sku_name, bundle.license_code)
          );
        }
      }
    }

    if (isGrouped) {
      return renderGroupedLicenses(formatedBundles, hasIndividualLicenses);
    }

    return renderIndividualLicenses(formatedBundles);
  }

  return generateOrderBundles();
};

export default OrderBundles;
