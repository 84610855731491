const RequestForQuote: {
  pageTitle: { keyText: 'rfq_hero_title'; fallbackText: 'Shop Products' };
  [key: string]: any;
  productTypes: {
    onPrem: 'OnPrem Orchestrated';
    automationSuite: 'Automation Suite';
    cloud: 'Cloud Orchestrated';
    standalone: 'Standalone';
    services: 'Services';
    processMining: 'Process Mining';
    dedicatedCloud: 'Dedicated Automation Cloud';
  };
  productLabelText: {
    onPrem: 'Standalone Orchestrator';
    automationSuite: 'Automation Suite';
    cloud: 'Automation Cloud';
    standalone: 'Standalone';
    services: 'Services';
    processMining: 'Process Mining';
    dedicatedCloud: 'Dedicated Automation Cloud';
  };
  onPremProductCategory: {
    all: { keyText: string; fallbackText: string };
    robots: { keyText: string; fallbackText: string };
    users: { keyText: string; fallbackText: string };
    platforms: { keyText: string; fallbackText: string };
    orchestrators: { keyText: string; fallbackText: string };
    services: { keyText: string; fallbackText: string };
  };
  productTags: {
    robots: { keyText: string; fallbackText: string };
    users: { keyText: string; fallbackText: string };
    platforms: { keyText: string; fallbackText: string };
    orchestrators: { keyText: string; fallbackText: string };
    automationSuite: { keyText: string; fallbackText: string };
    cloud: { keyText: string; fallbackText: string };
    services: { keyText: string; fallbackText: string };
  };
} = {
  pageTitle: {
    keyText: 'rfq_hero_title',
    fallbackText: 'Shop Products',
  },
  productTypes: {
    onPrem: 'OnPrem Orchestrated',
    cloud: 'Cloud Orchestrated',
    automationSuite: 'Automation Suite',
    standalone: 'Standalone',
    services: 'Services',
    processMining: 'Process Mining',
    dedicatedCloud: 'Dedicated Automation Cloud',
  },
  productLabelText: {
    onPrem: 'Standalone Orchestrator',
    automationSuite: 'Automation Suite',
    cloud: 'Automation Cloud',
    standalone: 'Standalone',
    services: 'Services',
    processMining: 'Process Mining',
    dedicatedCloud: 'Dedicated Automation Cloud',
  },
  onPremProductCategory: {
    all: {
      keyText: 'rfq_hapo_browse_products_category_all_label',
      fallbackText: 'All',
    },
    robots: {
      keyText: 'rfq_hapo_browse_products_category_robots_label',
      fallbackText: 'Robots',
    },
    users: {
      keyText: 'rfq_hapo_browse_products_category_users_label',
      fallbackText: 'Users',
    },
    platforms: {
      keyText: 'rfq_hapo_browse_products_category_platforms_label',
      fallbackText: 'Platforms',
    },
    orchestrators: {
      keyText: 'rfq_hapo_browse_products_category_orchestrators_label',
      fallbackText: 'Orchestrators',
    },
    services: {
      keyText: 'rfq_hapo_browse_products_category_services_label',
      fallbackText: 'Services',
    },
  },
  productTags: {
    robots: {
      keyText: 'rfq_hapo_browse_products_category_robots_label',
      fallbackText: 'Robots',
    },
    users: {
      keyText: 'rfq_hapo_browse_products_category_users_label',
      fallbackText: 'Users',
    },
    platforms: {
      keyText: 'rfq_hapo_browse_products_category_platform_capabilities_label',
      fallbackText: 'Platform Capabilities',
    },
    orchestrators: {
      keyText: 'rfq_hapo_browse_products_category_orchestrator_label',
      fallbackText: 'Orchestrator',
    },
    automationSuite: {
      keyText: 'rfq_hapo_browse_products_category_automation_suite_label',
      fallbackText: 'Automation Suite',
    },
    cloud: {
      keyText: 'rfq_hapo_browse_products_category_automation_cloud_label',
      fallbackText: 'Automation Cloud',
    },
    services: {
      keyText: 'rfq_hapo_browse_products_category_services_label',
      fallbackText: 'Services',
    },
  },
};

export default RequestForQuote;

export const QuoteDuration = [
  {
    label: '1 year',
    value: 1,
  },
  {
    label: '2 years',
    value: 2,
  },
  {
    label: '3 years',
    value: 3,
  },
  {
    label: '4 years',
    value: 4,
  },
  {
    label: '5 years',
    value: 5,
  },
];

/**
 * Product Codes that require announcement modal
 */
export const AnnouncementConfig: { [key: string]: { message: string } } = {
  // UiPath - Flex - Tester - Named User
  UIFDTNU0000: { message: 'hapo_warning_message_tester_named_user' },
  // UiPath - Flex - Test Management
  UIFDTM00000: { message: 'hapo_warning_message_test_managment' },
};

export const ANNOUNCEMENT_CONFIG_PRODUCT_CODES = Object.keys(
  AnnouncementConfig
);

/**
 * Constants for API Server
 */
export const NEW_ORCHESTRATOR = 'New Orchestrator';
export const EXISTING_ORCHESTRATOR = 'Existing Orchestrator';
export const CLOUD = 'Cloud';
export const STANDALONE = 'Standalone';
export const SERVICE = 'Service';
export const EXISTING_PROCESS_MINING = 'Existing Process Mining';
export const NEW_PROCESS_MINING = 'New Process Mining';
export const EXISTING_CLOUD = 'Existing Cloud';
export const AUTOMATION_SUITE = 'Automation Suite';
export const EXISTING_AUTOMATION_SUITE = 'Existing Automation Suite';
export const AUTOMATION_CLOUD = 'Automation Cloud';
export const EXISTING_DEDICATED_AUTOMATION_CLOUD = 'Existing Dedicated Automation Cloud';
export const NEW_DEDICATED_AUTOMATION_CLOUD = 'New Dedicated Automation Cloud';

export const ProductTypeToAPIType = new Map<string, string>([
  [ RequestForQuote.productTypes.cloud, CLOUD ],
  [ RequestForQuote.productTypes.standalone, STANDALONE ],
  [ RequestForQuote.productTypes.services, SERVICE ],
  [ RequestForQuote.productTypes.automationSuite, AUTOMATION_SUITE ],
]);

export const APITypeToProductType = new Map<string, string>([
  [ CLOUD, RequestForQuote.productTypes.cloud ],
  [ EXISTING_CLOUD, RequestForQuote.productTypes.cloud ],
  [ STANDALONE, RequestForQuote.productTypes.standalone ],
  [ SERVICE, RequestForQuote.productTypes.services ],
  [ NEW_ORCHESTRATOR, RequestForQuote.productTypes.onPrem ],
  [ EXISTING_ORCHESTRATOR, RequestForQuote.productTypes.onPrem ],
  [ EXISTING_PROCESS_MINING, RequestForQuote.productTypes.processMining ],
  [ NEW_PROCESS_MINING, RequestForQuote.productTypes.processMining ],
  [ AUTOMATION_SUITE, RequestForQuote.productTypes.automationSuite ],
  [ EXISTING_AUTOMATION_SUITE, RequestForQuote.productTypes.automationSuite ],
]);

export const ORCHESTRATOR_BUNDLE_TYPE = 'orchestrator';
export const PROCESS_MINING_BUNDLE_TYPE = 'pm server';
export const CLOUD_BUNDLE_TYPE = 'cloud';
export const AUTOMATION_SUITE_BUNDLE_TYPE = 'automation suite';
export const DEDICATED_AUTOMATION_CLOUD_BUNDLE_TYPE = 'dedicated automation cloud';

export const NewAutomationSuiteBundleOption = {
  type: AUTOMATION_SUITE_BUNDLE_TYPE,
  sku_name: AUTOMATION_SUITE,
  sku_id: 'CRPAASxxxxx',
  product_code: 'CRPAASxxxxx',
  quantity: 1,
  min_quantity: 0,
  max_quantity: 99999,
};

export const NewAutomationCloudBundleOption = {
  type: CLOUD_BUNDLE_TYPE,
  sku_name: 'Automation Cloud',
  sku_id: 'CRPAACxxxxx',
  product_code: 'CRPAACxxxxx',
  quantity: 1,
  min_quantity: 0,
  max_quantity: 99999,
};

export type RFQBundleType =
  | 'orchestrator'
  | 'pm server'
  | 'cloud'
  | 'automation suite'
  | 'dedicated automation cloud';

export enum DEPLOYMENT_TYPE {
  ORCHESTRATOR = 'OrchestratorMSI',
  AUTOMATION_SUITE = 'service-fabric',
  AUTOMATION_CLOUD = 'cloud',
  DEDICATED_AUTOMATION_CLOUD = 'dedicated',
}

/**
 * *********************************************
 */
