/**
 * Returns a number from a string and returns 0
 * @param number: string value
 * @returns Number or 0 if invalid
 */
export const getNumber = (number: string) => {
  const re = /^[0-9\b]+$/;

  // if value is not blank, then test the regex
  return re.test(number) ? parseInt(number, 10) : 0;
};

// disable keyboard for date picker
export const handleKeypress = (event: Event) => {
  event.preventDefault();
  return false;
};

/**
 * calculate adjusted font size, shrink font size to fit in a container
 * @param text x
 * @param initialFontSize max font size
 * @param maxWidth width of container
 * @returns
 */
export const calculateAdjustedFontSize = (
  text: number | string,
  initialFontSize: number,
  maxWidth: number
): number => {
  const canvas = document.createElement('canvas');
  document.body.appendChild(canvas); // Append the canvas to the DOM
  canvas.style.display = 'none'; // Hide the canvas from view
  const context = canvas.getContext('2d');
  if (context) {
    context.font = `${initialFontSize}px sans-serif`;

    const textMetrics = context.measureText(text.toString());
    const width = textMetrics.width;
    if (width + 10 > maxWidth) {
      // Calculate the ratio of text width to container width
      const ratio = maxWidth / width;

      // Calculate the adjusted font size
      return Math.floor(initialFontSize * ratio) - 2;
    }
  }
  return initialFontSize;
};
