import TableContainer from '@mui/material/TableContainer';
import styled from 'styled-components';

export const NewCPTableContainer = styled(TableContainer)`
  font-family: ${p => p.theme.typography.fontFamily};
  background: white;
  margin-bottom: 2rem;
  .MuiTableCell-body,
  .MuiTableCell-head {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7;
  }
  .MuiTableCell-head {
    color: rgb(39, 49, 57);
    background-color: #f4f5f7;
    line-height: 1.5rem;
  }
  .MuiTableCell-body {
    color: ${props => props.theme.palette.ink[600]};
  }
  .MuiTableCell-root {
    border-bottom: 1px solid rgb(207, 216, 221);
  }
  .heading .MuiTableCell-head {
    font-weight: 600 !important;
  }
`;
