import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as styles from '../assets/css/CustomerPortal-LandingPage';
import BasicHero from '../components/BasicHero/CustomerPortal-BasicHero';
import { UNREGISTERED_PATH_PREFIX } from '../constants/network.constants';
import { onPublicLogin } from '../utils/publicExperience';

export type CustomerPortalLandingProps = {
  text: any;
  isPublicLandingPage: boolean;
  isNavigationDisabled?: boolean;
  setShowSupportForm?: (value: boolean) => void;
};

const CustomerPortalLanding = (props: CustomerPortalLandingProps) => {
  const {
    text,
    isPublicLandingPage,
    isNavigationDisabled,
    setShowSupportForm,
  } = props;
  const { t } = useTranslation('common');
  const history = useHistory();
  const baseClass = 'LandingPage';

  return (
    <>
      <BasicHero
        title={isPublicLandingPage ? t('support_form_new_technical_support_request_title', 'New Technical Support Request') : ''}
        smallFont />
      <styles.LandingPageContainer>
        <div className={`${baseClass}__Welcome-Text`}>
          <div className={`${baseClass}__Heading`}>
            {t('support_form_welcome_heading', 'Welcome to the UiPath Customer Support Portal')}
          </div>
          <div className={`${baseClass}__Description`}>
            {t(text.description1.textKey, text.description1.fallbackText)}
          </div>
          <div
            className={`${baseClass}__Description`}
            dangerouslySetInnerHTML={{ __html: t(text.description2.textKey, text.description2.fallbackText) }} />
          <div className={`${baseClass}__Button-Container`}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => isPublicLandingPage ? onPublicLogin() : history.push(`${UNREGISTERED_PATH_PREFIX}/activate-account`)}
              className={`${baseClass}__Button`}
              data-testid='register-button'
            >
              {t(text.button1.textKey, text.button1.fallbackText)}
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => isPublicLandingPage && setShowSupportForm ? setShowSupportForm(true) : history.push(`${UNREGISTERED_PATH_PREFIX}/support/add-case`)}
              className={`${baseClass}__Button`}
              data-testid='continue-button'
              disabled={isNavigationDisabled}
            >
              {t(text.button2.textKey, text.button2.fallbackText)}
            </Button>
          </div>
        </div>
      </styles.LandingPageContainer>
    </>
  );
};

export default CustomerPortalLanding;
