import styled from 'styled-components';

import ApolloIcon from '../../../components/ApolloIcon';
import type { MessageType } from '../../../components/StickyMessageBanner';

const messageTypeToBackgroundColor: { [key in MessageType]: string; } = {
  info: 'green',
  warning: 'yellow',
  error: 'red',
};

export const StickyMessageBannerContainer = styled.div<{}>`
  position: sticky;
  top: 0;
  z-index: 100;
  text-align: center;
  font-size: ${p => p.theme.spacing(1.75)}px;
  width: inherit;

  .FlexColumnCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .FlexRowCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const StickyMessageBannerItem = styled.div<{
  messageType: MessageType;
}>`
  display: flex;
  flex-direction: row;
  background-color: ${p => p.theme.palette[messageTypeToBackgroundColor[p.messageType] ?? messageTypeToBackgroundColor.info][300]};
  padding: ${p => p.theme.spacing(2)}px;
`;

export const StickyMessageTextSection = styled.div<{}>`
  flex: 95;
  color: black;
  a {
    color: ${p => p.theme.palette.blue[500]};
    font-weight: 500;
  }
`;

export const StickyMessageCloseButtonSection = styled.div<{}>`
  flex: 5;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: inherit;
`;

export const Icon = styled(ApolloIcon)`
  && {
    font-size: ${p => p.theme.spacing(2.5)}px;
  }

  &.MessageType {
    padding-right: ${p => p.theme.spacing(5)}px;
    font-size: ${p => p.theme.spacing(4)}px;
  }

  &.CloseButton {
    cursor: pointer;
  }
`;
