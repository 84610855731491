export enum Locale {
  en = 'en',
  ja = 'ja',
  de = 'de',
  fr = 'fr',
  es = 'es',
  'pt-BR' = 'pt-BR',
  pt = 'pt',
  ru = 'ru',
  tr = 'tr',
  'es-MX' = 'es-MX',
  ko = 'ko',
  'zh-CN' = 'zh-CN',
  'zh-TW' = 'zh-TW',
}
