// This should already be in the DB and the same for all environments
export const CS_DIGITAL_ONBOARDING_KB_SUBCATEGORY_ID = '66880b74ce7ce824ae6db520';

// Certain characters for category/subcategory names cause issues when passing as URL parameters
export const INVALID_CATEGORY_CHARACTERS_REGEX = /[?#%\\/]/;

export type VideoThumbnailSize = 'small' | 'medium' | 'large';

/**
 * Used to check if the embedded video is a Vimeo video by abiding to the Vimeo URL pattern
 *
 * Also used to extract the video ID and video hash from the URL
 */
export const EMBEDDED_VIDEO_VIMEO_MATCHER_REGEX = /https:\/\/vimeo\.com\/manage\/videos\/(\d+)\/(\w+)/;

export const EMBEDDED_VIDEO_SCREEN_ASPECT_RATIO = 16 / 9;
export const EMBEDDED_VIDEO_SIZE_TO_THUMBNAIL_HEIGHT: Record<VideoThumbnailSize, number> = {
  small: 75,
  medium: 100,
  large: 200,
};

export const EXTERNAL_URL_REGEX = /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
