import React from 'react';
import { useLocation } from 'react-router-dom';

import AuthUserUtil from '../../lib/auth.util';
import ClassicFooter from './ClassicFooter';

const Footer: React.FC = () => {
  const location = useLocation();

  // Don't render the footer for cloud users or on the support chat page
  if (AuthUserUtil.isCloudUser() || [ '/support/chat' ].includes(location.pathname)) {
    return <></>;
  }

  return (
    <ClassicFooter />
  );
};

export default Footer;
