import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  ${props => `
    margin-bottom: ${props.theme.spacing(1)}px;
  `};
`;
