import styled from 'styled-components';

// Pagination Assets
import arrowLongLeft from './../../../assets/img/svg/arrows/ArrowLong_Left_Black.svg';
import arrowLongLeftGray from './../../../assets/img/svg/arrows/ArrowLong_Left_GrayNeutral25.svg';
import arrowLongRight from './../../../assets/img/svg/arrows/ArrowLong_Right_Black.svg';
import arrowLongRightGray from './../../../assets/img/svg/arrows/ArrowLong_Right_GrayNeutral25.svg';
import arrowLongRightWhite from './../../../assets/img/svg/arrows/ArrowLong_Right_White.svg';

export const PaginationWrapper = styled.div`
  & {
    display: flex;

    padding-bottom: ${p => p.theme.spacing(5)}px;
    justify-content: space-between;
    align-items: center;

    ${p => p.theme.breakpoints.up('md')} {
      justify-content: flex-start;
      margin-left: ${p => p.theme.spacing(5)}px;
      max-width: auto;

      &.CustomerPortalPagination--noLeftMargin {
        margin-left: 0;
      }
    }

    #LeftButton,
    #RightButton {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7;
      color: #3a464f;
      background-color: white;
      width: ${p => p.theme.spacing(5)}px;
      height: ${p => p.theme.spacing(5)}px;
      vertical-align: middle;
      position: relative;
      border: none;
      border-radius: 2px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      outline: none;

      ${p => p.theme.breakpoints.up('md')} {
        width: ${p => p.theme.spacing(4)}px;
        height: ${p => p.theme.spacing(4)}px;
      }

      &:before {
        position: absolute;
        content: '';
        width: ${p => p.theme.spacing(2)}px;
        height: ${p => p.theme.spacing(2)}px;
      }

      &:hover:enabled {
        background-color: ${p => p.theme.palette.blue[500]};
      }
    }

    #LeftButton {
      margin-right: ${p => p.theme.spacing(2.5)}px;

      ${p => p.theme.breakpoints.up('md')} {
        margin-right: ${p => p.theme.spacing(2)}px;
      }

      &:before {
        background: url(${arrowLongLeft}) no-repeat center center / contain;
      }

      &:disabled {
        &:before {
          background-image: url(${arrowLongLeftGray});
          pointer-events: none;
        }
      }
    }

    #RightButton {
      margin-left: ${p => p.theme.spacing(2.5)}px;

      ${p => p.theme.breakpoints.up('md')} {
        margin-left: ${p => p.theme.spacing(2)}px;
      }

      &:before {
        background: url(${arrowLongRight}) no-repeat center center / contain;
      }

      &:hover {
        &:before {
          background: url(${arrowLongRightWhite}) no-repeat center center / contain;
        }
      }

      &:disabled {
        &:before {
          background-image: url(${arrowLongRightGray});
          pointer-events: none;
        }
      }
    }
  }

  .CustomerPortalPagination__NumberedButtonContainer {
    display: flex;
    align-items: center;

    .CustomerPortalPagination__Ellipses {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7;
      color: #3a464f;
      margin-right: ${p => p.theme.spacing(0.5)}px;
    }

    .CustomerPortalPagination__NumberedButton {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7;
      color: #3a464f;
      margin-right: ${p => p.theme.spacing(0.5)}px;
      width: ${p => p.theme.spacing(3)}px;
      height: ${p => p.theme.spacing(3)}px;
      background: inherit;
      border: none;
      border-radius: 2px;
      transition-duration: 0.4s;
      cursor: pointer;
      outline: none;

      ${p => p.theme.breakpoints.up('md')} {
        width: ${p => p.theme.spacing(4)}px;
        height: ${p => p.theme.spacing(4)}px;
      }

      &:hover {
        ${p => p.theme.breakpoints.up('md')} {
          background: ${p => p.theme.palette.blue[500]};
          color: white;
        }
      }



      ${'' /* Use more spacing for screens larger than medium */}
      ${p => p.theme.breakpoints.up('md')} {
        width: ${p => p.theme.spacing(5)}px;
        height: ${p => p.theme.spacing(5)}px;
      }

      &--Active {
        color: ${p => p.theme.palette.blue[500]};
      }
    }
  }
`;
