export enum FeatureFlags {
  CHATBOT_ENABLED = 'chatbot-enabled',
  ELEVATE_ESCALATION_ENABLED = 'elevate-escalation-enabled',
  QA_SFDC_EMAIL_DOMAIN_CHECK_DISABLED = 'qa-sfdc-email-domain-check-disabled',
  DOCSAI_SEARCH_ENABLED = 'docsai-search-enabled',
  KNOWN_ISSUES_ENABLED = 'known-issues-enabled',
  DOCSAI_REGENERATE_RESPONSE_ENABLED = 'docsai-regenerate-response-enabled',
  CLOUD_MIGRATION_ENABLED = 'cloud-migration-enabled',
  ENTITY_MANAGEMENT_ENABLED = 'entity-management-enabled',
}
