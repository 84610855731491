import PropTypes from 'prop-types';
import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';

import * as styles from '../../../assets/css/Company/GroupedLicense';
import { UNLIMITED_NUM } from '../../../constants/hapo.constants';
import CPLinearProgressBar from '../../CustomerPortal-ProgressBar';

/**
 * Grouped License shown on HAPO Dashboard
 */
const GroupedLicense = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  const isUnlimited = parseInt(props.maxAllowedQuantity) === UNLIMITED_NUM;
  const maxAllowedQuantity = isUnlimited
    ? t('company_hapo_dashboard_unlimited_quantity', 'Unlimited')
    : props.maxAllowedQuantity;
  const availableQuantity = isUnlimited
    ? t('company_hapo_dashboard_unlimited_quantity', 'Unlimited')
    : props.availableQuantity;

  // returns Individual licenses under given group
  const generateLicenseBundles = () => props.licenses.map((license: any) => (
    <div
      className="GroupedLicense__IndividualLicense"
      key={license.display_name}
    >
      <p className="GroupedLicense__Name">{license.display_name}</p>
      <div className="GroupedLicense__EmptyColum_1" />
      <p className="GroupedLicense__Stats">
        {license.consumed_quantity ?? 0}
      </p>
      <div className="GroupedLicense__EmptyColum_3" />
    </div>
  ));

  return (
    <styles.GroupedLicense data-testid="GroupedLicense">
      <div className="GroupedLicense__Header">
        <p className="GroupedLicense__Header_Title">{props.groupName}</p>
        <p className="GroupedLicense__Stats">{maxAllowedQuantity}</p>
        <p className="GroupedLicense__Stats">{props.consumedQuantity}</p>
        <p className="GroupedLicense__Stats">{availableQuantity}</p>
        <div className="GroupedLicense__Progress">
          {!isUnlimited && (
            <CPLinearProgressBar
              progress={props.consumedQuantity}
              total={props.maxAllowedQuantity}
            />
          )}
        </div>
      </div>
      <hr className="GroupedLicense__Header_Separator" />
      {generateLicenseBundles()}
    </styles.GroupedLicense>
  );
};

/**
 * Property Types for GroupedLicense component
 */
GroupedLicense.propType = {
  groupName: PropTypes.string.isRequired,
  licenses: PropTypes.arrayOf(PropTypes.any).isRequired,
  maxAllowedQuantity: PropTypes.number.isRequired,
  availableQuantity: PropTypes.number.isRequired,
  consumedQuantity: PropTypes.number.isRequired,
};

export default GroupedLicense;
