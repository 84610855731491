import Popover from '@mui/material/Popover';
import styled from 'styled-components';

import { transitionStandard } from '../../js_mixins/transitions';

export const DocumentAdminWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AdminOptionsMenu = styled.img`
  flex-basis: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 16px;
  height: 16px;
  padding-left: ${p => p.theme.spacing(2)}px;
  padding-right: ${p => p.theme.spacing(2)}px;
  cursor: pointer;
  ${transitionStandard('all')};
`;

export const DocInfoWrapper = styled.div`
  flex-basis: 100%;
  cursor: pointer;

  ${props => `
    ${props.theme.breakpoints.up('md')} {
      display: flex;
      justify-content: space-between;
    }
  `};

  .CustomerPortalKBDocument__KnowledgeBaseTitle {
    word-break: break-word;
  }

  &:hover {
    cursor: pointer;

    .CustomerPortalKBDocument__KnowledgeBaseTitle {
      text-decoration: underline;
    }

    .CustomerPortalDownloadIcon__ImageContainer {
      background: ${props =>
    props['data-documenttype'] === 'file'
      ? props.theme.palette.blue[500]
      : props['data-documenttype'] === 'video'
        ? props.theme.palette.red[500]
        : props['data-documenttype'] === 'link'
          ? props.theme.palette.green[500]
          : 'inherit'};
    }
  }
`;

export const DocumentInfo = styled.div`
  ${props => props.theme.breakpoints.up('md')} {
    width: 65.53%;
    margin-right: ${props => props.theme.spacing(3)}px;
  }
`;

export const SubCategoryText = styled.p`
  // TODO: Extend p-small when in theme
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.35;
  text-transform: uppercase;
  color: ${props => props.theme.palette.grey[500]};
  margin-bottom: ${props => props.theme.spacing(1)}px;
`;

export const KBTitle = styled.h3`
  // TODO: Replace when in theme
  font-size: 2rem;
  line-height: 1.2;
  color: ${props => props.theme.palette.blue[500]};
  margin-bottom: ${props => props.theme.spacing(2)}px;
`;

// Download Icon
export const DownloadIconWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 8px;

  ${props => `
    ${props.theme.breakpoints.up('md')} {
      justify-content: flex-end;
      flex-shrink: 0;
      align-self: center;
      flex-basis: 30.37%;
      max-width: 30.37%;
    }
  `};
`;

export const DownloadIconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 8px;
  margin-right: ${props => props.theme.spacing(1)}px;
  ${transitionStandard('all')};
`;

export const DocInfoContainer = styled.div`
  flex-basis: 62.92%;
  max-width: 62.92%;
  flex-grow: 0;
`;

export const DocExtension = styled.p`
  /* TODO: Replace when in design system */
  color: #353c4a;
`;

export const DocFileSize = styled.p`
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  /* TODO: Replace when in design system */
  color: #353c4a;
`;

export const AdminPopover = styled(Popover)`
  position: relative;
  .MuiPopover-paper {
    min-width: 160px;
    border-radius: ${p => p.theme.spacing(1)}px;
    margin-top: 12px;
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
  }
`;

export const AdminPopoverOption = styled.p`
  padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.grey[50]}};
  }
`;

export const DeleteFormWrapper = styled.form`
  margin-top: ${p => p.theme.spacing(2)}px;
`;
