import Container from '@mui/material/Container';
import React from 'react';

type NewContainerProps = {
  cssClass?: string;
  children?: any;
  className?: string;
  dataTestId?: string;
};

/* This is a pure react component */
const CustomerPortalNewContainer = (props: NewContainerProps) => {
  const cssClass = props.cssClass ? ` ${props.cssClass}` : '';
  const dataTestId = props.dataTestId ?? '';

  return (
    <Container
      className={`CustomerPortalNewContainer${cssClass} ${props.className}`}
      data-testid={dataTestId}>
      {props.children}
    </Container>
  );
};

export default CustomerPortalNewContainer;
