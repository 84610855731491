import { useContext } from 'react';

import { StoreContext } from '../store';

const RevealState = () => {
  const { state } = useContext(StoreContext);
  if (state) {
    return state;
  }
  return null;

};

export default RevealState;
