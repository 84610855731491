import styled from 'styled-components';

export default styled.h1`
  font-size: 3.2rem;
  line-height: 1.25;
  text-align: center;
  padding: 0 24px;
  ${(props) => `
    margin-bottom: ${props.theme.spacing(3)}px;
    ${props.theme.breakpoints.up('md')} {
      font-size: 5.6rem;
      line-height: 1.15;
    }
  `};
`;
