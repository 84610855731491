import { MenuItem } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Style
import {
  Card,
  ProductVersionCardMenu,
  VersionsView,
} from '../../assets/css/ProductDownloads/ProductDownloads-Card';
// Assets
import DownArrow from '../../assets/img/svg/arrows/Arrow_Down_24.svg';
import UpArrow from '../../assets/img/svg/arrows/Arrow_Up_24.svg';
import Download from '../../assets/img/svg/download/download.svg';
import { PRODUCT_DOWNLOAD_CLICK } from '../../constants/telemetry.constants';
import type { IDataObject } from '../../interfaces/dataObject.interface';
import { useTriggerTrackEventWithStateData } from '../../lib/AppInsights/AppInsights';

const downloadTypes = [
  {
    type: '64bit',
    keyText: '64-bit_version',
    fallbackText: '64-bit version',
    testId: '64bitDownloadMenuItem',
  },
  {
    type: '32bit',
    keyText: '32-bit_version',
    fallbackText: '32-bit version',
    testId: '32bitDownloadMenuItem',
  },
];

type ProductDownloadsSectionCardProps = {
  product: any;
};

const ProductDownloadsSectionCard = ({ product }: ProductDownloadsSectionCardProps) => {
  function hasProductVersions() {
    return product.versionsCollection.items?.length > 0 ?? false;
  }

  const [ isOpen, setIsOpen ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState<any>(null);
  const [ downloadVersion, setdownloadVersion ] = useState<any>(null);
  const columnTitles = [
    {
      id: 'version',
      keyText: 'version',
      fallbackText: 'Version',
      canShow: true,
    },
    {
      id: 'release-date',
      keyText: 'release_date',
      fallbackText: 'Release Date',
      canShow: true,
    },
    {
      id: 'windows',
      keyText: 'windows',
      fallbackText: 'Windows',
      canShow: true,
    },
    {
      id: 'docs',
      keyText: 'download_product_docs',
      fallbackText: 'Docs',
      canShow: !product.versionsCollection.items.filter(
        (version: any) => !version?.releaseNotesLink
      ).length,
    },
    {
      id: 'download-links',
      keyText: '',
      fallbackText: '',
      canShow: true,
    },
  ];

  const { t } = useTranslation('common');
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  const downloadFileFromLink = async (url: string) => {
    const fileName = url.split('/').pop();
    // Certain file types are not downloaded directly by browsers
    if (fileName?.endsWith('.xml')) {
      window.alert(
        t('product_download_new_tab',
          'This file will now open in a new tab. To download, right click and save the page.')
      );
      window.open(url, '_blank');
    } else {
      // Perform default download if cannot parse filename or not ending with .xml
      window.open(url, '_self');
    }
  };

  const trackDownload = (productVersion: any) => {
    triggerTrackEventWithStateData(PRODUCT_DOWNLOAD_CLICK, {
      ProductName: product.productName,
      VersionNumber: productVersion.versionNumber,
    });
  };

  const handleToggle = (e: any) => {
    setIsOpen(!isOpen);
  };

  // Either download the version or Open a dropdown for download links if there are multiple links
  const handleDownloadButtonClick = (event: any) => {
    const selectedVersion =
      product.versionsCollection.items[event.currentTarget.dataset.position];

    if (
      selectedVersion.downloadLink32Bit &&
      selectedVersion.downloadLink64Bit
    ) {
      if (event.target.dataset.parent === 'download') {
        setAnchorEl(event.currentTarget);
      }
      setdownloadVersion(selectedVersion);
    } else {
      downloadFileFromLink(selectedVersion.downloadLink64Bit);
      trackDownload(selectedVersion);
    }
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
    setdownloadVersion(null);
  };

  const handleDownload = (type: string) => {
    switch (type) {
      case '32bit':
        downloadFileFromLink(downloadVersion.downloadLink32Bit);
        break;
      case '64bit':
      default:
        downloadFileFromLink(downloadVersion.downloadLink64Bit);
        break;
    }
    trackDownload(downloadVersion);
    handleDownloadClose();
  };

  const versionCompatibility = (version: any) => {
    let compatibility = '';
    if (version.downloadLink64Bit) {
      compatibility = '64-bit';
    }
    if (version.downloadLink32Bit) {
      compatibility = compatibility ? compatibility + ', 32-bit' : '32-bit';
    }

    return compatibility;
  };

  const getLatestVersion = () => {
    const latestVerison = product.versionsCollection.items.filter(
      (version: any) => version?.latestVersion
    )[0];
    if (latestVerison) {
      return <p>
Latest:
        {latestVerison.versionNumber}
      </p>;
    }
  };

  const getVersionsList = (versions: IDataObject) => (
    <VersionsView>
      <div className="Divider" />
      <div className="VersionsHeader">
        {columnTitles
          .filter((title: any) => title.canShow)
          .map((title: any) => (
            <div key={title.id}>
              <p>{t(title.keyText, title.fallbackText)}</p>
            </div>
          ))}
      </div>
      {versions.map((version: IDataObject, index: number) => (
        <div
          className="ProductVersionView"
          key={version.versionNumber}
          data-testid="ProductVersionView"
        >
          <div className="ProductVersionView--TitleView">
            <p>
              {t('product_version', `Version: {{version}}`, { version: version.versionNumber })}
            </p>
            {version.latestVersion && (
              <p className="LatestTag">{t('latest', 'Latest')}</p>
            )}
          </div>
          <p>
            {version.releaseDate
              ? moment(version.releaseDate).format('LL')
              : ''}
          </p>
          <p>{versionCompatibility(version)}</p>
          <a
            href={version.releaseNotesLink}
            target="_blank"
            rel="noreferrer">
            {version.releaseNotesLink
              ? t('release_notes', 'Release Notes')
              : ''}
          </a>
          <div className="ProductVersionView--DownloadView">
            <img
              src={Download}
              alt="Menu Opener"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleDownloadButtonClick}
              data-parent="download"
              data-position={index}
              className="DownloadMenuOpener"
              data-testid="downloadButton"
            />
          </div>
        </div>
      ))}
    </VersionsView>
  );

  return (
    <>
      <ProductVersionCardMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleDownloadClose}
        onClick={handleDownloadClose}
        className="Select__Outer"
        elevation={0}
        data-testid="ProductDownloadCardMenu"
      >
        {downloadTypes.map((downloadType: any) => (
          <MenuItem
            onClick={() => {
              handleDownload(downloadType.type);
            }}
            className="Menu-Option Select__Option"
            data-testid={downloadType.testId}
            key={downloadType.type}
          >
            {t(downloadType.keyText, downloadType.fallbackText)}
          </MenuItem>
        ))}
      </ProductVersionCardMenu>

      <Card>
        <div
          className="ProductView"
          data-testid="ProductView"
          onClick={handleToggle}
        >
          <div className="ProductDetailsView">
            <h6>{product.productName}</h6>
            {hasProductVersions() && getLatestVersion()}
          </div>
          {hasProductVersions() && (
            <img
              className="ProductView-Column--Opener-Icon"
              src={isOpen ? UpArrow : DownArrow}
              alt="Down Arrow"
            />
          )}
        </div>
        {isOpen && (
          <div className="VersionList">
            {hasProductVersions() &&
              getVersionsList(product.versionsCollection.items)}
          </div>
        )}
      </Card>
    </>
  );
};

export default ProductDownloadsSectionCard;
