import {
  Button,
  Popover,
} from '@mui/material';
import styled from 'styled-components';

export const MainButton = styled(Button)`
  && {
    background-color: white;
    color: ${p => p.theme.palette.blue[500]};
    border: 1px solid ${p => p.theme.palette.ink[300]};
    cursor: pointer;
    border-radius: ${p => p.theme.spacing(1)}px;
    font-weight: 600;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    height: 40px;
    width: 170px;

    &.MuiButton-text.MuiButton-text:hover {
      background-color: white;
      cursor: pointer;
    }

    .icon {
      margin-left: ${p => p.theme.spacing(0.5)}px;
    }
  }
`;

export const Dropdown = styled(Popover)`
  text-align: center;
  margin-top: ${p => p.theme.spacing(0.25)}px;
  font-size: 1.4rem;
`;

export const DropdownOption = styled.div`
  justify-content: flex-start;
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 8px 16px;
  position: relative;
  z-index: 99;
  width: 140px;

  .container {
    justify-content: center;

    .icon {
      margin-right: ${p => p.theme.spacing(0.5)}px;
    }
  }

  &:hover {
    background-color: ${p => p.theme.palette.grey[50]};
    color: ${p => p.theme.palette.blue[500]};
  }

  &.disabled {
    background-color: ${p => p.theme.palette.grey[300]};
    color:  ${p => p.theme.palette.grey[700]};
    cursor: not-allowed;
  }
`;
