// Layout and Components
import Popover from '@mui/material/Popover';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// Translations
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import styled from 'styled-components';

import NoUserIcon from '../../assets/img/No_User_Profile.svg';
import ZipIcon from '../../assets/img/svg/file_icons/Box_Black.svg';
import FileIcon from '../../assets/img/svg/file_icons/File_Text.svg';
// Images Icons
import FolderIcon from '../../assets/img/svg/file_icons/Folder_Black.svg';
import VideoIcon from '../../assets/img/svg/file_icons/Play_Icon.svg';
// Constants
import { USER_PICTURE_BY_AVATARID_URL } from '../../constants/network.constants';
// Utils
import { MimeTypeToExtension } from '../../lib/file.utils';
import { getStringEnv } from '../../lib/index.util';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import ApolloIcon from '../ApolloIcon';

const SelectOption = styled.div`
  .ExpandOptions__Button {
    padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
    cursor: pointer;
    outline: none;
    border: 0;
    background-color: inherit;
    font-size: 1.4rem;
    font-weight: 400 !important;
    line-height: 1.7;
    width: 100%;
    text-align: left;

    &:hover {
      background: ${p => p.theme.palette.grey[100]};
      color: ${p => p.theme.palette.blue[500]};
    }
  }
`;

const CustomCollabDoc = styled.div`
  .CustomerPortalCollabDocOptions {
    margin-right: 16px !important;
  }

  .icon {
    font-size: 24px;
  }
`;

const OptionsPopover = styled(Popover)`
  cursor: pointer;
`;

type CustomerPortalCollabDocProps = {
  mimeType: string;
  fileId: string;
  handleFileClick: Function;
  handleDeleteDocClick: (fileId: string, fileName: string, createdByUiPath: boolean) => void;
  isFolder: boolean;
  fileName: string;
  authorAvatar?: string;
  authorName: string;
  dateString: string;
  slug: string;
  createdByUiPath: boolean;
  [key: string]: any;
};

// Function to create a child component of the table of document items
const CustomerPortalCollabDoc = ({
  mimeType,
  fileId,
  handleFileClick,
  handleDeleteDocClick,
  isFolder,
  fileName = 'File Name',
  authorAvatar = NoUserIcon,
  authorName = 'Author Name',
  dateString,
  slug,
  createdByUiPath,
}: CustomerPortalCollabDocProps) => {
  // Translate method
  const { t } = useTranslation('common');

  let fileIconSrc = isFolder
    ? FolderIcon
    : mimeType === 'application/zip'
      ? ZipIcon
      : undefined;

  if (!fileIconSrc) {
    const fileProperties = MimeTypeToExtension.get(mimeType);
    if (fileProperties) {
      fileIconSrc =
        fileProperties.internalFileType === 'video' ? VideoIcon : FileIcon;
    }
  }

  /* States */
  const [ popoverAnchorEl, setPopoverAnchorEl ] = useState(null);

  const allowDeleteMove = UserPermissionsHelper.isDeleteCollabAllowed(
    createdByUiPath
  );

  const copyTextHyperlink = isFolder
    ? `${getStringEnv(process.env.PUBLIC_URL)}/collab-space/folders/${fileId}/${slug}`
    : `${getStringEnv(process.env.PUBLIC_URL)}/collab-space/assets/${fileId}/${slug}`;

  /* Event Handler */
  const handleFileClickLocal = () => {
    handleFileClick(isFolder, fileId, slug);
  };

  const handleDocOptionsClose = () => {
    setPopoverAnchorEl(null);
  };

  const handleDeleteDocClickLocal = (e: any) => {
    handleDeleteDocClick(fileId, fileName, createdByUiPath);
    handleDocOptionsClose();
  };

  const handleTextCopied = () => {
    handleDocOptionsClose();
  };

  return (
    <CustomCollabDoc>
      <div
        className={`CustomerPortalCollabDoc${
          isFolder ? ' CustomerPortalCollabDoc--Folder' : ''
        }`}
      >
        <div
          className="CustomerPortalCollabDocFile"
          onClick={handleFileClickLocal}
        >
          <div className="CustomerPortalCollabDoc__Icon-Container">
            <img
              className="CustomerPortalCollabDoc__Icon"
              src={fileIconSrc}
              alt="Document Icon"
            />
          </div>
          <span className="CustomerPortalCollabDoc__Name">{fileName}</span>
        </div>
        <div className="CustomerPortalCollabDocAuthor">
          {authorName === 'UiPath' ? (
            <div className="CustomerPortalCollabDocAuthor__Avatar CustomerPortalCollabDocAuthor__Avatar--UiPath">
              <i />
            </div>
          ) : (
            <img
              className="CustomerPortalCollabDocAuthor__Avatar"
              src={
                authorAvatar === NoUserIcon
                  ? NoUserIcon
                  : `${USER_PICTURE_BY_AVATARID_URL}/${authorAvatar}`
              }
              alt="Avatar Icon"
            />
          )}
          <span className="CustomerPortalCollabDocAuthor__Name">
            {authorName}
          </span>
        </div>
        <Moment
          format="MMM DD, YYYY"
          className="CustomerPortalCollabDocDate">
          {dateString}
        </Moment>
        <button
          className="CustomerPortalCollabDocOptions"
          data-testId="docOptionButton"
          onClick={(e: any) => {
            if (popoverAnchorEl !== e.currentTarget) {
              setPopoverAnchorEl(e.currentTarget);
            }
          }}
        >
          <ApolloIcon
            icon='more_horiz'
            fontSize='inherit'
            className='icon'
          />
          <OptionsPopover
            id={`Doc_${fileId}_OptionsPopover`}
            open={Boolean(popoverAnchorEl)}
            classes={{ paper: 'CustomerPortalCollabDocExpandedOptions' }}
            anchorEl={popoverAnchorEl}
            onClose={handleDocOptionsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            disableRestoreFocus
          >
            <SelectOption className="CustomerPortalCollabDocExpandedOptions__Option">
              <CopyToClipboard
                text={copyTextHyperlink}
                onCopy={handleTextCopied}
              >
                <button className="ExpandOptions__Button">
                  {t('collaboration_space_copy_link_cta', 'Copy Link')}
                </button>
              </CopyToClipboard>
            </SelectOption>
            {allowDeleteMove && (
              <SelectOption
                className="CustomerPortalCollabDocExpandedOptions__Option"
                onClick={handleDeleteDocClickLocal}
              >
                <button className="ExpandOptions__Button">
                  {t('collaboration_space_delete_file_cta', 'Delete')}
                </button>
              </SelectOption>
            )}
          </OptionsPopover>
        </button>
      </div>
    </CustomCollabDoc>
  );
};

export default CustomerPortalCollabDoc;
