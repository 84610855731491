import Switch from '@mui/material/Switch';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// style
import * as styled from '../assets/css/CustomerPortalNotificationsModal';
// Client
import { axiosPost } from '../client/axios';
import { BACKEND_HOST_NAME } from '../constants/network.constants';
import { useAuth } from '../contexts/auth';
// store
import { StoreContext } from '../store';
import Button from './Button/Button';
// components
import CustomerPortalLoader from './CustomerPortal-Loader';
import Modal from './Modal';

type NotificationModalProps = {
  onClose: () => void;
  onError: () => void;
  onSuccess?: () => void;
  open: boolean;
};

const NotificationsModal = (props: NotificationModalProps) => {
  // Translate method
  const { t } = useTranslation('common');

  /* Lifecycle */
  useEffect(() => {
    getNoficationSettings();
  }, [ props.open ]);

  const baseClass = 'CustomerPortalMyCompanyBody';
  const [ checked, setChecked ] = useState(false);
  const [ prevChecked, setPrevChecked ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChecked(event.target.checked);
  };

  const getNoficationSettings = async () => {
    if (props.open) {
      setLoading(true);

      try {
        const jsonResults = await axiosPost(
          `${BACKEND_HOST_NAME}/api/v1/user/preferences/getNotificationSettings`,
          state.companyId,
          await getAccessToken()
        );

        setLoading(false);
        setPrevChecked(jsonResults.data?.weeklyNotification ?? false);
        setChecked(jsonResults.data?.weeklyNotification ?? false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        props.onError();
      }
    }
  };

  const handleClose = async () => {
    if (prevChecked !== checked) {
      // only save if there was actually a change
      const body: any = { checked };

      setLoading(true);
      try {
        await axiosPost(
          `${BACKEND_HOST_NAME}/api/v1/user/preferences/updateNotificationSettings`,
          state.companyId,
          await getAccessToken(),
          body
        );

        setLoading(false);
        if (props.onSuccess) {
          props.onSuccess();
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        props.onError();
      }
    }
    props.onClose();
  };

  return (
    <Modal
      modalTitle="Standard Modal"
      modalDescription="Add User Modal"
      modalHeading={t(
        'notifications_page_notifications_settings_modal_title',
        'Edit your notification settings'
      )}
      innerClasses={`${baseClass}__Add-User-Modal`}
      handleClose={handleClose}
      open={props.open}
      data-testid="NotificationSettingsModal"
    >
      {loading && (
        <div
          className="Modal-Fields-Container"
          data-testid="NotificationSettingsModal__loader"
        >
          <CustomerPortalLoader />
        </div>
      )}

      {!loading && (
        <div className="Modal-Fields-Container">
          <div className="row" />
          <styled.notificationsRow>
            <styled.notificationsColumnLeft>
              <Switch
                checked={checked}
                onChange={handleChange('switchA')}
                value="switchA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                data-testid="NotificationSettingsModal__switch"
              />
            </styled.notificationsColumnLeft>
            <styled.notificationsColumnRight>
              <styled.consentTitle>
                {t(
                  'notifications_page_notifications_settings_modal_desc_title',
                  'Request to receive notifications on email'
                )}
              </styled.consentTitle>
              <styled.consentDescription>
                {t(
                  'notifications_page_notifications_settings_modal_desc',
                  'By activating this, I will receive a weekly email containing all updates related to new users joining my Customer Portal team, new shared resources in my collaboration space, and new resources available in Knowledge Base.'
                )}
              </styled.consentDescription>
            </styled.notificationsColumnRight>
          </styled.notificationsRow>

          <Button
            text={t(
              'notifications_page_notifications_settings_modal_submit',
              'Done'
            )}
            onClick={handleClose}
          />
        </div>
      )}
    </Modal>
  );
};

export default NotificationsModal;
