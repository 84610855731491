import styled from 'styled-components';

export default styled.button`
  font-family: inherit !important;
  line-height: 1.15 !important;
  outline: none;
  border: none;
  text-transform: uppercase !important;
  text-align: center;
  font-size: 10px !important;
  font-weight: bold !important;
  letter-spacing: 0.5px;
  ${props => `
    background: ${props.theme.palette.blue[100]};
    color: ${props.theme.palette.blue[500]};
    padding: ${props.theme.spacing(0.5)}px ${props.theme.spacing(1)}px;
    margin-right: ${props.theme.spacing(1)}px;
  `};
  border-radius: 2px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:nth-child(n+5) {
    display: none;
  }

  ${p => p.theme.breakpoints.up('md')} {
    &:nth-child(n+5) {
      display: block;
    }
  }
`;
