import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { _500 } from '../../constants/telemetry.constants';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';

const CustomerPortalPage500 = (props: any) => {
  const { t } = useTranslation('common');

  useTrackPageViewEvent(_500);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('500');
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('500_page_title', 'Internal Server Error | Customer Portal')}</title>
      </Helmet>

      <ErrorPage
        errorType='503'
        header={t('page_500_hero_text', 'Sorry, our bad :-(')}
        description={t(
          'page_500_description',
          'We\'re working on getting this problem fixed. Be back soon.'
        )}
      >
        <Link
          data-testid='ErrorPage-500__CTA'
          className='link'
          to='/'
        >
          {t('page_500_cta', 'Go to homepage')}
        </Link>
      </ErrorPage>
    </>
  );
};

export default CustomerPortalPage500;
