import type { ReactNode } from 'react';
import React from 'react';

import AuthUserUtil from '../../lib/auth.util';
import Auth0AuthGuard from './auth0';
import IdentityAuthGuard from './identity';

interface Props {
  children?: ReactNode;
}

const AuthGuard = ({ children }: Props) => {
  if (AuthUserUtil.isCloudUser()) {
    return (
      <IdentityAuthGuard>
        {children}
      </IdentityAuthGuard>
    );
  }

  return (
    <Auth0AuthGuard>
      {children}
    </Auth0AuthGuard>
  );
};

export default AuthGuard;
