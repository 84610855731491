import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { _401_RESTRICTED_SUPPORT } from '../../constants/telemetry.constants';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';

const RestrictedSupport = (props: any) => {
  const { t } = useTranslation('common');

  useTrackPageViewEvent(_401_RESTRICTED_SUPPORT);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('401');
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('401_restricted_support_page_title', 'Access Restricted | Customer Portal')}</title>
      </Helmet>

      <ErrorPage
        errorType='401'
        header={t('support_restricted_header', 'Access Restricted')}
        description={t('support_restricted_description', 'Unfortunately, access to support-related information is currently restricted to U.S. residents only for this account.')}>
        <div>
          <h6>{t('support_restricted_header_two', `Believe there's been a mistake?`)}</h6>
          <p>{t('support_restricted_description_two', `If you think this restriction is an error, please request access to "U.S. Only Support" through the #help-salesforce channel.`)}</p>
          <br />
          <h6>{t('support_restricted_header_three', 'Recently granted access?')}</h6>
          <p>
            {t('support_restricted_description_three', 'If you were recently provided access, please note it may take up to 24 hours for these changes to be reflected in the Customer Portal.')}
          </p>
          <br />
        </div>
        <Link
          data-testid='ErrorPage-401__CTA'
          className='link'
          to='/'
        >
          {t('page_401_cta', 'Go to homepage')}
        </Link>
      </ErrorPage>
    </>
  );
};

export default RestrictedSupport;
