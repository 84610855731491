/*
 **********************
 * Dashboard Chart Colors
 **********************
 */

export const statusColors = [
  {
    'name': 'New',
    'value': '#FF266D',
  },
  {
    'name': 'Closed',
    'value': '#70ADE9',
  },
  {
    'name': 'In process',
    'value': '#1665B3',
  },
  {
    'name': 'Customer Action',
    'value': '#FFBB27',
  },
  {
    'name': 'Not Specified',
    'value': '#1C637A',
  },
];

export const productColors = [
  {
    'name': 'Action Center',
    'value': '#061C31',
  },
  {
    'name': 'AI Capabilities',
    'value': '#0C3761',
  },
  {
    'name': 'Attended browser embedding SDK',
    'value': '#11508D',
  },
  {
    'name': 'Document Understanding',
    'value': '#A244A1',
  },
  {
    'name': 'Orchestrator',
    'value': '#FF266D',
  },
  {
    'name': 'Orchestrator Mobile',
    'value': '#FFBB27',
  },
  {
    'name': 'Partner Product',
    'value': '#70E795',
  },
  {
    'name': 'Process Mining',
    'value': '#1665B3',
  },
  {
    'name': 'Robot',
    'value': '#1976D2',
  },
  {
    'name': 'Studio',
    'value': '#E9F1FA',
  },
  {
    'name': 'Studio X',
    'value': '#358ADF',
  },
  {
    'name': 'Task Capture',
    'value': '#70ADE9',
  },
  {
    'name': 'Task Mining',
    'value': '#AFE8FA',
  },
  {
    'name': 'Test Manager',
    'value': '#73D6F7',
  },
  {
    'name': 'UiPath Assistant',
    'value': '#44CFFC',
  },
  {
    'name': 'UiPath Connect',
    'value': '#38C6F4',
  },
  {
    'name': 'UiPath Insights',
    'value': '#35BBE7',
  },
  {
    'name': 'UiPath Test Suite',
    'value': '#278AAB',
  },
  {
    'name': 'Upgrades and License Migration',
    'value': '#0D303C',
  },
  {
    'name': 'Not Specified',
    'value': '#1C637A',
  },
];

export const priorityColors = [
  {
    'name': 'urgent',
    'value': '#FF266D',
  },
  {
    'name': 'high',
    'value': '#1665B3',
  },
  {
    'name': 'medium',
    'value': '#FFBB27',
  },
  {
    'name': 'low',
    'value': '#70ADE9',
  },
  {
    'name': 'Not Specified',
    'value': '#1C637A',
  },
];

/*
 **********************
 * Test Data
 * TODO: Move it out to a test file when we start writing tests
 **********************
 */
export const lastMonthStatusData = [
  {
    name: 'New',
    allTypes: [ 'New', 'Closed' ],
    types: [ 'New' ],
    'New': 1,
  },
  {
    name: 'Closed',
    allTypes: [ 'Closed' ],
    types: [ 'Closed' ],
    'Closed': 11,
  },
];

export const lastMonthProductsData = [
  {
    name: 'Orchestrator',
    allTypes: [ 'Orchestrator', 'partner product', 'Studio', 'Attended browser embedding SDK' ],
    types: [ 'Orchestrator' ],
    'Orchestrator': 5,
  },
  {
    name: 'partner product',
    allTypes: [ 'Orchestrator', 'partner product', 'Studio', 'Attended browser embedding SDK' ],
    types: [ 'partner product' ],
    'partner product': 12,
  },
  {
    name: 'Studio',
    allTypes: [ 'Orchestrator', 'partner product', 'Studio', 'Attended browser embedding SDK' ],
    types: [ 'Studio' ],
    'Studio': 18,
  },
  {
    name: 'Attended browser embedding SDK',
    allTypes: [ 'Orchestrator', 'partner product', 'Studio', 'Attended browser embedding SDK' ],
    types: [ 'Attended browser embedding SDK' ],
    'Attended browser embedding SDK': 21,
  },
];

export const lastMonthPrioritiesData = [
  {
    name: 'High',
    allTypes: [ 'High', 'Medium', 'Low' ],
    types: [ 'High' ],
    'High': 3,
  },
  {
    name: 'Medium',
    allTypes: [ 'High', 'Medium', 'Low' ],
    types: [ 'Medium' ],
    'Medium': 12,
  },
  {
    name: 'Low',
    allTypes: [ 'High', 'Medium', 'Low' ],
    types: [ 'Low' ],
    'Low': 11,
  },
];

export const lastThreeMonthStatusData = [
  {
    name: 'May 2020',
    allTypes: [ 'New', 'Closed' ],
    types: [ 'New', 'Closed' ],
    'New': 3,
    'Closed': 10,
  },
  {
    name: 'April 2020',
    allTypes: [ 'New', 'Closed' ],
    types: [ 'New', 'Closed' ],
    'New': 6,
    'Closed': 8,
  },
  {
    name: 'March 2020',
    allTypes: [ 'New', 'Closed' ],
    types: [ 'New', 'Closed' ],
    'New': 12,
    'Closed': 5,
  },
];

export const lastThreeMonthProductsData = [
  {
    name: 'May 2020',
    allTypes: [ 'Orchestrator', 'partner product', 'Studio', 'Attended browser embedding SDK' ],
    types: [ 'Orchestrator', 'partner product', 'Studio' ],
    'Orchestrator': 5,
    'partner product': 12,
    'Studio': 18,
  },
  {
    name: 'April 2020',
    allTypes: [ 'Orchestrator', 'partner product', 'Studio', 'Attended browser embedding SDK' ],
    types: [ 'Orchestrator', 'Studio' ],
    'Orchestrator': 5,
    'Studio': 12,
  },
  {
    name: 'March 2020',
    allTypes: [ 'Orchestrator', 'partner product', 'Studio', 'Attended browser embedding SDK' ],
    types: [ 'partner product', 'Studio', 'Attended browser embedding SDK' ],
    'partner product': 5,
    'Studio': 5,
    'Attended browser embedding SDK': 7,
  },
];

export const lastThreeMonthPrioritiesData = [
  {
    name: 'May 2020',
    allTypes: [ 'High', 'Medium', 'Low' ],
    types: [ 'High', 'Medium', 'Low' ],
    'High': 3,
    'Medium': 12,
    'Low': 11,
  },
  {
    name: 'April 2020',
    allTypes: [ 'High', 'Medium', 'Low' ],
    types: [ 'High', 'Medium', 'Low' ],
    'High': 1,
    'Medium': 2,
    'Low': 4,
  },
  {
    name: 'March 2020',
    allTypes: [ 'High', 'Medium', 'Low' ],
    types: [ 'High', 'Medium', 'Low' ],
    'High': 5,
    'Medium': 2,
    'Low': 7,
  },
];
