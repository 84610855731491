import styled from 'styled-components';

export const Container = styled.section`
    position: relative;
    background-color: white;
    width: 100%;
    height: 100vh;
`;

export const Logo = styled.div`
  svg {
    margin: ${p => p.theme.spacing(5)}px 0;
  }
`;

export const LogoutPromptLink = styled.div`
  p {
    font-size: 1.2rem;
    line-height: 20px;
  }

  span {
    color: ${p => p.theme.palette.blue[500]};
    cursor: pointer;
  }
`;

export const EmailIcon = styled.div`
  svg {
    display: block;
    margin: 0 auto;
  }
`;

export const Spinner = styled.div`
  margin: ${p => p.theme.spacing(5)}px 0;

  svg {
    display: block;
    margin: 0 auto;
    width: ${p => p.theme.spacing(12.5)}px;
    height: ${p => p.theme.spacing(12.5)}px;
  }
`;

export const Body = styled.div`
  margin-top: ${p => p.theme.spacing(8)}px;

  h1 {
    padding-bottom: ${p => p.theme.spacing(3)}px;
    font-size: 2.4rem;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    color: ${p => p.theme.palette.ink[550]};
  }

  p {
    font-size: 1.4rem;
    line-height: 20px;
    text-align: center;
    color: ${p => p.theme.palette.ink[550]};

    span {
      font-weight: 700;
    }
  }

  .CustomerPortalNotVerifiedPage__ctaContainer {
    margin: ${p => p.theme.spacing(3)}px 0;

    button {
      display: block;
      margin: 0 auto;
      padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(2)}px;
      min-width: ${p => p.theme.spacing(8)}px;
      font-size: 1.4rem;
      line-height: 20px;
      font-weight: 600;
      cursor: pointer;
      color: ${p => p.theme.palette.blue[500]};
      background-color: white;
      text-decoration: none;
      user-select: none;
      box-shadow: none;
      border: 2px solid ${p => p.theme.palette.blue[100]}
      border-radius: ${p => p.theme.spacing(0.5)}px;
      outline: 0;

      &:hover {
        background-color: ${p => p.theme.palette.blue[100]};
      }
    }
  }
`;
