import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Constants
import type RFQ from '../../../constants/requestForQuote.constants';
import type { GlobalStoreState } from '../../../interfaces/contextStore';
// Utils
import { getRelevantSkusFromProductType } from '../../../lib/requestForQuote.utils';
// Global state
import { StoreContext } from './../../../store';
import RequestForQuoteLicenseCard from './CustomerPortal-RequestForQuote-LicenseCard';

interface IRequestForQuoteLicenses {
  searchText: string;
  productCategory: typeof RFQ.onPremProductCategory[keyof typeof RFQ.onPremProductCategory];
  productTag: typeof RFQ.productTags[keyof typeof RFQ.productTags];
  cloudLicenses: any[];
  windowDimension: {
    width: number;
    height: number;
  };
  isAddToCartAllowed: boolean;
  requiresAutomationCloudBundle: boolean;
  requiresAutomationSuiteBundle: boolean;
  handleShowPopUp: (data: any, e: any) => void;
  selectedCategory: GlobalStoreState['rfqCart']['selectedCategory'];
  lastSelectedOrchestrator: GlobalStoreState['rfqCart']['lastSelectedOrchestrator'];
  cartProducts: GlobalStoreState['rfqCart']['products'] | GlobalStoreState['hapoProducts'];
  handleQuantityUpdate: (
    licenseId: string,
    newQuantity: number,
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
  ) => void;
  handleRemoveLicenseFromCart: (
    licenseId: string,
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
  ) => void;
  handleAddLicenseToCart: (
    license: {
      [key: string]: any;
      _id: string;
      sku_name: string;
      min_quantity: number;
      product_code: string;
    },
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
  ) => void;
}

const RequestForQuoteLicenses = (props: IRequestForQuoteLicenses) => {
  // Translate method
  const { t } = useTranslation('common');
  // Global state
  const { dispatch } = useContext(StoreContext);

  // Constants
  const productType = props.selectedCategory;
  const lastSelectedOrchestrator = props.lastSelectedOrchestrator;
  const foundOrch = props.cartProducts?.find(
    stateSku => stateSku._id === lastSelectedOrchestrator?._id
  );
  const requiresAutomationCloudBundle = props.requiresAutomationCloudBundle;
  const requiresAutomationSuiteBundle = props.requiresAutomationSuiteBundle;
  // Array of skus in state that we are interested in
  const relevantSkusInState = getRelevantSkusFromProductType(
    productType,
    props.cartProducts,
    foundOrch,
    requiresAutomationCloudBundle,
    requiresAutomationSuiteBundle
  );

  const searchText = props.searchText;
  const productCategory = props.productCategory;
  const productTag = props.productTag;
  const licenses = props.cloudLicenses || [];

  // User permissions
  const isAddToCartAllowed = props.isAddToCartAllowed;

  /**
   * Retrieves all the unique sku ids in cart so that they're highlighted
   * - If selected an orchestrator, only highlight last_selected_orchestrator's nested/2nd level products
   * - Else highlight whatever is on the 1st level
   */
  const getSelectedProducts = (): string[] => [ ...new Set(relevantSkusInState.map(sku => sku.sku_id!)) ];

  // State
  // To highlight products selected
  const [ selectedProduct, setSelectedProduct ] = useState<string[]>(
    getSelectedProducts()
  );

  useEffect(() => {
    setSelectedProduct(getSelectedProducts());
  }, [ props.selectedCategory, props.cartProducts, lastSelectedOrchestrator ]);

  // Methods
  const handleShowPopUp = (
    data: {
      _id: string;
      name: string;
      description: string;
      short_description: string;
      min_quantity: number;
      product_code: string;
    },
    e: any
  ) => {
    if (
      e.target.getAttribute('data-type') === 'showPopUp' ||
      e.currentTarget.getAttribute('data-type') === 'showPopUp'
    ) {
      // Update the quantity

      props.handleShowPopUp(
        {
          showPopUp: true,
          popUpContent: {
            ...data,
            quantity: getQuantityValue({ _id: data._id }, 0),
          },
        },
        e
      );
    }
  };

  /**
   * Retrieves all the quantities tied to relevant skus
   * - If selected an orchestrator, only get quantity last_selected_orchestrator's nested/2nd level products
   * - Else get quantity whatever is on the 1st level
   */
  const getQuantityValue = (license: any, defaultVal: number = 1) => (
    relevantSkusInState.find(sku => sku.sku_id === license._id)?.quantity ||
      defaultVal
  );

  return (
    <div
      className="RequestForQuote__BrowseProducts__Products"
      data-testid="RequestFotQuoteLicenses"
    >
      {licenses
        ?.filter((license: any) => (
          license.tag?.toLowerCase() ===
            productTag?.fallbackText.toLowerCase()
        ))
        .filter(
          (el: any) =>
            el.sku_name.toLowerCase().includes(searchText.toLowerCase()) ||
            el.long_description
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            el.tag.toLowerCase().includes(searchText.toLowerCase())
        ).length === 0 && (
        <p className="RequestForQuote__BrowseProducts__NotFound">
          {t(
            'rfq_licenses_component_no_results',
            `No {{productCategory}} licenses found!`,
            {
              productCategory: t(
                productCategory.keyText,
                productCategory.fallbackText
              ),
            }
          )}
        </p>
      )}

      {licenses
        .filter((license: any) => (
          license.tag?.toLowerCase() === productTag.fallbackText.toLowerCase()
        ))
        .filter(
          (el: any) =>
            el.sku_name.toLowerCase().includes(searchText.toLowerCase()) ||
            el.long_description
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            el.tag.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((license: any) => (
          <RequestForQuoteLicenseCard
            windowDimension={props.windowDimension}
            key={license._id}
            license={license}
            productCategory={productCategory}
            skuInCart={selectedProduct.includes(license._id)}
            productType={productType}
            lastSelectedOrchestrator={lastSelectedOrchestrator}
            isAddToCartAllowed={isAddToCartAllowed}
            dispatch={dispatch}
            handleShowPopUp={handleShowPopUp}
            getQuantityValue={getQuantityValue}
            handleQuantityUpdate={props.handleQuantityUpdate}
            handleRemoveLicenseFromCart={props.handleRemoveLicenseFromCart}
            handleAddLicenseToCart={props.handleAddLicenseToCart}
          />
        ))}
    </div>
  );
};

export default RequestForQuoteLicenses;
