import { Header } from '@customer-portal/constants';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Redirect,
  useParams,
} from 'react-router-dom';

// Constants
import { COLLAB_ASSETS_URL } from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
import downloadDocument from '../../lib/customerPortalDownload.utils';
// Utils
import { StoreContext } from '../../store/index';

type CollabDownloadProps = {
  data?: any;
  config?: any;
  [key: string]: any;
};

// This is the download component used when directed here from copy link
const CollabDownload = (props: CollabDownloadProps) => {
  const { assetId } = useParams<any>() as any;
  const { getAccessToken } = useAuth();
  // State
  const {
    state, dispatch: dispatchContext,
  } = useContext(StoreContext);
  const [ startedFetching, setStartedFetching ] = useState(false);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        await downloadDocument(
          `${COLLAB_ASSETS_URL}/${assetId}`,
          { company_id: state.companyId },
          {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            },
          }
        );
      } catch (e) {
        const errorMessage =
          e.response?.data?.data
            ? `Error downloading file: ${e.response.data.data}`
            : `Error downloading file: ${e.toString()}`;
        dispatchContext({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatchContext({
          type: 'setBannerMsg',
          payload: errorMessage,
        });
      }
    };

    fetchDocument();
    setStartedFetching(true);
  }, [ props.location.pathname ]);

  return (
    <>{startedFetching && <Redirect to="/" />}</>
  );
};

export default CollabDownload;
