import styled from 'styled-components';

export const CustomCharCount = styled.div`
  margin-left: auto;
  font-size: 12px;
  padding-top: 6px;
  color: ${p => p.theme.palette.grey[700]};
  
  &.maxChar {
    color: ${p => p.theme.palette.red[700]};
  }
`;
