import type { ReactNode } from 'react';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import { useAuth } from '../../contexts/auth';
import { StoreContext } from '../../store';
import { featureFlag } from '../../utils/featureFlag';
import AppLoader from '../AppLoader';
import BrandedLogoAndText from '../BrandedLogoAndText';
import CloudPromptBanner from '../CloudPrompt-Banner';
import CustomerPortalHeader from '../cp_header/CustomerPortal-Header';

const Container = styled.div`
  background-color: ${p => p.theme.palette.common.white};
  display: flex;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid ${p => p.theme.palette.grey[300]};
`;

const BrandedLogoAndTextStyled = styled(BrandedLogoAndText)`
  .BrandedLogoAndText__Container {
    flex: 1;
    white-space: nowrap;
    padding-left: 67px;

    cursor: pointer;
    .BrandedLogoAndText__Container-Img {
      height: 22px;
    }

    .BrandedLogoAndText__Container-Text {
      font-size: 16px;
    }
  }
`;

interface Props {
  children?: ReactNode;
}

const ClassicShell = ({ children }: Props) => {
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  // Show the loader until the `checkUser` action is dispatched and completed inside the children component for the first time.
  // If user was checked previously and being re-checked, show the children to prevent loading-screen when navigating between pages
  const showLoader = !state.finishedInitialUserCheck;
  const [ isCloudMigrationEnabled, setIsCloudMigrationEnabled ] = useState(false);

  const updateCloudMigrationEnabled = async () => {
    try {
      const { companyId } = state;

      const isCloudMigrationFeatureEnabled = await featureFlag.isCloudMigrationEnabled(
        companyId,
        await getAccessToken()
      );

      setIsCloudMigrationEnabled(isCloudMigrationFeatureEnabled);
    } catch (e) {
      setIsCloudMigrationEnabled(false);
    }
  };

  useEffect(() => {
    updateCloudMigrationEnabled();
  }, []);

  return (
    <>
      {showLoader && (<AppLoader />)}
      {isCloudMigrationEnabled && <CloudPromptBanner />}
      {/* Hide header when UI state requires it to be hidden */}
      <Container
        style={showLoader || state.ui?.header?.hidden ? { display: 'none' } : {}}
      >
        <BrandedLogoAndTextStyled useOnClick />
        <CustomerPortalHeader />
      </Container>
      {children}
    </>
  );
};

export default ClassicShell;
