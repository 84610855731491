export const RFQ_RESET_CART = 'RFQ_RESET_CART';
export const RFQ_CHANGE_CATEGORY = 'RFQ_CHANGE_CATEGORY';
export const RFQ_SELECT_EXISTING_ORCHESTRATOR_OPTION = 'RFQ_SELECT_EXISTING_ORCHESTRATOR_OPTION';
export const RFQ_CHANGE_ONPREM_SELECTION = 'RFQ_CHANGE_ONPREM_SELECTION';
export const RFQ_ADD_1ST_LEVEL_LICENSE = 'RFQ_ADD_1ST_LEVEL_LICENSEADD_1ST_LEVEL_LICENSE';
export const RFQ_ADD_2ND_LEVEL_LICENSE = 'RFQ_ADD_2ND_LEVEL_LICENSE';
export const RFQ_REMOVE_1ST_LEVEL_LICENSE = 'RFQ_REMOVE_1ST_LEVEL_LICENSE';
export const RFQ_REMOVE_2ND_LEVEL_LICENSE = 'RFQ_REMOVE_2ND_LEVEL_LICENSE';
export const RFQ_UPDATE_1ST_LEVEL_LICENSE_QUANTITY = 'RFQ_UPDATE_1ST_LEVEL_LICENSE';
export const RFQ_UPDATE_2ND_LEVEL_LICENSE_QUANTITY = 'RFQ_UPDATE_2ND_LEVEL_LICENSE';
export const RFQ_CLICK_LICENSE_IN_CART_VIEW = 'RFQ_CLICK_LICENSE_IN_CART_VIEW';
export const RFQ_CONTINUE_TO_CART_SUCCESS = 'RFQ_CONTINUE_TO_CART_SUCCESS';
export const RFQ_CONTINUE_SHOPPING = 'RFQ_CONTINUE_SHOPPING';
export const RFQ_LICENSE_NICKNAMES_LOADED = 'RFQ_LICENSE_NICKNAMES_LOADED';
export const RFQ_DISPLAY_ANNOUNCEMENT_MODAL = 'RFQ_DISPLAY_ANNOUNCEMENT_MODAL';
