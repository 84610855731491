import styled from 'styled-components';

// Images and Icons
import HeroBGSrc from '../../img/svg/kb/KB_BG.svg';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('${HeroBGSrc}');
  background-position-x: 50%;
  background-repeat: no-repeat;
  ${props => `
    margin-bottom: ${props.theme.spacing(6)}px;
    ${props.theme.breakpoints.up('md')} {
      background-size: cover;
    }
    @media screen and (min-width: 1920px) {
      margin-bottom: ${props.theme.spacing(8)}px;
    }
    // For the Container
    .CustomerPortalKnowledgeBase__HeadingContainer {
      margin-top: ${props.theme.spacing(8)}px;
      ${props.theme.breakpoints.up('md')} {
        margin-top: ${props.theme.spacing(20)}px;
      }
    }

  `};

  .CustomerPortalPageKnowledgeBase__SearchContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

`;
