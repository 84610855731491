/* eslint-disable react/no-unstable-nested-components */
import 'date-fns';

import {
  FileSizes,
  Header,
  SupportWhiteListedFileExtensions,
} from '@customer-portal/constants';
import {
  Icon,
  IconButton,
} from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import type { DesktopDatePickerSlotsComponent } from '@mui/x-date-pickers';
import {
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import type { UncapitalizeObjectKeys } from '@mui/x-date-pickers/internals';
import axios from 'axios';
import moment from 'moment';
import React, {
  createRef,
  useContext,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import validator from 'validator';

import * as styles from '../../assets/css/Premium/Ticket';
import OpenIcon from '../../assets/img/svg/action_icons/Expand_Icon.svg';
// Images
import CloudUp from '../../assets/img/svg/file_icons/Cloud_Up_Black.svg';
import { AccountType } from '../../constants/account.constants';
import { enterpriseSuccessIncidentTypes } from '../../constants/enterpriseIncidentTypes';
// Constants
import {
  CREATE_PREMIUM_CARE_CASE_URL_DEV,
  CREATE_PREMIUM_CARE_CASE_URL_LOCAL,
  CREATE_PREMIUM_CARE_CASE_URL_PROD,
} from '../../constants/network.constants';
// Constants
import premiumCareIncidentTypes from '../../constants/premiumCareIncidentTypes';
import premiumSupportIncidentTypes from '../../constants/premiumSupportIncidentTypes';
import { MAX_DESCRIPTION_LENGTH } from '../../constants/support.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import type { IDataObject } from '../../interfaces/dataObject.interface';
import { getNodeEnv } from '../../lib/env.utils';
// Store
import { StoreContext } from '../../store';
// Components
import Loader from '../CustomerPortal-Loader';
import TextLabel from '../TextLabel';
// lib
import { isFileAttachmentSupported } from './../../lib/file.utils';
// Google Analytics
import { CustomerPortalGoogleAnalyticsEvent } from './../CustomerPortal-GoogleAnalytics';

type PCAddTicketProps = {};

interface IFormData {
  accountId: string;
  contactEmail: string;
  contactName: string;
  subjectId: string;
  startDate: string;
  description: string;
  attachment: string;
  errors: any;
}

const PremiumCareTicketBody = (props: PCAddTicketProps) => {
  const { t } = useTranslation('common');
  const baseClass = 'PremiumCareTicketBody';
  const history = useHistory();
  const { getAccessToken } = useAuth();
  const [ selectedTicketIndex, setSelectedTicketIndex ] = useState(0);
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const [ isFormSubmitted, setIsFormSubmitted ] = useState<boolean>(false);
  const [ formData, setFormData ] = useState<IFormData>({
    accountId: state.companyName,
    contactEmail: state.userEmail,
    contactName: state.userName,
    subjectId: '',
    startDate: new Date().toISOString(),
    description: '',
    attachment: '',
    errors: {},
  });

  const isPremiumSupport = state.companyType === AccountType.PREMIUM_SUPPORT;
  const isEnterpriseSuccess = state.companyType === AccountType.ENTERPRISE;

  const getIncidentTypes = () => {
    if (isEnterpriseSuccess) {
      return enterpriseSuccessIncidentTypes;
    } else if (isPremiumSupport) {
      return premiumSupportIncidentTypes;
    }
    return premiumCareIncidentTypes;
  };

  let CREATE_PREMIUM_CARE_CASE_URL: string;

  switch (getNodeEnv()) {
    case 'development':
      CREATE_PREMIUM_CARE_CASE_URL = CREATE_PREMIUM_CARE_CASE_URL_DEV;
      break;
    case 'localhost':
      CREATE_PREMIUM_CARE_CASE_URL = CREATE_PREMIUM_CARE_CASE_URL_LOCAL;
      break;
    default:
      CREATE_PREMIUM_CARE_CASE_URL = CREATE_PREMIUM_CARE_CASE_URL_PROD;
  }

  const [ ticketSections, setTicketSections ] = useState([
    { isOpen: true },
    { isOpen: true },
    { isOpen: true },
  ]);

  const handleFilterClick = (event: any) => {
    setSelectedTicketIndex(event.target.dataset.ticketIndex);
    // Reset form data
    const newFormData = { ...formData };
    newFormData.startDate = new Date().toISOString();
    newFormData.description = '';

    setFormData(newFormData);
  };

  const handleToggle = (event: any) => {
    if (ticketSections && ticketSections.length > 0) {
      const position = event.target.id;
      const newSectionData = [ ...ticketSections ];
      newSectionData[position].isOpen = !newSectionData[position].isOpen;
      setTicketSections(newSectionData);
    }
  };

  const generateTicketTypes = () => {
    const infrastructureSections: any = [];
    const technicalSections: any = [];

    getIncidentTypes().forEach((ticket: IDataObject, i: number) => {
      const activeClass =
        `${i}` === `${selectedTicketIndex}` ? 'Active' : 'Inactive';
      const filterBaseClass = `${baseClass}__Ticket-Filter-Item`;
      const filterItem = (
        <styles.FilterItem
          className={`${filterBaseClass} ${filterBaseClass}--${activeClass}`}
          key={i}
          onClick={handleFilterClick}
          data-ticket-index={i}
          data-testid="TicketType"
        >
          {t(ticket.name.keyText, ticket.name.fallbackText)}
        </styles.FilterItem>
      );

      if (ticket.section === 'infrastructure_assistance') {
        return infrastructureSections.push(filterItem);
      } else if (ticket.section === 'technical_assistance') {
        return technicalSections.push(filterItem);
      }
    });
    const sections = [
      {
        name: {
          keyText:
            'support_premium_care_form_section_category_infrastructure_assistance',
          fallbackText: 'Infrastructure Assistance',
        },
        items: infrastructureSections,
        id: 'infrastructure_assistance',
      },
      ...(isPremiumSupport
        ? []
        : [
          {
            name: {
              keyText:
                'support_premium_care_form_section_category_technical_assistance',
              fallbackText: 'Technical Assistance',
            },
            items: technicalSections,
            id: 'technical_assistance',
          },
        ]),
    ];

    const sectionsToShow = sections.map((section: IDataObject, i: number) => {
      const openClass = ticketSections[i].isOpen ? 'Open' : 'Closed';
      return (
        <div
          className={`${baseClass}__Types-Container`}
          key={i}>
          <div className={`${baseClass}__Type-Opener`}>
            <p className={`${baseClass}__Type-Title`}>
              {t(section.name.keyText, section.name.fallbackText)}
            </p>
            {section.items && (
              <img
                onClick={handleToggle}
                className={`${baseClass}__Type-Opener-Icon ${baseClass}__Type-Opener-Icon--${openClass}`}
                src={OpenIcon}
                alt="Down Arrow"
                id={`${i}`}
                data-testid={`ticket-type-toggle${i}`}
              />
            )}
          </div>
          {section.items && (
            <div
              className={`${baseClass}__Type-Children-Container ${baseClass}__Type-Children-Container--${openClass}`}
              data-testid={`types-container-${openClass}`}
            >
              {section.items}
            </div>
          )}
        </div>
      );
    });

    const contactIndex = getIncidentTypes().length - 1;
    const contactIsActive =
      `${selectedTicketIndex}` === `${contactIndex}` ? 'Active' : 'Inactive';
    const filterBaseClass = `${baseClass}__Ticket-Filter-Item`;

    return (
      <>
        {sectionsToShow}
        {!isPremiumSupport && !isEnterpriseSuccess && (
          <styles.FilterItem
            className={`${filterBaseClass} ${filterBaseClass}--${contactIsActive}`}
            onClick={handleFilterClick}
            data-ticket-index={contactIndex}
            data-testid="TicketType"
          >
            {t(
              'support_premium_care_form_section_category_contact_technical_advisor',
              'Contact your Technical Advisor'
            )}
          </styles.FilterItem>
        )}
      </>
    );
  };

  const fileInput = createRef<any>();

  const selectedTicket = getIncidentTypes()[selectedTicketIndex];

  const handleDateChange = (date: Date) => {
    const newFormData = { ...formData };
    newFormData.startDate = date.toISOString();
    setFormData(newFormData);
  };

  const handleDescriptionChange = (e: any) => {
    const newFormData = { ...formData };
    newFormData.description = e.target.value;
    setFormData(newFormData);

    if (formData.errors.description) {
      newFormData.errors.description = '';
      setFormData(newFormData);
    }

    if (newFormData.description.length >= MAX_DESCRIPTION_LENGTH) {
      newFormData.errors.description = t(
        'support_form_error_message_invalid_desc_textbox_field',
        `Please condense the description field, as it exceeds {{maxDescriptionLength}} characters or more.`,
        { maxDescriptionLength: MAX_DESCRIPTION_LENGTH },
      );
    }
  };

  const handleContactEmailChange = (e: any) => {
    const newFormData = { ...formData };
    newFormData.contactEmail = e.target.value;
    setFormData(newFormData);

    if (formData.errors.contactEmail) {
      newFormData.errors.contactEmail = '';
      setFormData(newFormData);
    }
  };

  const handleContactNameChange = (e: any) => {
    const newFormData = { ...formData };
    newFormData.contactName = e.target.value;
    setFormData(newFormData);

    if (formData.errors.contactName) {
      newFormData.errors.contactName = '';
      setFormData(newFormData);
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileUploadInputChange = async (e: any) => {
    if (!e) {
      return;
    }
    e.preventDefault();

    const file = e.target.files[0];

    if (file && file.size > FileSizes.TwentyFiveMegabytes) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_form_attachment_size_error',
          'Error uploading document: File exceeds 25MB'
        ),
      });
      return;
    }

    // Allowed mime types
    // Document: .txt, .csv, .rtf, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .xml
    // Image: .bmp, .gif, .jpeg, .jpg, .png
    // Arhive: .zip, .rar, .7zip
    // Audio: .mp3, .ogg
    // Video: .mpeg, .mpg, .mov

    if (!isFileAttachmentSupported(file, SupportWhiteListedFileExtensions)) {
      formData.errors.fileInput = `File [${file.name}] is not allowed!`;
    } else {
      formData.errors.fileInput = '';
    }

    if (file && !formData.errors.fileInput) {
      const newFormData = { ...formData };
      newFormData.errors.fileInput = '';
      setFormData(newFormData);
    }

    const newFormData = { ...formData };
    newFormData.attachment = file.name;
    setFormData(newFormData);
  };

  const handleFormSubmit = async () => {
    const newFormData = { ...formData };
    // If contact email is empty
    if (formData.contactEmail.length === 0) {
      newFormData.errors.contactEmail = t(
        'support_premium_care_form_no_contact_email_error_msg',
        'Please fill the contact email field!'
      );
      setFormData(newFormData);
    // If contact email is invalid
    } else if (!validator.isEmail(formData.contactEmail)) {
      newFormData.errors.contactEmail = t(
        'support_form_error_message_invalid_email_textbox_field',
        'Please enter a valid email!'
      );
      setFormData(newFormData);
    }

    // If contact name is empty
    if (formData.contactName.length === 0) {
      newFormData.errors.contactName = t(
        'support_premium_care_form_no_contact_name_error_msg',
        'Please fill the contact name field!'
      );
      setFormData(newFormData);
    }

    // If description is empty or too long
    if (formData.description.length === 0) {
      newFormData.errors.description = t(
        'support_premium_care_form_no_description_error_msg',
        'Please fill the description field!'
      );
      setFormData(newFormData);
    }
    if (formData.description.length >= MAX_DESCRIPTION_LENGTH) {
      newFormData.errors.description = t(
        'support_form_error_message_invalid_desc_textbox_field',
        `Please condense the description field, as it exceeds {{maxDescriptionLength}} characters or more.`,
        { maxDescriptionLength: MAX_DESCRIPTION_LENGTH },
      );
      setFormData(newFormData);
    }

    if (
      !formData.errors.fileInput &&
        !formData.errors.description &&
        !formData.errors.contactEmail &&
        !formData.errors.contactName
    ) {
      const attachmentForm = document.getElementById('case_attachment');
      const fData = new FormData(attachmentForm as any);
      fData.set('accountId', state.companyId);
      fData.set('contactEmail', formData.contactEmail);
      fData.set('contactName', formData.contactName);
      fData.set('startDate', formData.startDate as any);
      fData.set(
        'subject',
        t(selectedTicket.name.keyText, selectedTicket.name.fallbackText)
      );
      fData.set('description', formData.description as any);

      setIsFormSubmitted(true);
      try {
        dispatch({
          type: 'setBannerType',
          payload: 'info',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: isPremiumSupport
            ? t(
              'support_premium_support_form_create_case_info_msg',
              'Creating a new preventive care case. This may take a few minutes.'
            )
            : t(
              'support_premium_care_form_create_case_info_msg',
              'Creating a new preventive care case. This may take a few minutes.'
            ),
        });

        const result = await axios.post(
          `${CREATE_PREMIUM_CARE_CASE_URL}`,
          fData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${await getAccessToken()}`,
              [Header.SELECTED_ACCOUNT_ID]: state.companyId,
              [Header.AUTH_TYPE]: getAuthType(),
            },
          }
        );

        if (result.status === 200) {
          dispatch({
            type: 'setBannerType',
            payload: 'success',
          });

          dispatch({
            type: 'setBannerMsg',
            payload: t(
              'support_premium_care_form_create_case_success_msg',
              'The case has been successfully created.'
            ),
          });

          // Analytics
          CustomerPortalGoogleAnalyticsEvent(
            'Preventive Care Case Created',
            selectedTicket.name.fallbackText
          );

          history.push('/support?section=cases-premium');
        }
      } catch (e) {
        const errorMessage = e.response
          ? t(
            'support_premium_care_form_create_case_error_msg',
            `Error while creating the case: ${e.response}`,
            { error: e.response }
          )
          : t(
            'support_form_create_case_error_without_error_response_msg',
            'Error while creating the case'
          );

        dispatch({
          type: 'setBannerType',
          payload: 'error',
        });

        dispatch({
          type: 'setBannerMsg',
          payload: errorMessage,
        });
      } finally {
        setIsFormSubmitted(false);
      }
    }
  };

  return (
    <styles.PremiumCareTicketBody>
      <div className={`${baseClass}__Ticket-Type`}>
        <h6 className={`${baseClass}__Ticket-Type-Header`}>
          {t('support_premium_care_form_filter_title', 'Select Type')}
        </h6>
        {generateTicketTypes()}
      </div>
      <div className={`${baseClass}__Ticket-Body`}>
        <h3 className={`${baseClass}__Ticket-Name`}>
          {t(selectedTicket.name.keyText, selectedTicket.name.fallbackText)}
        </h3>
        <p className={`${baseClass}__Ticket-Description`}>
          {t(
            selectedTicket.description.keyText,
            selectedTicket.description.fallbackText
          )}
        </p>
        {selectedTicket.id === 'contact_advisor' && (
          <p className={`${baseClass}__Ticket-Description Bold`}>
            {t(
              'support_premium_care_form_section_category_contact_technical_advisor_disclaimer',
              'Important: If you are having a technical issue with your production environment, please open a technical support case.'
            )}
          </p>
        )}

        {isFormSubmitted && (
          <div className={`${baseClass}__Loader`}>
            <Loader />
          </div>
        )}

        {!isFormSubmitted && (
          <div className={`${baseClass}__Ticket-Fields`}>
            <TextLabel
              label={t(
                'support_premium_care_form_account_id_label',
                'Account ID'
              )}
              required
              disabled
            />
            <TextField
              className={`${baseClass}__Ticket-Field`}
              variant={'outlined' as any}
              placeholder={formData.accountId}
              error={false}
              disabled
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              data-testid="AccountIDTextField"
            />

            <TextLabel
              label={t(
                'support_premium_care_form_subject_id_label',
                'Subject ID'
              )}
              required
              disabled
            />
            <TextField
              className={`${baseClass}__Ticket-Field`}
              variant={'outlined' as any}
              placeholder={t(
                selectedTicket.name.keyText,
                selectedTicket.name.fallbackText
              )}
              error={false}
              disabled
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: 'Tall' }}
              data-testid="SubjectIDTextField"
            />

            <FormControl>
              <TextLabel
                label={t(
                  'support_form_contact_name_label',
                  'Contact Name'
                )}
                required
              />
              <TextField
                className={`${baseClass}__Ticket-Field`}
                variant='outlined'
                value={formData.contactName}
                onChange={handleContactNameChange}
                error={false}
                InputLabelProps={{ shrink: true }}
                InputProps={{ className: 'Tall' }}
                inputProps={{ maxLength: 70 }}
                data-testid="ContactNameTextField"
              />
              {formData.errors?.contactName && (
                <styles.PremiumTicketValidationErrorMessage data-testid="input-ValidationErrorMessage">
                  {formData.errors.contactName}
                </styles.PremiumTicketValidationErrorMessage>
              )}
            </FormControl>

            <FormControl className={`${baseClass}__Ticket-Field`}>
              <TextLabel
                label={t(
                  'support_form_contact_email_label',
                  'Contact Email'
                )}
                required
              />
              <TextField
                className={`${baseClass}__Ticket-Field`}
                variant={'outlined' as any}
                value={formData.contactEmail}
                onChange={handleContactEmailChange}
                error={false}
                InputLabelProps={{ shrink: true }}
                InputProps={{ className: 'Tall' }}
                inputProps={{ maxLength: 320 }}
                data-testid="ContactEmailTextField"
              />
              {formData.errors?.contactEmail && (
                <styles.PremiumTicketValidationErrorMessage data-testid="input-ValidationErrorMessage">
                  {formData.errors.contactEmail}
                </styles.PremiumTicketValidationErrorMessage>
              )}
            </FormControl>

            {selectedTicket.id !== 'contact_advisor' && (
              <FormControl className={`${baseClass}__Ticket-Field`}>
                <TextLabel
                  label={t(
                    'support_premium_care_form_start_date_label',
                    'Start Date'
                  )}
                  required
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {/* Specify the DesktopDatePicker instead of the generic DatePicker, which behaves differently on mobile */}
                  {/* The DesktopDatePicker still works on mobile/small screens */}
                  <DesktopDatePicker
                    view='day'
                    views={[ 'day' ]}
                    openTo='day'
                    slots={{
                      openPickerButton: (props) => (
                        <IconButton
                          {...props}
                          data-testid='StartDatePicker' />
                      ),
                      openPickerIcon: () => (
                        <Icon className='material-icons-outlined'>
                          calendar_today
                        </Icon>
                      ),
                    } as UncapitalizeObjectKeys<DesktopDatePickerSlotsComponent<Date>>}
                    slotProps={{
                      textField: {
                        id: 'date-picker-inline',
                        className: 'Date-Picker',
                        required: true,
                        fullWidth: true,
                        InputProps: { className: 'Tall' },
                      },
                      field: { className: `${baseClass}__Ticket-Field--Datepicker` },
                      toolbar: { hidden: true },
                    } as any}
                    format="MM/dd/yyyy"
                    className={`${baseClass}__Ticket-Field--Datepicker`}
                    minDate={new Date()}
                    value={moment(formData.startDate).toDate()}
                    onChange={handleDateChange as any}
                  />
                </LocalizationProvider>
                <p className={`${baseClass}__Ticket-Field--Datepicker-HelpText`}>
                  Recommendation: Please choose a start date which can allow us to better prepare for your request
                  (15 days in advance of today’s date).
                </p>
              </FormControl>
            )}

            <FormControl className={`${baseClass}__Ticket-Field`}>
              <TextLabel
                label={t(
                  'support_premium_care_form_description_label',
                  'Description'
                )}
                required
              />
              <TextareaAutosize
                className={`${baseClass}__Ticket-Field--Textarea`}
                aria-label="empty textarea"
                required
                minRows={5}
                onChange={handleDescriptionChange}
                value={formData.description}
                data-testid="description"
              />
              {formData.errors?.description && (
                <styles.PremiumTicketValidationErrorMessage data-testid="input-ValidationErrorMessage">
                  {formData.errors.description}
                </styles.PremiumTicketValidationErrorMessage>
              )}
            </FormControl>

            <div className={`${baseClass}__Ticket-Field`}>
              <TextLabel
                label={t('support_form_attachment_label', 'Attachment')}
              />
              <p>
                {t(
                  'support_form_attachment_blurb_1',
                  'Single file upload is allowed (25MB max file size). In case of multiple files, we request for a zip file as single attachment.'
                )}
              </p>
              <p>
                {t(
                  'support_form_attachment_blurb_2',
                  'Please make sure not to send any personal or confidential data.'
                )}
              </p>
            </div>

            <div className={`${baseClass}__Ticket-Field--Upload`}>
              <Button
                variant="outlined"
                className={`${baseClass}__Ticket-Field--Upload-Button`}
                disableElevation
                onClick={handleUploadButtonClick}
                startIcon={<img
                  src={CloudUp}
                  alt="Cloud up icon" />}
              >
                {t('support_form_button_upload_file', 'Upload File')}
              </Button>

              <form
                id="case_attachment"
                className={`${baseClass}__Ticket-Field--Upload-Form`}
              >
                <input
                  className="CustomerPortalUploadForm__Input"
                  name="attachment"
                  type="file"
                  onChange={handleFileUploadInputChange}
                  ref={fileInput}
                />
              </form>
              {formData.errors.fileInput && (
                <styles.PremiumTicketValidationErrorMessage>
                  {formData.errors.fileInput}
                </styles.PremiumTicketValidationErrorMessage>
              )}
              {!formData.errors.fileInput && formData.attachment && (
                <styles.PremiumTicketUploadedFileInfo>
                  {formData.attachment}
                </styles.PremiumTicketUploadedFileInfo>
              )}
            </div>

            <div className={`${baseClass}__Ticket-Submit`}>
              <Button
                variant="contained"
                disableElevation
                color="secondary"
                onClick={handleFormSubmit}
                data-testid="submit-button"
              >
                {t('support_form_button_submit', 'Submit')}
              </Button>
              <Button
                variant="outlined"
                disableElevation
                onClick={() => history.push('/support?section=cases-premium')}
                className='cancelBtn'
              >
                {t('support_form_button_cancel', 'Cancel')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </styles.PremiumCareTicketBody>
  );
};

export default PremiumCareTicketBody;
