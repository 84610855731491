import styled from 'styled-components';

export const LandingPageContainer = styled.div`
  .LandingPage__Title {
    font-weight: bold;
    font-size: 36px;
    line-height: 38px;
    color: #000000;
    margin: 16px 0;
  }
  .LandingPage__Welcome-Text {
    margin: 1% 25% 6%;
    @media (min-width: 1650px) {
      margin: 3% 33% 6%;
    }
  }
  .LandingPage__Confirmation-Text {
    margin: 2% 25% 30%;
  }
  .LandingPage__Heading {
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    margin: 16px 0 28px;
    text-align: center;
  }
  .LandingPage__SubHeading {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin: 16px 0;
  }
  .LandingPage__Description {
    font-weight: 200;
    font-size: 18px;
    line-height: 24px;
    margin: 20px 0;
    white-space: pre-wrap;
  }
  .LandingPage__Button-Container {
    display: flex;
    flex-direction: column;
    margin: 8% 24% 0;
    .LandingPage__Button {
      margin-bottom: 28px;
      font-size: 16px !important;
      padding: 10px 16px;
      &:hover {
        background-color: ${p => p.theme.palette.blue[500]};
        opacity: 0.9;
      }
    }
  }
  a {
    color: ${p => p.theme.palette.blue[500]};
    &:hover {
      cursor: pointer;
    }
  }
`;
