import type {
  FacetSearch,
  FacetSearchState,
} from '@coveo/headless';
import type { SpecificFacetSearchResult } from '@coveo/headless/dist/definitions/api/search/facet-search/specific-facet-search/specific-facet-search-response';
import { Autocomplete } from '@mui/lab';
import { TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import ApolloIcon from '../../../../components/ApolloIcon';

const Container = styled.div`
  .MuiInputBase-root.MuiOutlinedInput-root {
    border: 1px solid ${p => p.theme.palette.grey[500]} !important;
    &:hover, &:focus, &:focus-within, &:active {
      border-color: transparent !important;
    }
  }

  .Autocomplete__ResultItem {
    cursor: pointer;
    &:hover {
      cursor: pointer;
      background-color: ${p => p.theme.palette.grey[200]};
    }
  }
`;

const AutocompleteResultItem = styled.div`
  padding: 4px 12px;
  cursor: pointer;
  &:hover {
    background-color: ${p => p.theme.palette.grey[100]};
  }
`;

const TextFieldStyled = styled(TextField)`
  &&& {
    .MuiInputBase-root {
      padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(1)}px;
      border: 1px solid ${p => p.theme.palette.grey[500]};
    }
    .MuiInputBase-input {
      border: none;
    }
  }
    &:hover,
    &:active {}
  }
`;

const ApolloIconStyled = styled(ApolloIcon)`
  color: ${p => p.theme.palette.grey[600]};
`;

type Props = {
  controller: FacetSearch;
  state: FacetSearchState;
  placeholder?: string;
};

const FacetSearchBox = (props: Props) => {
  const {
    controller, state, placeholder,
  } = props;

  const onSearchTextChange = (_: React.ChangeEvent<{}>, value: string) => {
    controller.updateText(value);
    if (value.length < 1) {
      controller.clear();
    } else {
      controller.search();
    }
  };

  const selectFacetValue = (
    _: React.ChangeEvent<{}>,
    specificFacetResult: SpecificFacetSearchResult | string | null
  ) => {
    if (specificFacetResult && typeof specificFacetResult !== 'string') {
      controller.select(specificFacetResult);
    }
  };

  return (
    <Container>
      <Autocomplete
        freeSolo
        onChange={selectFacetValue}
        onInputChange={onSearchTextChange}
        inputValue={state.query}
        options={state.values}
        size='small'
        style={{ margin: '16px 0' }}
        getOptionLabel={(facetSearchResult: any) => facetSearchResult.rawValue}
        renderOption={(_, facetSearchResult: SpecificFacetSearchResult) => (
          <AutocompleteResultItem
            onClick={(_: any) => selectFacetValue(_, facetSearchResult)}
          >
            {facetSearchResult.displayValue}
            {' '}
(
            {facetSearchResult.count}
)
          </AutocompleteResultItem>
        )}
        renderInput={(params) => (
          <TextFieldStyled
            {...params}
            variant={'outlined' as any}
            placeholder={placeholder ?? 'Search...'}
            InputProps={{
              ...params.InputProps,
              startAdornment: <ApolloIconStyled icon='search' />,
            }}
          />
        )}
      />
    </Container>
  );
};

export default FacetSearchBox;
