import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styled from '../../assets/css/Support/PremiumSupportContactModal';
// Constants
import tollFreeNumbers from '../../constants/premiumSupportTollFreeNumbers';
import type { IDataObject } from '../../interfaces/dataObject.interface';
// Components
import Modal from '../Modal';

type PremiumSupportContactModalProps = {
  onClose: () => void;
  open: boolean;
};

const PremiumSupportContactModal = (props: PremiumSupportContactModalProps) => {
  // Translate method
  const { t } = useTranslation('common');
  const baseClass = 'PremiumSupportContactModal';
  const generateNumbers = () => tollFreeNumbers.map((number: IDataObject, i: number) => (
    <div
      className={`${baseClass}__Number-Container`}
      key={i}>
      <p className={`Bold ${baseClass}__Number--Country`}>{number.name}</p>
      <div className={`${baseClass}__Number-Attribute-Container`}>
        <p
          className={`${baseClass}__Number-Attribute ${baseClass}__Number-Attribute--Heading`}
        >
          {t(
            'support_premium_support_cases_hero_premium_support_modal_language',
            'Language'
          )}
        </p>
        <p className={`${baseClass}__Number-Attribute`}>
          {number.language}
        </p>
      </div>
      <div className={`${baseClass}__Number-Attribute-Container`}>
        <p
          className={`${baseClass}__Number-Attribute ${baseClass}__Number-Attribute--Heading`}
        >
          {t(
            'support_premium_support_cases_hero_premium_support_modal_timings',
            'Timings'
          )}
        </p>
        <p className={`${baseClass}__Number-Attribute`}>{number.timing}</p>
      </div>
      <div className={`${baseClass}__Number-Attribute-Container`}>
        <p
          className={`${baseClass}__Number-Attribute ${baseClass}__Number-Attribute--Heading`}
        >
          {t(
            'support_premium_support_cases_hero_premium_support_modal_phone',
            'Phone'
          )}
        </p>
        <p
          className={`${baseClass}__Number-Attribute ${baseClass}__Number-Attribute--Phone`}
        >
          {number.phone}
        </p>
      </div>
    </div>
  ));

  return (
    <Modal
      modalTitle="Standard Modal"
      modalDescription="Toll free number modal for premium support"
      modalHeading={t(
        'support_premium_support_cases_hero_premium_support_modal_title',
        'Toll Free Numbers'
      )}
      innerClasses={`${baseClass}__Inner`}
      handleClose={props.onClose}
      open={props.open}
    >
      <styled.ContactModalWrapper>
        {generateNumbers()}
      </styled.ContactModalWrapper>
    </Modal>
  );
};

export default PremiumSupportContactModal;
