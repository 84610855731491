import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
} from 'react-router-dom';

import {
  CheckoutContinueShoppingButtonContainer,
  CustomerPortalHeaderActions,
  CustomerPortalHeaderInner,
  CustomerPortalHeaderInnerMobileDrawer,
  CustomerPortalHeaderMenuList,
  CustomerPortalHeaderNonTextItems,
  CustomerPortalHeaderNotifications,
  CustomerPortalHeaderTextItems,
  CustomerPortalHeaderUserProfile,
  HamburgerLine,
  HeaderLogoHamburgerContainer,
  NavBarBottom,
  NavBarOuter,
  NavOpener,
  NavOpenerInner,
} from '../../assets/css/CustomerPortal-Header';
// Images
// Style
// Client
import { axiosGet } from '../../client/axios';
// Constants
import { getHeaderItems } from '../../constants/header.constants';
import { BACKEND_HOST_NAME } from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
// Auth
// Interfaces
import type { INotification } from '../../interfaces/notifications.interface';
// Utils
import { UserPermissionsHelper } from '../../lib/UserPermissions';
// Store
import { StoreContext } from '../../store';
import { headlessSearchEngine } from '../../utils/search';
import BrandedLogoAndText from '../BrandedLogoAndText';
// Components
import MainMenuLink from '../CustomerPortal-MainMenu-Link';
import MainMenuNotifications from '../CustomerPortal-MainMenu-Notifications';
import MainMenuUser from '../CustomerPortal-MainMenu-User';
import MiniSearchBar from '../search/MiniSearchBar/MiniSearchBar';

const CustomerPortalHeader = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  // Global state
  const { state } = useContext(StoreContext);
  const { pathname } = useLocation();
  const { getAccessToken } = useAuth();
  // Local state
  const [ nav_state, setNavState ] = useState({ navOpen: false });
  const [ isCheckout, setIsCheckout ] = useState(false);
  const [ isHapoCheckout, setIsHapoCheckout ] = useState(false);
  const [ notifications, setNotifications ] = useState<INotification[]>([]);
  // Constants
  const isEditLicenseAllowed = UserPermissionsHelper.isEditLicenseAllowed();
  const isEditHAPOLicenseAllowed = UserPermissionsHelper.isEditHAPOLicenseAllowed();

  const {
    mainHeaderItems,
    sideHeaderItemsMetaData: {
      showGlobalSearch, showNotifications, userSettingsDropdownChildren,
    },
  } = getHeaderItems();

  const headerCount = mainHeaderItems.length;

  // Methods
  useEffect(() => {
    if (pathname === '/request-quote/checkout') {
      setIsCheckout(true);
    } else if (isCheckout) {
      setIsCheckout(false);
    }

    if (pathname === '/hapo/checkout') {
      setIsHapoCheckout(true);
    } else if (isHapoCheckout) {
      setIsHapoCheckout(false);
    }
  }, [ pathname ]);

  const getNofications = async () => {
    if (!state.notificationsLoaded && showNotifications) {
      try {
        const notificationsResult = await axiosGet(
          `${BACKEND_HOST_NAME}/api/v1/notifications/getTopNotifications`,
          state.companyId,
          await getAccessToken(),
          { params: { limit: 5 } }
        );

        if (notificationsResult?.data) {
          setNotifications(notificationsResult.data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getNofications();
  }, []);

  const toggleDrawer = (open: any) => (event?: { [key: string]: any }) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setNavState({
      ...nav_state,
      navOpen: open,
    });
  };

  const navOpener = (openState: any) => {
    const ariaText = openState === 'true' ? 'Open' : 'Close';
    return (
      <NavOpener
        aria-label={`${ariaText} navigation drawer`}
        onClick={toggleDrawer(openState)}
      >
        <NavOpenerInner headerCount={headerCount}>
          <HamburgerLine navOpen={nav_state.navOpen} />
          <HamburgerLine navOpen={nav_state.navOpen} />
          <HamburgerLine navOpen={nav_state.navOpen} />
        </NavOpenerInner>
      </NavOpener>
    );
  };

  const renderNavDrawer = () => (
    <CustomerPortalHeaderInnerMobileDrawer
      headerCount={headerCount}
      anchor="right"
      open={nav_state.navOpen}
      onClose={toggleDrawer(false)}
    >
      <CustomerPortalHeaderInner
        cssClass="CustomerPortalHeader__Inner-Mobile">
        <HeaderLogoHamburgerContainer>
          <BrandedLogoAndText />
          {navOpener(false)}
        </HeaderLogoHamburgerContainer>
      </CustomerPortalHeaderInner>
      {mainHeaderItems.map((listItem, index) => (
        // featureFlag filter not currently being used, but could be useful later?
        <MainMenuLink
          data={listItem}
          isMobile
          handleDrawerClose={toggleDrawer(false)}
          dropdownMenuLinks={listItem.dropdownChildren}
          key={`mainMenuLink-${index}`}
        />
      ))}
      <NavBarBottom>
        <CustomerPortalHeaderUserProfile>
          <MainMenuUser
            data={userSettingsDropdownChildren}
            isMobile
            handleDrawerClose={toggleDrawer(false)}
          />
        </CustomerPortalHeaderUserProfile>
      </NavBarBottom>
    </CustomerPortalHeaderInnerMobileDrawer>
  );

  return (
    <>
      <NavBarOuter
        className="CustomerPortalHeader"
        data-testid="CustomerPortalHeader"
      >
        <CustomerPortalHeaderInner
          isCheckout={(isCheckout && isEditLicenseAllowed) ||
            (isHapoCheckout && isEditHAPOLicenseAllowed)}
        >
          <Hidden lgUp>{renderNavDrawer()}</Hidden>
          <HeaderLogoHamburgerContainer>
            {state.isUiPath}
            {(!isCheckout || !isHapoCheckout) && navOpener(true)}
          </HeaderLogoHamburgerContainer>
          {isCheckout && isEditLicenseAllowed ? (
            <CheckoutContinueShoppingButtonContainer>
              <Button
                color="secondary"
                component={Link}
                to="/request-quote"
                className="CustomerPortalHeader__Checkout-Button"
              >
                {t('header_menu_continue_shopping', 'Continue Shopping')}
              </Button>
            </CheckoutContinueShoppingButtonContainer>
          ) : isHapoCheckout && isEditHAPOLicenseAllowed ? (
            <CheckoutContinueShoppingButtonContainer>
              <Button
                color="secondary"
                component={Link}
                to="/hapo"
                className="CustomerPortalHeader__Checkout-Button"
              >
                {t('header_menu_continue_shopping', 'Continue Shopping')}
              </Button>
            </CheckoutContinueShoppingButtonContainer>
          ) : (
            <CustomerPortalHeaderActions headerCount={headerCount}>

              <CustomerPortalHeaderTextItems>
                <CustomerPortalHeaderMenuList>
                  {mainHeaderItems.map((listItem, index) => (
                    // featureFlag filter not currently being used, but could be useful later?
                    <MainMenuLink
                      headerCount={headerCount}
                      data={listItem}
                      isMobile={false}
                      dropdownMenuLinks={listItem.dropdownChildren}
                      key={`mainMenuLink-${index}`}
                    />
                  ))}
                </CustomerPortalHeaderMenuList>
              </CustomerPortalHeaderTextItems>

              <CustomerPortalHeaderNonTextItems>
                {showGlobalSearch && (
                  <MiniSearchBar engine={headlessSearchEngine} />
                )}

                {showNotifications && (
                  <CustomerPortalHeaderNotifications>
                    <MainMenuNotifications
                      notifications={notifications}
                      setNotifications={setNotifications}
                    />
                  </CustomerPortalHeaderNotifications>
                )}

                <CustomerPortalHeaderUserProfile>
                  <MainMenuUser
                    headerCount={headerCount}
                    data={userSettingsDropdownChildren} />
                </CustomerPortalHeaderUserProfile>
              </CustomerPortalHeaderNonTextItems>

            </CustomerPortalHeaderActions>
          )}
        </CustomerPortalHeaderInner>
      </NavBarOuter>
    </>
  );
};

export default CustomerPortalHeader;
