import { Locale } from '@customer-portal/constants';

export const NODE_ENV_TO_DOT_COM_COOKIE_NAME_SUFFIX = new Map([
  [ 'localhost', 'local' ],
  [ 'development', 'dev' ],
  [ 'staging', 'staging' ],
  [ 'production', 'prod' ],
]);

export enum DotComLanguage {
  'en-US' = 'en-US',
  'fr-FR' = 'fr-FR',
  'de-DE' = 'de-DE',
  'ja-JP' = 'ja-JP',
  'ko-KR' = 'ko-KR',
  'pt-BR' = 'pt-BR',
  'es-MX' = 'es-MX',
  'zh-CN' = 'zh-CN',
}

export const DOT_COM_LANGUAGE_TO_LOCALE: { [key in DotComLanguage]: Locale; } = {
  [DotComLanguage['en-US']]: Locale.en,
  [DotComLanguage['fr-FR']]: Locale.fr,
  [DotComLanguage['de-DE']]: Locale.de,
  [DotComLanguage['ja-JP']]: Locale.ja,
  [DotComLanguage['ko-KR']]: Locale.ko,
  [DotComLanguage['pt-BR']]: Locale['pt-BR'],
  [DotComLanguage['es-MX']]: Locale['es-MX'],
  [DotComLanguage['zh-CN']]: Locale['zh-CN'],
};

export const LOCALE_TO_DOT_COM_LANGUAGE: { [key in Locale]: DotComLanguage; } = {
  [Locale.en]: DotComLanguage['en-US'],
  [Locale.ja]: DotComLanguage['ja-JP'],
  [Locale.de]: DotComLanguage['de-DE'],
  [Locale.fr]: DotComLanguage['fr-FR'],
  [Locale.es]: DotComLanguage['es-MX'],
  [Locale['pt-BR']]: DotComLanguage['pt-BR'],
  [Locale.pt]: DotComLanguage['pt-BR'],
  [Locale.ru]: DotComLanguage['en-US'],
  [Locale.tr]: DotComLanguage['en-US'],
  [Locale['es-MX']]: DotComLanguage['es-MX'],
  [Locale.ko]: DotComLanguage['ko-KR'],
  [Locale['zh-CN']]: DotComLanguage['zh-CN'],
  [Locale['zh-TW']]: DotComLanguage['zh-CN'],
};
