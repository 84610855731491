import styled from 'styled-components';

export const NoCompanyId = styled.section`
  position: relative;
  background-color: #f5f5f6;

  .CustomerPortalNoCompanyId__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 75vh;

    ${p => p.theme.breakpoints.up('sm')} {
      flex-direction: row;
      justify-content: space-between;
    }

    .CustomerPortalNoCompanyId__message {
      width: 100%;
      padding: ${p => p.theme.spacing(8)}px;
      text-align: center;

      h2 {
        margin: ${p => p.theme.spacing(6)}px;
        font-size: 24px;
      }
    }
  }
`;
