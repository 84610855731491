import type {
  CaseAssistEngine,
  DocumentSuggestionListState,
  DocumentSuggestionResponse,
} from '@coveo/headless/case-assist';
// Styles
import { Button } from '@mui/material';
// eslint-disable-next-line unused-imports/no-unused-imports
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionButtonDiv,
  EmptyResultContainer,
  EmptyResultDescription,
  EmptyResultLink,
  LoaderContainer,
  ResultContainer,
} from '../../../assets/css/Support/CaseAssist/SupportCaseDeflectionModal';
import {
  CaseAssistAnalyticsActions,
  defaultSuggestions,
} from '../../../constants/caseAssist.constants';
import { logCaseAssistAnalyticsEvent } from '../../../utils/caseAssist';
import type { LocalizedString } from '../../../utils/localization';
import CustomerPortalLoader from '../../CustomerPortal-Loader';
// Components
import Modal from '../../Modal';
import SupportCaseDeflectionListItem from './SupportCaseDeflection-ListItem';

type SupportCaseDeflectionModalProps = {
  open?: boolean;
  engine: CaseAssistEngine;
  responseState: DocumentSuggestionListState;
  onClose?: () => void;
  onSubmit?: () => void;
  isNewSupportCase: boolean;
  resultsShownFirst?: string;
};

const SupportCaseDeflectionModal = ({
  open,
  engine,
  responseState,
  onClose,
  onSubmit,
  isNewSupportCase,
  resultsShownFirst,
}: SupportCaseDeflectionModalProps) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    if (!responseState.loading) {
      if (responseState.documents?.length > 0) {
        const suggestedDocuments = responseState.documents.map(
          (suggestion: DocumentSuggestionResponse) => suggestion.clickUri
        );
        const data = 'Articles: ' + JSON.stringify(suggestedDocuments) +
          ', Results shown first: ' + resultsShownFirst;
        logCaseAssistAnalyticsEvent(
          engine,
          CaseAssistAnalyticsActions.CASE_COVEO_SUGGESTIONS_SHOWN,
          data
        );
      } else {
        logCaseAssistAnalyticsEvent(
          engine,
          CaseAssistAnalyticsActions.CASE_COVEO_SUGGESTIONS_EMPTY
        );
      }
    }
  }, [ responseState ]);

  const getEmptyStateView = () => defaultSuggestions.map(
    (
      document: {
        title: LocalizedString;
        description: LocalizedString;
        link: string;
      },
      index: number
    ) => (
      <EmptyResultContainer key={index}>
        <EmptyResultLink
          href={document.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(document.title.textKey, document.title.fallbackText)}
        </EmptyResultLink>
        <EmptyResultDescription>
          {t(
            document.description.textKey,
            document.description.fallbackText
          )}
        </EmptyResultDescription>
      </EmptyResultContainer>
    )
  );

  const generateSuggestionsList = () => responseState.documents.map(
    (suggestion: DocumentSuggestionResponse, index: number) => (
      <SupportCaseDeflectionListItem
        engine={engine}
        suggestion={suggestion}
        index={index}
        key={suggestion.uniqueId}
      />
    )
  );

  return (
    <div>
      {
        isNewSupportCase ? (
          <div>
            {/* Loading */}
            {responseState.loading &&
              <LoaderContainer>
                <CustomerPortalLoader />
              </LoaderContainer>}

            <div>
              <ResultContainer>
                {/* Data */}
                {!responseState.loading &&
                  responseState.documents.length > 0 &&
                  generateSuggestionsList()}
                {/* Empty State */}
                {!responseState.loading &&
                  responseState.documents.length < 1 &&
                  getEmptyStateView()}
              </ResultContainer>
            </div>
          </div>
        ) : (
          <Modal
            modalTitle="Recommended Solutions"
            modalDescription="Recommended Solutions related to the case being created"
            modalHeading={t(
              'support_form_recommended_solution',
              'Recommended Solutions'
            )}
            innerClasses="Support__Case-Suggestions-Modal"
            handleClose={onClose}
            open={open ?? false}
          >
            <div>
              {/* Loading */}
              {responseState.loading && <CustomerPortalLoader />}

              <div>
                <ResultContainer>
                  {/* Data */}
                  {!responseState.loading &&
                    responseState.documents.length > 0 &&
                    generateSuggestionsList()}
                  {/* Empty State */}
                  {!responseState.loading &&
                    responseState.documents.length < 1 &&
                    getEmptyStateView()}
                  <ActionButtonDiv>
                    <Button
                      variant="outlined"
                      disableElevation
                      onClick={onClose}>
                      {t('support_form_suggestions_button_back', 'Back')}
                    </Button>
                    <Button
                      variant="contained"
                      disableElevation
                      color="secondary"
                      onClick={onSubmit}
                    >
                      {t('support_form_button_create_case', 'Create Case')}
                    </Button>
                  </ActionButtonDiv>
                </ResultContainer>
              </div>
            </div>
          </Modal>
        )
      }
    </div>
  );
};

export default SupportCaseDeflectionModal;
