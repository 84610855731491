import {
  Header,
  KBEmbeddedVideoType,
} from '@customer-portal/constants';
import axios from 'axios';
import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

// Components
import CustomerPortalKBDocument from '../components/knowledge/KB-Document';
// Constants
import KB_ACTION from '../constants/kbActions.constants';
import { EMBEDDED_VIDEO_VIMEO_MATCHER_REGEX } from '../constants/knowledge.constants';
import { KB_ASSETS_URL } from '../constants/network.constants';
import {
  getAuthType,
  useAuth,
} from '../contexts/auth';
import type { IDataObject } from '../interfaces/dataObject.interface';
// Utils
import type {
  IKBSearchParamBuilder,
  IKBSearchState,
} from '../interfaces/knowledgeBase.interface';
import { StoreContext } from '../store';

export const renderKBCard = (
  item: IDataObject,
  isDisplayDescription = false,
  handleDeleteFormSubmit?: (
    fileId: string,
    categoryName: string,
    subcategoryName: string
  ) => Promise<void>
) => {
  if (!item) {
    return null;
  }

  const {
    _id: id,
    display_name,
    description,
    mime_type: mimeType,
    size,
    specs,
    hyperlink,
    category,
    subcategory,
    keywords,
    featured,
    sort_ranking,
    is_marked_for_notification: notification,
    dataTestId,
    languages,
    type,
    embedded_video_raw,
    embedded_video_type,
    embedded_video_id,
  } = item;

  const keywordsString = Array.isArray(keywords) ? keywords.join(' ') : '';

  return (
    <CustomerPortalKBDocument
      key={id}
      category={category}
      subcategory={subcategory}
      keywords={keywordsString}
      featured={featured || false}
      sortRanking={sort_ranking ?? null}
      notification={notification || false}
      documentTitleText={display_name}
      isDisplayDescription={isDisplayDescription}
      documentDescriptionText={description}
      mimeType={mimeType}
      linkHref={hyperlink}
      specs={specs}
      fileSize={size}
      fileId={id}
      dataTestId={dataTestId}
      handleDeleteFormSubmit={handleDeleteFormSubmit}
      languages={languages}
      type={type}
      embeddedVideoRaw={embedded_video_raw}
      embeddedVideoType={embedded_video_type}
      embeddedVideoId={embedded_video_id}
      embeddedVideoThumbnailSize={isDisplayDescription ? 'medium' : 'small'}
    />
  );
};

export class KBSearchParamBuilder implements IKBSearchParamBuilder {
  filterCategories: Set<string>;
  filterSubcategories: Set<string>;
  sortBy: string | null | undefined;
  sortDirection: number;
  top: number | undefined;
  skip: number | undefined;
  isShowDescription: boolean;
  action: string;
  filterCategoryTypeCount: string;
  keywords: Set<string>;

  constructor(docType: 'KB_Documents' | 'Support_KB_Documents') {
    this.filterCategories = new Set<string>();
    this.filterSubcategories = new Set<string>();
    this.sortBy = docType === 'KB_Documents' ? 'sort_ranking' : 'created_on';
    this.sortDirection = docType === 'KB_Documents' ? 1 : -1;
    this.filterCategoryTypeCount = 'category';
    this.isShowDescription = false;
    this.action = 'search';
    this.keywords = new Set<string>();
  }

  setAction(action: string) {
    this.action = action;
    return this;
  }
  setKeyword(keyword: string[]) {
    keyword.forEach(val => {
      this.keywords.add(val);
    });
    return this;
  }
  setFilterCategories(filterCategorySet: Set<string>) {
    this.filterCategories = filterCategorySet;
    return this;
  }
  setFilterSubcategories(filterSubcategorySet: Set<string>) {
    this.filterSubcategories = filterSubcategorySet;
    return this;
  }
  setSortBy(sortBy: string | null | undefined) {
    if (sortBy) {
      this.sortBy = sortBy;
    }
    return this;
  }
  setSortDirection(sortDirection: number) {
    if (sortDirection === -1 || sortDirection === 1) {
      this.sortDirection = sortDirection;
    }
    return this;
  }
  setTop(top: number) {
    this.top = top;
    return this;
  }
  setSkip(skip: number) {
    this.skip = skip;
    return this;
  }
  setFilterTypeCount(categoryType: string) {
    this.filterCategoryTypeCount = categoryType;
    return this;
  }
  setShowDescription(show: boolean) {
    this.isShowDescription = show;
    return this;
  }
  build() {
    return new KBSearchParam(this);
  }
}

export class KBSearchParam {
  filter_categories: string | null | undefined;
  filter_subcategories: string | null | undefined;
  sort_by: string | null | undefined;
  sort_direction: string | null | undefined;
  top: string | null | undefined;
  skip: string | null | undefined;
  filterCategoryTypeCount: string;
  is_show_description: string | null | undefined;
  action: string;
  keywords: string | null | undefined;

  constructor(builder: IKBSearchParamBuilder) {
    this.filter_categories = [ ...builder.filterCategories ].join('+');
    this.filter_subcategories = [ ...builder.filterSubcategories ].join('+');
    this.sort_by = builder.sortBy;
    this.sort_direction = builder.sortDirection.toString();
    this.top = builder.top ? builder.top.toString() : undefined;
    this.skip = builder.skip ? builder.skip.toString() : undefined;
    this.filterCategoryTypeCount = builder.filterCategoryTypeCount;
    this.is_show_description = builder.isShowDescription ? 'true' : 'false';
    this.action = builder.action;
    this.keywords = [ ...builder.keywords ].join('+');
  }

  toString() {
    let queryParam = '?';
    queryParam += `action=${this.action}&`;
    queryParam += this.keywords ? `keywords=${this.keywords}&` : '';
    queryParam += this.filter_categories
      ? `filter_categories=${this.filter_categories}&`
      : '';
    queryParam += this.filter_subcategories
      ? `filter_subcategories=${this.filter_subcategories}&`
      : '';
    queryParam += this.sort_by ? `sort_by=${this.sort_by}&` : '';
    queryParam +=
      this.sort_by && this.sort_direction
        ? `sort_direction=${this.sort_direction}&`
        : '';
    queryParam += `top=${this.top || '7'}&`;
    queryParam += this.skip ? `skip=${this.skip}&` : '';
    queryParam += `filterCategoryTypeCount=${this.filterCategoryTypeCount}&`;
    queryParam += this.is_show_description
      ? `is_show_description=${this.is_show_description}`
      : '';

    return queryParam;
  }
}

/**
 * Custom Hook for search
 */

/* Custom Hook to get kb data */
export const useKBFetcher = (
  initURL: string,
  docsPerPage: number,
  reducer: (state: any, action: { [key: string]: any }) => any,
  initialState: { [key: string]: any }
) => {
  const { state: userState } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const [ kbURL, setKbURL ] = useState(initURL);

  const [ state, dispatch ] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!kbURL) {
      return;
    }

    const getSearchResults = async (kbURL: string) => {
      try {
        const result = await axios.get(encodeURI(kbURL), {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            [Header.SELECTED_ACCOUNT_ID]: userState.companyId,
            [Header.AUTH_TYPE]: getAuthType(),
          },
        });

        if (!result.data && !result.data.data) {
          throw new Error('No data retrieved');
        }
        const kbResultData = result.data.data;
        const totalPages =
          kbResultData.total_count && docsPerPage > 0
            ? Math.ceil(kbResultData.total_count / docsPerPage)
            : 0;
        dispatch({
          type: KB_ACTION.FETCH_SUCCESS,
          payload: {
            totalPages,
            totalDocs: kbResultData.total_count,
            filterCategories: kbResultData.filter_counts,
            value: kbResultData.docs,
          },
        });
      } catch (e) {
        if (!e) {
          return;
        }
        console.log(e.toString());
        // If returned with error from backend
        if (e.response && e.response.status === 400) {
          dispatch({ type: KB_ACTION.FETCH_FAIL });
        }
      }
    };

    getSearchResults(kbURL);
  }, [ kbURL ]);

  // Return current state, url func, dispatch func
  return [ state, setKbURL, dispatch ];
};

/**
 * Search Event Handlers
 */

// Event when a Category on the Filter column is clicked
export const handleSearchCategoryFilterClick = (
  docsPerPage: number,
  KB_ASSETS_URL: string,
  searchState: IKBSearchState,
  setSearchURL: any,
  dispatchSearchAction: any,
  setSearchPaginationPage: any,
  elemID: string,
  filterType: string,
  history: any
) => {
  if (!elemID) {
    return;
  }

  const filter =
    filterType === 'subcategory'
      ? searchState.filterSubcategoriesParam
      : searchState.filterParam;
  const filterCategoryCopy = new Set<string>(filter);

  // Initial first time load
  if (filterCategoryCopy.has(elemID)) {
    filterCategoryCopy.delete(elemID);
  } else {
    filterCategoryCopy.add(elemID);
  }

  const queryParam = new KBSearchParamBuilder('KB_Documents')
    .setAction('search')
    .setKeyword(searchState.keyword ? searchState.keyword.split(' ') : [ '' ])
    .setSortBy(searchState.sortBy)
    .setSortDirection(searchState.sortDirection)
    .setTop(docsPerPage)
    .setSkip(0)
    .setShowDescription(true)
    .setFilterTypeCount(filterType);

  if (filterType === 'subcategory') {
    queryParam.setFilterCategories(searchState.filterParam);
    queryParam.setFilterSubcategories(filterCategoryCopy);
    dispatchSearchAction({
      type: KB_ACTION.SUBCATEGORY_UPDATED,
      payload: { value: elemID },
    });
  } else {
    queryParam.setFilterCategories(filterCategoryCopy);
    dispatchSearchAction({
      type: KB_ACTION.FILTER_UPDATED,
      payload: { value: elemID },
    });
  }

  // Update url params
  let newSearchParam = `q=${searchState.keyword}&page=1&s=${searchState.sortBy}&d=${searchState.sortDirection}`;
  if (filterType === 'subcategory') {
    newSearchParam = `${newSearchParam}&sub=${[
      ...filterCategoryCopy,
    ].toString()}`;
  } else {
    newSearchParam = `${newSearchParam}&c=${[
      ...filterCategoryCopy,
    ].toString()}`;
  }
  history.replace({ search: newSearchParam });

  setSearchURL(`${KB_ASSETS_URL}${queryParam.build().toString()}`);

  // Reset the page on new filter
  setSearchPaginationPage(1);
};

// Event when Search Sort item is clicked
export const handleSearchSortClick = (
  docsPerPage: number,
  KB_ASSETS_URL: string,
  searchState: IKBSearchState,
  setSearchURL: any,
  dispatchSearchAction: any,
  setSearchPaginationPage: any,
  sortVal: { path: string; direction: number } | null,
  filterType: string,
  history: any
) => {
  if (!sortVal) {
    return;
  }

  // Do nothing if sorting remains the same
  if (
    searchState.sortBy === sortVal.path &&
    searchState.sortDirection === sortVal.direction
  ) {
    return;
  }

  const queryParam = new KBSearchParamBuilder('KB_Documents')
    .setAction('search')
    .setKeyword(searchState.keyword ? searchState.keyword.split(' ') : [ '' ])
    .setFilterCategories(searchState.filterParam)
    .setFilterSubcategories(searchState.filterSubcategoriesParam)
    .setTop(docsPerPage)
    .setSkip(0)
    .setShowDescription(true)
    .setFilterTypeCount(filterType);

  if (sortVal) {
    queryParam.setSortBy(sortVal.path).setSortDirection(sortVal.direction);
  }

  setSearchURL(`${KB_ASSETS_URL}${queryParam.build().toString()}`);
  // Update url params
  let newSearchParam = `q=${searchState.keyword}&page=1&s=${
    sortVal ? sortVal.path : ''
  }&d=${sortVal ? sortVal.direction : ''}`;
  if (filterType === 'subcategory') {
    newSearchParam = `${newSearchParam}&sub=${[
      ...searchState.filterSubcategoriesParam,
    ].toString()}`;
  } else {
    newSearchParam = `${newSearchParam}&c=${[
      ...searchState.filterParam,
    ].toString()}`;
  }
  history.replace({ search: newSearchParam });

  setSearchPaginationPage(1);

  dispatchSearchAction({
    type: KB_ACTION.SORT_UPDATED,
    payload: {
      value: sortVal ? sortVal.path : null,
      sortDirection: sortVal ? sortVal.direction : null,
    },
  });
};

export const handleSearchPaginateClick = (
  docsPerPage: number,
  KB_ASSETS_URL: string,
  searchState: IKBSearchState,
  setSearchURL: any,
  dispatchSearchAction: any,
  setSearchPaginationPage: any,
  val: number,
  filterType: string,
  history: any
) => {
  const newSkip = docsPerPage * (val - 1);

  // Update Pagination component state
  setSearchPaginationPage(val);

  // Update search state
  dispatchSearchAction({
    type: KB_ACTION.PAGE_CLICKED,
    payload: { newSkip },
  });

  const queryParam = new KBSearchParamBuilder('KB_Documents')
    .setAction('search')
    .setKeyword(searchState.keyword ? searchState.keyword.split(' ') : [ '' ])
    .setFilterCategories(searchState.filterParam)
    .setFilterSubcategories(searchState.filterSubcategoriesParam)
    .setSortBy(searchState.sortBy)
    .setSortDirection(searchState.sortDirection)
    .setTop(docsPerPage)
    .setSkip(newSkip)
    .setShowDescription(true)
    .setFilterTypeCount(filterType);

  // Update state
  setSearchURL(`${KB_ASSETS_URL}${queryParam.build().toString()}`);
  // Update url params
  let newSearchParam = `q=${searchState.keyword}&page=${val}&s=${searchState.sortBy}&d=${searchState.sortDirection}`;
  if (filterType === 'subcategory') {
    newSearchParam = `${newSearchParam}&sub=${[
      ...searchState.filterSubcategoriesParam,
    ].toString()}`;
  } else {
    newSearchParam = `${newSearchParam}&c=${[
      ...searchState.filterParam,
    ].toString()}`;
  }
  history.replace({ search: newSearchParam });

  // Scroll back to top
  if (typeof window !== 'undefined') {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // Fallback for older browsers
      window.scrollTo(0, 0);
    }
  }
};

export const handleDeleteKBFormSubmit = async (
  fileId: string,
  access_token: string
) => new Promise<IDataObject>(async (resolve, reject) => {
  if (!fileId) {
    reject(new Error('No File ID provided'));
  }

  try {
    const result = await axios.delete(
      `${KB_ASSETS_URL}/${fileId}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          [Header.AUTH_TYPE]: getAuthType(),
        },
      }
    );
    resolve(result.data);
  } catch (e) {
    reject(e);
  }
});

/**
 * Returns the video URL of the embedded video from the raw embedded string
 */
export const getVideoURL = (embeddedVideoRaw: string, videoType: KBEmbeddedVideoType): string => {
  switch (videoType) {
    case KBEmbeddedVideoType.VIMEO: {
      const match = embeddedVideoRaw.match(EMBEDDED_VIDEO_VIMEO_MATCHER_REGEX);
      if (!match) {
        return '';
      }
      const [ _, videoId, videoHash ] = match;
      return videoId.length && videoHash.length ? `https://player.vimeo.com/video/${videoId}?h=${videoHash}` : '';
    }
    case KBEmbeddedVideoType.UNKNOWN:
    default:
      return '';
  }
};

/**
 * Returns the thumbnail URL of the embedded video from the video URL
 */
export const fetchThumbnailURL = async (videoURL: string, videoType: KBEmbeddedVideoType): Promise<string> => {
  let thumbnailURL = '';

  try {
    switch (videoType) {
      case KBEmbeddedVideoType.VIMEO: {
        if (!videoURL) {
          throw Error('Could not get video URL');
        }

        const resp = await axios.get(
          `https://vimeo.com/api/oembed.json?url=${videoURL}`
        );

        if (resp.status !== 200) {
          throw Error('Could not find thumbnail');
        }

        const respJSON = resp.data;
        thumbnailURL = respJSON?.thumbnail_url || '';
        break;
      }
      case KBEmbeddedVideoType.UNKNOWN:
      default:
        thumbnailURL = '';
        break;
    }
  } catch (e) {
    thumbnailURL = '';
  }

  return thumbnailURL;
};
