import { ModuleNames } from './contentful.constants';

export interface InternalUrl {
  slug?: string;
  __typename: string;
  blogCategory?: {
    seoMetadata: {
      pageCanonicalUrl: string;
    };
  };
}

export const internalLinkHandler = (internalLink: InternalUrl): string => {
  // set all slugs to begin with a '/' if it doesnt already begin with it...
  const slug =
    internalLink.slug && !internalLink.slug.startsWith('/')
      ? `/${internalLink.slug}`
      : internalLink.slug;

  switch (internalLink.__typename) {
    case 'ContentfulPageBlogPost': {
      // Grabs the canonicalUrl => 'uipath.com/blog/rpa' -> ['uipath.com', '/blog/rpa'] -> '/blog/rpa'
      const canonicalUrl =
        internalLink.blogCategory?.seoMetadata.pageCanonicalUrl.split('.com')[1];
      return `${canonicalUrl}${slug}`;
    }
    case 'ContentfulPageMainBlog':
      return `/blog`;
    default:
      return `${slug}`;
  }
};

export const createLinkBasedOnType = (item: any) => {
  if (!item) {
    return '/';
  }

  if (
    [
      ModuleNames.PAGE_PRODUCT,
      ModuleNames.PAGE_FLEX,
    ].includes(item.__typename)
  ) {
    return `/${item.slug}`;
  } else if (item.__typename === ModuleNames.TOPIC_CTA && item.externalUrl) {
    return `${item.externalUrl}`;
  } else if (item.__typename === ModuleNames.TOPIC_CTA && item.internalLink) {
    if (
      [
        ModuleNames.PAGE_PRODUCT,
        ModuleNames.PAGE_FLEX,
      ].includes(item.internalLink.__typename)
    ) {
      if (item.internalLink.slug.charAt(0) === '/') {
        return item.internalLink.slug;
      }
      return `/${item.internalLink.slug}`;

    }
  }
};
