import type {
  FacetValue,
  SearchEngine,
} from '@coveo/headless';
import React, {
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  FACET_FILTER_TYPES_TO_LABELS,
  FACETS_COMPONENT_PROPS,
} from './constants';
import FacetFilter from './FacetFilter/FacetFilter';
import FacetsMenu from './FacetsMenu/FacetsMenu';
import { FacetFilterTypes } from './types';

interface FacetValuesSelected {
  [faceValue: string]: FacetValue;
}

interface FacetsSelected {
  [facetId: string]: FacetValuesSelected;
}

interface Props {
  engine: SearchEngine;
}

const FacetsSection = (props: Props) => {
  const { engine } = props;

  const [
    facetValuesToToggle,
    setFacetValuesToToggle,
  ] = useState<FacetsSelected>({});
  const { t } = useTranslation('common');

  const updateFacetValuesToToggle = useCallback(
    (facetId: string, facetValues: FacetValue[]) => {
      const updatedFacetValuesSelected = facetValues.reduce(
        (allFacetValuesInteracted: FacetValuesSelected, currFacetValue) => {
          allFacetValuesInteracted[currFacetValue.value] = { ...currFacetValue } as FacetValue;
          return allFacetValuesInteracted;
        },
        {}
      );

      setFacetValuesToToggle((prev) => ({
        ...prev,
        [facetId]: !prev[facetId]
          ? updatedFacetValuesSelected
          : {
            ...prev[facetId],
            ...updatedFacetValuesSelected,
          },
      }));
    },
    [ facetValuesToToggle ]
  );

  return (
    <FacetsMenu engine={engine}>
      {Object.values(FacetFilterTypes).map((facetFilterType) => {
        const {
          label, searchLabel,
        } = FACET_FILTER_TYPES_TO_LABELS[facetFilterType];
        const facetHeaderLabel = t(label.textKey, label.fallbackText);
        const facetId = `${facetFilterType}`;
        const facetComponentProps = FACETS_COMPONENT_PROPS[facetFilterType];

        return (
          <FacetFilter
            engine={engine}
            field={facetFilterType}
            facetId={facetId}
            headerLabel={facetHeaderLabel}
            key={facetId}
            hasSelectAll
            asyncToggleFunction={updateFacetValuesToToggle}
            {...searchLabel && { searchPlaceholder: t(searchLabel.textKey, searchLabel.fallbackText) }}
            {...facetComponentProps}
          />
        );
      })}
    </FacetsMenu>
  );
};

export default FacetsSection;
