import React from 'react';

import * as styles from '../../../assets/css/HAPO/RequestHistory/OrderStatus';
import Kebab from '../../../assets/img/svg/action_icons/More.svg';
import {
  CANCELED,
  DISPLAY_STATUSES,
  PENDING,
  PROVISIONED,
} from '../../../constants/hapo.constants';
import { UserPermissionsHelper } from './../../../lib/UserPermissions';

const OrderStatus = (props: any) => {
  const orderId = props.orderId;
  const orderStatus = props.status;
  const isCancelHAPOOrderAllowed = UserPermissionsHelper.isCancelHAPOOrderAllowed();
  const isCompleteHAPOOrderAllowed = UserPermissionsHelper.isCompleteHAPOOrderAllowed();

  /**
   * There are only two options atm. Do not show the kebab if no options to choose from
   */
  let displayMenu = false;
  if ((orderStatus === PENDING && isCancelHAPOOrderAllowed) ||
    (orderStatus === PENDING && isCompleteHAPOOrderAllowed) ||
    (orderStatus === PROVISIONED && isCancelHAPOOrderAllowed) ||
    ((orderStatus === PENDING || orderStatus === PROVISIONED) && isCompleteHAPOOrderAllowed)
  ) {
    displayMenu = true;
  }

  const generateOrderStatus = () => {
    switch (orderStatus) {
      case PENDING:
        return (
          <>
            <span
              className="RequestHistoryListLabel RequestHistoryListLabel--inProgress"
              data-testid="Order_Status">
              {DISPLAY_STATUSES.pending}
            </span>
            {displayMenu && (
              <img
                src={Kebab}
                alt="Menu Opener"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={e => {
                  props.handleAttributeParentClick(
                    e.target,
                    orderId,
                    orderStatus
                  );
                }}
                data-testid="HapoOrderStatusMenuButton"
              />
            )}
          </>
        );

      case PROVISIONED:
        return (
          <>
            <styles.OrderStatus
              className="RequestHistoryListLabel RequestHistoryListLabel--provisioned"
              data-testid="Order_Status">
              {DISPLAY_STATUSES.provisioned}
            </styles.OrderStatus>
            {displayMenu && (
              <img
                src={Kebab}
                alt="Menu Opener"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={e => {
                  props.handleAttributeParentClick(
                    e.target,
                    orderId,
                    orderStatus
                  );
                }}
                data-testid="HapoOrderStatusMenuButton"
              />
            )}
          </>
        );

      case CANCELED:
        return (
          <span
            className="RequestHistoryListLabel RequestHistoryListLabel--cancelled"
            data-testid="Order_Status">
            {DISPLAY_STATUSES.canceled}
          </span>
        );

      default:
        return (
          <span
            className="RequestHistoryListLabel RequestHistoryListLabel--cancelled"
            data-testid="Order_Status">
            Not Defined
          </span>
        );
    }
  };

  return (
    <div
      className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestStatus"
      data-testid="HapoOrderStatus_wrapper"
    >
      {generateOrderStatus()}
    </div>
  );
};

export default OrderStatus;
