export const UNLIMITED_PRODUCT_QUANTITY = 1000000;

export const HAPOELA_PROD_COMMERCIAL_NAME = 'HAPO/ELA Production';
export const HAPOELA_NONPROD_COMMERCIAL_NAME = 'HAPO/ELA NonProduction';
export const UTO_COMMERCIAL_NAME = 'UiPath Tailored Offering';

export type CommercialName = {
  // License Bundles Mapping Code
  CRPA?: {
    // Subscription commercial Name
    NonProduction?: string;
    Production?: string;
    default: string;
  };
  PSF?: {
    Production?: string;
    default: string;
  };
  default: {
    NonProduction?: string;
    Production?: string;
    default: string;
  };
};

export type BundleCommercialName = {
  default: {
    NonProduction?: string;
    Production?: string;
    'Production Basic'?: string;
    'HAPO/ELA'?: string;
    'HAPO/ELA NonProduction'?: string;
    default: string;
  };
};

export const licenseBundlesMapping: {
  [key: string]: {
    licenseModel: string;
    boonName: string;
    commercialName: BundleCommercialName;
  };
} = {
  CEH: {
    licenseModel: 'ACCOUNT',
    boonName: 'Automation Hub',
    commercialName: {
      default: {
        Production: 'Cloud Automation Hub - Standard',
        default: 'Cloud Automation Hub - Standard',
      },
    },
  },
  'PG-PLATFORM': {
    licenseModel: 'INSTANCE',
    boonName: 'ProcessGold Platfom',
    commercialName: {
      default: {
        Production: 'Process Mining - Production',
        'HAPO/ELA': 'Process Mining - Production',
        'HAPO/ELA NonProduction': 'Process Mining - NonProduction',
        NonProduction: 'Process Mining - NonProduction',
        default: 'Process Mining',
      },
    },
  },
  UIP: {
    licenseModel: 'INSTANCE',
    boonName: 'UiPath Platform / Server',
    commercialName: {
      default: {
        NonProduction: 'Orchestrator - NonProduction',
        'HAPO/ELA': 'Orchestrator - Standard',
        'HAPO/ELA NonProduction': 'Orchestrator - NonProduction',
        'Production Basic': 'Orchestrator - Basic',
        Production: 'Orchestrator - Standard',
        default: 'Orchestrator - Standard',
      },
    },
  },
  CRPA: {
    licenseModel: 'INSTANCE',
    boonName: 'UiPath Flex Plan Bundle',
    commercialName: {
      default: {
        Production: 'UiPath - Flex Plan Bundle',
        'HAPO/ELA': 'UiPath - Flex Plan Bundle',
        default: 'UiPath - Flex Plan Bundle',
      },
    },
  },
  PSF: {
    licenseModel: 'ACCOUNT',
    boonName: 'Platform Service Fabric',
    commercialName: {
      default: {
        Production: 'Platform Service Fabric',
        default: 'Platform Service Fabric',
      },
    },
  },
};

export const licenseBundlesShowProducts: any = [
  'CEH',
  'PG-PLATFORM',
  'UIP',
  'CRPA',
  'PSF',
];

// Standalones
export const productFeaturesBundlesMapping: {
  [key: string]: {
    licenseModel: string;
    display: boolean;
    boonName: string;
    commercialName: BundleCommercialName;
  };
} = {
  'ATTNU-L': {
    licenseModel: 'MACHINE',
    display: false,
    boonName: 'Attended Named User - Legacy',
    commercialName: {
      default: {
        Production:
          'Attended - Named User <br/> and/or Attended Robot - Named User',
        NonProduction:
          'Attended - Named User <br/> and/or Attended Robot - Named User',
        default:
          'Attended - Named User <br/> and/or Attended Robot - Named User',
      },
    },
  },
  ATTNU: {
    licenseModel: 'USER',
    display: false,
    boonName: 'Attended Robot Named User',
    commercialName: {
      default: {
        Production:
          'Attended - Named User <br/> and/or Attended Robot - Named User',
        NonProduction:
          'Attended - Named User <br/> and/or Attended Robot - Named User',
        default:
          'Attended - Named User <br/> and/or Attended Robot - Named User',
      },
    },
  },
  ATTNL: {
    licenseModel: 'MACHINE',
    display: false,
    boonName: 'Attended Robot NodeLocked',
    commercialName: {
      default: {
        Production: 'Attended Robot - Node Locked',
        default: 'Attended Robot - Node Locked',
      },
    },
  },
  EE: {
    licenseModel: 'USER',
    display: false,
    boonName: 'Explorer Expert',
    commercialName: {
      default: {
        Production: 'Task Capture',
        default: 'Task Capture',
      },
    },
  },
  NONPR: {
    licenseModel: 'MACHINE',
    display: false,
    boonName: 'NonProduction Robot',
    commercialName: {
      default: {
        NonProduction: 'Robot - NonProduction',
        default: 'Robot - NonProduction',
      },
    },
  },
  STD: {
    licenseModel: 'USER',
    display: false,
    boonName: 'Studio Named User',
    commercialName: {
      default: {
        Production: 'RPA Developer - Named User <br/> and/or Studio Named User',
        default: 'RPA Developer - Named User <br/> and/or Studio Named User',
      },
    },
  },
  'STD-L': {
    licenseModel: 'MACHINE',
    display: false,
    boonName: 'Studio Named User - Legacy',
    commercialName: {
      default: {
        Production: 'RPA Developer - Named User <br/> and/or Studio Named User',
        default: 'RPA Developer - Named User <br/> and/or Studio Named User',
      },
    },
  },
  STDNL: {
    licenseModel: 'MACHINE',
    display: false,
    boonName: 'Studio NodeLocked',
    commercialName: {
      default: {
        Production: 'Studio - Node Locked',
        default: 'Studio - Node Locked',
      },
    },
  },
  UNATT: {
    licenseModel: 'MACHINE',
    display: false,
    boonName: 'Unattended Robot',
    commercialName: {
      default: {
        Production:
          'Unattended Robot - Concurrent Runtime <br/> and/or Unattended Robot - Node Locked',
        default:
          'Unattended Robot - Concurrent Runtime <br/> and/or Unattended Robot - Node Locked',
      },
    },
  },
  'CV-SERVER': {
    licenseModel: 'INSTANCE',
    boonName: 'Computer Vision Server',
    display: true,
    commercialName: {
      default: {
        Production: 'Computer Vision - On Prem',
        default: 'Computer Vision - On Prem',
      },
    },
  },
};

export const subscriptionMapping: any = {
  ENT: {
    boonName: 'Enterprise',
    commercialName: 'Production',
  },
  'ENT-NONPR': {
    boonName: 'Enterprise-NonProd',
    commercialName: 'NonProduction',
  },
  'ENT-STD': {
    boonName: 'Standard',
    commercialName: 'Production',
  },
  TRIAL: {
    boonName: 'Trial',
    commercialName: 'Trial',
  },
  NFR: {
    boonName: 'Not For Resale',
    commercialName: 'Not for Resale',
  },
  COM: {
    boonName: 'Community',
    commercialName: 'Community',
  },
  ENTX: {
    boonName: 'EnterpriseX',
    commercialName: 'Production',
  },
  'ENT-BASIC': {
    boonName: 'Enterprise-Basic',
    commercialName: 'Production Basic',
  },
  ENTPRO: {
    boonName: 'EnterprisePro',
    commercialName: 'Production',
  },
  'ENT-UPG': {
    boonName: 'Upgrade license',
    commercialName: 'Production',
  },
  'ENT-HAPOELA': {
    boonName: 'HAPOELA',
    commercialName: HAPOELA_PROD_COMMERCIAL_NAME,
  },
  'ENT-UIPATH': {
    boonName: 'UiPathers',
    commercialName: 'Internal license',
  },
  AAE: {
    boonName: 'Academic Alliance Edition',
    commercialName: 'Academic Alliance Edition',
  },
  'ENT-NFR': {
    boonName: 'Not For Resale',
    commercialName: 'Not for Resale',
  },
  'ENT-EXT': {
    boonName: 'Partner external license',
    commercialName: 'Production',
  },
  UIP: {
    boonName: 'UiPathers',
    commercialName: 'Internal license',
  },
  KIT: {
    boonName: 'Free Plan',
    commercialName: 'Free Plan',
  },
  'ENT-TEMP': {
    boonName: 'Enterprise-Temp',
    commercialName: 'Production',
  },
  'TRIAL-AHUB': {
    boonName: 'Trial Per Service - AutomationHub',
    commercialName: 'Trial Per Service - AutomationHub',
  },
  'TRIAL-ANL': {
    boonName: 'Trial Per Service - Insights',
    commercialName: 'Trial Per Service - Insights',
  },
  'TRIAL-PM': {
    boonName: 'Trial Per Service - ProcessMining',
    commercialName: 'Trial Per Service - ProcessMining',
  },
  UiCLOUD: {
    boonName: 'UiPathers',
    commercialName: 'Internal license',
  },
  'ENT-HAPOELANP': {
    boonName: 'HAPOELA-NonProd',
    commercialName: HAPOELA_NONPROD_COMMERCIAL_NAME,
  },
  'ENT-HAPOELAPRO': {
    boonName: 'Automation Dev - HAPOELAPRO',
    commercialName: HAPOELA_PROD_COMMERCIAL_NAME,
  },
  'ENT-HAPOELAX': {
    boonName: 'Citizen Dev - HAPOELAX',
    commercialName: HAPOELA_PROD_COMMERCIAL_NAME,
  },
  'ENT-UTO': {
    boonName: 'UiPath Tailored Offering',
    commercialName: UTO_COMMERCIAL_NAME,
  },
  PRO: {
    boonName: 'Pro',
    commercialName: 'Pro',
  },
  'PRO-TRIAL': {
    boonName: 'Pro Trial',
    commercialName: 'Pro Trial',
  },
  'TRIAL-RU': {
    boonName: 'Trial Per Product - RobotUnits',
    commercialName: 'Trial Per Product - RobotUnits',
  },
};

export const productsMapping: {
  [key: string]: {
    boonName: string;
    display: boolean;
    commercialName: CommercialName;
  };
} = {
  ABBYY: {
    boonName: 'Abbyy Document OCR',
    display: false,
    commercialName: {
      CRPA: {
        NonProduction: 'Not available yet',
        Production: 'Not available yet',
        default: 'Not available yet',
      },
      default: {
        NonProduction: 'Not available yet',
        Production: 'Not available yet',
        default: 'Not available yet',
      },
    },
  },
  ATTCU: {
    boonName: 'Attended Robot Concurrent User/ NL',
    display: true,
    commercialName: {
      CRPA: {
        Production:
          'Cloud Orchestrated Attended - Multiuser and/or Cloud Orchestrated Attended Robot Concurrent',
        default:
          'Cloud Orchestrated Attended - Multiuser and/or Cloud Orchestrated Attended Robot Concurrent',
      },
      default: {
        Production: 'Attended - Multiuser and/or Attended Robot Concurrent',
        default: 'Attended - Multiuser and/or Attended Robot Concurrent',
      },
    },
  },
  ATTNU: {
    boonName: 'Attended Robot Named User',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction:
          'Cloud Orchestrated Attended - Named User and/or Cloud Orchestrated Attended Robot - Named User',
        Production:
          'Cloud Orchestrated Attended - Named User and/or Cloud Orchestrated Attended Robot - Named User',
        default:
          'Cloud Orchestrated Attended - Named User and/or Cloud Orchestrated Attended Robot - Named User',
      },
      default: {
        NonProduction:
          'Attended - Named User and/or Attended Robot - Named User',
        Production: 'Attended - Named User and/or Attended Robot - Named User',
        default: 'Attended - Named User and/or Attended Robot - Named User',
      },
    },
  },
  ANL: {
    boonName: 'Insights',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Insights - Automation Cloud Add-On',
        default: 'Insights - Automation Cloud Add-On',
      },
      PSF: {
        Production: 'Insights - Platform Service Add-On',
        default: 'Insights - Platform Service Add-On',
      },
      default: {
        NonProduction: 'Insights - Orchestrator Add-On - NonProduction',
        Production: 'Insights - Orchestrator Add-On',
        default: 'Insights - Orchestrator Add-On',
      },
    },
  },
  NONPR: {
    boonName: 'NonProduction Robot',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Robot - Non-Production',
        Production: 'Cloud Orchestrated Robot - Non-Production',
        default: 'Cloud Orchestrated Robot - Non-Production',
      },
      PSF: {
        Production: 'Robot - NonProduction',
        default: 'Robot - NonProduction',
      },
      default: {
        NonProduction: 'Robot - NonProduction',
        Production: 'Robot - NonProduction',
        default: 'Robot - NonProduction',
      },
    },
  },
  ORCH: {
    boonName: 'Orchestrator',
    display: false,
    commercialName: {
      CRPA: {
        NonProduction: 'Should not be displayed',
        Production: 'Should not be displayed',
        default: 'Should not be displayed',
      },
      default: {
        NonProduction: 'Should not be displayed',
        Production: 'Should not be displayed',
        default: 'Should not be displayed',
      },
    },
  },
  STDCU: {
    boonName: 'Studio Concurrent User/ NL',
    display: true,
    commercialName: {
      default: {
        Production: 'RPA Developer - Multiuser and/or Studio Concurrent',
        default: 'RPA Developer - Multiuser and/or Studio Concurrent',
      },
    },
  },
  STDNU: {
    boonName: 'Studio Named User',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction:
          'Cloud Orchestrated RPA Developer - Named User and/or Cloud Orchestrated Studio Named User',
        Production:
          'Cloud Orchestrated RPA Developer - Named User and/or Cloud Orchestrated Studio Named User',
        default:
          'Cloud Orchestrated RPA Developer - Named User and/or Cloud Orchestrated Studio Named User',
      },
      default: {
        NonProduction: 'RPA Developer - Named User and/or Studio Named User',
        Production: 'RPA Developer - Named User and/or Studio Named User',
        default: 'RPA Developer - Named User and/or Studio Named User',
      },
    },
  },
  STDXCU: {
    boonName: 'StudioX Concurrent User/ NL',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Cloud Orchestrated Citizen Developer - Multiuser',
        default: 'Cloud Orchestrated Citizen Developer - Multiuser',
      },
      default: {
        Production: 'Citizen Developer - Multiuser',
        default: 'Citizen Developer - Multiuser',
      },
    },
  },
  STDXNU: {
    boonName: 'StudioX Named User',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Cloud Orchestrated Citizen Developer - Named User',
        default: 'Cloud Orchestrated Citizen Developer - Named User',
      },
      default: {
        NonProduction: 'Citizen Developer - Named User',
        Production: 'Citizen Developer - Named User',
        default: 'Citizen Developer - Named User',
      },
    },
  },
  TAUNATT: {
    boonName: 'Testing',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Cloud Orchestrated Testing Robot',
        default: 'Cloud Orchestrated Testing Robot',
      },
      PSF: {
        Production: 'Testing Robot',
        default: 'Testing Robot',
      },
      default: {
        NonProduction: 'Testing Robot',
        Production: 'Testing Robot',
        default: 'Testing Robot',
      },
    },
  },
  TAU: {
    boonName: 'Test Automation User',
    display: true,
    commercialName: {
      default: {
        NonProduction: 'Tester - Named User',
        Production: 'Tester - Named User',
        default: 'Tester - Named User',
      },
    },
  },
  STDPROCU: {
    boonName: 'StudioPro Concurrent User',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Cloud Orchestrated - RPA Developer Pro - Multiuser',
        default: 'Cloud Orchestrated - RPA Developer Pro - Multiuser',
      },
      default: {
        Production: 'RPA Developer Pro - Multiuser',
        default: 'RPA Developer Pro - Multiuser',
      },
    },
  },
  STDPRONU: {
    boonName: 'StudioPro Named User',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Cloud Orchestrated - RPA Developer Pro - Named User',
        default: 'Cloud Orchestrated - RPA Developer Pro - Named User',
      },
      default: {
        NonProduction: 'RPA Developer Pro - Named User',
        Production: 'RPA Developer Pro - Named User',
        default: 'RPA Developer Pro - Named User',
      },
    },
  },
  APPS: {
    boonName: 'Action Center',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Cloud Orchestrated Action Center',
        default: 'Cloud Orchestrated Action Center',
      },
      default: {
        Production: 'Action Center - Multiuser',
        default: 'Action Center',
      },
    },
  },
  UNATT: {
    boonName: 'Unattended Robot',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Unattended Robot',
        default: 'Unattended Robot',
      },
      PSF: {
        Production: 'Unattended Robot',
        default: 'Unattended Robot',
      },
      default: {
        Production:
          'Unattended Robot - Concurrent Runtime and/or Unattended Robot - Node Locked',
        default:
          'Unattended Robot - Concurrent Runtime and/or Unattended Robot - Node Locked',
      },
    },
  },
  AIW: {
    boonName: 'AI Robot',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Cloud - AI Robot',
        default: 'Cloud - AI Robot',
      },
      PSF: {
        Production: 'AI Robot',
        default: 'AI Robot',
      },
      default: {
        Production: 'AI Robot',
        default: 'AI Robot',
      },
    },
  },
  'CEH-B-USR': {
    boonName: 'CEH - Business Users',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'AH - Business Users',
        default: 'AH - Business Users',
      },
      default: {
        Production: 'AH - Business Users',
        default: 'AH - Business Users',
      },
    },
  },
  'CEH-COE-USR': {
    boonName: 'CEH - COE Users',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'AH - COE Users',
        default: 'AH - COE Users',
      },
      default: {
        Production: 'AH - COE Users',
        default: 'AH - COE Users',
      },
    },
  },
  CV: {
    boonName: 'Computer Vision',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Computer Vision - On Cloud',
        default: 'Computer Vision - On Cloud',
      },
      PSF: {
        Production: 'Computer Vision',
        default: 'Computer Vision',
      },
      default: {
        Production: 'Computer Vision - On Cloud',
        default: 'Computer Vision - On Cloud',
      },
    },
  },
  TIE: {
    boonName: 'Document Understanding',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Cloud Document Understanding Page Bundle',
        default: 'Cloud Document Understanding Page Bundle',
      },
      PSF: {
        Production: 'Document Understanding Page Bundle',
        default: 'Document Understanding Page Bundle',
      },
      default: {
        Production: 'Cloud Document Understanding Page Bundle',
        default: 'Cloud Document Understanding Page Bundle',
      },
    },
  },
  'DU-SRV': {
    boonName: 'Document Understanding On-Prem',
    display: true,
    commercialName: {
      default: {
        Production: 'Document Understanding Page Bundle',
        default: 'Document Understanding Page Bundle',
      },
    },
  },
  AIG: {
    boonName: 'GPU',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Cloud - AI Robot Pro',
        default: 'Cloud - AI Robot Pro',
      },
      PSF: {
        Production: 'AI Robot Pro',
        default: 'AI Robot Pro',
      },
      default: {
        Production: 'AI Robot Pro',
        default: 'AI Robot Pro',
      },
    },
  },
  ORCHT: {
    boonName: 'Platform Tenants',
    display: false,
    commercialName: {
      default: {
        Production: 'Should not be displayed',
        default: 'Should not be displayed',
      },
    },
  },
  'PG-APPLICATION': {
    boonName: 'ProcessMining Active Applications',
    display: true,
    commercialName: {
      default: {
        Production: 'Process Mining Active Applications',
        default: 'Process Mining Active Applications',
      },
    },
  },
  'PG-DEVELOPER': {
    boonName: 'ProcessMining Developer User',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Cloud Orchestrated Process Mining Developer - Named User',
        default: 'Cloud Orchestrated Process Mining Developer - Named User',
      },
      default: {
        Production: 'Process Mining Developer - Named User',
        default: 'Process Mining Developer - Named User',
      },
    },
  },
  'PG-ENDUSER': {
    boonName: 'ProcessMining User',
    display: true,
    commercialName: {
      default: {
        Production: 'Process Mining Business User - Named User',
        default: 'Process Mining Business User - Named User',
      },
    },
  },
  'PG-PROCESS': {
    boonName: 'ProcessMining Process',
    display: true,
    commercialName: {
      default: {
        Production: 'Process Mining Process',
        default: 'Process Mining Process',
      },
    },
  },
  AHUB: {
    boonName: 'Automation Hub',
    display: true,
    commercialName: {
      CRPA: {
        Production: 'Automation Hub',
        default: 'Automation Hub',
      },
      PSF: {
        Production: 'Automation Hub',
        default: 'Automation Hub',
      },
      default: {
        Production: 'Automation Hub',
        default: 'Automation Hub',
      },
    },
  },
  ATTUCU: {
    boonName: 'Attended - Multiuser',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Attended Multiuser',
        Production: 'Cloud Orchestrated Attended Multiuser',
        default: 'Cloud Orchestrated Attended Multiuser',
      },
      PSF: {
        Production: 'Attended Multiuser',
        default: 'Attended Multiuser',
      },
      default: {
        Production: 'Attended Multiuser',
        default: 'Attended Multiuser',
      },
    },
  },
  ATTUNU: {
    boonName: 'Attended - Named User',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Attended User',
        Production: 'Cloud Orchestrated Attended User',
        default: 'Cloud Orchestrated Attended User',
      },
      PSF: {
        Production: 'Attended User',
        default: 'Attended User',
      },
      default: {
        Production: 'Attended User',
        default: 'Attended User',
      },
    },
  },
  CTZDEVCU: {
    boonName: 'Citizen Developer - Multiuser',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Citizen Developer Multiuser',
        Production: 'Cloud Orchestrated Citizen Developer Multiuser',
        default: 'Cloud Orchestrated Citizen Developer Multiuser',
      },
      PSF: {
        Production: 'Citizen Developer Multiuser',
        default: 'Citizen Developer Multiuser',
      },
      default: {
        Production: 'Citizen Developer Multiuser',
        default: 'Citizen Developer Multiuser',
      },
    },
  },
  CTZDEVNU: {
    boonName: 'Citizen Developer - Named User',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Citizen Developer User',
        Production: 'Cloud Orchestrated Citizen Developer User',
        default: 'Cloud Orchestrated Citizen Developer User',
      },
      PSF: {
        Production: 'Citizen Developer User',
        default: 'Citizen Developer User',
      },
      default: {
        Production: 'Citizen Developer User',
        default: 'Citizen Developer User',
      },
    },
  },
  RPADEVCU: {
    boonName: 'RPA Developer - Multiuser',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated RPA Developer Mutliuser',
        Production: 'Cloud Orchestrated RPA Developer Mutliuser',
        default: 'Cloud Orchestrated RPA Developer Mutliuser',
      },
      PSF: {
        Production: 'RPA Developer Multiuser',
        default: 'RPA Developer Multiuser',
      },
      default: {
        Production: 'RPA Developer Multiuser',
        default: 'RPA Developer Multiuser',
      },
    },
  },
  RPADEVNU: {
    boonName: 'RPA Developer - Named User',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated RPA Developer User',
        Production: 'Cloud Orchestrated RPA Developer User',
        default: 'Cloud Orchestrated RPA Developer User',
      },
      PSF: {
        Production: 'RPA Developer User',
        default: 'RPA Developer User',
      },
      default: {
        Production: 'RPA Developer User',
        default: 'RPA Developer User',
      },
    },
  },
  RPADEVPROCU: {
    boonName: 'RPA Developer Pro - Multiuser',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Automation Developer or RPA Developer Pro Multiuser',
        Production: 'Cloud Orchestrated Automation Developer or RPA Developer Pro Multiuser',
        default: 'Cloud Orchestrated Automation Developer or RPA Developer Pro Multiuser',
      },
      PSF: {
        Production: 'Automation Developer or RPA Developer Pro Multiuser',
        default: 'Automation Developer or RPA Developer Pro Multiuser',
      },
      default: {
        Production: 'Automation Developer or RPA Developer Pro Multiuser',
        default: 'Automation Developer or RPA Developer Pro Multiuser',
      },
    },
  },
  RPADEVPRONU: {
    boonName: 'RPA Developer Pro - Named User',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Automation Developer or RPA Developer Pro User',
        Production: 'Cloud Orchestrated Automation Developer or RPA Developer Pro User',
        default: 'Cloud Orchestrated Automation Developer or RPA Developer Pro User',
      },
      PSF: {
        Production: 'Automation Developer or RPA Developer Pro User',
        default: 'Automation Developer or RPA Developer Pro User',
      },
      default: {
        Production: 'Automation Developer or RPA Developer Pro User',
        default: 'Automation Developer or RPA Developer Pro User',
      },
    },
  },
  IDU: {
    boonName: 'Insights Designer User',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Insights Designer User',
        Production: 'Cloud Orchestrated Insights Designer User',
        default: 'Cloud Orchestrated Insights Designer User',
      },
      PSF: {
        Production: 'Insights Designer User',
        default: 'Insights Designer User',
      },
      default: {
        Production: 'Insights Designer User',
        default: 'Insights Designer User',
      },
    },
  },
  TMNG: {
    boonName: 'Task Mining',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Task Mining',
        Production: 'Cloud Orchestrated Task Mining',
        default: 'Cloud Orchestrated Task Mining',
      },
      PSF: {
        Production: 'Task Mining',
        default: 'Task Mining',
      },
      default: {
        Production: 'Task Mining',
        default: 'Task Mining',
      },
    },
  },
  TSTDEVPRONU: {
    boonName: 'Test Developer Pro - Named User',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Test Developer Pro User',
        Production: 'Cloud Orchestrated Test Developer Pro User',
        default: 'Cloud Orchestrated Test Developer Pro User',
      },
      PSF: {
        Production: 'Test Developer Pro User',
        default: 'Test Developer Pro User',
      },
      default: {
        Production: 'Test Developer Pro User',
        default: 'Test Developer Pro User',
      },
    },
  },
  TSTNU: {
    boonName: 'Test - Named User',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Tester User',
        Production: 'Cloud Orchestrated Tester User',
        default: 'Cloud Orchestrated Tester User',
      },
      PSF: {
        Production: 'Tester User',
        default: 'Tester User',
      },
      default: {
        Production: 'Tester User',
        default: 'Tester User',
      },
    },
  },
  ACCU: {
    boonName: 'Action Center - Multiuser',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Action Center Multiuser',
        Production: 'Cloud Orchestrated Action Center Multiuser',
        default: 'Cloud Orchestrated Action Center Multiuser',
      },
      default: {
        Production: 'Cloud Orchestrated Action Center Multiuser',
        default: 'Cloud Orchestrated Action Center Multiuser',
      },
    },
  },
  ACNU: {
    boonName: 'Action Center - Named User',
    display: true,
    commercialName: {
      CRPA: {
        NonProduction: 'Cloud Orchestrated Action Center User',
        Production: 'Cloud Orchestrated Action Center User',
        default: 'Cloud Orchestrated Action Center User',
      },
      default: {
        Production: 'Cloud Orchestrated Action Center User',
        default: 'Cloud Orchestrated Action Center User',
      },
    },
  },
  RU: {
    boonName: 'Robot Units',
    display: true,
    commercialName: {
      CRPA: { default: 'Robot Units' },
      default: { default: 'Robot Units' },
    },
  },
  AIU: {
    boonName: 'AI Units',
    display: true,
    commercialName: {
      CRPA: { default: 'AI Units' },
      default: { default: 'AI Units' },
    },
  },
  AKIT: {
    boonName: 'Automation Express',
    display: true,
    commercialName: {
      CRPA: { default: 'Automation Express' },
      default: { default: 'Automation Express' },
    },
  },
  TMH: {
    boonName: 'Test Manager',
    display: true,
    commercialName: {
      CRPA: { default: 'Test Manager' },
      default: { default: 'Test Manager' },
    },
  },
  PMD: {
    boonName: 'Process Mining Developer',
    display: true,
    commercialName: {
      CRPA: { default: 'Process Mining Developer' },
      default: { default: 'Process Mining Developer' },
    },
  },
  PMBU: {
    boonName: 'Process Mining Business User',
    display: true,
    commercialName: {
      CRPA: { default: 'Process Mining Business User' },
      default: { default: 'Process Mining Business User' },
    },
  },
  ACR: {
    boonName: 'Automation Cloud Robot',
    display: false,
    commercialName: {
      CRPA: { default: 'Automation Cloud Robot' },
      default: { default: 'Automation Cloud Robot' },
    },
  },
  PM: {
    boonName: 'Process Mining',
    display: true,
    commercialName: {
      CRPA: { default: 'Process Mining' },
      default: { default: 'Process Mining' },
    },
  },
  TMU: {
    boonName: 'Test Manager',
    display: true,
    commercialName: {
      CRPA: { default: 'Test Management' },
      default: { default: 'Test Management' },
    },
  },
  STDPRO: {
    boonName: 'Studio Pro',
    display: true,
    commercialName: {
      CRPA: { default: 'Automation Developer' },
      default: { default: 'Automation Developer' },
    },
  },
  ATTR: {
    boonName: 'Attended Robot',
    display: true,
    commercialName: {
      CRPA: { default: 'Attended Named User' },
      default: { default: 'Attended Named User' },
    },
  },
};
