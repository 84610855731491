import styled from 'styled-components';

export const SelectAccountModalWrapper = styled.section`
 
  .No-Licenses-Text {
    color: #353c4a;
    text-align: center;
    background: ${p => p.theme.palette.grey[100]};
    border-radius: ${p => p.theme.spacing(1)}px;
    padding: ${p => p.theme.spacing(2)}px;
  }

  .Table-Row {
    cursor: pointer;
    &:hover {
        background-color: ${p => p.theme.palette.blue_secondary[100]};
      }
  }
`;
