import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const CustomerPortalLoaderStyle = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #414244;
  border-radius: 50%;
  margin: 10px;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
  -webkit-animation: ${spin} 2s linear infinite;
`;

/*
* This is a loader spinner for data
*/
const CustomerPortalLoader = () => (

  <CustomerPortalLoaderStyle data-testid="CustomerPortalLoaderStyle" />

);

export default CustomerPortalLoader;
