import React from 'react';

import {
  Icon,
  StickyMessageBannerContainer,
  StickyMessageBannerItem,
  StickyMessageCloseButtonSection,
  StickyMessageTextSection,
} from '../assets/css/Banners/StickyMessageBanner';

export type MessageType = 'info' | 'warning' | 'error';

interface Props {
  messages: Array<{
    type: MessageType;
    text: string;
  }>;
  handleCloseMessage: (index: number) => void;
}

const StickyMessageBanner = (props: Props) => {
  if (!props.messages.length) {
    return null;
  }

  return (
    <StickyMessageBannerContainer>
      {props.messages.map(({
        type, text,
      }, i) => (
        <StickyMessageBannerItem
          key={`${type}-${text}`}
          messageType={type}
        >
          {/* Text section */}
          <StickyMessageTextSection>
            <div className='FlexRowCenter'>
              <div className='FlexColumnCenter'>
                <Icon
                  className='MessageType'
                  icon={`${type}rounded`}
                />
              </div>
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </StickyMessageTextSection>

          {/* Close button section */}
          <StickyMessageCloseButtonSection>
            <div className='FlexColumnCenter'>
              <Icon
                icon='close'
                className='CloseButton'
                onClick={() => props.handleCloseMessage(i)}
              />
            </div>
          </StickyMessageCloseButtonSection>
        </StickyMessageBannerItem>
      ))}
    </StickyMessageBannerContainer>
  );
};

export default StickyMessageBanner;
