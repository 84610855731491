import type { SearchEngine } from '@coveo/headless';
import { buildQuerySummary } from '@coveo/headless';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import ResultCount from './ResultCount/ResultCount';
import ResultList from './ResultList/ResultList';

const Container = styled.div``;

interface Props {
  engine: SearchEngine;
}

const ResultsSection = (props: Props) => {
  const { engine } = props;
  const querySummaryControllerRef = useRef(buildQuerySummary(engine));
  const [ _, setQuerySummaryState ] = useState(
    querySummaryControllerRef.current.state
  );

  const setAndSubscribeController = useCallback(() => {
    const unsubscribeController = querySummaryControllerRef.current.subscribe(
      () => {
        setQuerySummaryState(querySummaryControllerRef.current.state);
      }
    );

    return () => {
      unsubscribeController();
    };
  }, []);

  useEffect(setAndSubscribeController, [ setAndSubscribeController ]);

  return (
    <Container>
      <ResultCount engine={engine} />
      <ResultList engine={engine} />
    </Container>
  );
};

export default ResultsSection;
