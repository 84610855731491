import type { IDataObject } from '../interfaces/dataObject.interface';

export const hapoEntitlementsReducer = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => {
  switch (action.type) {
    case 'FETCHED':
      return { ...action.payload };
    case 'RESET':
      return handleReset(state, action);
    case 'SKU_QUANTITY_CHANGED':
      return handleOnIndividualSkuQuantityChange(state, action);
    case 'GROUP_QUANTITY_CHANGED':
      return hanleOnGroupQuantityChange(state, action);
    case 'SKU_ADDED_TO_GROUP':
      return handleOnGroupSkuAdd(state, action);
    case 'SKU_ADDED':
      return handleOnSkuAdd(state, action);
    case 'GROUP_NAME_CHANGED':
      return handleOnChangeGroupName(state, action);
    case 'GROUP_ADDED':
      return handleOnAddGroup(state, action);
    case 'INDIVIDUAL_SKU_REMOVED':
      return handleOnDeleleIndividualSku(state, action);
    case 'GROUP_REMOVED':
      return handleOnDeleteGroup(state, action);
    case 'GROUP_SKU_REMOVED':
      return handleOnDeleteGroupSku(state, action);
    case 'MOVED':
      return handleMove(state, action);
    default:
      throw new Error('Unidentified reducer action type');
  }
};

const handleReset = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => ({
  ...state,
  groups: { ...action.payload?.config.groups },
  individual_skus: { ...action.payload?.config.individual_skus },
});

const handleOnIndividualSkuQuantityChange = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => {
  const productCode = action.payload?.productCode;
  const skuMaxQuantity = action.payload?.maxQuantity;
  state.individual_skus[productCode].max_allowed_quantity = skuMaxQuantity;
  state.individual_skus[productCode].available_quantity =
    skuMaxQuantity - state.individual_skus[productCode].consumed_quantity;
  return {
    ...state,
    individual_skus: state.individual_skus,
  };
};

const hanleOnGroupQuantityChange = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => {
  const changedQuanityGroupName = action.payload?.groupName;
  const groupMaxQuantity = action.payload?.maxQuantity;
  state.groups[changedQuanityGroupName].max_allowed_quantity = groupMaxQuantity;
  state.groups[changedQuanityGroupName].available_quantity =
    groupMaxQuantity - state.groups[changedQuanityGroupName].consumed_quantity;
  return {
    ...state,
    groups: state.groups,
  };
};

const handleOnGroupSkuAdd = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => {
  const groupToAddSku = action.payload?.groupName;
  const groupSkuToAdd = action.payload?.license;
  const SkuConsumedQuantity = action.payload?.consumedQuantity ?? 0;
  // Dont add sku if already Present
  const group = state.groups[groupToAddSku];
  const isAlreadyAdded =
    group.skus.filter(
      (sku: any) => sku.product_code === groupSkuToAdd.product_code
    ).length > 0;
  if (isAlreadyAdded) {
    return { ...state };
  }

  // check if sku exists in the orignal Config
  // and if it does then have the consumed and available quantity reflect the orginal value
  group.skus.push({
    product_code: groupSkuToAdd.product_code,
    display_name: groupSkuToAdd.sku_name,
    consumed_quantity: SkuConsumedQuantity,
  });
  group.consumed_quantity += SkuConsumedQuantity;
  group.available_quantity -= SkuConsumedQuantity;
  return { ...state };
};

const handleOnSkuAdd = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => {
  const skuToAdd = action.payload?.license;
  const consumedQuantity = action.payload?.consumedQuantity ?? 0;

  const individual_skus = state.individual_skus ?? {};
  // Individual SKUs
  const keys = Object.keys(individual_skus);
  const isSkuAlreadyAdded =
    keys.filter(key => key === skuToAdd.product_code).length > 0;
  if (isSkuAlreadyAdded) {
    return { ...state };
  }
  const rearrangedLicenses: any = {};
  // check if sku exists in the orignal Config
  // and if it does then have the consumed and available quantity reflect the orginal value
  rearrangedLicenses[skuToAdd.product_code] = {
    display_name: skuToAdd.sku_name,
    max_allowed_quantity: 0,
    consumed_quantity: consumedQuantity,
    available_quantity: -consumedQuantity,
  };
  keys.forEach((key: string) => {
    rearrangedLicenses[key] = individual_skus[key];
  });
  state.individual_skus = rearrangedLicenses;
  return { ...state };
};

const handleOnChangeGroupName = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => {
  const oldGroupName = action.payload?.oldGroupName;
  const newGroupName = action.payload?.newGroupName ?? 0;

  const groupNames = Object.keys(state.groups);
  const updatedGroups: any = {};
  groupNames.forEach((key: string) => {
    if (key === oldGroupName) {
      updatedGroups[newGroupName] = state.groups[key];
    } else {
      updatedGroups[key] = state.groups[key];
    }
  });
  return {
    ...state,
    groups: updatedGroups,
  };
};

const handleOnAddGroup = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => {
  const groupToAdd = action.payload?.groupName ?? 0;
  const groups = state.groups ?? {};
  // if already exists
  if (groups[groupToAdd]) {
    return;
  }

  groups[groupToAdd] = {
    max_allowed_quantity: 0,
    skus: [],
    consumed_quantity: 0,
    available_quantity: 0,
  };

  const exisitingGroups = Object.keys(groups);
  const rearrangedGroups: any = {};
  rearrangedGroups[groupToAdd] = {
    max_allowed_quantity: 0,
    skus: [],
    consumed_quantity: 0,
    available_quantity: 0,
  };

  exisitingGroups.forEach((group: string) => {
    rearrangedGroups[group] = groups[group];
  });
  state.groups = rearrangedGroups;
  return { ...state };
};

const handleOnDeleleIndividualSku = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => {
  const exisitngIndividualSkus = Object.keys(state.individual_skus);
  const remainingLicenses: any = {};
  exisitngIndividualSkus.forEach((key: string) => {
    if (key !== action.payload?.productCode ?? '') {
      remainingLicenses[key] = state.individual_skus[key];
    }
  });
  return {
    ...state,
    individual_skus: remainingLicenses,
  };
};

const handleOnDeleteGroup = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => {
  const existingGroupNames = Object.keys(state.groups);
  const remainingGroups: any = {};

  existingGroupNames.forEach((group: string) => {
    if (group !== action.payload?.groupName ?? '') {
      remainingGroups[group] = state.groups[group];
    }
  });

  return {
    ...state,
    groups: remainingGroups,
  };
};

const handleOnDeleteGroupSku = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => {
  const groupToUpdate = action.payload?.groupName ?? 0;
  const childSkuName = action.payload?.licenseName ?? 0;
  let deletedSku: any = {};
  const remainingSkus = state.groups[groupToUpdate].skus.filter((sku: any) => {
    if (sku.display_name === childSkuName) {
      deletedSku = sku;
    }
    return sku.display_name !== childSkuName;
  });
  state.groups[groupToUpdate].skus = remainingSkus;
  state.groups[groupToUpdate].consumed_quantity -= deletedSku.consumed_quantity;
  state.groups[groupToUpdate].available_quantity +=
    deletedSku.consumed_quantity;
  return {
    ...state,
    groups: state.groups,
  };
};

const handleMove = (
  state: any,
  action: { type: string; payload?: { [key: string]: any } }
) => {
  const destination = action.payload?.destination;
  const source = action.payload?.source;
  const type = action.payload?.type;

  if (!destination) {
    return state;
  }
  // Back to where it started
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return state;
  }

  if (type.startsWith('CHILD')) {
    const groupName = type.split('||')[1];
    const childLicenses: IDataObject[] = state.groups[groupName].skus;
    const movedChildLicense = childLicenses.splice(source.index, 1)[0];
    childLicenses.splice(destination.index, 0, movedChildLicense);
    state.groups[groupName].skus = childLicenses;
  } else if (type === 'GROUP') {
    const keys = Object.keys(state.groups);
    const movedKey = keys.splice(source.index, 1)[0];
    keys.splice(destination.index, 0, movedKey);
    const rearrangedGroups: any = {};

    keys.forEach((key: string) => {
      rearrangedGroups[key] = state.groups[key];
    });

    state.groups = rearrangedGroups;
  } else if (type === 'INDIVIDUAL') {
    const keys = Object.keys(state.individual_skus);
    const movedKey = keys.splice(source.index, 1)[0];
    keys.splice(destination.index, 0, movedKey);
    const rearrangedLicenses: any = {};

    keys.forEach((key: string) => {
      rearrangedLicenses[key] = state.individual_skus[key];
    });
    state.individual_skus = rearrangedLicenses;
  }
  return { ...state };
};
