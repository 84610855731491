import React, { useContext } from 'react';

import { StoreContext } from '../../store/index';
// Styles
import * as styles from './../../assets/css/RequestForQuote/RequestForQuoteHeader';
// Components
import Container from './../../components/CustomerPortal-New-Container';
// Constants
import type RFQ from './../../constants/requestForQuote.constants';

const RequestForQuoteHeader = ({
  pageTitle,
  selectedProductType,
  productTypesDisplayed,
  handleProductTypeChange,
}: {
  pageTitle: string;
  selectedProductType: string;
  productTypesDisplayed: Array<{
    productType: typeof RFQ.productTypes[keyof typeof RFQ.productTypes];
    labelText: string;
  }>;
  handleProductTypeChange: (dispatch: any, payload: string) => void;
}) => {
  const { dispatch } = useContext(StoreContext);
  const productType = selectedProductType;
  const productTypesElemArr = productTypesDisplayed;

  const handleProductTypeChangeLocal = (productType: string) => (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    handleProductTypeChange(dispatch, productType);
  };

  return (
    <styles.RequestForQuoteHeader
      data-testid="RequestForQuote__header"
      className="RequestForQuote__header"
    >
      <Container>
        <styles.Hero>
          <styles.Title>{pageTitle}</styles.Title>
        </styles.Hero>

        <styles.ProductTypes className="RequestForQuote__products">
          {productTypesElemArr.map(item => (
            <styles.ProductType
              aria-label={item.labelText}
              key={item.productType}
              className={`RequestForQuote__productType ${
                productType === item.productType
                  ? 'RequestForQuote__productType--active'
                  : ''
              }`}
              data-testid="DeploymentType__Pill"
              onClick={handleProductTypeChangeLocal(item.productType)}
            >
              <span className="RequestForQuote__productTypeBtn">
                <p>{item.labelText}</p>
              </span>
            </styles.ProductType>
          ))}
        </styles.ProductTypes>
      </Container>
    </styles.RequestForQuoteHeader>
  );
};

export default RequestForQuoteHeader;
