import {
  Header,
  UserRole,
} from '@customer-portal/constants';
import axios from 'axios';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Styles
import { PrimaryInfoBanner } from '../assets/css/Banners/CustomerPortal-PrimaryInfoBanner';
import { SecondaryInfoBanner } from '../assets/css/Banners/CustomerPortal-SecondaryInfoBanner';
import closeIcon from '../assets/img/svg/action_icons/Close.svg';
// Assets
import infoIcon from '../assets/img/svg/status_icons/info.svg';
// Constants
import { HAPO_RESET_CART } from '../constants/hapoActions.constants';
import { USER_SET_VIEWED_MULTI_ACCOUNT_TUTORIAL_URL } from '../constants/network.constants';
import { RFQ_RESET_CART } from '../constants/requestForQuoteActions.constants';
import {
  getAuthType,
  useAuth,
} from '../contexts/auth';
// Utils
import { UserPermissionsHelper } from '../lib/UserPermissions';
// Contexts
import { StoreContext } from '../store';
// Components
import Container from './CustomerPortal-New-Container';
import SwitchAccountModal from './SwitchAccountModal';

interface Props {
  showBanner: boolean;
}

const CustomerPortalBanner = ({ showBanner }: Props) => {
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  // Local state
  const [ infoBody, setInfoBody ] = useState({
    textKey: '',
    fallbackText: '',
  });
  const [ ctaText, setCtaText ] = useState({
    textKey: '',
    fallbackText: '',
  });
  const [ ctaHref, setCtaHref ] = useState('browse');
  // Hooks
  const { t } = useTranslation('common');
  const history = useHistory();
  const { getAccessToken } = useAuth();
  // Constants
  const isUiPathUser = UserPermissionsHelper.isUiPathUser();
  const isCompanySelected = UserPermissionsHelper.isCompanySelected();
  const isSwitchAccountAllowed = UserPermissionsHelper.isViewSwitchAccountModalAllowed();
  const isEditCompanySettingsAllowed = UserPermissionsHelper.isEditCompanySettingsAllowed();

  // Methods
  const regularUsersInTeam = () => {
    if (!state.teamUsers) {
      return false;
    }

    const team = state.teamUsers;
    const regularUsers = team.filter(function(member: any) {
      return (
        member.cpRole === UserRole.CP_USER || !member.CP_Admin__c
      );
    });

    if (regularUsers.length > 0) {
      return true;
    }

    return false;
  };

  const handleCTATutorialClose = async () => {
    dispatch({
      type: 'showMultiAccountTutorial',
      payload: false,
    });

    try {
      await axios.post(USER_SET_VIEWED_MULTI_ACCOUNT_TUTORIAL_URL, '', {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          [Header.AUTH_TYPE]: getAuthType(),
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCPUserSwitchAccount = async () => {
    // set multi account tutorial to false
    if (state.showMultiAccountTutorial) {
      try {
        await handleCTATutorialClose();
      } catch (e) {
        console.log(e);
      }
    }

    dispatch({
      type: 'setShowSwitchAccountModal',
      payload: true,
    });
  };

  const handleSecondaryInfoBannerClose = () => {
    dispatch({
      type: 'showSecondaryInfoBanner',
      payload: false,
    });
  };

  const handleGoToBrowseCompanies = () => {
    history.push('/browse');
  };

  const handleGoToTeamUsers = () => {
    history.push('/company?section=users');
  };

  const handleSelectedClientBar = () => {
    if (ctaHref === 'company') {
      handleGoToTeamUsers();
    } else if (!state.isUiPath) {
      dispatch({
        type: 'setHasCloudLicenses',
        payload: false,
      });
      dispatch({
        type: 'setSelectedOrchestrator',
        payload: null,
      });
      dispatch({
        type: 'setNewOrchestrator',
        payload: null,
      });
      dispatch({
        type: 'showMultiAccountTutorial',
        payload: false,
      });
      dispatch({
        type: RFQ_RESET_CART,
        payload: null,
      });
      dispatch({
        type: HAPO_RESET_CART,
        payload: null,
      });

      handleCPUserSwitchAccount();
    } else {
      dispatch({
        type: 'resetCompany',
        payload: null,
      });
      dispatch({
        type: 'setHasCloudLicenses',
        payload: false,
      });
      dispatch({
        type: 'setSelectedOrchestrator',
        payload: null,
      });
      dispatch({
        type: 'setNewOrchestrator',
        payload: null,
      });
      dispatch({
        type: 'setLicenseManagementEnabled',
        payload: false,
      });
      dispatch({
        type: RFQ_RESET_CART,
        payload: null,
      });
      dispatch({
        type: HAPO_RESET_CART,
        payload: null,
      });

      handleGoToBrowseCompanies();
    }
  };

  // Effects
  useEffect(() => {
    // UiPath user with no company selected
    if (isUiPathUser && !isCompanySelected) {
      setInfoBody({
        textKey: 'header_submenu_no_selected_account',
        fallbackText:
          'With no clients selected, you can only access the Documentation, Downloads, and Support Sections.',
      });
      setCtaText({
        textKey: 'header_submenu_select_a_client',
        fallbackText: 'Select a client',
      });
      if (history.location.pathname === '/browse') {
        dispatch({
          type: 'showSelectedCompanyBanner',
          payload: false,
        });
      } else {
        dispatch({
          type: 'showSelectedCompanyBanner',
          payload: true,
        });
      }
      return;
    }

    // UiPath user with company selected
    if (isUiPathUser && isCompanySelected) {
      setInfoBody({
        textKey: 'header_submenu_selected_account',
        fallbackText:
          'Selected customer account: <span>{{customer_name}}</span>',
      });
      setCtaText({
        textKey: 'header_submenu_switch_account',
        fallbackText: 'Switch Account',
      });
      if (history.location.pathname !== '/browse') {
        dispatch({
          type: 'showSelectedCompanyBanner',
          payload: true,
        });
      } else {
        dispatch({
          type: 'showSelectedCompanyBanner',
          payload: false,
        });
      }
      setCtaHref('browse');
      return;
    }

    // If external user (who's not a multi-account user & is an account admin) and
    // no regular users to Team Users, then tell them to add team members.
    if (
      isEditCompanySettingsAllowed &&
      !isSwitchAccountAllowed &&
      state.teamUsers
    ) {
      if (!regularUsersInTeam() && showBanner) {
        setInfoBody({
          textKey: state.userName
            ? 'header_submenu_finish_setup_with_username'
            : 'header_submenu_finish_setup',
          fallbackText: state.userName
            ? 'Welcome, {{username}}! Finish the setup by adding your team members in your company page.'
            : 'Welcome! Finish the setup by adding your team members in your company page.',
        });
        setCtaText({
          textKey: 'header_submenu_add_team_members',
          fallbackText: 'Add team members',
        });
        setCtaHref('company');
        dispatch({
          type: 'showSelectedCompanyBanner',
          payload: true,
        });
      } else {
        dispatch({
          type: 'showSelectedCompanyBanner',
          payload: false,
        });
      }
      return;
    }

    // If external user (who's a multi-account user) with no company selected
    if (isSwitchAccountAllowed && !isCompanySelected) {
      setInfoBody({
        textKey: 'header_submenu_cp_user_no_selected_account',
        fallbackText:
          'With no company account selected, you can only access the Knowledge Section, and your profile.',
      });
      setCtaText({
        textKey: 'header_submenu_cp_user_select_a_client',
        fallbackText: 'Select an account',
      });
      dispatch({
        type: 'showSelectedCompanyBanner',
        payload: true,
      });
      return;
    }

    // If external user (who's a multi-account user) with company selected
    if (isSwitchAccountAllowed && isCompanySelected) {
      setInfoBody({
        textKey: 'header_submenu_cp_user_selected_account',
        fallbackText:
          'Selected company account: <span>{{customer_name}}</span>',
      });
      setCtaText({
        textKey: 'header_submenu_switch_account',
        fallbackText: 'Switch Account',
      });
      dispatch({
        type: 'showSelectedCompanyBanner',
        payload: true,
      });
      // If account admin then show also the secondary banner if no regular user added to account
      if (
        isEditCompanySettingsAllowed &&
        !regularUsersInTeam() &&
        showBanner &&
        state.teamUsers
      ) {
        dispatch({
          type: 'showSecondaryInfoBanner',
          payload: true,
        });
      } else {
        dispatch({
          type: 'showSecondaryInfoBanner',
          payload: false,
        });
      }
      return;
    }

    // Default (for external users) to not showing the banner
    if (!isSwitchAccountAllowed) {
      dispatch({
        type: 'showSelectedCompanyBanner',
        payload: false,
      });
    }
  }, [ state.companyId, state.teamUsers, history.location.pathname ]);

  return (
    <>
      <PrimaryInfoBanner
        bannerState={state.showSelectedCompanyBanner}
        showMultiAccountTutorial={state.showMultiAccountTutorial}
        data-testid="CustomerPortalBanner__primary"
      >
        <Container className="CustomerPortalInfoBanner__container">
          <span
            className="CustomerPortalInfoBanner__body"
            data-testid="CustomerPortalBanner__primaryBannerText"
          >
            {infoBody.textKey === 'header_submenu_selected_account' ||
            infoBody.textKey === 'header_submenu_cp_user_selected_account' ? (
                <Trans
                  t={t}
                  i18nKey={infoBody.textKey}
                  defaults={infoBody.fallbackText}
                  values={{ customer_name: state.companyName }}
                  components={{
                    span: (
                      <span className="CustomerPortalInfoBanner__body CustomerPortalInfoBanner__body--highlight" />
                    ),
                  }}
                />
              ) : (
                <Trans
                  t={t}
                  i18nKey={infoBody.textKey}
                  defaults={infoBody.fallbackText}
                  values={{ username: state.userName }}
                />
              )}
          </span>
          <div className="CustomerPortalInfoBanner__ctaContainer">
            <span
              className="CustomerPortalInfoBanner__cta"
              onClick={handleSelectedClientBar}
              data-testid="CustomerPortalBanner__primaryBannerCTA"
            >
              {t(ctaText.textKey, ctaText.fallbackText)}
            </span>
            <div className="CustomerPortalInfoBanner__ctaTutorial">
              <p>
                {t(
                  'header_submenu_multi_account_tutorial_msg',
                  'You can now switch/select your account from here.'
                )}
              </p>
              <img
                className="closeIcon"
                src={closeIcon}
                alt="Close icon"
                onClick={handleCTATutorialClose}
                data-testid="CustomerPortalBanner__primaryBannerCloseMultiAccountTutorial"
              />
            </div>
          </div>
        </Container>
      </PrimaryInfoBanner>

      <SecondaryInfoBanner
        bannerState={state.showSecondaryInfoBanner}
        primaryBannerState={state.showSelectedCompanyBanner}
        data-testid="CustomerPortalBanner__secondary"
      >
        <img
          className="infoIcon"
          src={infoIcon}
          alt="Info icon" />
        <div className="infoBody">
          <p
            className="message"
            data-testid="CustomerPortalBanner__secondaryBannerMessage"
          >
            {t(
              'header_submenu_finish_setup',
              'Welcome! Finish the setup by adding your team members in your company page.'
            )}
          </p>
          <a
            className="cta"
            href="#"
            onClick={handleGoToTeamUsers}
            data-testid="CustomerPortalBanner__secondaryBannerCTA"
          >
            {t('header_submenu_add_team_members', 'Add Team Members')}
          </a>
        </div>
        <img
          className="closeIcon"
          src={closeIcon}
          alt="Close icon"
          onClick={handleSecondaryInfoBannerClose}
        />
      </SecondaryInfoBanner>
      {/* Switch Account */}
      {isSwitchAccountAllowed && <SwitchAccountModal />}
    </>
  );
};

export default CustomerPortalBanner;
