import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

import { transitionStandard } from '../../js_mixins/transitions';

// Page
export const Wrapper = styled.div`
  .NotificationBanner {
    z-index: 1;
  }

  .KnowledgeCategories__Add-Category-Opener {
    display: flex;
    height: ${p => p.theme.spacing(10)}px;
    align-items: center;
    background-color: ${p => p.theme.palette.blue[500]};
    padding-left: ${p => p.theme.spacing(2)}px;
    padding-right: ${p => p.theme.spacing(2)}px;
    border-radius: 8px;
    margin-bottom: ${p => p.theme.spacing(5)}px;
    cursor: pointer;

    .KnowledgeCategories__Add-Category-Icon {
      width: 24px;
      height: 24px;
      margin-right: 24px;
    }

    h6 {
      color: white;
    }

  }
`;

export const OptionsPopover = styled(Popover)`
  .MuiPopover-paper {
    background: white;
    width: 200px;
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
  }

`;

export const PopoverOption = styled.p`
  padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.grey[50]}};
    color: ${p => p.theme.palette.blue[500]};
  }
`;

// Table
export const TableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: ${p => p.theme.spacing(2)}px;
  border-radius: 8px;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  min-height: ${p => p.theme.spacing(10)}px;

  > :first-child {
    display: flex;
    flex-basis: 45%;
    max-width: 45%;
  }
  > :nth-child(2),
  > :nth-child(3) {
    flex-basis: 15%;
    margin-right: ${p => p.theme.spacing(5)}px;
    flex-shrink: 0;
  }
  > :nth-child(4) {
    margin-right: ${p => p.theme.spacing(3)}px;
    flex: 1;
    text-align: right;
  }
  > :nth-child(5) {
    flex-basis: ${p => p.theme.spacing(3)}px;
    max-width: ${p => p.theme.spacing(3)}px;
    height: ${p => p.theme.spacing(3)}px;
  }

  .TableRow__DateTime,
  .TableRow__Hyperlink {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7;
  }

`;

export const TableHeader = styled(TableRow)`
  margin-bottom: ${p => p.theme.spacing(2)}px;
  min-height: 0;

  > :nth-child(1) {
    p {
      padding-left: ${p => p.theme.spacing(6)}px;
    }
  }
`;

export const TableRowCategory = styled(TableRow)`
  .TableRowCategory__Name {
    font-size: 1.8rem;
    line-height: 1.35;
  }
`;

export const TableRowSubcategory = styled(TableRow)`
  background-color: ${p => p.theme.palette.grey[100]};
  min-height: ${p => p.theme.spacing(8)}px;
  border-left: 1px solid white;
  border-right: 1px solid white;

  .TableRowSubcategory__Name {
    padding-left: ${p => p.theme.spacing(6)}px;
  }

  &:last-child {
    border-bottom: 1px solid white;
  }
`;

export const TableRowIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: ${p => p.theme.spacing(2)}px;
  margin-right: ${p => p.theme.spacing(2)}px;
  cursor: pointer;
  position: relative;
  ${transitionStandard('all')};

  &.ExpandOption--Active {
    transform: rotate(180deg);
  }
`;

export const ModalContentFormWrapper = styled.form`
  margin-top: ${p => p.theme.spacing(3)}px;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;

  .KnowledgeCategories__Item {
    display: flex;
    justify-content: space-around;

    .KnowledgeCategories__TextInput {
      flex: 5;
    }
  }

  .KnowledgeCategories__Checkbox {
    flex: 2;
    margin-left: ${p => p.theme.spacing(1)}px;
    display: block;
  }

  .KnowledgeCategories__VisibilityControl {
    margin-top: ${p => p.theme.spacing(3)}px;
    width: 50%;

    .MuiInputLabel-root {
      color: ${p => p.theme.palette.text.secondary};
      margin-top: 14px;
      font-size: 19px;
      font-weight: 600;
      line-height: 20px;
    }
    .MuiFormLabel-asterisk {
      color: ${p => p.theme.palette.red[600]};
    }
    .MuiInputBase-root {
      margin-top: 54px;
      border-bottom: 1px solid ${p => p.theme.palette.grey[500]};
      &:hover {
        border-bottom-color: rgba(0, 0, 0, 0);
      }
      &:active {
        border-bottom-color: rgba(0, 0, 0, 0);
      }
    }
  }

  .KnowledgeCategories__SubmitButton {
    margin-top: ${p => p.theme.spacing(3)}px;
    width: 30%;
    align-self: end;
    font-weight: bold !important;
    font-size: 1.6rem !important;
    line-height: 2rem !important;
  }

  .MuiFormHelperText-root {
    color: ${p => p.theme.palette.error.main};
    margin: 4px 0px;
    font-size: 12px;
    line-height: 16px;
  }

  .KnowledgeCategories__FormError {
    color: ${p => p.theme.palette.error.main};
    fontSize: 14px;
  }

  .UploadForm__SubmitButton {
    font-size: 16px !important;
  }
`;

export const RenameTextField = styled(TextField)`
  width: 100%;

  border: none;

  &.MuiTextField-root {
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }

  .MuiInput-underline {
    &:after {
      display: none;
    }
  }
  .MuiInputBase-root {
    font-family: ${p => p.theme.typography.fontFamily};
    font-size: 1.4rem;
    background: ${p => p.theme.palette.grey[100]};
    border-radius: ${p => p.theme.spacing(1)}px;
    &:before {
      display: none;
    }
  }
  .MuiFormHelperText-root {
    font-family: ${p => p.theme.typography.fontFamily};
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.5px;
    color: ${p => p.theme.palette.error.main};
  }
  .MuiInputBase-input {
    font-size: 1.4rem;
    line-height: 2.4rem;
    height: auto;
    color: #353C4A;
    font-weight: 400;
    padding: 8px 16px;
  }
`;
