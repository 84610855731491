import styled from 'styled-components';

import NavIcon from '../../../img/svg/arrows/Nav_Icon_ArrowRightWhite_CircleBlue.svg';
import { ContentWrapper } from '../../CustomerPortalContentWrapper';

export const SupportKB = styled.section`
  position: relative;
  ${ContentWrapper}

  .CustomerPortalSupportKB__CategoriesContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .heroBackButton {
    font-size: 1.2rem;
    color: #353c4a;
    text-transform: uppercase;
    cursor: pointer;
    position: absolute;
    top: 24px;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: '';
      background: url(${NavIcon}) no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: ${p => p.theme.spacing(6)}px;
  max-width: 1000px;
  width: 100%;

  ${p => p.theme.breakpoints.down('sm')} {
    max-width: 100%;
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }

  .CustomerPortalSupportKB__ProductName {
    display: inline-flex;
    align-items: center;
  }

  a.MuiButtonBase-root.MuiButton-root {
    font-size: 14px;
    text-transform: none;
  }
`;

export const ProductItem = styled.div.attrs(p => ({ active: p.active || false }))`
    min-height: 40px;
    cursor: pointer;
    color: ${p => (p.active ? 'white' : 'initial')};
    background-color: ${p =>
    p.active ? p.theme.palette.blue[500] : 'white'};

    margin: ${p => p.theme.spacing(1)}px;
    padding-left: ${p => p.theme.spacing(2)}px
    padding-right: ${p => p.theme.spacing(2)}px
    border-radius: ${p => p.theme.spacing(1)}px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    font-size: 1.4rem;
    font-weight: normal;

    transition: all 0.3s ease;

    &:hover {
      color: ${p =>
    p.active ? 'white' : p.theme.palette.blue[500]};

      transition: all 0.3s ease;
    }
  `;
