import '../assets/css/WebChat.css';

import { Locale } from '@customer-portal/constants';

import {
  BASE_SCRIPT,
  WEBCHAT_ROOT_QUERY_SELECTOR,
} from '../constants/webchat.constants';

type WebchatOpts = { language: Locale } & WebchatOptions;

// Loads the script via JS to ensure that we can capture when the script
// is finished loading and run any callbacks
const loadWebchatJS = (configUrl: string, options?: WebchatOpts): Promise<Webchat> => (
  new Promise(async (resolve, reject) => {
    // If script tags or divs exist, assume the chatbot's already been loaded
    if (getChatbotWidgetScripts().length || getChatbotWidgetDivs().length) {
      return;
    }

    const baseScript = document.createElement('script');
    baseScript.src = BASE_SCRIPT.url;
    baseScript.integrity = `${BASE_SCRIPT.integrityHashPrefix}-${BASE_SCRIPT.integrityHashString}`;
    baseScript.crossOrigin = 'anonymous';
    baseScript.id = BASE_SCRIPT.elementId;
    document.body.appendChild(baseScript);
    baseScript.onload = async () => {
      const { initWebchat } = window;
      try {
        const webchat = await initWebchat(configUrl, options);
        resolve(webchat);
      } catch (e) {
        reject(e);
      }
    };
  })
);

const getChatbotWidgetScripts = (): HTMLScriptElement[] => {
  const allScripts = document.getElementsByTagName('script');
  return Array.from(allScripts).filter((script) => (
    script.src === BASE_SCRIPT.url
  ));
};

const getChatbotWidgetDivs = (): Element[] => {
  const allDivs = document.querySelectorAll(WEBCHAT_ROOT_QUERY_SELECTOR);
  return Array.from(allDivs);
};

export const getChatbotEndpointForLanguage = (language: Locale): string => {
  // Feature flag must be enabled for the current company and language must be set to Japanese
  // Prerequisite: Chatbot feature flag must also be enabled for the current company for this function to be invoked
  if (language === Locale.ja) {
    return process.env.REACT_APP_COGNIGY_CONFIG_JAPAN_URL as string;
  }

  return process.env.REACT_APP_COGNIGY_CONFIG_URL as string;
};

let wcclient: Webchat | undefined;
let wcclientInitializing: boolean = false;

class WebChatClient {
  private isHidden: boolean = true;
  private static SHOW_HIDE_MAX_ATTEMPTS = 5;

  // Retry with exponential back-off strategy
  private retry(func: (times?: number) => void, times: number) {
    setTimeout(() => {
      func(times + 1);
    }, Math.pow(2, times) * 100);
  }

  public async initialize(options?: WebchatOpts): Promise<void> {
    const language = options?.language as Locale ?? Locale.en;
    const configUrl: string = getChatbotEndpointForLanguage(language);
    if (!wcclient && !wcclientInitializing) {
      try {
        wcclientInitializing = true;
        wcclient = await loadWebchatJS(configUrl, options);
        wcclientInitializing = false;
      } catch (e) {
        wcclientInitializing = false;
      }
    }
  }

  public async deinitialize(): Promise<void> {
    this.hide();

    for (const scriptTag of getChatbotWidgetScripts()) {
      scriptTag.remove();
    }

    for (const div of getChatbotWidgetDivs()) {
      div.remove();
    }

    wcclient = undefined;
    wcclientInitializing = false;
  }

  public show(attempts = 0): void {
    if (!wcclient || !this.isHidden) {
      return;
    }

    // Query selector needed since chatbot sits outside of react app
    const el: HTMLElement = document.querySelector(WEBCHAT_ROOT_QUERY_SELECTOR) as HTMLElement;

    if (!el) {
      console.warn(`Attempt ${attempts + 1}: Cognigy chatbot element not found in DOM!`);

      if (attempts < WebChatClient.SHOW_HIDE_MAX_ATTEMPTS) {
        this.retry(this.show.bind(this), attempts);
      }

      return;
    }

    // Keep the widget closed before showing it
    wcclient.close();
    el.style.display = 'flex';
    this.isHidden = false;
  }

  public hide(attempts = 0): void {
    if (!wcclient || this.isHidden) {
      return;
    }

    // Query selector needed since chatbot sits outside of react app
    const el: HTMLElement = document.querySelector(WEBCHAT_ROOT_QUERY_SELECTOR) as HTMLElement;

    if (!el) {
      console.warn(`Attempt ${attempts + 1}: Cognigy chatbot element not found in DOM!`);

      if (attempts < WebChatClient.SHOW_HIDE_MAX_ATTEMPTS) {
        this.retry(this.hide.bind(this), attempts);
      }

      return;
    }

    // Keep the widget closed before hiding it
    wcclient.close();
    el.style.display = 'none';
    this.isHidden = true;
  }
}

export const webchatClient = new WebChatClient();
