import React, {
  useContext,
  useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import SwitchAccountModal from '../../components/SwitchAccountModal';
import { _403 } from '../../constants/telemetry.constants';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';

const CustomerPortalPage403 = (props: any) => {
  const { t } = useTranslation('common');

  const {
    state, dispatch,
  } = useContext(StoreContext);

  useTrackPageViewEvent(_403);

  const isSwitchAccountAllowed: boolean = UserPermissionsHelper.isViewSwitchAccountModalAllowed();

  const handleCPUserSwitchAccount = async (e: any) => {
    e.preventDefault();
    dispatch({
      type: 'setShowSwitchAccountModal',
      payload: true,
    });
  };

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('403');
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('403_page_title', 'Access Denied | Customer Portal')}</title>
      </Helmet>

      <ErrorPage
        errorType='403'
        header={t('page_403_hero_text', 'Access Denied')}
        description={t(
          'page_403_description',
          `Please contact the ${state.companyName} administrator to verify the permissions on your account.`,
          { company_name: state.companyName }
        )}
      >
        {state.userCompanies && state.userCompanies.length > 1 && (
          <div
            data-testid='ErrorPage-403__CTA'
            className='link'
            onClick={handleCPUserSwitchAccount}
          >
            {t('page_403_switch_account_cta', 'Switch Account')}
          </div>
        )}
      </ErrorPage>

      {isSwitchAccountAllowed && <SwitchAccountModal />}
    </>
  );
};

export default CustomerPortalPage403;
