import React from 'react';

import CustomerPortalLanding from '../../components/CustomerPortal-Landing';
import { CloudUnregisteredLandingPage } from '../../constants/support.constants';

const CustomerPortalUnregisteredLandingPage = (props: any) => (<CustomerPortalLanding
  text={CloudUnregisteredLandingPage}
  isPublicLandingPage={false}
/>
);

export default CustomerPortalUnregisteredLandingPage;
