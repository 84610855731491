// Group array of objects by object key
export function groupArrayOfObjects(xs: any[], key: string) {
  return xs.reduce(function(rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

/**
 * Removing duplicates in an Array of Objects
 */
export const removeDuplicatesInArrayOfObjects = (arr: [{}], objKey: string) => {
  const seen = new Set();

  return arr.filter((el: any) => {
    const duplicate = seen.has(el[objKey]);
    seen.add(el[objKey]);
    return !duplicate;
  });
};
