import styled from 'styled-components';

import { transitionStandard } from '../../js_mixins/transitions';

export const LinkOutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 905px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 80px;
  .SupportKBLinkOut__Title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    color: ${p => `${p.theme.palette.ink[650]}`};
  }
  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: center;
    .SupportKBLinkOut__Title {
      margin-bottom: 0;
    }
  }
  .SupportKBLinkOut__SupportSectionLink {
    width: 100%;
    max-width: 258px;
    border: 1px solid ${p => `${p.theme.palette.ink[300]}`};
    border-radius: ${p => `${p.theme.spacing(1)}`}px;
    background: ${p => `white`};
    padding: 26px 0 20px 0;
    text-align: center;
    cursor: pointer;
    ${transitionStandard('all')};
    p {
      color: ${p => `${p.theme.palette.ink[650]}`};
      font-weight: 600;
    }
    img {
      filter: none;
    }
    &:hover {
      background: ${p => `${p.theme.palette.blue[500]}`};
      p {
        color: ${p => `white`};
      }
      img {
        filter: brightness(0) invert(1);
      }
    }
    ${p => p.theme.breakpoints.down('sm')} {
      margin: 24px 0;
    }
  }

  .SupportKBLinkOut__SupportSectionNewCase {
    display: grid;
    grid-auto-columns: 80%;
    justify-content: center;
    justify-items: center;
  }
`;
