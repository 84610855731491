import type { UserPermission } from '@customer-portal/constants';
import {
  DEFAULT_PERMISSIONS,
  UserRole,
} from '@customer-portal/constants';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../assets/css/AddUser';
// Clients
import { axiosPatch } from '../../client/axios';
// Constants
import { BACKEND_HOST_NAME } from '../../constants/network.constants';
// Context
import { useAuth } from '../../contexts/auth';
import { StoreContext } from '../../store';
// Components
import Button from '../Button/Button';
import AddUserRow from './Company-User-Form-Row';

interface IUsersToAdd {
  email: string;
  cpRole: string;
  permissions: any[];
  emailValidation: { isValid: boolean; errorMsg: string };
  roleIsValid: boolean;
  permissionsValid: boolean;
}

interface IRequestEdits {
  op: string;
  path: string;
  value: string | string[] | undefined;
}

export const EditUserPermissionsForm = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  // Constants
  const initUser: IUsersToAdd = {
    email: props.userInfo.Email,
    cpRole: '',
    permissions: [],
    emailValidation: {
      isValid: true,
      errorMsg: '',
    },
    roleIsValid: true,
    permissionsValid: false,
  };
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  // Local state
  const [ user, setUser ] = useState<IUsersToAdd>(initUser);
  const [ isLoading, setIsLoading ] = useState(false);

  const handlePermissionsChange = (
    selectedUserType: string,
    permissionItems: any[]
  ) => {
    const permissionsArray: any = [];

    for (const permissionsItem of permissionItems) {
      for (const permission of permissionsItem.permissions) {
        if (permission.value) {
          permissionsArray.push(`${permissionsItem.id}${permission.name}`);
        }
      }
    }

    setUser((prevState: IUsersToAdd) => {
      const userToUpdate: IUsersToAdd = { ...prevState };
      userToUpdate.permissions = permissionsArray;
      userToUpdate.cpRole = selectedUserType;
      userToUpdate.permissionsValid = true;
      return userToUpdate;
    });
  };

  // Edit user
  const editUser = async () => {
    let editUserResult: any;

    setIsLoading(true);

    const permsToSave = user.cpRole === UserRole.CP_USER ? [
      ...(DEFAULT_PERMISSIONS[UserRole.CP_USER] ?? []),
      ...user.permissions,
    ] : DEFAULT_PERMISSIONS[UserRole.CP_ADMIN] ?? [];

    const permsBodyItem: IRequestEdits = {
      op: 'replace',
      path: 'permissions',
      value: user.permissions.filter(
        (perm: string) => !DEFAULT_PERMISSIONS[UserRole.CP_USER]?.includes(perm as UserPermission)
      ),
    };

    const cpRoleBodyItem: IRequestEdits = {
      op: 'replace',
      path: 'cpRole',
      value: user.cpRole,
    };

    const body: {
      email: string;
      requestEdits: IRequestEdits[];
    } = {
      email: initUser.email,
      requestEdits: [],
    };

    if (user.permissions !== props.permissions) {
      body.requestEdits.push(permsBodyItem);
    }

    if (user.cpRole !== props.userInfo.cpRole) {
      body.requestEdits.push(cpRoleBodyItem);
    }

    try {
      editUserResult = await axiosPatch(
        `${BACKEND_HOST_NAME}/api/v1/user/permissions`,
        state.companyId,
        await getAccessToken(),
        body
      );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if (e.response?.status && e.response.status === 403) {
        dispatch({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: `Error: ${e.response.data.message}`,
        });
      } else {
        dispatch({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: `An error occurred while saving permissions! Error: ${e.message}`,
        });
      }
    }

    if (
      editUserResult?.data?.message &&
      editUserResult.data.message === 'Resource Updated'
    ) {
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: `User has been successfully updated.`,
      });
      const foundIndex: any = state?.teamUsers?.findIndex(
        (item: any, i: number) => item.Email === user.email
      );
      const newTeamUsers: any = [ ...(state.teamUsers as []) ];
      if (foundIndex !== -1) {
        const updatedUser = { ...newTeamUsers[foundIndex] };
        updatedUser.cpRole = user.cpRole;
        updatedUser.permissions = permsToSave;
        newTeamUsers[foundIndex] = updatedUser;
      }
      dispatch({
        type: 'setTeamUsers',
        payload: newTeamUsers,
      });
    }

    props.handleModalClose();
  };

  const init = () => {

    const cpRoleToSet = props.userInfo.cpRole;
    let foundPerms: any = [];

    if (props.userInfo.permissions) {
      foundPerms = props.userInfo.permissions;
    }

    setUser((prevState: IUsersToAdd) => {
      const userToUpdate: IUsersToAdd = { ...prevState };
      userToUpdate.cpRole = cpRoleToSet;
      userToUpdate.permissions = foundPerms;
      return userToUpdate;
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <styles.AddUserFormContainer data-testid="CompanyEditUserPermissionsForm__wrapper">
      <AddUserRow
        emailDisabled
        onPermissionsChange={handlePermissionsChange}
        roleValid={user.roleIsValid}
        permissionsValid={user.permissionsValid}
        emailValidation={user.emailValidation}
        email={user.email}
        permissions={user.permissions}
        selectedUserType={user.cpRole}
      />

      <Button
        className="AddUserForm__Button"
        isValid
        isLoading={isLoading}
        text={t('company_team_edit_permissions_modal_save_btn', 'Save')}
        onClick={editUser}
      />
    </styles.AddUserFormContainer>
  );
};

export default EditUserPermissionsForm;
