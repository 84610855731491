import Popover from '@mui/material/Popover';
import styled from 'styled-components';

export const OptionsPopover = styled(Popover)`
  .MuiPopover-paper {
    background: white;
    width: 200px;
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
  }

`;

export const PopoverOption = styled.p`
  padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.grey[50]}};
    color: ${p => p.theme.palette.blue[500]};
  }
`;

// Table
export const TableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: ${p => p.theme.spacing(2)}px;
  border-radius: 8px;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  min-height: ${p => p.theme.spacing(10)}px;

  > :first-child {
    display: flex;
    flex-basis: 45%;
    max-width: 45%;
  }
  > :nth-child(2),
  > :nth-child(3) {
    flex-basis: 15%;
    margin-right: ${p => p.theme.spacing(5)}px;
    flex-shrink: 0;
    text-align: center;
  }
  > :nth-child(4) {
    margin-right: ${p => p.theme.spacing(3)}px;
    flex: 1;
    text-align: right;
  }
  > :nth-child(5) {
    flex-basis: ${p => p.theme.spacing(3)}px;
    max-width: ${p => p.theme.spacing(3)}px;
    height: ${p => p.theme.spacing(3)}px;
  }
`;

export const TableHeader = styled(TableRow)`
  margin-bottom: ${p => p.theme.spacing(2)}px;
  min-height: 0;

  > :nth-child(1) {
    p {
      padding-left: ${p => p.theme.spacing(6)}px;
    }
  }
`;

export const TableRowLicense = styled(TableRow)`
  .TableRowLicense__Name {
    font-size: 1.8rem;
    line-height: 1.35;
  }
  .TableRowLicense__Max-Quantity {
    input {
      border-radius: 0px !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 1px solid ${p => p.theme.palette.grey[900]};
    }

    .MuiInput-underline:after {
      border-color: ${p => p.theme.palette.orange[500]};
    }
`;

export const TableRowIconSpace = styled.div`
  width: 16px;
  height: 16px;
  margin-left: ${p => p.theme.spacing(2)}px;
  margin-right: ${p => p.theme.spacing(2)}px;
  position: relative;
`;

export const TableRowIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: ${p => p.theme.spacing(2)}px;
  margin-right: ${p => p.theme.spacing(2)}px;
  cursor: pointer;
  position: relative;
`;
