export enum EscalationValidationMessageType {
  closed = 'closed-message',
  escalated = 'escalated-message',
  sla_active_and_not_expired = 'sla-active-and-not-expired-message',
  none = 'none',
}

export enum ElevateEscalationValidationMessageType {
  closed = 'closed-message',
  escalated_recently = 'escalated-recently-message',
  not_escalated = 'not-escalated-message',
  not_high_or_urgent_case = 'not-high-or-urgent-case-message',
  none = 'none',
}

export enum FollowUpValidationMessageType {
  not_closed = 'not-closed-message',
  days = 'days-message',
  followed_up = 'followed-up-message',
  out_of_support = 'out-of-support-message',
  none = 'none',
}

export enum ReopenValidationMessageType {
  out_of_support = 'out-of-support-message',
  none = 'none',
}

export enum AttachLogsValidationMessageType {
  closed = 'closed-message',
  none = 'none',
}

export enum ActionType {
  Escalate = 'Escalate',
  ElevateEscalation = 'ElevateEscalation',
  FollowUp = 'FollowUp',
  Reopen = 'Reopen',
  AttachLogs = 'AttachLogs',
  None = 'None',
}

export const EscalationValidationMessage = {
  [EscalationValidationMessageType.closed]: {
    localText: 'support_incident_cases_escalate_case_validation_closed',
    fallbackText: 'This case has been closed and cannot be escalated.',
  },
  [EscalationValidationMessageType.escalated]: {
    localText: 'support_incident_cases_escalate_case_validation_escalated',
    fallbackText: 'This case is already escalated and cannot be escalated again.',
  },
  [EscalationValidationMessageType.sla_active_and_not_expired]: {
    localText: 'support_incident_cases_escalate_case_validation_sla_active_and_not_expired',
    fallbackText: 'This case is being reviewed and cannot be escalated at this time.',
  },
  [EscalationValidationMessageType.none]: {
    localText: '',
    fallbackText: '',
  },
};

export const ElevateEscalationValidationMessage = {
  [ElevateEscalationValidationMessageType.closed]: {
    localText: 'support_incident_cases_elevate_escalate_case_validation_closed',
    fallbackText: 'This case has been closed and cannot be escalated further.',
  },
  [ElevateEscalationValidationMessageType.escalated_recently]: {
    localText: 'support_incident_cases_elevate_escalate_case_validation_escalated_recently',
    fallbackText: 'This case has been recently escalated. An escalation manager will get back to you shortly.',
  },
  [ElevateEscalationValidationMessageType.not_escalated]: {
    localText: 'support_incident_cases_elevate_escalate_case_validation_not_escalated',
    fallbackText: 'Please use the “Escalate” action to initiate an escalation.',
  },
  [ElevateEscalationValidationMessageType.not_high_or_urgent_case]: {
    localText: 'support_incident_cases_elevate_escalate_case_validation_not_high_or_urgent_case',
    fallbackText: 'Only High and Urgent cases can require a Elevate Escalation.',
  },
  [ElevateEscalationValidationMessageType.none]: {
    localText: '',
    fallbackText: '',
  },
};

export const FollowUpValidationMessage = {
  [FollowUpValidationMessageType.not_closed]: {
    localText: 'support_incident_cases_follow_up_case_validation_closed',
    fallbackText: 'This case is open, you may leave a comment to follow up with the assigned product support engineer.',
  },
  [FollowUpValidationMessageType.days]: {
    localText: 'support_incident_cases_follow_up_case_validation_days',
    fallbackText: 'This case has been closed for more than 6 months, please create a new case if support is still required.',
  },
  [FollowUpValidationMessageType.followed_up]: {
    localText: 'support_incident_cases_follow_up_case_validation_followed_up',
    fallbackText: 'Case {{followupCaseNumber}} was already created as a follow-up.',
  },
  [FollowUpValidationMessageType.out_of_support]: {
    localText: 'support_incident_cases_follow_up_case_validation_out_of_support',
    fallbackText: 'This case is referencing a software version that is no longer supported.',
  },
  [FollowUpValidationMessageType.none]: {
    localText: '',
    fallbackText: '',
  },
};

export const ReopenValidationMessage = {
  [ReopenValidationMessageType.out_of_support]: {
    localText: 'support_incident_cases_follow_up_case_validation_out_of_support',
    fallbackText: 'This case is referencing a software version that is no longer supported.',
  },
  [ReopenValidationMessageType.none]: {
    localText: '',
    fallbackText: '',
  },
};

export const AttachLogsValidationMessage = {
  [AttachLogsValidationMessageType.closed]: {
    localText: 'support_incident_cases_attach_logs_validation_closed',
    fallbackText: 'Attachments can only be added to open cases.',
  },
  [AttachLogsValidationMessageType.none]: {
    localText: '',
    fallbackText: '',
  },
};

export const ALLOWED_PRIORITY_HIEREARCHICAL_ESCALATION = [ 'High', 'Urgent' ];
