import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Styles
import * as styles from '../assets/css/CustomerPortalNoCompanyId';
import {
  Button,
  ButtonsContainer,
} from './../assets/css/CustomerPortalLogin';
// Utils
// Components
import Container from './CustomerPortal-New-Container';

const CustomerPortalNoCompanyId = () => {
  // Translate method
  const { t } = useTranslation('common');
  const message = t(
    'component_no_company_id_selected_msg',
    'No customer selected. Please select a customer!'
  );
  const history = useHistory();

  return (
    <styles.NoCompanyId data-testid="NoCompany">
      <Container cssClass="CustomerPortalNoCompanyId__container">
        <div className="CustomerPortalNoCompanyId__message">
          <h2>{message}</h2>
          <ButtonsContainer>
            <Button onClick={() => history.push('/browse')}>
              {t(
                'component_no_company_id_selected_browse_customers_btn',
                'Browse Customers'
              )}
            </Button>
          </ButtonsContainer>
        </div>
      </Container>
    </styles.NoCompanyId>
  );
};

export default CustomerPortalNoCompanyId;
