import {
  CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY,
  CP_POST_AUTH_REDIRECT_URL_KEY,
  CP_PRIVATE_STATE_KEY,
} from '../constants/state.constants';
import { useAuth } from '../contexts/auth';
import {
  unInitAppIns,
  useTriggerTrackEventWithStateData,
} from '../lib/AppInsights/AppInsights';
import CoveoUtil from '../utils/coveo';
import { webchatClient } from '../utils/webchatClient';

const useLogout = () => {
  const { logout } = useAuth();
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  return async () => {
    try {
      triggerTrackEventWithStateData('User.Logout');
      sessionStorage.removeItem(CP_PRIVATE_STATE_KEY);
      localStorage.removeItem(CP_POST_AUTH_REDIRECT_URL_KEY);
      localStorage.removeItem(CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY);
      CoveoUtil.deleteSessionData();
      webchatClient.deinitialize();
      unInitAppIns();
    } catch (error) {
      console.error('Error occurred during logout:', error);
    } finally {
      await logout();
    }
  };
};

export default useLogout;
