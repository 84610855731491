import { Locale } from '@customer-portal/constants';

export enum ContentfulLanguage {
  'en-US' = 'en-US',
  'fr-FR' = 'fr-FR',
  'de-DE' = 'de-DE',
  'ja-JP' = 'ja-JP',
  'ko-KR' = 'ko-KR',
  'pt-BR' = 'pt-BR',
  'es-MX' = 'es-MX',
  'zh-CN' = 'zh-CN',
}

interface ContentfulLanguageCode {
  name: string;
  code: ContentfulLanguage;
}

export const ContentfulLanguageCodes: { [key: string]: ContentfulLanguageCode } = {
  ENGLISH: {
    name: 'U.S. English',
    code: ContentfulLanguage['en-US'],
  },
  FRENCH: {
    name: 'French',
    code: ContentfulLanguage['fr-FR'],
  },
  GERMAN: {
    name: 'German',
    code: ContentfulLanguage['de-DE'],
  },
  JAPANESE: {
    name: 'Japanese',
    code: ContentfulLanguage['ja-JP'],
  },
  KOREAN: {
    name: 'Korean',
    code: ContentfulLanguage['ko-KR'],
  },
  PORTUGUESE: {
    name: 'Portuguese',
    code: ContentfulLanguage['pt-BR'],
  },
  SPANISH: {
    name: 'Spanish',
    code: ContentfulLanguage['es-MX'],
  },
  CHINESE: {
    name: 'Chinese (Simplified)',
    code: ContentfulLanguage['zh-CN'],
  },
};

export const LOCALE_TO_CONTENTFUL_LANGUAGE: { [key in Locale]: ContentfulLanguage; } = {
  [Locale.en]: ContentfulLanguage['en-US'],
  [Locale.ja]: ContentfulLanguage['ja-JP'],
  [Locale.de]: ContentfulLanguage['de-DE'],
  [Locale.fr]: ContentfulLanguage['fr-FR'],
  [Locale.es]: ContentfulLanguage['es-MX'],
  [Locale['pt-BR']]: ContentfulLanguage['pt-BR'],
  [Locale.pt]: ContentfulLanguage['pt-BR'],
  [Locale.ru]: ContentfulLanguage['en-US'],
  [Locale.tr]: ContentfulLanguage['en-US'],
  [Locale['es-MX']]: ContentfulLanguage['es-MX'],
  [Locale.ko]: ContentfulLanguage['ko-KR'],
  [Locale['zh-CN']]: ContentfulLanguage['zh-CN'],
  [Locale['zh-TW']]: ContentfulLanguage['zh-CN'],
};

export const ModuleNames: { [key: string]: string } = {
  CUSTOM_NAV: 'ContentfulComponentCustomNav',
  MAIN_NAV_BANNER: 'ContentfulComponentMainNavBanner',
  HERO_POC: 'ContentfulHero',
  HERO: 'ContentfulComponentHero',
  FLEXIBLE_HERO: 'ContentfulComponentFlexibleHero',
  PRODUCT_VALUE_PROP: 'ContentfulProductValuePropModule',
  PRODUCT_VIDEO_VALUE_PROP: 'ContentfulProductContentValueProp',
  VIDEO_SPOTLIGHT: 'ContentfulComponentSpotlight',
  MEDIA_TEXT: 'ContentfulComponentAssetText',
  MEDIA_ASSET: 'ContentfulComponentMediaAsset',
  RESOURCE_ROW: 'ContentfulComponentResourceRow',
  BENEFITS: 'ContentfulComponentBenefits',
  ICONS: 'ContentfulComponentIcons',
  TABLE: 'ContentfulComponentTable',
  GLOBAL_MEDIA_TEXT: 'ContentfulComponentAssetText',
  GLOBAL_FEATURE_CARD: 'ContentfulGlobalFeatureCard',
  GLOBAL_FEATURE_CARD_VERTICAL: 'ContentfulGlobalFeatureCardVertical',
  GLOBAL_DOWNLOAD_ASSET: 'ContentfulGlobalDownloadAsset',
  GLOBAL_SECTION_HIGHLIGHT: 'ContentfulGlobalSectionHighlight',
  GLOBAL_CONTENT_COLUMNS_POC: 'ContentfulGlobalContentColumns',
  GLOBAL_CONTENT_COLUMNS: 'ContentfulComponentColumns',
  GLOBAL_KEY_STATS: 'ContentfulComponentKeyStats',
  GLOBAL_KEY_STATS_POC: 'ContentfulGlobalKeyStats',
  GLOBAL_QUOTES_SLIDER: 'ContentfulGlobalQuoteSlider',
  GLOBAL_CONTENT_BLOCK_POC: 'ContentfulGlobalContentBlock',
  GLOBAL_CONTENT_BLOCK: 'ContentfulComponentContentBlock',
  GLOBAL_MAIN_CTA: 'ContentfulComponentMainCta',
  GLOBAL_MAIN_CTA_POC: 'ContentfulGlobalBottomCtaSection',
  GRID: 'ContentfulComponentGrid',
  DIVIDER: 'ContentfulComponentDivider',
  GRID_POC: 'ContentfulGrid',
  VIDEO: 'ContentfulProductVideoSection',
  TRY_PRODUCT_MODULE: 'ContentfulTryProductModule',
  CAROUSEL: 'ContentfulGlobalQuoteSlider',
  PRODUCT_DATA_TRIO: 'ContentfulGlobalKeyStats',
  IMAGE: 'ContentfulImage',
  MARKETO_FORM: 'ContentfulComponentMarketoForm',
  QUOTE: 'ContentfulComponentQuote',
  FEATURED_RESOURCE: 'ContentfulComponentFeaturedResource',
  SLIDER: 'ContentfulComponentSlider',
  PAGE_PRODUCT: 'ContentfulPageProduct',
  PAGE_FLEX: 'ContentfulPageFlex',
  TOPIC_CTA: 'ContentfulTopicCta',
  TOPIC_INTERNAL_LINK: 'ContentfulTopicInternalLink',
  MENU_GROUP: 'ContentfulMenuGroup',
  RESOURCE_TABS: 'ContentfulComponentResourceTabs',
  PLATFORM_DIAGRAM: 'ContentfulComponentPlatformDiagram',
  COLLAPSIBLE_QUADRANT: 'ContentfulComponentCollapsibleQuadrant',
  SPEAKER_TEXT: 'ContentfulComponentSpeakerText',
  BIO: 'ContentfulComponentBio',
  TILES: 'ContentfulComponentTiles',
  AUTOMATION_USE_CASE: 'ContentfulTopicAutomationUseCase',
  TEASER: 'ContentfulTeaser',
  BLOG_POST: 'ContentfulPageBlogPost',
  WRAPPER_ASSET: 'ContentfulWrapperAsset',
  WRAPPER_VIDEO: 'ContentfulWrapperVideo',
  PARAGRAPH_BLOCK: 'ContentfulComponentParagraphBlock',
  TOPIC_CUSTOMER_DETAILS: 'ContentfulTopicCustomerDetails',
  TOPIC_REGION: 'ContentfulTopicResourceRegion',
  TOPIC_INDUSTRY: 'ContentfulTopicResourceIndustry',
  TOPIC_DEPARTMENT: 'ContentfulTopicResourceDepartment',
  TOPIC_PARTNER_DETAILS: 'ContentfulTopicPartnerDetails',
  TOPIC_RESOURCE_PRODUCT: 'ContentfulTopicResourceProduct',
  TOPIC_RESOURCE_TOPIC: 'ContentfulTopicResourceTopic',
  TOPIC_COMPANY_BIO: 'ContentfulTopicCompanyBio',
  TOPIC_COUNTRY: 'ContentfulTopicCountry',
  TOPIC_CITY: 'ContentfulTopicCity',
  TOPIC_OFFICE_LOCATION: 'ContentfulTopicOfficeLocation',
  TOPIC_OFFICE_REGION: 'ContentfulTopicOfficeRegion',
  TOPIC_FAQ: 'ContentfulTopicFaq',
  TOPIC_AGENDA: 'ContentfulTopicAgenda',
  TOPIC_EVENT_TYPE: 'ContentfulTopicEventType',
  TOPIC_WEBINAR_TYPE: 'ContentfulTopicWebinarType',
  TOPIC_ICON_LINK: 'ContentfulTopicIconLink',
  PAGE_MAIN_BLOG: 'ContentfulPageMainBlog',
  PAGE_RESOURCE_DEMO: 'ContentfulPageResourceDemo',
  PAGE_CUSTOMER_STORY: 'ContentfulPageResource',
  PAGE_SOLUTIONS: 'ContentfulPageSolutions',
  PAGE_ABOUT_RPA: 'ContentfulPageAboutRpa',
  PAGE_PRODUCT_DOWNLOAD: 'ContentfulPageDownload',
  PAGE_THANK_YOU: 'ContentfulPageThankYou',
  PAGE_PLACEHOLDER: 'ContentfulPagePlaceholder',
  PAGE_WEBINAR_RECORDING: 'ContentfulPageWebinarRecording',
  PAGE_ANALYST_REPORT: 'ContentfulPageAnalystReport',
  PAGE_WHITE_PAPER: 'ContentfulPageWhitePaper',
  PAGE_RAW_HTML: 'ContentfulPageRawHtml',
  HOMEPAGE: 'ContentfulHomepage',
  PRODUCT_DOWNLOAD_COMPONENT: 'ContentfulComponentProductDownload',
  WRAPPER_PDF: 'ContentfulWrapperPdf',
  ANCHOR_LINK: 'ContentfulTopicAnchorLink',
  CONTENT_ROWS: 'ContentfulComponentContentRows',
  ANNOUNCEMENT: 'ContentfulComponentAnnouncement',
  MICROCOPY_SET: 'ContentfulMicrocopySet',
  THANK_YOU_FEATURED_RESOURCE: 'ContentfulComponentThankYouFeaturedResource',
  RESOURCE_LISTS: 'ContentfulComponentResourceLists',
  RESOURCE_LINK_CATEGORY: 'ContentfulTopicResourceList',
  RAW_HTML: 'ContentfulComponentRawHtml',
};

export const ModulesMapping: { [key: string]: string } = {
  componentBio: ModuleNames.BIO,
  componentHero: ModuleNames.HERO,
  componentFlexibleHero: ModuleNames.FLEXIBLE_HERO,
  componentTiles: ModuleNames.TILES,
  componentFeaturedResource: ModuleNames.FEATURED_RESOURCE,
  componentAssetText: ModuleNames.MEDIA_TEXT,
  componentIcons: ModuleNames.ICONS,
  componentSpotlight: ModuleNames.VIDEO_SPOTLIGHT,
  componentDivider: ModuleNames.DIVIDER,
  componentContentBlock: ModuleNames.GLOBAL_CONTENT_BLOCK,
  componentContentRows: ModuleNames.CONTENT_ROWS,
  componentCollapsibleQuadrant: ModuleNames.COLLAPSIBLE_QUADRANT,
  componentGrid: ModuleNames.GRID,
  componentMainCta: ModuleNames.GLOBAL_MAIN_CTA,
  componentKeyStats: ModuleNames.GLOBAL_KEY_STATS,
  componentBenefits: ModuleNames.BENEFITS,
  componentPlatformDiagram: ModuleNames.PLATFORM_DIAGRAM,
  componentQuote: ModuleNames.QUOTE,
  componentColumns: ModuleNames.GLOBAL_CONTENT_COLUMNS,
  componentResourceTabs: ModuleNames.RESOURCE_TABS,
  componentMediaAsset: ModuleNames.MEDIA_ASSET,
  componentResourceRow: ModuleNames.RESOURCE_ROW,
  componentResourceLists: ModuleNames.RESOURCE_LISTS,
  componentParagraphBlock: ModuleNames.PARAGRAPH_BLOCK,
  componentTable: ModuleNames.TABLE,
  componentSlider: ModuleNames.SLIDER,
  componentMarketoForm: ModuleNames.MARKETO_FORM,
  componentProductDownload: ModuleNames.PRODUCT_DOWNLOAD_COMPONENT,
  componentSpeakerText: ModuleNames.SPEAKER_TEXT,
  componentRawHtml: ModuleNames.RAW_HTML,
  teaser: ModuleNames.TEASER,
  pageResource: ModuleNames.PAGE_CUSTOMER_STORY,
  pageWhitePaper: ModuleNames.PAGE_WHITE_PAPER,
  pageAnalystReport: ModuleNames.PAGE_ANALYST_REPORT,
  pagePlaceholder: ModuleNames.PAGE_PLACEHOLDER,
  pageResourceDemo: ModuleNames.PAGE_RESOURCE_DEMO,
  pageAboutRpa: ModuleNames.PAGE_ABOUT_RPA,
  pageBlogPost: ModuleNames.BLOG_POST,
  pageMainBlog: ModuleNames.PAGE_MAIN_BLOG,
  pageFlex: ModuleNames.PAGE_FLEX,
  pageSolutions: ModuleNames.PAGE_SOLUTIONS,
  pageRawHtml: ModuleNames.PAGE_RAW_HTML,
  wrapperPdf: ModuleNames.WRAPPER_PDF,
  WrapperAsset: ModuleNames.WRAPPER_ASSET,
  wrapperVideo: ModuleNames.WRAPPER_VIDEO,
  topicAutomationUseCase: ModuleNames.AUTOMATION_USE_CASE,
  topicAnchorLink: ModuleNames.ANCHOR_LINK,
  topicCustomerDetails: ModuleNames.TOPIC_CUSTOMER_DETAILS,
  topicCta: ModuleNames.TOPIC_CTA,
  topicInternalLink: ModuleNames.TOPIC_INTERNAL_LINK,
  topicFaq: ModuleNames.TOPIC_FAQ,
  topicAgenda: ModuleNames.TOPIC_AGENDA,
  topicResourceIndustry: ModuleNames.TOPIC_INDUSTRY,
  topicResourceDepartment: ModuleNames.TOPIC_DEPARTMENT,
  topicResourceTopic: ModuleNames.TOPIC_RESOURCE_TOPIC,
  topicResourceProduct: ModuleNames.TOPIC_RESOURCE_PRODUCT,
  topicWebinarType: ModuleNames.TOPIC_WEBINAR_TYPE,
  topicResourceRegion: ModuleNames.TOPIC_REGION,
  topicOfficeRegion: ModuleNames.TOPIC_OFFICE_REGION,
  topicIconLink: ModuleNames.TOPIC_ICON_LINK,
  topicCompanyBio: ModuleNames.TOPIC_COMPANY_BIO,
  topicPartnerDetails: ModuleNames.TOPIC_PARTNER_DETAILS,
  topicEventType: ModuleNames.TOPIC_EVENT_TYPE,
  microcopySet: ModuleNames.MICROCOPY_SET,
};
