import Button from '@mui/material/Button';
import React, {
  useContext,
  useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import styled from 'styled-components';

// styles
import * as styles from '../../assets/css/CustomerPortal-VerifyEmail';
import { ReactComponent as MailLogo } from '../../assets/img/svg/mail.svg';
import { ReactComponent as Spinner } from '../../assets/img/svg/spinner.svg';
import BrandedLogoAndText from '../../components/BrandedLogoAndText';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
// Components
import Container from '../../components/CustomerPortal-New-Container';
import BottomPageOptions from '../../components/verify_email/BottomPageOptions';
import { SIGN_IN_WITH_ANOTHER_ACCOUT_LINK_TEXT } from '../../constants/activateUser.constants';
// Constants
import { AUTH0_FROM_EMAIL } from '../../constants/auth.constants';
import { CP_POST_AUTH_REDIRECT_URL_KEY } from '../../constants/state.constants';
import { NOT_VERIFIED_EMAIL } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import useLogout from '../../hooks/useLogout';
// Contexts
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import AuthUserUtil from '../../lib/auth.util';
import { StoreContext } from '../../store';

const BrandedLogoAndTextStyled = styled(BrandedLogoAndText)`
  .BrandedLogoAndText__Container {
    margin: ${p => p.theme.spacing(3)}px 0;
    cursor: pointer;
    .BrandedLogoAndText__Container-Img {
      height: 48px;
    }

    .BrandedLogoAndText__Container-Text {
      font-size: 16px;
      line-height: 14px;
    }
  }
`;

interface IUserMetadata {
  firstName?: string;
  lastName?: string;
  country?: string;
  marketingAdditionalFields?: string;
  marketingConditionAccepted?: string;
  user_signup_language?: string;
  termsAndConditionsB2CAccepted?: string;
}

const CustomerPortalVerifyEmail = () => {
  const { user } = useAuth();
  const { dispatch } = useContext(StoreContext);
  const logout = useLogout();
  const { t } = useTranslation('common');

  const EMAIL: string = AuthUserUtil.getEmail(user);

  const linkTextSignInWithAnotherAccount = t(
    SIGN_IN_WITH_ANOTHER_ACCOUT_LINK_TEXT.keyText,
    SIGN_IN_WITH_ANOTHER_ACCOUT_LINK_TEXT.fallbackText
  );

  useTrackPageViewEvent(NOT_VERIFIED_EMAIL);

  const handleContinueToCustomerPortalClick = (e: any) => {
    e.preventDefault();
    // Ensure that the next redirect waits until user context is re-fetched before the App renders
    // (helps prevent flickering of unregistered page)
    dispatch({
      type: 'setFinishedInitialUserCheck',
      payload: false,
    });
    const redirectUrl = localStorage.getItem(CP_POST_AUTH_REDIRECT_URL_KEY);
    const url = redirectUrl?.startsWith('/register?email=') ? '/' : redirectUrl;
    localStorage.removeItem(CP_POST_AUTH_REDIRECT_URL_KEY);
    window.location.href = url ?? '/';
  };

  const handleKeyDownLogout = async (e: React.KeyboardEvent) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      await logout();
    }
  };

  const handleClickLogout = async (e: React.MouseEvent) => {
    e.preventDefault();
    await logout();
  };

  /* Lifecycle */
  useEffect(() => {
    // hooks go here!!
    CustomerPortalGoogleAnalyticsPageView('Not Verified Email');
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t('verify_email_page_title', 'Verify Email | Customer Portal')}
        </title>
      </Helmet>

      <styles.Container>
        <Container cssClass="CustomerPortalVerifyEmail__container">
          <styles.Logo>
            <a href='/'>
              <BrandedLogoAndTextStyled useLineBreak />
            </a>
          </styles.Logo>
          <styles.EmailIcon>
            <MailLogo />
          </styles.EmailIcon>
          <styles.Body data-testid="VerifyEmailPage__Body">
            <h1 data-testid="VerifyEmailPage__Title">
              {t(
                'verify_email_page_hero_title',
                'Verify your email to continue'
              )}
            </h1>
            <p data-testid="VerifyEmailPage__Desc">
              <Trans
                t={t}
                i18nKey="verify_email_page_hero_description"
                defaults={`Complete the verification by opening the link we have sent to <strong>${EMAIL}</strong>.`}
                values={{ email: EMAIL }}
              />
            </p>
            <p>
              {t(
                'verify_email_page_hero_disclaimer',
                `If it's not there, please check your spam folder for ${AUTH0_FROM_EMAIL}`,
                { email: AUTH0_FROM_EMAIL }
              )}
            </p>
            <styles.Spinner>
              <Spinner />
            </styles.Spinner>
            <p>
              {t(
                'verify_email_page_hero_email_verified_text',
                'If you have completed the email verification, click the below button to continue.'
              )}
            </p>
            <div className="CustomerPortalNotVerifiedPage__ctaContainer">
              <Button
                variant='outlined'
                onClick={handleContinueToCustomerPortalClick}
                data-testid="VerifyEmailPage__ContinueButton"
              >
                {t('verify_email_page_hero_continue_btn_text', 'Continue')}
              </Button>
            </div>
            <styles.LogoutPromptLink>
              <p>
                <span
                  role="button"
                  onClick={handleClickLogout}
                  onKeyDown={handleKeyDownLogout}
                  tabIndex={0}
                  data-testid="VerifyEmailPage__LogoutPromptLink"
                >
                  {linkTextSignInWithAnotherAccount}
                </span>
              </p>
            </styles.LogoutPromptLink>
          </styles.Body>
        </Container>
        <BottomPageOptions
          positionRelative={false}
        />
      </styles.Container>
    </>
  );
};

export default CustomerPortalVerifyEmail;
