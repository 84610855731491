import _ from 'lodash';

/**
 * Call a function only after a certain period of time
 * @param func
 * @param waitFor
 */
export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const isValidDate = (date: unknown) =>
  // Quack quack
  (
    date &&
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN((date as Date).getTime())
  )
;

/**
 * Helper function to recursively traverse through an object or
 * array of nested objects and returns a deep clone with all
 * keys converted to snake_case
 *
 * @example
 * const obj = { foO: 'bar', walDo: [{ Bar: 'baz', tHuD: 'waldo' }] };
 * snakeCaseDeep(obj);
 * // it will return { fo_o: 'bar', wal_do: [ { bar: 'baz', t_hu_d: 'waldo' } ] }
 *
 * @param obj {any}
 * @returns {any}
 */
export const snakeCaseDeep = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeCaseDeep(v));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [_.snakeCase(key)]: snakeCaseDeep(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

export const matchesUrlPattern = (str: string, path: string): boolean => {
  // Split the URL and the pattern into their components
  const strParts = str.split('/');
  const pathParts = path.split('/');

  // If the URL and the pattern have different numbers of components, they don't match
  if (strParts.length !== pathParts.length) {
    return false;
  }

  // Check each component of the URL and the pattern
  for (let i = 0; i < strParts.length; i++) {
    // If the pattern component starts with ":", it's a dynamic parameter and can match any URL component
    // Otherwise, the URL component and the pattern component must be exactly the same
    if (!pathParts[i].startsWith(':') && strParts[i] !== pathParts[i]) {
      return false;
    }
  }

  // If all components match, the URL matches the pattern
  return true;
};
