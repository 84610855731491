import React from 'react';
import styled from 'styled-components';

import Phone_24 from '../../assets/img/svg/action_icons/Phone_24.svg';
import { CustomButton } from '../Button/Button';

const PremiumCallButton = styled(CustomButton).attrs(props => ({ disabled: props.disabled }))`
  background-color: ${p =>
    p.disabled ? p.theme.palette.grey[300] : 'white'};
  color: ${p =>
    p.disabled ? p.theme.palette.ink[450] : p.theme.palette.blue[500]};
  border: 1px solid ${p => p.theme.palette.ink[300]};
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 8px 16px;
  outline: none;
  min-width: 120px;
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};

  &:before {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    content: '';
    background: url(${Phone_24});
    margin-right: ${p => p.theme.spacing(0.5)}px;
  }
`;

type PremiumSupportButtonProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  [key: string]: any;
};
const PremiumSupportButton = (props: PremiumSupportButtonProps) => {
  const {
    onClick: handleOnClick,
    className,
    disabled = true,
    children,
  } = props;
  return (
    <PremiumCallButton
      onClick={handleOnClick}
      className={className}
      disabled={disabled}
    >
      {children}
    </PremiumCallButton>
  );
};

export default PremiumSupportButton;
