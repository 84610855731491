import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  ${props => `
    margin-bottom: ${props.theme.spacing(8)}px;
    ${props.theme.breakpoints.up('md')} {
      flex-basis: 50%;
      max-width: 50%;

      &:nth-child(odd) {
        margin-right: ${props.theme.spacing(1.67)}px;
      }

      &:nth-child(even) {
        margin-left: ${props.theme.spacing(1.67)}px;
      }
    }
  `};

  .CustomerPortalKBDocument {
    ${props => `
      margin-bottom: ${props.theme.spacing(4)}px;
      ${props.theme.breakpoints.up('md')} {
        margin-bottom: ${props.theme.spacing(8)}px;
      }
    `};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
