import { ContentfulLanguageCodes } from './contentful.constants';

export const mapLocale = (localeOfEntry: string) => {
  let pageLocale, isLocalized = false;

  if (localeOfEntry && localeOfEntry !== ContentfulLanguageCodes.ENGLISH.name) {
    for (const locale in ContentfulLanguageCodes) {
      if (ContentfulLanguageCodes[locale].name === localeOfEntry) {
        isLocalized = true;
        pageLocale = ContentfulLanguageCodes[locale].code;
      }
    }
  } else {
    // Default to English if no locale is selected
    pageLocale = ContentfulLanguageCodes.ENGLISH.code;
  }

  return {
    pageLocale,
    isLocalized,
  };
};
