/*
 **********************
 * Frontend
 **********************
 */

export const UNREGISTERED_PATH_PREFIX = '/unregistered';

export const PUBLIC_EXPERIENCE_PATH = '/support/add-case';
// If this query param is present, we skip the public landing page and require login to the main app
export const PUBLIC_EXPERIENCE_FORCE_LOGIN_QUERY_PARAM = {
  key: 'login',
  value: 'true',
};

/*
 **********************
 * Backend
 **********************
 */

export const BACKEND_HOST_NAME = process.env.REACT_APP_BACKEND_HOST_NAME;
export const BACKEND_LOCAL_HOST_NAME = process.env.REACT_APP_BACKEND_HOST_NAME;
const PUBLIC_API_VERSION = '/public/api/v1';
const API_VERSION = '/api/v1';

/*
 **********************
 * Common Routes - DO NOT USE - DEPRECATING
 **********************
 */
export const ACCOUNT_INFO_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/account`;
export const ACCOUNT_LICENSES_URL = `${BACKEND_HOST_NAME}${API_VERSION}/license/licenseBySfdcCompanyId`;
export const HAPO_LICENSES_URL = `${BACKEND_HOST_NAME}${API_VERSION}/hapo`;

/*
 **********************
 * Local Routes - DO NOT USE - DEPRECATING
 **********************
 */
// Create Premium Care Case
export const CREATE_PREMIUM_CARE_CASE_URL_LOCAL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/proactiveCare/create`;

/*
 **********************
 * Development Routes - DO NOT USE - DEPRECATING
 **********************
 */
// Create Premium Care Case
export const CREATE_PREMIUM_CARE_CASE_URL_DEV = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/proactiveCare/create`;

/*
 **********************
 * Production Routes
 **********************
 */
// For Feature Flags
export const FEATURE_FLAG_URL = `${BACKEND_HOST_NAME}${API_VERSION}/feature-flags`;

// For Product Downloads
export const PRODUCT_DOWNLOADS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/product-downloads`;

// For KB
export const KB_CATEGORIES_URL = `${BACKEND_HOST_NAME}${API_VERSION}/kb/categories`;
export const KB_ASSETS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/kb/assets`;
export const KB_ASSET_URL = `${BACKEND_HOST_NAME}${API_VERSION}/kb/asset`;

// For Support KB
export const SUPPORT_KB_URL = `${BACKEND_HOST_NAME}${API_VERSION}/support-kb`;
export const SUPPORT_KB_DOCS_URL = `${SUPPORT_KB_URL}/articles`;
export const SUPPORT_KB_PRODUCTS = `${SUPPORT_KB_URL}/categories`;

// For Known Issues
export const KNOWN_ISSUES_URL = `${BACKEND_HOST_NAME}${API_VERSION}/jira/known-issues`;
export const KNOWN_ISSUE_DETAILS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/jira/known-issue`;

// For Browse
export const BROWSE_URL = `${BACKEND_HOST_NAME}${API_VERSION}/browse`;
export const BROWSE_CUSTOMERS_URL = `${BROWSE_URL}/customers`;

// For Company
export const BUNDLE_PRODUCTS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/license/licenseByLicenseCode`;
// For Collab
export const COLLAB_FOLDERS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/collab/folders`;
export const COLLAB_ASSETS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/collab/assets`;
// For homepage asset reviews modal
export const DOCUMENT_REVIEW_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/preferences/getKbDocumentsDownloaded`;
export const DOCUMENT_REVIEW_SAVE_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/preferences/setKbDocumentRatings`;
// For Edit Profile Activity
export const EP_NOTIFICATIONS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/notifications/getNotificationsByUserId`;
// Upload avatar
export const UPLOAD_AVATAR_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/setProfilePhoto`;
// Set user language
export const SET_USER_LANGUAGE_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/preferences/setUserLanguage`;
// Upload Logo
export const UPLOAD_LOGO_URL = `${BACKEND_HOST_NAME}${API_VERSION}/companies/logo`;
// View Avatar
export const VIEW_AVATAR_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/avatars`;
// View Logo
export const VIEW_LOGO_URL = `${BACKEND_HOST_NAME}${API_VERSION}/companies/logo`;
export const EP_KB_ACTIVITY_URL_PROD = `${BACKEND_HOST_NAME}${API_VERSION}/user/getKbDocumentsDownloaded`;
// For Profile photos
export const USER_PICTURE_BY_USERID_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user`;
export const USER_PICTURE_BY_AVATARID_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/avatars`;
export const USER_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user`;
// For Support
export const SFDC_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc`;
export const EMAIL_URL = `${BACKEND_HOST_NAME}${API_VERSION}/email`;
export const SFDC_PREMIUM_DASHBOARD_URL = `${BACKEND_HOST_NAME}${API_VERSION}/premiumDashboard`;
export const CREATE_SUPPORT_CASE_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/support/create`;
export const CREATE_ESCALATE_CASE_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/support/escalate`;
export const CREATE_ELEVATE_ESCALATION_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/support/elevateEscalation`;
export const CREATE_FOLLOW_UP_CASE_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/support/followup`;
export const CREATE_REOPEN_CASE_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/support/reopen`;
export const SUPPORT_DATA_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/support/supportCaseForm`;
export const UPLOAD_SUPPORT_FILE_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/support/uploadFile`;
export const CREATE_ATTACHMENT_RECORD_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/support/createAttachmentRecord`;
export const SUPPORT_FILE_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/support/file`;
// For Public
export const PUBLIC_GET_CSRF_TOKEN_URL = `${BACKEND_HOST_NAME}${PUBLIC_API_VERSION}/getCsrfToken`;
export const PUBLIC_FOOTER_DATA_URL = `${BACKEND_HOST_NAME}${PUBLIC_API_VERSION}/footerData`;
export const PUBLIC_FEATURE_FLAG_URL = `${BACKEND_HOST_NAME}${PUBLIC_API_VERSION}/feature-flags`;
// For Public Support
export const PUBLIC_SUPPORT_DATA_URL = `${BACKEND_HOST_NAME}${PUBLIC_API_VERSION}/support/supportCaseForm`;
export const PUBLIC_CREATE_SUPPORT_CASE_URL = `${BACKEND_HOST_NAME}${PUBLIC_API_VERSION}/support/createCase`;
export const PUBLIC_VALIDATE_USER_LICENSE_CODE_URL = `${BACKEND_HOST_NAME}${PUBLIC_API_VERSION}/support/validateUserLicenseCode`;
export const PUBLIC_VALIDATE_USER_EMAIL_DOMAIN_URL = `${BACKEND_HOST_NAME}${PUBLIC_API_VERSION}/support/validateEmailDomain`;
export const PUBLIC_ACTIVATE_USER_ACCOUNT_URL = `${BACKEND_HOST_NAME}${PUBLIC_API_VERSION}/support/activateUserAccount`;
export const PUBLIC_SUPPORT_GPT_SEARCH_URL = `${BACKEND_HOST_NAME}${PUBLIC_API_VERSION}/support-gpt/search`;
export const PUBLIC_LOG_CUSTOM_COVEO_EVENT_URL = `${BACKEND_HOST_NAME}${PUBLIC_API_VERSION}/case-assist/logCustomEvent`;
// Create Premium Care Case
export const CREATE_PREMIUM_CARE_CASE_URL_PROD = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/proactiveCare/create`;
// RFQ Product Catalog
export const RFQ_PRODUCTS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/rfq/licenses`;
export const RFQ_MAPPING_CHECK_URL = `${BACKEND_HOST_NAME}${API_VERSION}/rfq/licenses/displayOptionsMapped`;
// HAPO
export const HAPO_PRODUCTS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/hapo/catalog`;
export const HAPO_GET_ORDERS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/hapo/getHapoOrders`;
export const HAPO_UPDATE_ORDER_STATUS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/hapo/updateHapoOrderStatus`;
export const HAPO_ORDER_URL = `${BACKEND_HOST_NAME}${API_VERSION}/hapo/order`;
export const HAPO_CART_VALIDATION_URL = `${BACKEND_HOST_NAME}${API_VERSION}/hapo/validateCart`;

// Announcement Alerts
export const USER_ANNOUNCEMENT_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/announcement`;

// Update show multi account tutorial
export const USER_SET_VIEWED_MULTI_ACCOUNT_TUTORIAL_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/setViewedMultiAccountTutorial`;

// Update Cloud Banner Settings
export const UPDATE_CLOUD_BANNER_SETTINGS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/updateCloudBannerSettings`;

// Upload 360 questionnaire to a SFDC Account
export const UPLOAD_360_QUESTIONNAIRE_FILE_TO_ACCOUNT_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/account/upload360Questionnaire`;
// Delete 360 questionnaire from a SFDC Account
export const DELETE_360_QUESTIONNAIRE_FILE_FROM_ACCOUNT_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/account/delete360Questionnaire`;

// Self registration
export const VALIDATE_USER_LICENSE_CODE_URL = `${BACKEND_HOST_NAME}${API_VERSION}/license/validateUserLicenseCode`;
export const VALIDATE_USER_EMAIL_DOMAIN_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/validateEmailDomain`;
export const INVALID_USER_EMAIL_DOMAIN_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/invalidEmailDomain`;
export const ACTIVATE_USER_ACCOUNT_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/activateUserAccount`;
export const CREATE_ACTIVATE_USER_ACCOUNT_SUPPORT_TICKET_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/support/createActivateUserAccountSupportTicket`;
export const GET_ACTIVATE_USER_ACCOUNT_SUPPORT_TICKET_URL = `${BACKEND_HOST_NAME}${API_VERSION}/sfdc/cases/support/getActivateUserAccountCase`;

// Update company settings
export const UPDATE_COMPANY_SETTINGS_URL = `${BACKEND_HOST_NAME}${API_VERSION}/companies/settings`;

// Activate Admin User
export const ACTIVATE_ADMIN_USER_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/activateAdminUser`;

export const USER_COMPANIES_URL = `${BACKEND_HOST_NAME}${API_VERSION}/user/userCompanies`;

// Docs gpt
export const SUPPORT_GPT_SEARCH_URL = `${BACKEND_HOST_NAME}${API_VERSION}/support-gpt/search`;

export const LOG_CUSTOM_COVEO_EVENT = `${BACKEND_HOST_NAME}${API_VERSION}/case-assist/logCustomEvent`;

// Entity Management
export const ENTITIES_URL = `${BACKEND_HOST_NAME}${API_VERSION}/entities`;

/*
 **********************
 * Debug API Endpoints
 **********************
 */
export const CLEAR_CACHE_URL = `${BACKEND_HOST_NAME}${API_VERSION}/cache/clear`;
export const RECALCULATE_CONSUMED_QUANTITY_URL = `${BACKEND_HOST_NAME}${API_VERSION}/hapo/sync`;
export const BATCH_JOB_URL = `${BACKEND_HOST_NAME}/batch-job${API_VERSION}/job/run`;
