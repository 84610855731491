import { Link } from 'react-router-dom';
import styled from 'styled-components';

import HomeCardBg from '../img/HomeCardGrid.svg';
import HomeCardBgLight from '../img/HomeCardGridLight.svg';
import heroBg from '../img/Homepage-Hero-Header.svg';
import subHeroBg from '../img/Homepage-Hero-SubHeader.svg';
import ArrowGray from '../img/svg/arrows/ArrowLong_Right_GrayNeutral25.svg';
import ArrowBlue from '../img/svg/arrows/ArrowLong_Right_Icon.svg';
import { ContentWrapper } from './CustomerPortalContentWrapper';

export const CardLink = styled(Link) <{ disabled?: boolean }>`
  color: ${p => p.disabled ? p.theme.palette.grey[500] : p.theme.palette.blue[500]};
  letter-spacing: 0.5px;
  font-size: 1.5rem;
  line-height: 1.6rem;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  padding: 16px 0;
  &:after {
    content: "";
    background: url("${p => p.disabled ? ArrowGray : ArrowBlue}");
    width: 16px;
    height: 16px;
    position: absolute;
    background-position: center;
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
    right: -24px;
  }
  ${p => p.disabled && `
    pointer-events: none;
  `}
`;

interface Props {
  isHomePage: boolean;
}

export const PageWrapper = styled.section<Props>`
  border-bottom: 1px solid ${p => p.theme.palette.grey[200]};
  ${p => !p.isHomePage && ContentWrapper}

  .CustomerPortalPageHome__container {
    min-height: 600px;
  }

  .CustomerPortalPage__CardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    ${p => p.theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  }

  .CustomerPoralHome__Card-Inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    .CustomerPoralHome__Card-Text {
      max-width: 100%;
    }
    &.Disabled {
      h1,
      p {
        color: black;
      }
      ${CardLink} {
        opacity: 0.15;
        click-events: none;
        cursor: default;
      }
    }
  }

  .CustomerPortalHome__Trio-Two-Outer {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 4rem;
    padding-bottom: 2rem;
    ${p => p.theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  }

  .CustomerPortalPage__Button {
    margin-bottom: 16px;
    padding: 16px 24px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 8px;
    font-size: 1.6rem;
    transition: all 0.3s ease;
    outline: none;

    &:hover {
      color: #fff;
      background-color: #0067df;
      transition: all 0.3s ease;
    }
  }
`;

export const HomeCard = styled.div`
  min-height: 236px;
  width: 100%;
  max-width: 384px;
  background-image: url(${HomeCardBg});
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  flex-direction: row;
  ${p => p.theme.breakpoints.down('sm')} {
    max-width: 30%;
    background-image: url(${HomeCardBgLight});
  }
  ${p => p.theme.breakpoints.down('xs')} {
    max-width: 100%;
    height: 192px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

export const HomeTrioTwoItem = styled.div`
  width: 100%;
  max-width: 384px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${p => p.theme.breakpoints.down('sm')} {
    max-width: 30%;
    .Button__Inner-Link {
      padding: 12px 8px;
    }
  }
  .CustomerPoralHome__Trio-Text {
    min-height: 156px;
    ${p => p.theme.breakpoints.down('sm')} {
      min-height: 0;
    }
  }
  ${p => p.theme.breakpoints.down('xs')} {
    max-width: 100%;
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
  h6 {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: ${p => p.theme.spacing(1)}px;
  }
  p {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #21323b;
    white-space: pre-line;
    margin-bottom: 16px;
    ${p => p.theme.breakpoints.down('xs')} {
      max-width: 328px;
    }
  }
  .CustomerPortalHome_Trio-Subheading {
    font-weight: bold;
  }
  .CustomerPoralHome__Trio-Text--Insider-Blurb {
    margin-top: ${p => p.theme.spacing(2)}px;
    a {
      color: ${p => p.theme.palette.blue[500]};
      font-weight: bold;
    }
  }
`;

export const HomeSection = styled.section`
  position: relative;
  background-color: #fff;
  min-height: calc(100vh - 48px);

  .CustomerPortalHome__container {
    display: flex;
    justify-content: space-between;
  }
`;

export const Hero = styled.div`
  position: relative;
  background-color: #fff;
  background: url(${heroBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 18rem;
  margin-bottom: 1rem;
  ${p => p.theme.breakpoints.down('xs')} {
    display: none;
  }
`;

export const SubHero = styled.div`
  position: relative;
  background-color: #fff;
  background: url(${subHeroBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 18rem;
  margin-top: 3rem;
  ${p => p.theme.breakpoints.down('xs')} {
    display: none;
  }
  border-bottom: 1px solid ${p => p.theme.palette.grey[200]};
`;

export const CustomerPortalPageHomeMainTitle = styled.h5`
  text-align: center;
  color: rgb(39, 49, 57);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 8px;
  ${p => p.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const CustomerPortalPageHomeTitle = styled.h1`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: rgb(0, 0, 0);
  font-size: 3.8rem;
  font-weight: 600;
  line-height: 6rem;
  margin-bottom: 24px;
  ${p => p.theme.breakpoints.down('xs')} {
    font-size: 4.2rem;
    line-height: 4.8rem;
  }
`;
