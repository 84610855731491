import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Clients
import { axiosPost } from '../../client/axios';
// Network constants
import {
  DOCUMENT_REVIEW_SAVE_URL,
  DOCUMENT_REVIEW_URL,
} from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
// Interfaces
import type { IDataObject } from '../../interfaces/dataObject.interface';
// Store
import { StoreContext } from '../../store';
import Button from '../Button/Button';
// Components
import Modal from '../Modal';
import FeedbackFormRow from './Feedback-Form-Row';

interface FeedbackRatings {
  id: string;
  text: string;
  score: number | null;
  valid: boolean;
}

const FeedbackForm = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  // Local state
  const [ ratings, setRatings ] = useState<FeedbackRatings[]>([]);
  const [ isOpen, setIsOpen ] = useState(false);

  // Methods
  const fetchDocsPromise = async () => {
    try {
      return await axiosPost(DOCUMENT_REVIEW_URL, state.companyId, await getAccessToken());
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDocsToReview = async () => {
    try {
      const documentsRes: any = await fetchDocsPromise();

      if (documentsRes.status === 200 && documentsRes.data) {
        if (documentsRes.data.length > 0) {
          // Success
          const documentsArray = documentsRes.data.map(
            (document: IDataObject, i: number) => ({
              id: document._id,
              text: document.kb_name,
              score: null,
              valid: false,
            })
          );
          setRatings(documentsArray);
          setIsOpen(true);
        } else {
          dispatch({
            type: 'setSeenFeedbackModal',
            payload: true,
          });
        }
      }
    } catch (e) {
      console.log(e.toString());
    }
  };

  const saveDocReviewsPromise = async () => {
    let body: any;

    if (ratings && ratings.length > 0) {
      body = ratings.map((rating: IDataObject, i: number) => ({
        _id: rating.id,
        seen: true,
        rating: rating.score,
      }));
    }

    try {
      return await axiosPost(
        DOCUMENT_REVIEW_SAVE_URL,
        state.companyId,
        await getAccessToken(),
        body
      );
    } catch (e) {
      console.log(e);
    }
  };

  const saveDocReviews = async () => {
    try {
      const reviewRes: any = await saveDocReviewsPromise();
      if (
        reviewRes.status === 200 &&
        reviewRes.data &&
        ratings &&
        reviewRes.data.length === ratings.length
      ) {
        let validResponses = 0;
        if (ratings) {
          reviewRes.data.forEach((response: IDataObject, i: number) => {
            if (response.success === 1) {
              validResponses++;
            }
          });
          if (validResponses === ratings.length) {
            setModalSeen(true);
          } else {
            setModalSeen(false);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setModalSeen = (isSuccessful: boolean) => {
    setIsOpen(false);
    dispatch({
      type: 'setSeenFeedbackModal',
      payload: true,
    });
    // Only trigger the banners if a user submits the form themselves.
    if (formValid()) {
      if (isSuccessful) {
        handleSuccess();
      } else {
        handleError();
      }
    }
  };

  const handleError = () => {
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'feedback_form_component_error_msg',
        'There was an error sending your feedback.'
      ),
    });
  };

  const handleSuccess = () => {
    dispatch({
      type: 'setBannerType',
      payload: 'success',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'feedback_form_component_success_msg',
        'Thank you! Your feedback has been sent'
      ),
    });
  };

  const handleRatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const ratingId = event.target.id;
    setRatings((prevState: any[]) => {
      const newRatings = [ ...prevState ];
      newRatings[parseInt(ratingId)].score = parseInt(value);
      newRatings[parseInt(ratingId)].valid = true;
      return newRatings;
    });
  };

  const generateRatingInputs = () => {
    if (ratings && ratings.length > 0) {
      return ratings.map((rating: any, i: number) => (
        <FeedbackFormRow
          onRatingChange={handleRatingChange}
          rating={rating}
          key={i}
          formPosition={i}
        />
      ));
    }
  };

  const formValid = () => {
    let validInputs = 0;
    if (ratings) {
      ratings.forEach((rating: IDataObject) => {
        if (rating.valid) {
          validInputs++;
        }
      });
      const isValid = validInputs > 0 ? true : false;
      return isValid;
    }
    return false;

  };

  useEffect(() => {
    // Only allow feedback for non uipath users.
    if (!state.isUiPath) {
      if (!state.seenFeedbackModal) {
        fetchDocsToReview();
      }
    }
  }, []);

  return (
    <Modal
      modalTitle="Standard Modal"
      modalDescription="Reviews Modal"
      modalHeading={t(
        'feedback_form_component_modal_title',
        `Hello ${state.userName ? state.userName.split(' ')[0] : ''}`,
        { userName: state.userName ? state.userName.split(' ')[0] : '' }
      )}
      innerClasses="ReviewModal"
      handleClose={saveDocReviews}
      open={isOpen}
      testId="FeedbackForm__modal"
    >
      <div className="Modal-Fields-Container">
        <h6 className="ReviewsModal__Subtitle Regular">
          {t(
            'feedback_form_component_modal_desc',
            'We\'re happy to see you\'ve found documents on Customer Portal that caught your eye. Please rate them & let us know if you found them useful.'
          )}
        </h6>
        <div className="ReviewModal__Reviews">{generateRatingInputs()}</div>
        <div className="ReviewModal__Submit">
          <Button
            className="ReviewModal__Button"
            isValid={formValid()}
            text={t('feedback_form_component_modal_btn', 'Submit Feedback')}
            onClick={saveDocReviews}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackForm;
