import PropTypes from 'prop-types';
import React from 'react';

// Styles
import * as styles from '../assets/css/Label';

type LabelProps = {
  text: string;
  type: string;
  className?: string;
};

const Label = (props: LabelProps) => {
  const generateClasses = () => {
    const labelTypes = [ 'Active', 'Disabled', 'Expired', 'Pending' ];
    const labelType =
      props.type.charAt(0).toUpperCase() + props.type.substring(1);
    if (labelTypes.includes(labelType)) {
      return ` Label__${labelType}`;
    }
    return '';

  };

  return (
    <styles.LabelContainer
      className={`Label ${generateClasses()} ${props.className}`}
    >
      <p className="Label__Text Bold">{props.text}</p>
    </styles.LabelContainer>
  );
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Label;
