import { Locale } from '@customer-portal/constants';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import React, {
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  PublicCustomerPortalHeaderInner,
  PublicHeaderLanguageSelectorAndLoginContainer,
  PublicNavBarOuter,
} from '../../assets/css/CustomerPortal-Header';
import ArrowDown from '../../assets/img/svg/arrows/Nav_Icon_ArrowDown_44x44.svg';
import { ReactComponent as LanguageIcon } from '../../assets/img/svg/language.svg';
// Images
import {
  getAllLanguageOptions,
  getValidLanguage,
  languageToLocale,
  localeToLanguage,
} from '../../constants/localization.constants';
import { onPublicLogin } from '../../utils/publicExperience';
import BrandedLogoAndText from '../BrandedLogoAndText';

const CustomerPortalPublicHeader = () => {
  const {
    t, i18n,
  } = useTranslation('common');

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [ languageSelectorOpen, setLanguageSelectorOpen ] = useState(false);

  const toggleLanguageSelectorOpen = () => {
    setLanguageSelectorOpen(prev => !prev);
  };

  const closeLanguageSelector = (event?: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return;
    }

    setLanguageSelectorOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Tab':
        event.preventDefault();
        setLanguageSelectorOpen(false);
        break;
      case 'Escape':
        setLanguageSelectorOpen(false);
        break;
    }
  };

  const handleChangeLanguage = (event: React.MouseEvent<HTMLLIElement>) => {
    const language: string = (event.target as HTMLLIElement).getAttribute('data-lang') ?? '';
    const locale: string = languageToLocale.get(language) as string;
    if (locale && locale in Locale) {
      i18n.changeLanguage(locale);
    }
    closeLanguageSelector();
  };

  return (
    <>
      <PublicNavBarOuter
        position="fixed"
        className="PublicCustomerPortalHeader"
        elevation={0}
        data-testid="PublicCustomerPortalHeader"
      >
        <PublicCustomerPortalHeaderInner>
          <BrandedLogoAndText />
          <PublicHeaderLanguageSelectorAndLoginContainer>
            <div className="VerifyEmailPage__BottomOptions__languageSelector">
              <LanguageIcon />
              <Button
                className="VerifyEmailPage__BottomOptions__languageSelectorBtn"
                ref={anchorRef}
                id="composition-button"
                aria-controls={languageSelectorOpen ? 'composition-menu' : undefined}
                aria-expanded={languageSelectorOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={() => toggleLanguageSelectorOpen()}
                data-testid="VerifyEmailPage__BottomOptions__LanguageButton"
              >
                <span>
                  {localeToLanguage.get(
                    getValidLanguage(i18n.language)
                  )}
                </span>
                <img
                  src={ArrowDown}
                  alt="dropdown arrow" />
              </Button>
              <Popper
                className="VerifyEmailPage__BottomOptions__languagesDropdown"
                open={languageSelectorOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                data-testid="VerifyEmailPage__BottomOptions__LanguagesDropdown"
              >
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps}>
                    <Paper>
                      <ClickAwayListener onClickAway={() => closeLanguageSelector()}>
                        <MenuList
                          autoFocusItem={languageSelectorOpen}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          {getAllLanguageOptions().map(
                            (language: string, idx: number) => (
                              <MenuItem
                                className={`VerifyEmailPage__BottomOptions__languageItem ${language ===
                                  localeToLanguage.get(
                                    getValidLanguage(
                                      i18n.language
                                    )
                                  )
                                  ? 'VerifyEmailPage__BottomOptions__languageItem--active'
                                  : ''
                                }`}
                                key={`lang-${idx}`}
                                onClick={handleChangeLanguage}
                                data-lang={language}
                                data-testid="VerifyEmailPage__BottomOptions__LanguageItem"
                              >
                                {language}
                              </MenuItem>
                            )
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            <Button
              variant='contained'
              className='PublicCustomerPortalHeader__Login-Button'
              onClick={onPublicLogin}
            >
              {t('header_menu_login_signup', 'Login/Sign Up')}
            </Button>
          </PublicHeaderLanguageSelectorAndLoginContainer>
        </PublicCustomerPortalHeaderInner>
      </PublicNavBarOuter>
    </>
  );
};

export default CustomerPortalPublicHeader;
