export const FETCH_DATA_REDUCER_ACTION_TYPE = {
  FETCH_INIT: 'FETCH_INIT',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAIL: 'FETCH_FAIL',
};

/**
 * This is a generic reducer which can be used for any fetch call from backend
 *
 * @param state any state from FETCH_DATA_REDUCER_STATE
 * @param action
 * @returns it returns
 * { isLoading: boolean, error: Error | undefined, data: any}
 */
export const fetchDataReducer = (
  state: any,
  action: { [key: string]: any }
) => {
  switch (action.type) {
    case FETCH_DATA_REDUCER_ACTION_TYPE.FETCH_INIT:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        data: action.data,
      };
    case FETCH_DATA_REDUCER_ACTION_TYPE.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        data: action.data,
      };
    case FETCH_DATA_REDUCER_ACTION_TYPE.FETCH_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      throw new Error('Unidentified reducer action type');
  }
};
