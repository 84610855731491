const premiumCareIncidentTypes = [
  {
    id: 'infrastructure_review',
    section: 'infrastructure_assistance',
    name: {
      keyText:
        'support_premium_care_form_section_item_infrastructure_review_title',
      fallbackText: 'Infrastructure review',
    },
    description: {
      keyText:
        'support_premium_care_form_section_item_infrastructure_review_desc',
      fallbackText:
        'Assess infrastructure (planned or implemented) and provide recommendations and high-level architecture options.',
    },
  },
  {
    id: 'infrastructure_scaling',
    section: 'infrastructure_assistance',
    name: {
      keyText:
        'support_premium_care_form_section_item_infrastructure_scaling_guidance_title',
      fallbackText: 'Infrastructure scaling guidance​',
    },
    description: {
      keyText:
        'support_premium_care_form_section_item_infrastructure_scaling_guidance_desc',
      fallbackText:
        'Analyze infrastructure to determine whether it can support an  increased number of robots. Provide recommendations to support scaling requirements.',
    },
  },
  {
    id: 'production_environment_install',
    section: 'infrastructure_assistance',
    name: {
      keyText:
        'support_premium_care_form_section_item_installation_environment_title',
      fallbackText: 'Installation of an environment',
    },
    description: {
      keyText:
        'support_premium_care_form_section_item_installation_environment_desc',
      fallbackText:
        'Join a screen share to guide the client through building the core UiPath platform. Included are the following: Installation of Orchestrator, License generation and application in Orchestrator (assumes all robots get their license from Orchestrator), Creation of one tenant (in case of multitenancy), Installation of one robot, Robot connection to Orchestrator, Confirmation that the platform is fully functional in terms of infrastructure, Provision of one robot with Studio, connect the Robot and test deployment of a single package. Installation/validation of HA/DR environment. Assist with technical issues which may arise during the installation.',
    },
  },
  {
    id: 'production_environment_upgrade',
    section: 'infrastructure_assistance',
    name: {
      keyText:
        'support_premium_care_form_section_item_upgrade_environment_title',
      fallbackText: 'Upgrade of an environment',
    },
    description: {
      keyText:
        'support_premium_care_form_section_item_upgrade_environment_desc',
      fallbackText:
        'Join a screen share to guide the client through upgrading the core UiPath platform. Included are the following: Technical assessment of client infrastructure for the upgrade planning process, Advise on backup (MS SQL DB, Orchestrator directory, Elasticsearch indices), Upgrade Orchestrator version, Upgrade Robot version, Upgrade Studio version, Verify proper upgrade by checking assets, jobs, packages, processes, robots. Includes upgrade/migration of HA, DR and/or cloud environments.',
    },
  },
  {
    id: 'implementation_review',
    section: 'technical_assistance',
    name: {
      keyText:
        'support_premium_care_form_section_item_implementation_review_title',
      fallbackText: 'Implementation review',
    },
    description: {
      keyText:
        'support_premium_care_form_section_item_implementation_review_desc',
      fallbackText:
        'Assess and provide recommendations for the optimal implementation of the Orchestrator features like queues, assets, user roles, schedules and licenses.',
    },
  },
  {
    id: 'process_deployments',
    section: 'technical_assistance',
    name: {
      keyText:
        'support_premium_care_form_section_item_process_go_live_assistance_title',
      fallbackText: 'Process go-live assistance',
    },
    description: {
      keyText:
        'support_premium_care_form_section_item_process_go_live_assistance_desc',
      fallbackText:
        'Join a screen share to assist with technical issues with UiPath components which may arise during the deployment of a process in a production environment.',
    },
  },
  {
    id: 'best_coding_practices',
    section: 'technical_assistance',
    name: {
      keyText: 'support_premium_care_form_section_item_code_review_title',
      fallbackText: 'Code Review',
    },
    description: {
      keyText: 'support_premium_care_form_section_item_code_review_desc',
      fallbackText:
        'Review workflow/process code and recommend coding best practices including performance optimization and exception management.',
    },
  },
  {
    id: 'new_version',
    section: 'technical_assistance',
    name: {
      keyText:
        'support_premium_care_form_section_item_new_release_walkthrough_title',
      fallbackText: 'New Release Technical Capabilities Walkthrough​',
    },
    description: {
      keyText:
        'support_premium_care_form_section_item_new_release_walkthrough_desc',
      fallbackText:
        'Provide technical information about key product capabilities and bug fixes in a new release.',
    },
  },
  {
    id: 'contact_advisor',
    section: 'contact_advisor',
    name: {
      keyText: 'support_premium_care_form_section_item_contact_advisor_title',
      fallbackText: 'Contact your technical advisor',
    },
    description: {
      keyText: 'support_premium_care_form_section_item_contact_advisor_desc',
      fallbackText:
        'Intended for general questions including: assistance on an existing case, how-To information, troubleshooting guidance, technical advisory or support service issues.​',
    },
  },
];

export default premiumCareIncidentTypes;
