import {
  COUNTRY_CODE_TO_SUPPORT_GROUP_MAP,
  CP_ENG_MEMBERS,
  UserPermission,
  UserRole,
} from '@customer-portal/constants';
import {
  doesUserHavePermissions,
  doesUserHaveRoles,
} from '@customer-portal/utils';

import {
  AccountType,
  PREMIUM_ACCOUNT_TYPES,
} from '../constants/account.constants';
import state from './RevealStateHelper';

export const UserPermissionsHelper = {
  isRegisteredUser() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return globalState.isRegistered;
  },
  isGlobalSearchAllowed() {
    return this.isRegisteredUser();
  },
  isUiPathUserWithNoCompanySelected() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.isUiPathUser() && !this.isCompanySelected();
  },
  isCompanySelected() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return !!globalState.companyId;
  },
  /**
   * NOTE: Only use this function if the user is a UiPath user
   * If the user is a UiPath user, we need to use the new RBAC model
   * by combining the roles list and the roles of the current company
   * TODO: We should eventually move off of role-checks and purely use hasPermissions
   */
  hasInternalRoles(roles: UserRole[], match: 'any' | 'all' = 'all') {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    if (!this.isUiPathUser()) {
      return false;
    }

    return doesUserHaveRoles({
      adminUserRoles: globalState.currentAdmin?.roles ?? [],
      userCompanyRoles: globalState.currentUserCompany?.userCompany?.roles ?? [],
      roles,
      match,
    });
  },
  isCompanyInUserTerritories() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    const {
      territories, companyTerritory,
    } = globalState;
    return companyTerritory?.length > 0 &&
      territories?.length > 0 &&
      territories.includes(companyTerritory);
  },
  isShowNotificationsAllowed() {
    // If user isn't registered or hasn't selected a company
    // they should not see notifications
    if (!this.isRegisteredUser() || !this.isCompanySelected()) {
      return false;
    }

    // If an external user, they should see notifications
    if (!this.isUiPathUser()) {
      return true;
    }

    return (
      this.isUiPathSuperAdmin() ||
      this.isUiPathAdmin() ||
      this.isSupportUser() ||
      this.isTechnicalAccountManager() ||
      this.isSpecificAccountUser()
    );
  },
  canUserSupportRestrictedAccount() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    // the restrictions are only for uipath users except for uipath super admin
    if (!globalState.isUiPath || this.isUiPathSuperAdmin()) {
      return true;
    }
    const supportOnlyProvidedByCountry = globalState.currentUserCompany?.additionalCompanyInfo?.supportOnlyProvidedByCountry;
    if (!supportOnlyProvidedByCountry) {
      return true;
    }
    const supportGroupCode = COUNTRY_CODE_TO_SUPPORT_GROUP_MAP[supportOnlyProvidedByCountry];
    return !!globalState.groupsUserBelongsTo?.includes(supportGroupCode);
  },
  isViewAnalyticsAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.hasPermissions([ UserPermission.ADMIN_ANALYTICS_READ ]);
  },
  isEditKBAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.hasPermissions([ UserPermission.KNOWLEDGE_WRITE ]);
  },
  isEditSuperAdminsAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.hasPermissions([ UserPermission.ADMIN_USERS_WRITE ]);
  },
  isEditProfileAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.hasPermissions([ UserPermission.PROFILE_WRITE ]);
  },
  isEditEmailNotificationAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.hasPermissions([ UserPermission.EMAILNOTIFICATIONS_WRITE ]);
  },
  isViewLicenseAllowed() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }
    return this.hasPermissions([ UserPermission.LICENSE_READ ]) ||
      this.isCompanyInUserTerritories();
  },
  isEditLicenseAllowed() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }

    return this.hasPermissions([ UserPermission.LICENSE_WRITE ]);
  },
  isViewTeamUsersAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.hasPermissions([ UserPermission.TEAMUSERS_READ ]) ||
      this.isCompanyInUserTerritories();
  },
  isEditTeamUsersAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.hasPermissions([ UserPermission.TEAMUSERS_WRITE ]);
  },
  isUiPathUser(): boolean {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return globalState.isUiPath;
  },
  isViewSwitchAccountModalAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return !this.isUiPathUser() && this.isPartOfMultipleCompanies();
  },
  isUiPathAdmin() {
    return this.hasInternalRoles([ UserRole.UI_ADMIN ]);
  },
  isUiPathSuperAdmin() {
    return this.hasInternalRoles([ UserRole.UI_SUPER_ADMIN ]);
  },
  isUiPathSuperOperationsAdmin() {
    return this.hasInternalRoles([ UserRole.UI_SUPER_OPERATIONS_ADMIN ]);
  },
  isSupportUser() {
    return this.hasInternalRoles([ UserRole.UI_SUPPORT ]);
  },
  isTechnicalAccountManager() {
    return this.hasInternalRoles([ UserRole.UI_TECHNICAL_ACCOUNT_MANAGER ]);
  },
  isSpecificAccountUser() {
    return this.hasInternalRoles([ UserRole.UI_SPECIFIC_ACCOUNT_USER ]);
  },
  isAutomationProgramAdvisor() {
    return this.hasInternalRoles([ UserRole.UI_AUTOMATION_PROGRAM_ADVISOR ]);
  },
  isCompanyMatch(myCompanies: string[] = [], companyId: string) {
    // Function for uipathers to see if uipath-admin matches companyid
    if (myCompanies.includes(companyId)) {
      return true;
    }
    return false;
  },
  // Global check for whether it should show or not (not just disable)
  isCollabSpaceEnabled() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }

    // UiPath users don't have userCompanies, so they read from the one stored at the top level
    if (this.isUiPathUser()) {
      return globalState.isCollabSpaceEnabled === false ? false : true;
    }

    const globalStateUserCompany = globalState.userCompanies.find(({ userCompany }) => globalState.companyId === userCompany.companyId);
    if (globalStateUserCompany && globalStateUserCompany.additionalCompanyInfo?.isCollabSpaceEnabled === false) {
      return false;
    }

    return true;
  },
  // Currently the defacto way for checking if a user is an admin for an account
  isEditCompanySettingsAllowed() {
    return this.isEditTeamUsersAllowed();
  },
  isViewCollabAllowed() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }

    if (!this.isCollabSpaceEnabled()) {
      return false;
    }

    return this.hasPermissions([ UserPermission.COLLAB_READ ]);
  },
  isCreateCollabAllowed() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }

    if (!this.isCollabSpaceEnabled()) {
      return false;
    }

    return this.hasPermissions([ UserPermission.COLLAB_FULL_WRITE, UserPermission.COLLAB_EXTERNAL_WRITE ], 'any');
  },
  isDeleteCollabAllowed(createdByUiPath: boolean) {
    const globalState = state();

    if (!globalState?.companyId) {
      return false;
    }

    return (
      this.hasPermissions([ UserPermission.COLLAB_FULL_WRITE ]) ||
      (!createdByUiPath && this.hasPermissions([ UserPermission.COLLAB_EXTERNAL_WRITE ]))
    );
  },
  isViewSupportAllowed() {
    if (
      this.isRegisteredUser() &&
      (!this.isCompanySelected() && !this.isUiPathUser())
    ) {
      return false;
    }

    return this.hasPermissions([ UserPermission.SUPPORT_READ ]);
  },
  isEditSupportAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    return this.hasPermissions([ UserPermission.SUPPORT_WRITE ]);
  },
  isViewPremiumSupportAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    if (!globalState.companyId) {
      return false;
    }

    return this.hasPermissions([ UserPermission.SUPPORT_PREMIUMCARE_READ ]) &&
      this.isPremiumAccount();
  },
  isEditPremiumSupportAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    if (!globalState.companyId) {
      return false;
    }
    return this.hasPermissions([ UserPermission.SUPPORT_PREMIUMCARE_WRITE ]) &&
      this.isPremiumAccount() && !this.isActivateSuccessCustomer();
  },
  isPremiumAccount() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    return PREMIUM_ACCOUNT_TYPES.includes(globalState.companyType);
  },

  isActivateSuccessCustomer() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return (
      globalState && [ AccountType.ACTIVATE ].includes(globalState.companyType)
    );
  },

  isRegularUsersInTeam() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    const globalStateTeamUsers = globalState.teamUsers;

    if (globalStateTeamUsers) {
      if (globalStateTeamUsers.length !== 0) {
        const regularUsers = globalStateTeamUsers.filter(function(member: any) {
          return !member.CP_Admin__c;
        });
        if (regularUsers.length === 0) {
          return false;
        }
        return true;

      }
      const regularUsers = globalStateTeamUsers.filter(function(member: any) {
        return !member.CP_Admin__c;
      });
      if (regularUsers.length === 0) {
        return false;
      }
      return true;

    }
  },
  isViewProductCatalogAllowed() {
    const globalState = state();

    if (!globalState) {
      return false;
    }

    return this.hasPermissions([ UserPermission.ADMIN_PRODUCTCATALOG_READ ]);
  },
  isEditProductCatalogAllowed() {
    const globalState = state();

    if (!globalState) {
      return false;
    }

    return this.hasPermissions([ UserPermission.ADMIN_PRODUCTCATALOG_WRITE ]);
  },

  isViewHAPOLicenseAllowed() {
    const globalState = state();

    if (!globalState?.companyId) {
      return false;
    }

    return (
      globalState.isLicenseManagementEnabled &&
      this.hasPermissions([
        UserPermission.LICENSE_READ,
        UserPermission.HAPO_LICENSE_READ,
      ])
    );
  },

  isEditHAPOLicenseAllowed() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }

    return (
      globalState.isLicenseManagementEnabled &&
      this.hasPermissions([
        UserPermission.LICENSE_READ,
        UserPermission.HAPO_LICENSE_READ,
        UserPermission.HAPO_LICENSE_WRITE,
      ])
    );
  },

  isCancelHAPOOrderAllowed() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }

    // We additionally allow UI_SUPER_OPERATIONS to cancel HAPO orders
    // TODO: We should move off of role checks and likely add a new permission (e.g. hapo.license.cancel)
    // to determine if a user can cancel HAPO orders
    return globalState.isLicenseManagementEnabled &&
      (this.hasInternalRoles([ UserRole.UI_SUPER_OPERATIONS ]) || this.hasPermissions([ UserPermission.ADMIN_HAPO_EDIT ]));
  },

  isCompleteHAPOOrderAllowed() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }

    // We additionally allow UI_SUPER_OPERATIONS to cancel HAPO orders
    // TODO: We should move off of role checks and likely add a new permission (e.g. hapo.license.complete)
    // to determine if a user can complete HAPO orders
    return globalState.isLicenseManagementEnabled &&
      (this.hasInternalRoles([ UserRole.UI_SUPER_OPERATIONS ]) || this.hasPermissions([ UserPermission.ADMIN_HAPO_EDIT ]));
  },

  isManageHAPOAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    return this.hasPermissions([ UserPermission.ADMIN_HAPO_EDIT ]);
  },

  isViewHAPOOrderChangesInCSVAllowed() {
    return this.isUiPathUser();
  },

  isView360QuestionnaireAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    if (!this.isPremiumAccount() || this.isActivateSuccessCustomer()) {
      return false;
    }

    if (
      this.isUiPathUser() ||
      this.hasPermissions([ UserPermission.SUPPORT_READ ])
    ) {
      return true;
    }

    return false;
  },

  isEdit360QuestionnaireAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    return this.hasPermissions([ UserPermission.SUPPORT_INFRA_QUESTIONAIRE_WRITE ]);
  },

  isRFQEnabled() {
    const globalState = state();
    return !(globalState?.isSoldToPartner) && globalState?.companyCountry !== 'Japan';
  },

  hasPermissions(permissions: UserPermission[], match: 'any' | 'all' = 'all') {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    return doesUserHavePermissions({
      adminUserRoles: globalState.currentAdmin?.isActive ? globalState.currentAdmin?.roles ?? [] : [],
      userCompanyRoles: globalState.currentUserCompany?.userCompany?.roles ?? [],
      permissions,
      match,
    });
  },

  isPartOfMultipleCompanies() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return globalState.userCompanies?.length > 1;
  },

  isViewEntityManagementAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    return this.isCompanySelected() && this.hasPermissions([ UserPermission.ENTITY_READ ]);
  },

  isEditEntityManagementAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    return this.isCompanySelected() && this.hasPermissions([ UserPermission.ENTITY_WRITE ]);
  },

  isDebugPageManagementAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    return CP_ENG_MEMBERS.includes(globalState.userEmail);
  },

  isMoveToCloudAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.hasPermissions([ UserPermission.MOVE_TO_CLOUD_WRITE ]);
  },
};
