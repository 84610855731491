import React from 'react';

// Styles
import * as styles from '../../assets/css/Company/Header';
// Images
import HeaderBG from '../../assets/img/Company_Header_Bg.png';

type CompanyHeaderProps = {};

const CompanyHeader = (props: CompanyHeaderProps) => (
  <styles.CompanyHeader>
    <div
      className="CompanyHeader__Bg CompanyHeader__Bg-Image"
      style={{ backgroundImage: `url(${HeaderBG})` }}
    />
  </styles.CompanyHeader>
);

export default CompanyHeader;
