import type { User as Auth0SPAUser } from '@auth0/auth0-spa-js';
import { AuthType } from '@customer-portal/constants';
import type { User as OIDCUser } from 'oidc-client-ts';

import { AUTH0_CUSTOM_TOKEN_CLAIMS_URL } from '../constants/auth.constants';
import { getAuthType } from '../contexts/auth';
import type { IUserMetadata } from '../interfaces/support.interface';

export default class AuthUserUtil {
  public static getName(user: Auth0SPAUser | OIDCUser | null): string {
    if (AuthUserUtil.isCloudUser()) {
      return AuthUserUtil.getCloudUserFullName(user as OIDCUser);
    }

    return (user as Auth0SPAUser)?.name ?? '';
  }

  public static getFirstName(user: Auth0SPAUser | OIDCUser | null): string {
    if (AuthUserUtil.isCloudUser()) {
      return ((user as OIDCUser)?.profile.first_name as string | undefined) ?? '';
    }

    return '';
  }

  public static getLastName(user: Auth0SPAUser | OIDCUser | null): string {
    if (AuthUserUtil.isCloudUser()) {
      return ((user as OIDCUser)?.profile.last_name as string | undefined) ?? '';
    }

    return '';
  }

  public static getEmail(user: Auth0SPAUser | OIDCUser | null): string {
    if (AuthUserUtil.isCloudUser()) {
      return (user as OIDCUser)?.profile.email?.toLowerCase() ?? '';
    }

    return (user as Auth0SPAUser)?.email?.toLowerCase() ?? '';
  }

  public static getCountry(user: Auth0SPAUser | OIDCUser | null): string {
    if (AuthUserUtil.isCloudUser()) {
      return ((user as OIDCUser)?.profile.country as string | undefined) ?? '';
    }

    return (user as Auth0SPAUser)?.country ?? '';
  }

  public static getMetadata(user: Auth0SPAUser | OIDCUser | null): IUserMetadata | undefined {
    if (AuthUserUtil.isCloudUser()) {
      return (user as OIDCUser)?.profile?.[`${AUTH0_CUSTOM_TOKEN_CLAIMS_URL}/user_metadata`] as IUserMetadata | undefined;
    }

    return (user as Auth0SPAUser)?.[`${AUTH0_CUSTOM_TOKEN_CLAIMS_URL}/user_metadata`] as IUserMetadata | undefined;
  }

  public static isEmailVerified(user: Auth0SPAUser | OIDCUser | null): boolean {
    if (AuthUserUtil.isCloudUser()) {
      return !!(user as OIDCUser)?.profile.email_verified;
    }

    return !!(user as Auth0SPAUser)?.email_verified;
  }

  public static getAuthConnection(user: Auth0SPAUser | OIDCUser | null): string {
    if (AuthUserUtil.isCloudUser()) {
      return ((user as OIDCUser)?.profile.auth0_con as any) ?? '';
    }

    return (user as Auth0SPAUser)?.[`${AUTH0_CUSTOM_TOKEN_CLAIMS_URL}/connection`] ?? '';
  }

  public static getId(user: Auth0SPAUser | OIDCUser | null): string {
    if (AuthUserUtil.isCloudUser()) {
      return ((user as OIDCUser)?.profile.ext_sub as any) ?? '';
    }

    return (user as Auth0SPAUser)?.sub ?? '';
  }

  public static getCloudId(user: Auth0SPAUser | OIDCUser | null): string {
    if (AuthUserUtil.isCloudUser()) {
      return (user as OIDCUser)?.profile.sub ?? '';
    }

    return '';
  }

  public static isCloudUser(): boolean {
    const authType = getAuthType();
    return authType === AuthType.Cloud;
  }

  /**
   * Obtain the full name of the Identity user.
   * Returns empty string if the user's profile could not be found, or if all name fields are missing.
   */
  private static getCloudUserFullName(user: OIDCUser | null): string {
    if (!user?.profile) {
      return '';
    }
    const firstName = user.profile.first_name as string | undefined;
    const lastName = user.profile.last_name as string | undefined;
    const name = user.profile.name as string | undefined;

    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return firstName ? firstName : (name ? name : '');
  }
}
