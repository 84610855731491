import Backdrop from '@mui/material/Backdrop';
import styled from 'styled-components';

export const ModalOuter = styled.div`
  background: white;
  border-radius: 2px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 22px 40px 40px 40px;
  width: 100%;
  max-width: 840px;
  box-sizing: border-box;
  z-index: 9999;

  &.GetUserInfoModal, &.ActivateAdminUserModal, &.AnnouncementModal {
    .Modal-Title-Container { 
      display: none;
    }

    @media screen and (max-width: 480px) {
      padding-top: 180px;
    }
  }
  
  &.MyCompanyBody__Add-User-Modal {
    width: 100%;
    max-width: 840px;
    padding: 24px 40px 24px 24px;
    max-height: 85vh;
    overflow: auto;
    .Modal-Close-Icon {
      margin-right: 10px;
    }
  }

  &.Audit__Diff-Modal {
    width: 100%;
    padding: 24px 0px 0px 32px;
    max-height: 100vh;
    overflow: auto;
    .Modal-Close-Icon {
      margin-right: 22px;
      padding: 10px;
    }
  }

  &.KB__Resource-Modal {
    max-height: 90%;
    overflow: hidden;
    overflow: none;
    display: flex;
    flex-direction: column;
    background-color: ${p => p.theme.palette.grey[200]};
  }

  &.Support__Case-Suggestions-Modal {
    width: 100%;
    max-width: 740px;
    padding: 24px 0px 0px 32px;
    max-height: 100vh;
    overflow: auto;
    .Modal-Close-Icon {
      margin-right: 22px;
      padding: 10px;
    }
  }
  .Modal-Title-Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .Modal-Close-Icon {
    cursor: pointer;
  }
  .Modal-Title {
    color: ${p => p.theme.palette.ink[600]};
  }
  .Modal-Fields-Container {
    width: 100%;
    margin-top: 24px;
  }
  &.ReviewModal {
    padding: 8px 0 32px 0;
    max-width: 634px;
    .Modal-Title-Container,
    .ReviewsModal__Subtitle,
    .ReviewModal__Submit {
      padding: 0 8px 0 56px;
    }
    .Modal-Title-Container {
      align-items: flex-start;
    }
    .Modal-Title {
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.6rem;
      color: black;
      margin-top: 24px;
    }
    .Modal-Fields-Container {
      margin-top: 8px;
    }
    .ReviewsModal__Subtitle {
      margin-right: 46px;
      line-height: 2.4rem;
      color: #333c4b;
    }
    .ReviewModal__Reviews {
      padding: 16px 56px;
      margin: 32px 0;
      background: ${p => p.theme.palette.grey[100]};
    }
  }
  &.AddCustomLicenseNameModal {
    max-width: 588px;
  }

  &.RequestHistory__cancelRequest {
    padding: 24px;
    width: 90%;
    outline: none;

    @media screen and (min-width: 768px) {
      width: 456px;
    }

    .Modal-Title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }

    .Modal-Fields-Container {
      margin-top: 16px;
      
      p {
        font-size: 14px;
        line-height: 20px;
      }

    }

    .Modal-Close-Icon {
      position: absolute;
      top: 24px;
      right:24px;
      width: 10px;
      height: 10px;
      cursor: pointer;
    }

    .Modal-Buttons {
      margin-top: 32px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .Button {
        min-width: 80px;
        padding: 6px 16px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      .ConfirmButton {
        margin-right: 8px;
        background: #0067DF;
        border: 1px solid #0067DF;
        color: #F4F9FA;
      }

      .CancelButton {
        border: 1px solid #A4B1B8;
        background: transparent;
        color: #0067DF;
      }
    }
  }
`;

export const CustomBackdrop = styled(Backdrop)`
  background-color: black !important;
  opacity: 0.9 !important;
`;
