import React from 'react';

interface Props {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  threshold?: number;
  className?: string;
  id?: string;
  loading?: 'eager' | 'lazy';
}

const WrapperImage = ({
  src,
  alt,
  width,
  height,
  className,
  id,
  loading,
}: Props) => (
  <img
    data-testid="WrapperImage"
    data-cy="WrapperImage"
    src={src}
    alt={alt}
    width={width}
    height={height}
    className={className}
    id={id}
    loading={loading}
  />
);

export default WrapperImage;
