import { Locale } from '@customer-portal/constants';
import type { InitOptions as i18InitOptions } from 'i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import type { BackendOptions as i18BackendOptions } from 'i18next-http-backend';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { getStringEnv } from './App/lib/index.util';
import {
  getCloudLanguageFromCookie,
  getDotComLanguageFromCookie,
  setCloudLanguageCookie,
} from './App/utils/localization';

// https://www.npmjs.com/package/i18next-http-backend/v/1.4.0
const backendOptions: i18BackendOptions = {
  loadPath: `${getStringEnv(process.env.PUBLIC_URL)}/locales/{{lng}}/{{ns}}.json`,
  crossDomain: true,
};

const languageDetectorName = 'cp-custom-language-detector';

const i18nextOptions: i18InitOptions = {
  ns: [ 'common' ],
  fallbackLng: Locale.en,
  interpolation: { escapeValue: false },
  supportedLngs: Object.values(Locale),
  nsSeparator: false,
  keySeparator: false,
  detection: {
    order: [ languageDetectorName ],
    caches: [ languageDetectorName ],
    checkWhitelist: true,
  },
  backend: backendOptions,
  react: { useSuspense: false },
};

// https://github.com/i18next/i18next-browser-languageDetector/blob/master/README.md#adding-own-detection-functionality
const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: languageDetectorName,
  lookup(_) {
    return getCloudLanguageFromCookie() ?? getDotComLanguageFromCookie();
  },

  cacheUserLanguage(language: Locale, _) {
    setCloudLanguageCookie(language);
  },
});

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next);

if (!i18n.isInitialized) {
  i18n.init(i18nextOptions);
}

export default i18n;
