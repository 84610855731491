import Popover from '@mui/material/Popover';
import styled from 'styled-components';

export const AddNewTicketPopover = styled(Popover)`
  .MuiPopover-paper {
    background: white;
    width: 238px;
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
    margin-top: ${p => p.theme.spacing(1)}px;
  }
`;

export const PopoverOption = styled.p`
  padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.grey[50]}};
    color: ${p => p.theme.palette.blue[500]};
  }
`;
