import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../../assets/css/Knowledge/AddNewCasePopOver';
// Helpers
import type { IDataObject } from '../../../interfaces/dataObject.interface';
// Google Analytics
import { CustomerPortalGoogleAnalyticsEvent } from '../../CustomerPortal-GoogleAnalytics';

type AddNewCasePopOverProps = {
  popoverAnchorElem: any;
  handleCategoryOptionClose: () => void;
  onCaseCreateClick: (link: string) => void;
  screenName: string;
};

/**
 *
 * Popover menu when add new case button is clicked
 */
const AddNewCasePopOver = ({
  popoverAnchorElem,
  handleCategoryOptionClose,
  onCaseCreateClick,
  screenName, // for analytics
}: AddNewCasePopOverProps) => {
  // Translate method
  const { t } = useTranslation('common');

  const newCaseTypes = [
    {
      title: t(
        'support_incident_cases_create_new_technical_support_case',
        'Technical Support'
      ),
      link: '/support/add-case', // switch back to '/support/chat' when case assistance tool is enabled
      gaEvent: 'Create Technical Support Case',
    },
    {
      title: t(
        'support_incident_cases_create_new_license_queries_case',
        'License Queries & Activation'
      ),
      link:
        'https://www.uipath.com/company/contact-us/licensing-queries-activations',
      gaEvent: 'Create License Queries & Activation Case',
    },
    {
      title: t(
        'support_incident_cases_create_new_academy_certifications_and_academic_alliance_case',
        'Academy, Certifications and Academic Alliance'
      ),
      link: 'https://www.uipath.com/company/contact-us/contact-learning',
      gaEvent: 'Create Academy, Certifications and Academic Alliance Case',
    },
  ];

  const baseClass = 'AddNewCasePopOver';

  return (
    <styles.AddNewTicketPopover
      open={Boolean(popoverAnchorElem)}
      elevation={0}
      anchorEl={popoverAnchorElem}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleCategoryOptionClose}
      data-testid={`${baseClass}__CreateCase_PopoverMenu`}
    >
      {newCaseTypes.map((type: IDataObject, idx: number) => (
        <styles.PopoverOption
          key={idx}
          onClick={() => {
            onCaseCreateClick(type.link);
            CustomerPortalGoogleAnalyticsEvent(
              'Create Support Case',
              type.gaEvent,
              `${screenName}`
            );
            handleCategoryOptionClose();
          }}
          data-testid={`${baseClass}__CreateCase_PopoverMenuOption`}
        >
          {type.title}
        </styles.PopoverOption>
      ))}
    </styles.AddNewTicketPopover>
  );
};

export default AddNewCasePopOver;
