// A modal to display the difference between two audit history records using json-diff package
import { Differ } from 'json-diff-kit';
import React from 'react';

import {
  AuditDiffViewer,
  HeaderView,
} from '../../../assets/css/Admin/hapoEntitlements/AuditHistoryDiffModal';
import type { IHapoHistory } from '../../../interfaces/hapoHistory.interface';
import Modal from '../../Modal';

type AuditHistoryDiffModalProps = {
  open: boolean;
  data: IHapoHistory;
  handleClose: () => void;
};

export const AuditHistoryDiffModal = ({
  open,
  data,
  handleClose,
}: AuditHistoryDiffModalProps) => {

  const differ = new Differ({
    detectCircular: true,
    maxDepth: Infinity,
    showModifications: true,
    arrayDiffMethod: 'lcs',
  });

  return (
    <Modal
      modalTitle="Audit History"
      modalHeading="Audit History"
      modalDescription="History of changes made to the HAPO entitlements"
      handleClose={handleClose}
      open={open}
      innerClasses="Audit__Diff-Modal"
    >
      <HeaderView>
        <h4>Old</h4>
        <h4>New</h4>
      </HeaderView>
      <AuditDiffViewer
        diff={differ.diff(data.summed_config, data.new_config.summed_config)}
        indent={4}
        lineNumbers
        highlightInlineDiff
        inlineDiffOptions={{
          mode: 'word',
          wordSeparator: ' ',
        }}
      />
    </Modal>
  );
};
