import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

type NotificationBannerActionsProps = {
  actions?: Array<{
    name: string;
    url: string;
  }>;
  handleClose?(): void;
};

const NotificationBannerActions = (props: NotificationBannerActionsProps) => {
  const history = useHistory();
  const handleClose = props.handleClose ? props.handleClose : () => {};
  const buttonActions = props.actions ? props.actions : [];

  const generateActionButtons = () => buttonActions.map((action: any) => (
    <button
      type="button"
      onClick={() => handleButtonAction(action.url)}>
      {action.name}
    </button>
  ));

  const handleButtonAction = (url: string) => {
    if (url) {
      handleClose();
      history.push(url);
    }

    handleClose();
  };

  return <div className="ActionButtons">{generateActionButtons()}</div>;
};

NotificationBannerActions.propTypes = {
  actions: PropTypes.array,
  handleBannerClose: PropTypes.func,
};

export default NotificationBannerActions;
