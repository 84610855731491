import styled from 'styled-components';

import NavIcon from '../img/svg/arrows/Nav_Icon_ArrowRightWhite_CircleBlue.svg';

export const Container = styled.section`
  width: 100%;
  min-height: calc(100vh - 48px);

  .heroBackButton {
    font-size: 1.2rem;
    color: #353c4a;
    text-transform: uppercase;
    display: inline-block;
    position: absolute;
    cursor:pointer;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      background:url(${NavIcon}) no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
`;

export const Logo = styled.div`
  svg {
    margin: ${p => p.theme.spacing(5)}px 0;
  }
`;

export const FormSubmittedLoading = styled.div`
  margin: ${p => p.theme.spacing(2)}px 0;
  padding:  ${p => p.theme.spacing(3)}px;

  svg {
    display: block;
    margin: 0 auto;
    width: ${p => p.theme.spacing(12.5)}px;
    height: ${p => p.theme.spacing(12.5)}px;
  }
`;

export const FormBody = styled.div`
  margin: ${p => p.theme.spacing(2)}px 0;
  padding:  ${p => p.theme.spacing(3)}px;
  background-color: white;

  display: flex;
  flex-direction: column;


  border: 2px solid ${p => p.theme.palette.ink[300]};
  box-shadow: 2px 2px 2px ${p => p.theme.palette.ink[150]};
  border-radius: 4px;

  .MuiTextField-root .MuiFormHelperText-contained {
    font-size: 12px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .MuiInputBase-root.Mui-disabled {
    background: rgba(211, 212, 214, 0.2);
  }

  .MuiInputBase-root {
    border: 1px solid ${p => p.theme.palette.grey[500]};
  }

  .MuiOutlinedInput-input {
    border: none !important;
  }

  .MuiInputLabel-root > .MuiInputLabel-asterisk {
    color: inherit;
    margin-left: 0;
  }

  .CustomerPortalActivateAccount__emailForm {
    margin-top: ${p => p.theme.spacing(1)}px;
  }

  .CustomerPortalActivateAccount__formField {
    &--Success-Color {
      color: green;
    }

    &--Error-Color {
      color: red;
    }
  }

  .RegistrationDetails {
    margin-top: ${p => p.theme.spacing(2)}px;
    padding-bottom: ${p => p.theme.spacing(2)}px;

    border-top: 1px solid ${p => p.theme.palette.ink[300]};
    border-bottom: 1px solid ${p => p.theme.palette.ink[300]};

    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .CustomerPortalActivateAccount__field {
      padding-top: ${p => p.theme.spacing(1)}px;

      &:nth-child(even) {
        margin-left: 16px;
      }

      flex: 0 0 calc(50% - 8px);

      .GetUserInfoForm__Select .Custom-Select__control {
        padding: 1px 0;
        background: white;
        border: 1px solid ${p => p.theme.palette.ink[400]};
        border-radius: ${p => p.theme.spacing(0.5)}px;

        &:hover {
          border: 1px solid black;
        }
      }

      .GetUserInfoForm__Select .Custom-Select__control.Custom-Select__control--is-focused {
        border: ${p => p.theme.spacing(0.25)}px solid ${p => p.theme.palette.blue[500]};
      }
    }

  }

  .CustomerPortalActivateAccount__continueButton {
    margin-top: ${p => p.theme.spacing(4)}px;
    padding: 10px 16px !important;
  }

  .CustomerPortalActivateAccount__createCaseContainer {
    margin-top: ${p => p.theme.spacing(2)}px;
    padding: ${p => p.theme.spacing(2)}px;

    background-color: ${p => p.theme.palette.orange[100]};
    border-radius: 4px;

    box-shadow: 2px 2px 2px ${p => p.theme.palette.ink[200]};
  }
`;

export const InfoBox = styled.div`
  margin-bottom: ${p => p.theme.spacing(2)}px;
  padding: ${p => p.theme.spacing(3)}px ${p => p.theme.spacing(6)}px;

  border-radius: 4px;
  background-color: ${p => p.theme.palette.blue[700]};
  box-shadow: 2px 2px 2px ${p => p.theme.palette.ink[200]};

  text-align: center;

  color: white;

  .CustomerPortalActivateAccount__title {
    padding-bottom: ${p => p.theme.spacing(2)}px;
    font-size: 2.4rem;
    line-height: 20px;
    font-weight: 700;
  }

  .CustomerPortalActivateAccount__description {
    padding-top: ${p => p.theme.spacing(1)}px;
  }
`;

export const CaseInfoBox = styled.div`
  margin-bottom: ${p => p.theme.spacing(2)}px;
  margin-top: ${p => p.theme.spacing(2)}px;
  padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(2)}px;

  border-radius: 4px;
  background-color: ${p => p.theme.palette.orange[100]};
  box-shadow: 2px 2px 2px ${p => p.theme.palette.ink[200]};

  text-align: left;

  color: black;

  .CustomerPortalActivateAccount__title {
    padding-bottom: ${p => p.theme.spacing(2)}px;
    font-size: 2.4rem;
    line-height: 20px;
    font-weight: 700;
  }

  ul {
    margin: 0;
    padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(4)}px;
  }

  p {
    font-size: 1.4rem;
    line-height: 20px;
  }
`;

export const ValidationErrorMessage = styled.span`
  color: ${p => p.theme.palette.red[500]};
  display: block;
  margin-top: 12px;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const Disclaimer = styled.div`
  a {
    text-decoration: underline;
  }

  .MuiFormControlLabel-root {
    margin-top: ${p => p.theme.spacing(2)}px;
  }

  .MuiIconButton-root.MuiIconButton-root.b2cTermsAndConditionsCheckbox,
  .MuiIconButton-root.MuiIconButton-root.generalTermsOfUseCheckbox {
    transform: scale(1.5);

    &:hover {
      background-color: transparent;
    }
  }

  .MuiIconButton-root.MuiIconButton-root.b2cTermsAndConditionsCheckbox {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiIconButton-root.MuiIconButton-root.generalTermsOfUseCheckbox {
    align-self: flex-start;

    &+span {
      padding-top: ${p => p.theme.spacing(0.75)}px;
    }
  }
`;

export const Body = styled.div`
  width: 60%;
  margin: ${p => p.theme.spacing(12)}px auto;

  .CustomerPortalActivateAccount__desc {
    p {
      text-align: center;
    }
  }

  .CustomerPortalActivateAccount__description {
    p {
      margin-bottom: ${p => p.theme.spacing(3)}px;
      font-size: 1.6rem;
      line-height: 20px;
      color: ${p => p.theme.palette.ink[550]};

      a {
        text-decoration: underline;
      }
    }
  }
`;

export const TextFieldHelperText = styled.div`
  margin-top: ${p => p.theme.spacing(0.5)}px;

  p {
    display: inline-block;
    font-size: 1.2rem;
    line-height: 20px;

    color: #526069;
  }

  span {
    color: ${p => p.theme.palette.blue[500]};
    cursor: pointer;
  }
`;
