import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../assets/css/Company/Page';
// Clients
import { axiosPost } from '../../client/axios';
// Constants
import { BACKEND_HOST_NAME } from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
// Store
import { StoreContext } from '../../store';
// components
import CustomerPortalLoader from '../CustomerPortal-Loader';
import Modal from '../Modal';
import SelectWithSearch from '../Select-With-Search';
import { ModalVariant } from './companyBody/Company-Body-Licenses';

type AddLicenseAttributeProps = {
  onClose: (
    newAttribute?: string | { _id: string; name: string },
    isChanged?: boolean
  ) => void;
  open: boolean;
  displayName: string;
  licenseId: string;
  note: string;
  modalVariant?: ModalVariant;
  entities: Array<{ _id: string; name: string }>;
  selectedEntity?: { _id: string; name: string };
};

const AddCustomLicenseNameModal = (props: AddLicenseAttributeProps) => {
  // Translate method
  const { t } = useTranslation('common');
  const baseClass = 'AddCustomLicenseNameModal';
  const { getAccessToken } = useAuth();
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  // Local state
  const [ loading, setLoading ] = useState(false);
  const [ displayName, setDisplayName ] = useState(props.displayName);
  const [ note, setNote ] = useState(props.note);
  const [ selectedEntity, setSelectedEntity ] = useState(props.selectedEntity);
  const NONE_OPTION = {
    label: t('support_form_dropdown_item_none', '--- None ---'),
    value: 'None',
  };

  useEffect(() => {
    setSelectedEntity(props.selectedEntity);
  }, [ props.selectedEntity ]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(event.target.value);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const handleSaveClose = (successfulSubmit: boolean) => {
    if (props.modalVariant === ModalVariant.CUSTOM_NAME) {
      if (!successfulSubmit) {
        props.onClose(props.displayName, false);
      } else {
        props.onClose(displayName, true);
      }
      return;
    }

    if (props.modalVariant === ModalVariant.NOTES) {
      if (!successfulSubmit) {
        props.onClose(props.note, false);
      } else {
        props.onClose(note, true);
      }
      return;
    }

    if (props.modalVariant === ModalVariant.UPDATE_ENTITY) {
      if (!successfulSubmit) {
        props.onClose(props.selectedEntity, false);
      } else {
        props.onClose(selectedEntity, true);
      }
      return;
    }
  };

  const handleSubmit = async () => {
    let updateResult: any;

    const body: {
      boon_license_cd: string;
      comment: string;
      display_name: string;
      entity_id?: string;
    } = {
      boon_license_cd: props.licenseId,
      comment: '',
      display_name: '',
      entity_id: undefined,
    };

    if (!props.modalVariant) {
      return;
    }

    switch (props.modalVariant) {
      case ModalVariant.CUSTOM_NAME:
        body.display_name = displayName;
        body.comment = props.note;
        break;
      case ModalVariant.NOTES:
        body.display_name = props.displayName;
        body.comment = note;
        break;
      case ModalVariant.UPDATE_ENTITY:
        body.display_name = props.displayName;
        body.comment = props.note;
        body.entity_id = selectedEntity?._id !== NONE_OPTION.value
          ? selectedEntity?._id
          : undefined;
        break;
      default:
        break;
    }

    setLoading(true);

    try {
      updateResult = await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/license/updateLicenseDisplayNameComment`,
        state.companyId,
        await getAccessToken(),
        body
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }

    const bannerText = props.modalVariant === ModalVariant.CUSTOM_NAME ? 'display name' :
      props.modalVariant === ModalVariant.NOTES ? 'note' :
        props.modalVariant === ModalVariant.UPDATE_ENTITY ? 'entity' :
          '';

    if (updateResult && updateResult.status === 200) {
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_licenses_add_custom_attribute_modal_success_msg',
          `License ${bannerText} was updated.`,
          { bannerText }
        ),
      });
      handleSaveClose(true);
    } else {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_licenses_add_custom_attribute_modal_error_msg',
          `License ${bannerText} could not be saved.`,
          { bannerText }
        ),
      });
      handleSaveClose(false);
    }
  };

  const generateModalBody = () => {
    if (props.modalVariant === ModalVariant.CUSTOM_NAME) {
      return (
        <TextField
          className={`${baseClass}__Ticket-Field`}
          variant={'outlined' as any}
          onChange={handleNameChange}
          error={false}
          required={false}
          InputLabelProps={{ shrink: true }}
          InputProps={{ className: 'Tall' }}
          inputProps={{ maxLength: 40 }}
          placeholder={
            props.displayName
              ? ''
              : t(
                'company_licenses_add_custom_attribute_modal_add_custom_name_placeholder',
                'Enter name'
              )
          }
          defaultValue={props.displayName}
        />
      );
    }

    if (props.modalVariant === ModalVariant.NOTES) {
      return (
        <FormControl className={`${baseClass}__Ticket-Field`}>
          <TextareaAutosize
            className={`${baseClass}__Ticket-Field--Textarea`}
            aria-label="Notes textarea"
            required
            minRows={5}
            onChange={handleNoteChange}
            defaultValue={props.note}
            placeholder={
              props.note
                ? props.note
                : t(
                  'company_licenses_add_custom_attribute_modal_add_notes_placeholder',
                  'Enter notes'
                )
            }
          />
        </FormControl>
      );
    }

    if (props.modalVariant === ModalVariant.UPDATE_ENTITY) {
      return (
        <div className={`${baseClass}__Ticket-Field--SelectWithSearch`}>
          <SelectWithSearch
            options={[
              ...(selectedEntity ? [ NONE_OPTION ] : []),
              ...props.entities
                .map((entity) => ({
                  label: entity.name,
                  value: entity._id,
                })),
            ]}
            value={!selectedEntity ? NONE_OPTION : {
              label: selectedEntity?.name,
              value: selectedEntity?._id,
            }}
            onChange={(option: { label: string; value: string }) => {
              setSelectedEntity({
                _id: option.value,
                name: option.label,
              });
            }}
            searchable
            placeholder={
              <div data-testid="filter-by-entity">
                {t('no_entity_selected', 'No entity selected')}
              </div>
            }
          />
        </div>
      );
    }

    return <></>;
  };

  const generateModalHeading = () => {
    if (props.modalVariant === ModalVariant.CUSTOM_NAME) {
      if (!props.displayName) {
        return t(
          'company_licenses_add_custom_attribute_modal_add_custom_name_title',
          'Add Custom Name'
        );
      }
      return t(
        'company_licenses_add_custom_attribute_modal_edit_custom_name_title',
        'Edit Custom Name'
      );

    }

    if (props.modalVariant === ModalVariant.NOTES) {
      if (!props.note) {
        return t(
          'company_licenses_add_custom_attribute_modal_add_notes_title',
          'Add Notes'
        );
      }
      return t(
        'view_notes',
        'View Notes'
      );
    }

    if (props.modalVariant === ModalVariant.UPDATE_ENTITY) {
      return t(
        'company_licenses_add_custom_attribute_modal_update_entity_title',
        'Update Entity'
      );
    }
  };

  const handleClose = () => {
    handleSaveClose(false);
  };

  if (!props.modalVariant) {
    return <></>;
  }

  return (
    <Modal
      modalTitle="Standard Modal"
      modalDescription="Add License Attribute Modal"
      modalHeading={generateModalHeading()}
      innerClasses={`${baseClass}`}
      handleClose={handleClose}
      open={props.open}
    >
      {loading && (
        <div className="Modal-Fields-Container">
          <CustomerPortalLoader />
        </div>
      )}

      {!loading && (
        <styles.NameModalInner
          className={`Modal-Fields-Container ${baseClass}__Form-Inner`}
        >
          {generateModalBody()}
          <Button
            variant="contained"
            className={`${baseClass}__Form-Button`}
            disableElevation
            color="secondary"
            onClick={handleSubmit}
          >
            {t('company_licenses_add_custom_attribute_modal_save_btn', 'Save')}
          </Button>
        </styles.NameModalInner>
      )}
    </Modal>
  );
};

export default AddCustomLicenseNameModal;
