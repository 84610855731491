import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';

import { HapoAddGroupModalWrapper } from '../../../assets/css/Admin/AddNewHapoGroupModal';
import { CPTableContainer } from '../../../assets/css/Table';
import { getELAGroups } from '../../../constants/hapo.constants';
import CustomerPortalPagination from '../../CustomerPortal-Pagination';
import CustomerPortalSearchInput from '../../CustomerPortal-SearchInput';
import Modal from '../../Modal';

export type addHapoGroupType = {
  sku_name: string;
  product_code: string;
  id: string;
};

type AddNewHapoGroupModalProps = {
  open: boolean;
  groupName?: string;
  existingGroups: string[];
  handleAddGroup: (group: string) => void;
  handleEditGroup: (oldGroupName: string, newGroupName: string) => void;
  handleClose: () => void;
};

const AddNewHapoGroupModal = ({
  open,
  groupName,
  existingGroups,
  handleAddGroup,
  handleEditGroup,
  handleClose,
}: AddNewHapoGroupModalProps) => {
  // Constants
  const limit = 5;
  const [ groups, setGroups ] = useState<string[]>([]);
  const [ searchPhrase, setSearchPhrase ] = useState<string | undefined>('');
  const [ searchPhraseChanged, setSearchPhraseChanged ] = useState(false);
  const [ currentPage, setCurrentPage ] = useState(1);

  const start = (currentPage - 1) * limit;
  const end = currentPage * limit;

  const handleSearchOnChange = (val: string) => {
    setSearchPhrase(val);
  };

  const handleOnGroupSelect = (group: string) => {
    groupName ? handleEditGroup(groupName, group) : handleAddGroup(group);
  };

  const handlePaginationClick = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (open) {
      fetchGroups();
    }
  }, [ open ]);

  useEffect(() => {
    if (searchPhraseChanged) {
      if (searchPhrase) {
        fetchGroups();
      } else {
        setGroups([]);
      }
      setSearchPhraseChanged(false);
    }
  }, [ searchPhraseChanged ]);

  const fetchGroups = () => {
    // Only show the groups those does not already exist
    const groups = getELAGroups().filter((groupName: string) => !existingGroups.includes(groupName));
    setGroups(groups);
  };

  const filteredGroups = groups.filter(group => {
    const phrase = searchPhrase?.toString();
    if (!phrase) {
      return true;
    }
    return new RegExp(phrase, 'gi').test(group);
  });

  const getGroupsTable = () => (
    <HapoAddGroupModalWrapper data-testid='HapoAddGroupModalWrapper'>
      <CPTableContainer>
        <Table
          size="medium"
          aria-label="Groups Table">
          <TableHead>
            <TableRow>
              <TableCell>Group Name</TableCell>
            </TableRow>
          </TableHead>
          {filteredGroups.length > 0 && (
            <TableBody>
              {filteredGroups.slice(start, end).map((group: string) => (
                <TableRow
                  className="Table-Row"
                  key={group}
                  data-testid={group}
                  onClick={() => handleOnGroupSelect(group)}
                >
                  <TableCell>{group}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {filteredGroups.length == 0 && (
          <p
            className="No-Groups-Text"
            data-testid="EmptyState">
            No Groups found!
          </p>
        )}
        {filteredGroups.length > limit && (
          <CustomerPortalPagination
            activePage={currentPage}
            handleClick={handlePaginationClick}
            numPagesBeforeEllipses={5}
            numResultsPerPage={limit}
            numResults={filteredGroups.length}
          />
        )}
      </CPTableContainer>
    </HapoAddGroupModalWrapper>
  );

  return (
    <Modal
      modalTitle="Add new Group"
      modalHeading="Select a Group"
      modalDescription="Modal for selecting a ELA group"
      open={open}
      handleClose={handleClose}
      testId="AddNewHapoGroupModal"
    >
      <HapoAddGroupModalWrapper>
        <CustomerPortalSearchInput
          handleOnChange={handleSearchOnChange}
          handleSubmit={() => {}}
          placeholderText="Search by Name"
          keyword={searchPhrase || ''}
          data-testid="Search_Input"
        />
        {getGroupsTable()}
      </HapoAddGroupModalWrapper>
    </Modal>
  );
};

export default AddNewHapoGroupModal;
