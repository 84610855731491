import TextField from '@mui/material/TextField';
import React from 'react';
import styled from 'styled-components';

const CustomTextInput = styled(TextField)`
  background: ${p => p.theme.palette.grey[100]};
  border-radius: ${p => p.theme.spacing(1)}px;
  border: none;
  .MuiInput-underline {
    &:after {
      display: none;
    }
  }
  .MuiInputBase-root {
    font-family: ${p => p.theme.typography.fontFamily};
    font-size: 1.4rem;
    &:before {
      display: none;
    }
  }
  .MuiInputBase-input {
    font-size: 1.4rem;
    line-height: 2.4rem;
    height: auto;
    color: #353c4a;
    font-weight: 400;
    padding: 8px 16px;
  }

  &.GetUserInfoForm__Text-Input {
    width: 250px;  
    .MuiInputBase-input {
      padding: 12px 14px;
      font-size: 1.4rem;
      line-height: 20px;
      color: rgb(39, 49, 57);
      outline: none;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: black;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #0067df;
    }
  }
`;

type TextInputProps = {
  id: string;
  helperText?: React.ReactNode;
  ariaLabel?: string;
  label?: string;
  children?: any;
  select?: boolean;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void);
  value?: string;
  placeholder?: string;
  defaultValue?: string;
  onBlur?: React.EventHandler<any>;
  className?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
};

const TextInput = (props: TextInputProps) => (
  <CustomTextInput
    required={props.required}
    aria-label={props.ariaLabel}
    className={props.className}
    variant={'outlined' as any}
    value={props.value}
    placeholder={props.placeholder}
    defaultValue={props.defaultValue}
    onChange={props.onChange}
    onBlur={props.onBlur ? props.onBlur : undefined}
    id={props.id}
    type={props.type ? props.type : undefined}
    select={props.select ? props.select : false}
    label={props.label}
    helperText={props.helperText}
    disabled={props.disabled}
  >
    {props.children}
  </CustomTextInput>
);
export default TextInput;
