import { UserRole } from './roles.constants';

export enum AdminUserChangeType {
  ADD_NEW = 'add_new',
  EDIT_ROLES = 'edit_roles',
  EDIT_STATUS = 'edit_status',
  UNDEFINED = 'undefined',
}

export const ADMIN_USER_ROLE_TO_DISPLAY_NAME: Partial<Record<UserRole, string>> = {
  [UserRole.UI_SUPER_ADMIN]: 'Super Admin',
  [UserRole.UI_SUPER_CONTENT_EDITOR]: 'Content Editor',
  [UserRole.UI_SUPER_OPERATIONS]: 'UiPath Operations',
  [UserRole.UI_SUPER_OPERATIONS_ADMIN]: 'UiPath Operations Admin',
  [UserRole.UI_TECHNICAL_ACCOUNT_MANAGER]: 'Technical Account Manager',
  [UserRole.UI_SPECIFIC_ACCOUNT_USER]: 'Specific Account User',
  [UserRole.UI_AUTOMATION_PROGRAM_ADVISOR]: 'Automation Program Advisor',
};

export const ROLES_THAT_SHOULD_BE_ASSIGNED_TO_NONSTANDARD_ACCOUNTS = [
  UserRole.UI_TECHNICAL_ACCOUNT_MANAGER,
  UserRole.UI_AUTOMATION_PROGRAM_ADVISOR,
];
