export const TRIMMABLE_SUBSTRINGS = new Set([
  '<o:p>&nbsp;</o:p></span></p>',
  '<br clear="none" />',
  '<br>',
  '<br />',
]);

// Return the trimmable substring that needs to be trimmed off from the end
export const getLastTrimmableSubstring = (str: string) => {
  for (const substr of TRIMMABLE_SUBSTRINGS) {
    const findIndex = str.lastIndexOf(substr);
    if (findIndex !== -1 && findIndex === str.length - substr.length) {
      return substr;
    }
  }
  // If there's no suitable string to return, return an empty string
  return '';
};
