import cloneDeep from 'lodash/cloneDeep';

import {
  HAPOELA_NONPROD_COMMERCIAL_NAME,
  HAPOELA_PROD_COMMERCIAL_NAME,
  UTO_COMMERCIAL_NAME,
} from '../constants/license.constants';
import RequestForQuote, {
  ANNOUNCEMENT_CONFIG_PRODUCT_CODES,
  AnnouncementConfig,
  AUTOMATION_SUITE,
  CLOUD,
  DEPLOYMENT_TYPE,
  EXISTING_AUTOMATION_SUITE,
  EXISTING_CLOUD,
  EXISTING_DEDICATED_AUTOMATION_CLOUD,
  EXISTING_ORCHESTRATOR,
  EXISTING_PROCESS_MINING,
  NEW_ORCHESTRATOR,
  NEW_PROCESS_MINING,
  NewAutomationCloudBundleOption,
  NewAutomationSuiteBundleOption,
  ProductTypeToAPIType,
  SERVICE,
  STANDALONE,
} from '../constants/requestForQuote.constants';
import {
  RFQ_ADD_1ST_LEVEL_LICENSE,
  RFQ_ADD_2ND_LEVEL_LICENSE,
  RFQ_CHANGE_CATEGORY,
  RFQ_CHANGE_ONPREM_SELECTION,
  RFQ_DISPLAY_ANNOUNCEMENT_MODAL,
  RFQ_REMOVE_1ST_LEVEL_LICENSE,
  RFQ_REMOVE_2ND_LEVEL_LICENSE,
  RFQ_SELECT_EXISTING_ORCHESTRATOR_OPTION,
  RFQ_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
  RFQ_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
} from '../constants/requestForQuoteActions.constants';
import type { GlobalStoreState } from '../interfaces/contextStore';
import type { HapoSkuType } from '../types/hapo.types';
import type {
  ApiSkuType,
  OrchestratorType,
  SkuType,
} from '../types/requestForQuote.types';
import { getSubscriptionName } from './licenses.utils';

export const canShowLicense = (
  orchestratorType: OrchestratorType,
  displayObj: { [key: string]: any } | undefined | null
) => /Show/gim.test('' + displayObj?.[orchestratorType]);

export const isProcessMiningServer = (sku_name: string) =>
  /Process Mining Server/gim.test(sku_name);

export const canShowLicenseStr = (displayVal: string | unknown) => /Show/gim.test('' + displayVal);

export const licenseHasTagValue = (
  tagToCheck: string,
  licenseTag: string | undefined | null
) => new RegExp(tagToCheck, 'gim').test('' + licenseTag);

export const trimSKUName = (skuName: string) => `${skuName + ''}`.replace(/^UiPath[ -]*\s/gim, '');

/*

*/
/**
 * Determines if bundle subscription type is of HAPO/ELA
 * @param licenses
 */
export const getHAPOELAUTOBundles = (licenses: any[]) => licenses.filter(license =>
  isHAPOELAUTOBundleBySubscriptionCode(license.subscriptionCode)
);

export const getNonHAPOELAUTOBundles = (licenses: any[]) => licenses.filter(
  license => !isHAPOELAUTOBundleBySubscriptionCode(license.subscriptionCode)
);

export const isHAPOELAUTOBundleBySubscriptionCode = (
  subscriptionCode: string | undefined
) => {
  if (!subscriptionCode) {
    // If none, assume it's not HAPO ELA
    return false;
  }

  if ([ HAPOELA_PROD_COMMERCIAL_NAME, HAPOELA_NONPROD_COMMERCIAL_NAME, UTO_COMMERCIAL_NAME ]
    .includes(getSubscriptionName(subscriptionCode))) {
    return true;
  }

  return false;
};

/**
 * Returns array of boon bundles that are enabled and have not expired
 * - Same logic as in the Company Page
 * @param accountLicenses state.companyLicenses
 */
export const getActiveBundles = (accountLicenses: any[] | undefined) => {
  if (!Array.isArray(accountLicenses)) {
    return [];
  }

  return getStandAloneOrchestratorBundles(accountLicenses).concat(
    getActiveAutomationSuiteBundles(accountLicenses)
      .concat(getActiveCloudBundles(accountLicenses))
      .concat(getActiveProcessMiningBundles(accountLicenses))
      .concat(getActiveDedicatedCloudBundles(accountLicenses))
  );
};

export const getStandAloneOrchestratorBundles = (
  accountLicenses: any[] | undefined
) => {
  if (!Array.isArray(accountLicenses)) {
    return [];
  }
  return getActiveBundleList(accountLicenses, 'UIP').concat(
    getActiveBundleList(accountLicenses, 'CRPA', DEPLOYMENT_TYPE.ORCHESTRATOR)
  );
};

export const getActiveAutomationSuiteBundles = (
  accountLicenses: any[] | undefined
) => {
  if (!Array.isArray(accountLicenses)) {
    return [];
  }
  return getActiveBundleList(
    accountLicenses,
    'CRPA',
    DEPLOYMENT_TYPE.AUTOMATION_SUITE
  );
};

export const getActiveCloudBundles = (accountLicenses: any[] | undefined) => {
  if (!Array.isArray(accountLicenses)) {
    return [];
  }

  return getActiveBundleList(
    accountLicenses,
    'CRPA',
    DEPLOYMENT_TYPE.AUTOMATION_CLOUD
  );
};

export const getActiveDedicatedCloudBundles = (accountLicenses: any[] | undefined) => {
  if (!Array.isArray(accountLicenses)) {
    return [];
  }

  return getActiveBundleList(
    accountLicenses,
    'CRPA',
    DEPLOYMENT_TYPE.DEDICATED_AUTOMATION_CLOUD
  );
};

export const getActiveProcessMiningBundles = (
  accountLicenses: any[] | undefined
) => {
  if (!Array.isArray(accountLicenses)) {
    return [];
  }
  return getActiveBundleList(accountLicenses, 'PG-PLATFORM');
};

const getActiveBundleList = (accountLicenses: any[], bundleCode: string, deploymentType?: DEPLOYMENT_TYPE) =>
  accountLicenses.filter((license: any) => {
    // Same logic as in companies page
    const currentDate: Date = new Date();
    const licenseExpirationDate: Date = new Date(license.endDate);
    const isExpired = currentDate > licenseExpirationDate;
    return (
      license.bundleCode === bundleCode &&
        license.enabled &&
        !isExpired &&
        (deploymentType
          ? license.deploymentTypes ? license.deploymentTypes.includes(deploymentType) : false
          : true)
    );
  });

// Filter licenses
const onPremOrchestratorLicenses = (
  availablePurchaseLicenses: Array<SkuType | HapoSkuType>,
  lastSelectedBundleType: string | undefined,
  lastSelectedBundleName: string | undefined
) =>
  lastSelectedBundleType === EXISTING_ORCHESTRATOR ||
    lastSelectedBundleType === NEW_ORCHESTRATOR
    ? availablePurchaseLicenses.filter((item: any) => {
      // What is being selected right now and what do I show
      if (lastSelectedBundleName?.includes('Basic')) {
        return canShowLicense('Basic', item.display);
      } else if (
        lastSelectedBundleName?.includes('Standard')
      ) {
        return canShowLicense('Standard', item.display);
      } else if (lastSelectedBundleName?.includes('NonProduction')) {
        return canShowLicense('NonProduction', item.display);
      } else if (
        lastSelectedBundleName?.includes('Flex')
      ) {
        return canShowLicense('CRPA Orchestrator', item.display);
      }
      return false;
    })
    : [];

// Standalone = No Orchestrator MINUS the services
const getStandaloneLicenses = (
  availablePurchaseLicenses: Array<SkuType | HapoSkuType>
) =>
  availablePurchaseLicenses
    .filter(item => canShowLicense('No Orchestrator', item.display))
    .filter(item => !licenseHasTagValue('Services', item.tag));

// In RFQ
const getCloudLicenses = (
  availablePurchaseLicenses: Array<SkuType | HapoSkuType>
) =>
  availablePurchaseLicenses.filter(item =>
    canShowLicense('Cloud', item.display)
  );
// In HAPO check lastSelectedBundle
const getCloudLicensesAutomationCloudParent = (
  availablePurchaseLicenses: Array<SkuType | HapoSkuType>,
  lastSelectedBundleType: string | undefined
) =>
  lastSelectedBundleType === EXISTING_CLOUD || lastSelectedBundleType === CLOUD
    ? availablePurchaseLicenses.filter(item =>
      canShowLicense('Cloud', item.display)
    )
    : [];

// In HAPO check lastSelectedBundle
const getLicensesAutomationSuiteParent = (
  availablePurchaseLicenses: Array<SkuType | HapoSkuType>,
  lastSelectedBundleType: string | undefined
) =>
  lastSelectedBundleType === EXISTING_AUTOMATION_SUITE ||
    lastSelectedBundleType === AUTOMATION_SUITE
    ? availablePurchaseLicenses.filter(item =>
      canShowLicense('Automation Suite', item.display)
    )
    : [];

const getServicesLicenses = (
  availablePurchaseLicenses: Array<SkuType | HapoSkuType>
) =>
  availablePurchaseLicenses.filter((item: any) =>
    licenseHasTagValue('Services', item.tag)
  );

const getProcessMiningLicenses = (
  availablePurchaseLicenses: Array<SkuType | HapoSkuType>,
  lastSelectedBundleType: string | undefined
) =>
  lastSelectedBundleType === EXISTING_PROCESS_MINING ||
    lastSelectedBundleType === NEW_PROCESS_MINING
    ? availablePurchaseLicenses.filter(
      item =>
        canShowLicense('ProcessMining', item.display) &&
        !isProcessMiningServer(item.sku_name)
    )
    : [];

const getAutomationSuiteLicenses = (
  availablePurchaseLicenses: Array<SkuType | HapoSkuType>
) =>
  availablePurchaseLicenses.filter(item =>
    canShowLicense('Automation Suite', item.display)
  );
/**
 * Returns list of available products to purchase
 * @param requiresAutomationCloud
 * @param catalogLicenses
 * @param lastSelectedBundle
 */
export const getAvailableNewLicensesArr = (
  selectedProductCategory: GlobalStoreState['rfqCart']['selectedCategory'],
  catalogLicenses: Array<SkuType | HapoSkuType>,
  lastSelectedBundle: GlobalStoreState['rfqCart']['lastSelectedOrchestrator'],
  requiresAutomationCloud: boolean = false,
  requiresAutomationSuite: boolean = false
) => {
  let licenseArray: Array<SkuType | HapoSkuType> = [];
  // Only list the skus that are available to purchase - don't list orchestrators
  const availablePurchaseLicenses = (catalogLicenses || []).filter(
    item => item.tag !== RequestForQuote.productTags.orchestrators.fallbackText
  );
  const lastSelectedBundleType = lastSelectedBundle?.type;
  const lastSelectedBundleName = lastSelectedBundle?.license_name;
  switch (selectedProductCategory) {
    case RequestForQuote.productTypes.cloud: {
      licenseArray = requiresAutomationCloud
        ? getCloudLicensesAutomationCloudParent(
          availablePurchaseLicenses,
          lastSelectedBundleType
        )
        : getCloudLicenses(availablePurchaseLicenses);
      break;
    }
    case RequestForQuote.productTypes.dedicatedCloud: {
      licenseArray = lastSelectedBundleName ? getCloudLicenses(availablePurchaseLicenses) : [];
      break;
    }
    case RequestForQuote.productTypes.onPrem: {
      licenseArray = onPremOrchestratorLicenses(
        availablePurchaseLicenses,
        lastSelectedBundleType,
        lastSelectedBundleName
      );
      break;
    }
    case RequestForQuote.productTypes.standalone: {
      licenseArray = getStandaloneLicenses(availablePurchaseLicenses);
      break;
    }
    case RequestForQuote.productTypes.services: {
      licenseArray = getServicesLicenses(availablePurchaseLicenses);
      break;
    }
    case RequestForQuote.productTypes.processMining: {
      licenseArray = getProcessMiningLicenses(
        availablePurchaseLicenses,
        lastSelectedBundleType
      );
      break;
    }
    case RequestForQuote.productTypes.automationSuite: {
      licenseArray = requiresAutomationSuite
        ? getLicensesAutomationSuiteParent(
          availablePurchaseLicenses,
          lastSelectedBundleType
        )
        : getAutomationSuiteLicenses(availablePurchaseLicenses);
      break;
    }
  }
  return licenseArray;
};

/**
 * Creates a deep copy of array that deletes fields the API server doesn't like:
 * Deleted fields: _id, products[] (if on a standalone/Cloud/Service)
 * @param stateProducts: The products array stored inside state
 */
export const getDeepCopyofProductsForAPI = (
  stateProducts: GlobalStoreState['rfqCart']['products']
) => stateProducts.map(license => {
  const temp = cloneDeep(license);
  const tempDisplayName = temp['display_name'];
  delete temp['_id'];
  delete temp['display_name'];
  if ([ STANDALONE, SERVICE ].includes(temp.type)) {
    delete temp['products'];
  }
  // Overwrite existing_license_name with the display name
  return {
    ...temp,
    existing_license_name: tempDisplayName || temp.existing_license_name,
    products: temp.products?.map(childLicense => {
      const tempChild = cloneDeep(childLicense);
      delete tempChild['_id'];
      return tempChild;
    }),
  };
});

/**
 * Retrieves all the relevant sku ids in cart
 * - If selected a bundle, only highlight last_selected_orchestrator's nested/2nd level products
 * - Else highlight whatever is on the 1st level
 */
export const getRelevantSkusFromProductType = (
  productType: typeof RequestForQuote.productTypes[keyof typeof RequestForQuote.productTypes],
  stateProducts: GlobalStoreState['rfqCart']['products'] | GlobalStoreState['hapoProducts'],
  parentOrchestrator?: { [key: string]: any },
  requiresAutomationCloudBundle: boolean = false,
  requiresAutomationSuiteBundle: boolean = false
): Array<{
  _id?: string;
  type?: ApiSkuType;
  sku_id?: string;
  sku_name?: string;
  quantity?: number;
}> => {
  if (stateProducts.length < 1) {
    return [];
  }
  // Any time there is a bundle in question

  if (
    productType === RequestForQuote.productTypes.onPrem ||
    // If on cloud and requires a Cloud bundle such as in the case of HAPO
    (requiresAutomationCloudBundle &&
      productType === RequestForQuote.productTypes.cloud) ||
    (requiresAutomationSuiteBundle &&
      productType === RequestForQuote.productTypes.automationSuite) ||
    productType === RequestForQuote.productTypes.processMining
  ) {
    return parentOrchestrator?.products || [];
  }

  return stateProducts.filter(
    sku =>
      sku.sku_id !== undefined &&
      sku.quantity !== undefined &&
      sku.sku_name !== undefined &&
      sku.type === productType
  );
};

/**
 * Group licenses based on type
 * @param allProducts: The products array from state.rfqCart.products
 */
export const getLicensesGroupedByType = (
  stateProducts: GlobalStoreState['rfqCart']['products']
): { [key in ApiSkuType]: GlobalStoreState['rfqCart']['products'] } => stateProducts.reduce((acc, item) => {
  acc[item.type] = acc[item.type] || [];
  acc[item.type].push(item);
  return acc;
}, {} as { [key in ApiSkuType]: GlobalStoreState['rfqCart']['products'] });

export const getLicensesTypesInCart = (
  stateProducts: GlobalStoreState['rfqCart']['products']
) => {
  const groupedRes = getLicensesGroupedByType(stateProducts);
  const hasOnPremOrchLicenses =
    groupedRes[NEW_ORCHESTRATOR]?.length > 0 ||
    groupedRes[EXISTING_ORCHESTRATOR]?.length > 0;
  const hasStandaloneLicenses = groupedRes.Standalone?.length > 0;
  const hasServiceLicenses = groupedRes.Service?.length > 0;
  const hasProcessMiningLicenses =
    groupedRes[NEW_PROCESS_MINING]?.length > 0 ||
    groupedRes[EXISTING_PROCESS_MINING]?.length > 0;
  const hasCloudBundleLicenses =
    groupedRes[EXISTING_CLOUD]?.length > 0 || groupedRes[CLOUD]?.length > 0;
  const hasAutomationSuiteBundleLicenses =
    groupedRes[EXISTING_AUTOMATION_SUITE]?.length > 0 ||
    groupedRes[AUTOMATION_SUITE]?.length > 0;
  const hasDedicatedCloudBundleLicenses =
    groupedRes[EXISTING_DEDICATED_AUTOMATION_CLOUD]?.length > 0;
  return {
    hasOnPremOrchLicenses,
    hasStandaloneLicenses,
    hasServiceLicenses,
    hasProcessMiningLicenses,
    hasCloudBundleLicenses,
    hasAutomationSuiteBundleLicenses,
    hasDedicatedCloudBundleLicenses,
  };
};

/***
 * *******Begin Actions **************
 */

/**
 * Action Method - Adds a license to cart
 * - If selected an orchestrator, ref last_selected_orchestrator's nested/2nd level products
 * - Else place on the 1st level
 */
export const handleAddLicenseToCart = (
  license: {
    _id: string;
    sku_name: string;
    [key: string]: any;
    min_quantity: number;
    product_code: string;
  },
  productType: string,
  dispatch: any,
  lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator'],
  ignoreConfirmationModal: boolean = false
) => {
  const {
    _id, sku_name, min_quantity, product_code,
  } = license;
  const minQuantity = min_quantity ? min_quantity : 1;
  // If specific SKU then do something else
  if (
    !ignoreConfirmationModal &&
    ANNOUNCEMENT_CONFIG_PRODUCT_CODES.includes(product_code)
  ) {
    dispatch({
      type: RFQ_DISPLAY_ANNOUNCEMENT_MODAL,
      payload: {
        message: AnnouncementConfig[product_code].message,
        action: 'add_product',
        sku_id: _id,
        sku_name,
        min_quantity: minQuantity,
        product_code,
      },
    });
    return;
  }

  if (
    productType === RequestForQuote.productTypes.onPrem &&
    lastSelectedOrchestrator
  ) {
    dispatch({
      type: RFQ_ADD_2ND_LEVEL_LICENSE,
      payload: {
        parent_id: lastSelectedOrchestrator._id,
        sku_id: _id,
        sku_name,
        product_code,
        quantity: minQuantity,
      },
    });
  } else {
    dispatch({
      type: RFQ_ADD_1ST_LEVEL_LICENSE,
      payload: {
        type: ProductTypeToAPIType.get(productType),
        sku_id: _id,
        sku_name,
        product_code,
        quantity: minQuantity,
      },
    });
  }
};

export const handleAddOrchestratorToCart = (
  dispatch: any,
  skuName: string,
  skuId: string,
  productCode: string,
  bundleType: string
) => {
  dispatch({
    type: RFQ_ADD_1ST_LEVEL_LICENSE,
    payload: {
      type: bundleType,
      sku_name: skuName,
      sku_id: skuId,
      product_code: productCode,
      quantity: 1,
    },
  });
};

export const handleResetNewOrchestrator = (dispatch: any, orchId?: string) => {
  dispatch({
    type: RFQ_REMOVE_1ST_LEVEL_LICENSE,
    payload: { _id: orchId },
  });
};

export const handleSelectExistingOrchestratorDropdown = (
  dispatch: any,
  name: string,
  license: string,
  bundleType: string,
  displayName: string
) => {
  dispatch({
    type: RFQ_SELECT_EXISTING_ORCHESTRATOR_OPTION,
    payload: {
      type: bundleType,
      existing_license_code: license,
      existing_license_name: name,
      existing_license_displayName: displayName,
    },
  });
};

/**
 * Action Method - Updates quantity of license in cart
 * - If selected an orchestrator, use last_selected_orchestrator's nested/2nd level products
 * - Else use whatever is on the 1st level
 */
export const handleQuantityUpdate = (
  licenseId: string,
  newQuantity: number,
  productType: string,
  dispatch: any,
  lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
) => {
  if (
    productType === RequestForQuote.productTypes.onPrem &&
    lastSelectedOrchestrator
  ) {
    dispatch({
      type: RFQ_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
      payload: {
        parent_id: lastSelectedOrchestrator._id,
        sku_id: licenseId,
        quantity: newQuantity,
      },
    });
  } else {
    dispatch({
      type: RFQ_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
      payload: {
        sku_id: licenseId,
        quantity: newQuantity,
      },
    });
  }
};

/**
 * Action Method - This handles adding initial item AND removing of item
 * Using context of where the user is, determine where to add
 *  */
export const handleRemoveLicenseFromCart = (
  licenseId: string,
  productType: string,
  dispatch: any,
  lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
) => {
  if (
    productType === RequestForQuote.productTypes.onPrem &&
    lastSelectedOrchestrator
  ) {
    dispatch({
      type: RFQ_REMOVE_2ND_LEVEL_LICENSE,
      payload: {
        parent_id: lastSelectedOrchestrator._id,
        sku_id: licenseId,
      },
    });
  } else {
    dispatch({
      type: RFQ_REMOVE_1ST_LEVEL_LICENSE,
      payload: { sku_id: licenseId },
    });
  }
};

/**
 * Action Method for switching header product options
 */
export const handleProductTypeChange = (dispatch: any, productType: string) => {
  dispatch({
    type: RFQ_CHANGE_CATEGORY,
    payload: productType,
  });
};

export const handleOnPremOrchestratorChange = (
  dispatch: any,
  orchestratorType: string
) => {
  dispatch({
    type: RFQ_CHANGE_ONPREM_SELECTION,
    payload: orchestratorType,
  });
};

/***
 * *******End of Actions **************
 */

/***
 * *******Begin Validations **************
 */

/**
 * Method acts as a some() check to validate if cart is ready to be submitted
 * - if there is at least one min/max invalid returns true
 * - There is a sku with quantity 0
 *
 * Returns true if cart has at least one sku invalid
 * @param stateProducts Value of state.rfqCart.products
 * @param licenses: The list of active license to be purchased
 * @param activeAccountBundleLicenses Boon bundles
 */
export const isCartProductsInvalid = (
  stateProducts: GlobalStoreState['rfqCart']['products'],
  licenses: SkuType[],
  activeAccountBundleLicenses: string[]
) => {
  // Memo the defined min/max values from database
  const memo: {
    [key: string]: {
      min_quantity: number;
      max_quantity: number | undefined;
      license_found: boolean;
    };
  } = {};

  const getLicenseMinMaxValue = (
    _id: string | undefined
  ): {
    min_quantity: number;
    max_quantity: number | undefined;
    license_found: boolean;
  } => {
    if (!_id) {
      return {
        min_quantity: 0,
        max_quantity: undefined,
        license_found: false,
      };
    }
    if (!memo[_id]) {
      const license = licenses.find(sku => sku._id === _id);
      memo[_id] = {
        min_quantity: license?.min_quantity || 0,
        max_quantity: license?.max_quantity || 0,
        license_found: license ? true : false,
      };
    }
    return memo[_id];
  };

  const isSkuQuantityOutOfMinMax = (
    skuQuantity: number | undefined,
    definedMin: number,
    definedMax: number | undefined
  ) => (
    !skuQuantity ||
      skuQuantity === 0 ||
      (skuQuantity &&
        (skuQuantity < definedMin ||
          skuQuantity > (definedMax ? definedMax : Number.MAX_SAFE_INTEGER)))
  );

  for (const parentSku of stateProducts) {
    if (parentSku.type === CLOUD) {
      return isSkuQuantityOutOfMinMax(
        parentSku.quantity,
        NewAutomationCloudBundleOption.min_quantity,
        NewAutomationCloudBundleOption.max_quantity
      );
    } else if (parentSku.type === AUTOMATION_SUITE) {
      return isSkuQuantityOutOfMinMax(
        parentSku.quantity,
        NewAutomationSuiteBundleOption.min_quantity,
        NewAutomationSuiteBundleOption.max_quantity
      );
    } else if ([ STANDALONE, SERVICE ].includes(parentSku.type)) {
      const {
        min_quantity,
        max_quantity,
        license_found,
      } = getLicenseMinMaxValue(parentSku?.sku_id);
      if (
        isSkuQuantityOutOfMinMax(
          parentSku.quantity,
          min_quantity,
          max_quantity
        ) ||
        !license_found
      ) {
        return true;
      }
    } else if (
      [
        NEW_ORCHESTRATOR,
        EXISTING_ORCHESTRATOR,
        NEW_PROCESS_MINING,
        EXISTING_PROCESS_MINING,
        EXISTING_CLOUD,
        EXISTING_AUTOMATION_SUITE,
      ].includes(parentSku.type)
    ) {
      // If existing orchestrator has expired
      if (
        (parentSku.type === EXISTING_ORCHESTRATOR ||
          parentSku.type === EXISTING_PROCESS_MINING ||
          parentSku.type === EXISTING_CLOUD ||
          parentSku.type === EXISTING_AUTOMATION_SUITE) &&
        !activeAccountBundleLicenses.includes(
          parentSku.existing_license_code || ''
        )
      ) {
        return true;
      }

      // If any of the child skus have something invalid
      for (const childSku of parentSku.products || []) {
        const {
          min_quantity,
          max_quantity,
          license_found,
        } = getLicenseMinMaxValue(childSku.sku_id);

        if (
          isSkuQuantityOutOfMinMax(
            childSku.quantity,
            min_quantity,
            max_quantity
          ) ||
          !license_found
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

/***
 * *******End of Validations **************
 */
