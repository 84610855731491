import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import React, {
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

interface MultiEmailInputProps {
  label?: string;
  inputClass?: string;
  containerClass?: string;
  updateItems: (items: string[]) => void;
  updateErrors?: (error: boolean) => void;
}

const MultiEmailInput = (props: MultiEmailInputProps) => {
  // Translate method
  const { t } = useTranslation('common');
  const [ items, setItems ] = useState<string[]>([]);
  const [ value, setValue ] = useState<string>('');
  const [ error, setError ] = useState<string | null>(null);
  const baseClass = 'MultiEmailInput';

  useEffect(() => {
    props.updateItems(items);
  }, [ items ]);

  const handleKeyDown = (evt: any) => {
    if ([ 'Enter', 'Tab', ',' ].includes(evt.key)) {
      evt.preventDefault();

      const newValue = value.trim();

      if (newValue && isValid(newValue)) {
        const newItems = [ ...items, newValue ];
        setItems(newItems);
        setValue('');
      }
    }
  };

  const handleChange = (evt: any) => {
    setValue(evt.target.value);
    setError(null);
    if (props.updateErrors && typeof props.updateErrors === 'function') {
      props.updateErrors(true);
    }
  };

  const handleDelete = (item: any) => {
    const newItems = items.filter(i => i !== item);
    setItems(newItems);
  };

  const handlePaste = (evt: any) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData('text');
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      const toBeAdded = emails.filter((email: string) => isValid(email));
      if (toBeAdded.length > 0) {
        const newItems = [ ...items, ...toBeAdded.flat() ];
        setItems(newItems);
      }
    }
  };

  const isValid = (email: string) => {
    let error = null;

    if (isInList(email)) {
      error = t(
        'multi_email_input_component_validation_email_already_added',
        `${email} has already been added.`,
        { email }
      );
    }

    if (!isEmail(email)) {
      error = t(
        'multi_email_input_component_validation_not_valid_email',
        `${email} is not a valid email address.`,
        { email }
      );
    }

    if (items.length === 5) {
      error = t(
        'multi_email_input_component_validation_limit_to_5_emails',
        'Please limit the amount of emails entered to 5.'
      );
    }

    if (error) {
      setError(error);
      return false;
    }

    return true;
  };

  const isInList = (email: string) => items.includes(email);

  const isEmail = (email: string) => /^[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+$/.test(email);

  return (
    <div className={`${baseClass} ${props.containerClass}`}>
      {props.label && (
        <InputLabel className={`${baseClass}__Chip`}>{props.label}</InputLabel>
      )}

      {items.map(item => (
        <Chip
          key={item}
          label={item}
          onDelete={() => handleDelete(item)} />
      ))}
      <TextField
        className={`${baseClass}__Text-Field ${props.inputClass}`}
        variant={'outlined' as any}
        value={value}
        onChange={handleChange}
        error={Boolean(error)}
        required={false}
        InputLabelProps={{ shrink: true }}
        InputProps={{ className: 'Tall' }}
        onPaste={handlePaste}
        helperText={
          error
            ? error
            : t(
              'multi_email_input_component_input_label',
              'Type or paste email addresses and press the Enter key'
            )
        }
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default MultiEmailInput;
