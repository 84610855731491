import React, { useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// styles
import * as styles from '../../assets/css/CustomerPortalFooter';
import { PREMIUM_ACCOUNT_TYPE_TO_MAIN_MENU_TITLE } from '../../constants/account.constants';
import { CS_DIGITAL_ONBOARDING_KB_SUBCATEGORY_ID } from '../../constants/knowledge.constants';
import useLogout from '../../hooks/useLogout';
// AppInsights
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
import BrandedLogoAndText from '../BrandedLogoAndText';
import Container from '../CustomerPortal-New-Container';

const BrandedLogoAndTextStyled = styled(BrandedLogoAndText)`
  .BrandedLogoAndText__Container {
    cursor: pointer;
    .BrandedLogoAndText__Container-Img {
      height: 48px;
    }
    .BrandedLogoAndText__Container-Text {
      font-size: 16px;
      line-height: 14px;
    }
  }
`;

const year = new Date().getFullYear();
const links = [
  {
    textKey: 'header_menu_documentation',
    fallbackText: 'Documentation',
    category: 'kb',
    href: '/knowledge-base',
  },
  {
    textKey: 'header_menu_product_downloads',
    fallbackText: 'Downloads',
    category: 'downloads',
    href: '/product-downloads',
  },
  {
    textKey: 'header_menu_collaboration',
    fallbackText: 'Collaboration',
    category: 'collab',
    href: '/collab-space',
  },
  {
    textKey: 'header_menu_company',
    fallbackText: 'Company',
    category: 'company',
    href: '/company?section=contacts',
  },
  {
    textKey: 'header_menu_premium_account_premium',
    fallbackText: 'Premium',
    category: 'user',
    href: '/support?section=cases-premium',
  },
  {
    textKey: 'header_menu_enablement',
    fallbackText: 'Enablement',
    category: 'kb',
    href: `/knowledge-base/category/Onboarding?sub=${CS_DIGITAL_ONBOARDING_KB_SUBCATEGORY_ID}`,
  },
  {
    textKey: 'header_menu_support',
    fallbackText: 'Support',
    category: 'user',
    href: '/support?section=tickets-incident',
  },
  {
    textKey: 'header_menu_logout',
    fallbackText: 'Logout',
    category: 'user',
  },
];

const ClassicFooter = () => {
  const { state } = useContext(StoreContext);
  const { t } = useTranslation('common');
  const logout = useLogout();
  const isCollabSpaceEnabled = UserPermissionsHelper.isCollabSpaceEnabled();
  const isViewCollabAllowed = UserPermissionsHelper.isViewCollabAllowed();
  const isViewSupportAllowed = UserPermissionsHelper.isViewSupportAllowed();
  const isViewPremiumSupportAllowed = UserPermissionsHelper.isViewPremiumSupportAllowed();
  const isPremiumAccount = UserPermissionsHelper.isPremiumAccount();

  const handleDisabledClick = (e: any) => {
    e.preventDefault();
  };

  // Handle user logout.
  const handleUserLogout = async (e: any) => {
    e.preventDefault();
    await logout();
  };

  const linkIsDisabled = (link: string) => {
    // Always show kb, product downloads, enablement, and logout
    if ([ 'Documentation', 'Downloads', 'Enablement', 'Logout' ].includes(link)) {
      return false;
    } else if (link === 'Collaboration') {
      return !isViewCollabAllowed;
    } else if (link === 'Support') {
      return !isViewSupportAllowed;
    } else if (link === 'Premium') {
      return !isViewSupportAllowed || !isViewPremiumSupportAllowed;
    } else if (link === 'Company' && state.companyId !== '') {
      return false;
    }

    return true;
  };

  const generateColLinks = () => links
    .filter((link) => {
      if (!isCollabSpaceEnabled && link.fallbackText === 'Collaboration') {
        return false;
      }

      if (link.fallbackText === 'Premium') {
        return isViewSupportAllowed && isViewPremiumSupportAllowed &&
            state.companyType in PREMIUM_ACCOUNT_TYPE_TO_MAIN_MENU_TITLE;
      }

      if (link.fallbackText === 'Enablement') {
        return !isPremiumAccount;
      }

      return true;
    })
    .map((link, i) => {
      const isLinkDisabled = linkIsDisabled(link.fallbackText);

      if (link.fallbackText === 'Support') {
        // Change the link of support based on the user type
        const linkHref = UserPermissionsHelper.isUiPathUserWithNoCompanySelected()
          ? '/support/add-case'
          : link.href;
        const href = !linkHref || isLinkDisabled ? '#' : linkHref;

        return (
          <Link
            to={href}
            key={i}
            {...(isLinkDisabled ? { onClick: handleDisabledClick } : {})}
            className={`CustomerPortalFooter__Link${isLinkDisabled ? ' Disabled' : ''}`}
            data-testid={isLinkDisabled ? 'DisabledLink' : 'EnabledLink'}
          >
            {t(link.textKey, link.fallbackText)}
          </Link>
        );
      }

      if (link.fallbackText === 'Logout') {
        return (
          <div
            key={i}
            onClick={handleUserLogout}
            className="CustomerPortalFooter__Link"
            data-testid="EnabledLink"
          >
            {t(link.textKey, link.fallbackText)}
          </div>
        );
      }

      if (link.fallbackText === 'Premium') {
        const textKey = PREMIUM_ACCOUNT_TYPE_TO_MAIN_MENU_TITLE[state.companyType].textKey;
        const fallbackText = PREMIUM_ACCOUNT_TYPE_TO_MAIN_MENU_TITLE[state.companyType].fallbackText;

        // For Activate Success customers, the Premium link should redirect to the tickets dashboard
        const linkHref = UserPermissionsHelper.isActivateSuccessCustomer()
          ? '/support?section=tickets-dashboard'
          : link.href;
        const href = !linkHref || isLinkDisabled ? '#' : linkHref;
        return (
          <Link
            to={href}
            key={i}
            {...(isLinkDisabled ? { onClick: handleDisabledClick } : {})}
            className={`CustomerPortalFooter__Link${isLinkDisabled ? ' Disabled' : ''}`}
            data-testid={isLinkDisabled ? 'DisabledLink' : 'EnabledLink'}
          >
            {t(textKey, fallbackText)}
          </Link>
        );
      }

      return (
        <Link
          to={!link.href || isLinkDisabled ? '#' : link.href}
          key={i}
          {...(isLinkDisabled ? { onClick: handleDisabledClick } : {})}
          className={`CustomerPortalFooter__Link${isLinkDisabled ? ' Disabled' : ''}`}
          data-testid={isLinkDisabled ? 'DisabledLink' : 'EnabledLink'}
        >
          {t(link.textKey, link.fallbackText)}
        </Link>
      );
    });

  const showLegalCopy = () => (
    <styles.FooterCopyText data-testid="Copyright">
      {t(
        'footer_copyright',
        `Copyright © ${year} UiPath. All rights reserved`,
        { year }
      )}
      {' '}
      <styles.FooterLink
        href="https://www.uipath.com/hubfs/legalspot/Legal%20Terms/UiPath_General_Terms_of_Use.pdf"
        target="_blank"
        data-testid="TermsOfUse"
      >
        {t('footer_terms_of_use', 'Terms of Use')}
      </styles.FooterLink>
      {' · '}
      <styles.FooterLink
        href="https://www.uipath.com/legal/privacy-policy"
        target="_blank"
        data-testid="PrivacyPolicy"
      >
        {t('footer_privacy_policy', 'Privacy Policy')}
      </styles.FooterLink>
    </styles.FooterCopyText>
  );

  return (
    <styles.CustomerPortalFooterContainer>
      <div
        className="CustomerPortalFooter__Inner-Container"
        data-testid="Footer"
      >
        <Container cssClass="CustomerPortalFooter__Grid-Container">
          <div className="CustomerPortalFooter__Logo-Column">
            <BrandedLogoAndTextStyled
              useLineBreak
              useOnClick
            />
            <div className="Footer__Mobile-Copy">{showLegalCopy()}</div>
          </div>
          <div className="CustomerPortalFooter__Links-Copy-Col">
            <div className="CustomerPortalFooter__Links-Row">
              <a
                className="CustomerPortalFooter__Link"
                data-testid="EnabledLink"
                href="https://www.uipath.com/customer-portal-faq"
                target="_blank"
                rel="noreferrer"
              >
                  FAQ
              </a>
              {generateColLinks()}
            </div>
            <div className="Footer__Desk-Copy">{showLegalCopy()}</div>
          </div>
        </Container>
      </div>
    </styles.CustomerPortalFooterContainer>
  );
};

export default ClassicFooter;
