import type { KBEmbeddedVideoType } from '@customer-portal/constants';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import type { VideoThumbnailSize } from '../../constants/knowledge.constants';
import { webchatClient } from '../../utils/webchatClient';
import VideoPopup from './VideoPopup/VideoPopup';
import VideoPreview from './VideoPreview/VideoPreview';

const mainVideoCss = css`
  border: 0;
  box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 20%);
`;

const VideoStyled = styled.video`
  ${mainVideoCss}
`;
const IframeStyled = styled.iframe`
  ${mainVideoCss}
`;

type Props = React.IframeHTMLAttributes<HTMLIFrameElement> & {
  open?: boolean;
  onClose: () => void;
  isEmbeddedVideo?: boolean;
  embeddedVideoRaw: string;
  embeddedVideoType: KBEmbeddedVideoType;
  embeddedVideoId: string;
  embeddedVideoThumbnailSize?: VideoThumbnailSize;
};

const VideoPlayer = (props: Props) => {
  const [ isVideoPopupOpen, setIsVideoPopupOpen ] = useState(false);
  const [ isClientSlide, setIsClientSide ] = useState(false);
  const playButtonRef = useRef<HTMLButtonElement | null>(null);
  const videoElementRef = useRef<HTMLVideoElement | null>(null);
  const iframeElementRef = useRef<HTMLIFrameElement | null>(null);
  const {
    open,
    onClose,
    src,
    isEmbeddedVideo = true,
    ...otherIframeProps
  } = props;
  const processedSrc = useMemo(() => {
    if (!src) {
      return '';
    }

    const srcURL = new URL(src);
    const searchParams = srcURL.searchParams;

    // Only append if this is a a non-video file such as youtube, vimeo
    if (isEmbeddedVideo) {
      searchParams.append('autoplay', '1');
      srcURL.search = searchParams.toString();
    }

    return srcURL.href;
  }, [ src, isEmbeddedVideo ]);

  useEffect(() => {
    setIsClientSide(true);
  }, []);

  useEffect(() => {
    open && openVideoPopup();
  }, [ open ]);

  const openVideoPopup = () => {
    !isVideoPopupOpen && setIsVideoPopupOpen(true);
    webchatClient.hide();
  };

  const closeVideoPopup = () => {
    if (open ?? isVideoPopupOpen) {
      onClose();
      setIsVideoPopupOpen(false);
      playButtonRef?.current?.focus();
      webchatClient.show();
    }
  };

  return isClientSlide ? (
    <div data-testid="VideoPlayer__container">
      <VideoPreview
        {...props}
        onPreviewClick={openVideoPopup}
        isEmbeddedVideo={isEmbeddedVideo}
        playButtonRef={playButtonRef}
      />
      <VideoPopup
        isVideoPopupOpen={isVideoPopupOpen}
        closeVideoPopup={closeVideoPopup}
        isEmbeddedVideo={isEmbeddedVideo}
        contentElementRef={!isEmbeddedVideo ? videoElementRef : iframeElementRef}
      >
        {!isEmbeddedVideo ? (
          <VideoStyled
            src={processedSrc}
            controls
            controlsList="nodownload"
            autoPlay
            width={otherIframeProps.width}
            height={otherIframeProps.height}
            ref={videoElementRef}
          />
        ) : (
          <IframeStyled
            {...otherIframeProps}
            src={processedSrc}
            allowFullScreen
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ref={iframeElementRef}
          />
        )}
      </VideoPopup>
    </div>
  ) : (
    <VideoPreview
      {...props}
      renderStaticPreview />
  );
};

export default VideoPlayer;
