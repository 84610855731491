export const PUBLIC_SUPPORT_CSRF_COOKIE_NAME = 'csrf_token';
export const PUBLIC_SUPPORT_CSRF_COOKIE_EXPIRY_MS = 60 * 60 * 1000; // 1 hour

export const PUBLIC_SUPPORT_FORM_RATE_LIMIT_RETRY_AFTER_MS = 60 * 1000; // 1 minute
export const PUBLIC_SUPPORT_FORM_CSRF_POLLING_INTERVAL_MS = 5 * 1000; // 5 seconds

export enum CaseRecordType {
  CLOUD_ELEMENTS = '0125b000000LVtwAAG',
  INCIDENT = '01236000001QF1EAAW',
  JAPAN_INCIDENT = '0121Q000001QFMTQA4',
  PREMIUM = '0121Q0000012XTQQA2',
  SALES_OPERATION = '0121Q000001QFMVQA4',
  SERVICE_REQUEST = '01236000001QF1GAAW',
  CASE_ESCALATION = '0125b000000LVSeAAO',
}

export const CLOUD_ELEMENTS_PRODUCTS = [
  'Embedded Cloud Elements',
  'Embedded Cloud Elements Platform',
];

export const SUPPORTED_BASIC_CASE_TYPES = [
  CaseRecordType.INCIDENT,
  CaseRecordType.JAPAN_INCIDENT,
  CaseRecordType.SERVICE_REQUEST,
  CaseRecordType.CLOUD_ELEMENTS,
];

export const SUPPORTED_RECORD_TYPES = [
  CaseRecordType.PREMIUM,
  ...SUPPORTED_BASIC_CASE_TYPES,
];
