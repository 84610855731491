import React from 'react';
import styled from 'styled-components';

const ExportCSVButtonComp = styled.button`
  padding: 10px 16px;
  background-color: #fff;
  border: 1px solid ${p => p.theme.palette.ink[300]};
  border-radius: 8px;
  color: ${p => p.theme.palette.blue[500]};
  font-size: 1.4rem;
  font-weight: 600;
  cursor: pointer;
`;

type ExportCSVButtonProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  [key: string]: any;
};

const ExportCSVButton = (props: ExportCSVButtonProps) => {
  const {
    onClick: handleOnClick, className, children,
  } = props;
  return (
    <ExportCSVButtonComp
      onClick={handleOnClick}
      className={className}>
      {children}
    </ExportCSVButtonComp>
  );
};

export default ExportCSVButton;
