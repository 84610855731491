import Dinero from 'dinero.js';
import React, { useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';

import {
  BundleCard,
  BundleCardContainer,
  BundleParent,
  BundleParentContainer,
  ProductContainer,
  ProductQty,
  ProductsMainWrapper,
} from '../../../assets/css/Admin/Admin-RFQModal';
import * as styles from '../../../assets/css/Category/KB-Upload-Modal';
import { ModalContentFormWrapper } from '../../../assets/css/Knowledge/CategoryManager-Table';
import Button from '../../Button/Button';
import Modal from '../../Modal';
import TextLabel from '../../TextLabel';

enum FieldChange {
  CompanyId = 'company_id',
  CompanyName = 'company_name',
  SFDCOpportunityId = 'sfdc_opportunity_id',
  TotalValueUSD = 'total_value_usd',
}

type AdminRFQModalProps = {
  dataTestId?: string;
  isEdit: boolean;
  isModalOpen: boolean;
  quoteData: { [key: string]: any };
  handleFormSubmit: any;
  handleModalClose: Function;
  children?: any;
};

const AdminRFQModal = (props: AdminRFQModalProps) => {
  const {
    isEdit,
    isModalOpen,
    handleFormSubmit,
    handleModalClose,
    quoteData,
    dataTestId,
  } = props;

  const isViewOnly = !isEdit;
  const { t } = useTranslation('common');

  const [ formData, setFormData ] = useState<any>({
    ...quoteData,
    total_value_usd: quoteData.total_value_usd ?? 0,
    total_value_usd_display: Dinero({
      amount: quoteData.total_value_usd ?? 0,
      currency: 'USD',
    }).toFormat(),
  });

  const generateProducts = () => {
    if (!Array.isArray(formData.products)) {
      return undefined;
    }

    return (
      <BundleCardContainer data-testid={dataTestId || ''}>
        {formData.products.map((product: any) => {
          const {
            sku_name,
            existing_license_name,
            existing_license_code,
            type,
            quantity,
            _id,
            products,
          } = product;

          const skuName = existing_license_name ?? sku_name ?? '';
          const bundleType = existing_license_code ?? type ?? '';
          return (
            <BundleCard key={_id}>
              <BundleParentContainer>
                <BundleParent>
                  <span>
                    <strong>{skuName}</strong>
                  </span>
                  <br />
                  <span>{bundleType}</span>
                </BundleParent>
                <ProductQty>{quantity}</ProductQty>
              </BundleParentContainer>
              {Array.isArray(products) && products.length > 0 && (
                <ProductContainer>
                  {products.map((childProduct: any) => (
                    <BundleParentContainer key={childProduct._id}>
                      <BundleParent>{childProduct.sku_name}</BundleParent>
                      <ProductQty>{childProduct.quantity}</ProductQty>
                    </BundleParentContainer>
                  ))}
                </ProductContainer>
              )}
            </BundleCard>
          );
        })}
      </BundleCardContainer>
    );
  };

  const handleDataChange = (e: any, field: string) => {
    const tempData = { ...formData };
    switch (field) {
      case FieldChange.CompanyId: {
        tempData.company_id = e.target.value;
        break;
      }
      case FieldChange.CompanyName: {
        tempData.company_name = e.target.value;
        break;
      }
      case FieldChange.SFDCOpportunityId: {
        tempData.sfdc_opportunity_id = e.target.value;
        break;
      }
      default:
        break;
    }

    setFormData(tempData);
  };

  const handleDataKeyUp = (e: React.KeyboardEvent<any>, field: string) => {
    const tempData = { ...formData };

    if (field === FieldChange.TotalValueUSD) {
      const inputNum = parseInt(e.key, 10);
      let updateValueDisplay = false;
      if (!isNaN(inputNum)) {
        // If new number is less than max safe, then ok
        const newValue = parseInt(
          tempData.total_value_usd.toString() + e.key,
          10
        );

        if (newValue < Number.MAX_SAFE_INTEGER) {
          tempData.total_value_usd = newValue;
          updateValueDisplay = true;
        }
      } else if (e.key === 'Backspace') {
        // Remove last digit
        tempData.total_value_usd = parseInt(
          tempData.total_value_usd.toString().slice(0, -1) || 0,
          10
        );
        updateValueDisplay = true;
      }

      if (updateValueDisplay) {
        tempData.total_value_usd_display = Dinero({
          amount: tempData.total_value_usd,
          currency: 'USD',
        }).toFormat();
      }
    }

    setFormData(tempData);
  };

  return (
    <Modal
      modalTitle="Cart Details"
      modalHeading="Cart Details"
      modalDescription="Modal for cart details"
      open={isModalOpen}
      handleClose={(e: any) => handleModalClose()}
    >
      <ModalContentFormWrapper
        onSubmit={(e: any) => {
          e.preventDefault();
          handleFormSubmit(formData);
        }}
      >
        {/* Never editable */}
        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_cart_id',
              'Cart ID:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={formData._id}
            placeholder="Cart ID"
            disabled
          />
        </styles.FormControlWrapper>

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_cart_state',
              'Cart State:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={formData.state}
            placeholder="Cart State"
            disabled
          />
        </styles.FormControlWrapper>

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_customer_id_long',
              'Customer / ID Long:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={formData.company_id}
            onChange={e => handleDataChange(e, FieldChange.CompanyId)}
            placeholder="Customer ID"
            disabled={isViewOnly}
            inputProps={{ 'aria-label': 'customer-id' }}
          />
        </styles.FormControlWrapper>

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_customer_name',
              'Customer Name:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={formData.company_name}
            onChange={e => handleDataChange(e, FieldChange.CompanyName)}
            placeholder="Customer Name"
            disabled={isViewOnly}
            inputProps={{ 'aria-label': 'customer-name' }}
          />
        </styles.FormControlWrapper>

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_products',
              'Products:'
            )}
          />
          <ProductsMainWrapper>{generateProducts()}</ProductsMainWrapper>
        </styles.FormControlWrapper>

        {formData.duration && (
          <styles.FormControlWrapper className="UploadForm__FormControl">
            <TextLabel
              label={t(
                'rfq_analytics_cart_details_duration',
                'Duration:'
              )}
            />
            <styles.TextInput
              variant={'outlined' as any}
              className="UploadForm__Input"
              value={formData.duration / 365 + ' year(s)'}
              placeholder="Duration"
              disabled
            />
          </styles.FormControlWrapper>
        )}

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_created_by',
              'Created By:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={formData.created_by?.name ?? ''}
            placeholder="Created By Name"
            disabled
          />
        </styles.FormControlWrapper>

        {formData.note && (
          <styles.FormControlWrapper className="UploadForm__FormControl UploadForm__DescriptionArea">
            <TextLabel
              label={t(
                'rfq_analytics_cart_details_note',
                'Note:'
              )}
            />
            <styles.TextInput
              variant={'outlined' as any}
              className="UploadForm__Textarea"
              value={formData.note}
              rows={2}
              multiline
              placeholder="Note from customer"
              disabled
            />
          </styles.FormControlWrapper>
        )}

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_sfdc_opportunity_id',
              'SFDC Opportunity ID:'
            )}
          />
          <styles.TextInput
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={formData.sfdc_opportunity_id || ''}
            onChange={e => handleDataChange(e, FieldChange.SFDCOpportunityId)}
            placeholder="SFDC Opportunity ID"
            disabled={isViewOnly}
            inputProps={{ 'aria-label': 'opportunity-id' }}
          />
        </styles.FormControlWrapper>

        <styles.FormControlWrapper className="UploadForm__FormControl">
          <TextLabel
            label={t(
              'rfq_analytics_cart_details_total_value_usd',
              'Total Value USD:'
            )}
          />
          <styles.TextInput
            type="text"
            variant={'outlined' as any}
            className="UploadForm__Input"
            value={formData.total_value_usd_display}
            onKeyUp={e => handleDataKeyUp(e, FieldChange.TotalValueUSD)}
            placeholder="Cart Value in USD"
            disabled={isViewOnly}
            inputProps={{ 'aria-label': 'total-value-usd' }}
          />
        </styles.FormControlWrapper>

        {isEdit && (
          <Button
            className="UploadForm__SubmitButton"
            onClick={e => undefined}
            text="Submit"
          />
        )}
      </ModalContentFormWrapper>
    </Modal>
  );
};

export default AdminRFQModal;
