import 'draft-js/dist/Draft.css';

import {
  ContentState,
  convertFromHTML,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  RichTextFormatButton,
  RichTextFormatButtonWrapper,
  RichTextLabel,
  RichTextWrapper,
} from '../assets/css/RichTextEditor';
// Image assets
import FormatBoldIcon from '../assets/img/svg/rich_text/format_bold_24px.svg';
import FormatItalicIcon from '../assets/img/svg/rich_text/format_italic_24px.svg';
import FormatUnderlineIcon from '../assets/img/svg/rich_text/format_underlined_24px.svg';

type RichTextEditorProps = {
  label?: string;
  handleOnChange?: (html: string, isEmpty: boolean) => void;
  placeholder?: string;
  defaultValue?: string;
  stripPastedStyles?: boolean;
  shouldReset?: boolean;
  setShouldReset?: React.Dispatch<React.SetStateAction<boolean>>;
};

const RichTextEditor = ({
  label,
  handleOnChange,
  placeholder,
  defaultValue = '',
  stripPastedStyles = false,
  shouldReset,
  setShouldReset,
}: RichTextEditorProps) => {
  const blocksFromHTML = convertFromHTML(defaultValue);
  const defaultState = EditorState.createWithContent(
    ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
  );
  const [ editorState, setEditorState ] = useState(defaultState);
  const editor = useRef<Editor>(null);

  const focusEditor = () => {
    if (editor?.current) {
      editor.current.focus();
    }
  };

  useEffect(() => {
    if (shouldReset && !!setShouldReset) {
      setEditorState(EditorState.createEmpty());
      setShouldReset(false);
    }
  }, [ shouldReset ]);

  const handleKeyCommand = (command: string) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      handleOnChangeLocal(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const handleOnChangeLocal = (editorState: EditorState) => {
    setEditorState(editorState);

    const isEmpty = !editorState.getCurrentContent().hasText();
    if (handleOnChange) {
      handleOnChange(stateToHTML(editorState.getCurrentContent()), isEmpty);
    }

    // Return the html version - keep this in case inline is needed
    // props.handleOnChange(stateToHTML(editorState.getCurrentContent(), {
    //   blockStyleFn: (block: ContentBlock) => {
    //     return {
    //       style: {
    //         'font-size': '1.4rem',
    //         color: '#526069',
    //         'line-height': '1.7'
    //       }
    //     }
    //   }
    // }));
  };

  const onSubmit = () => setEditorState(EditorState.createEmpty());

  const onFormatMouseDown = (inlineStyle: string) => (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    handleOnChangeLocal(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const onFormatButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => e.preventDefault();

  const onTab = (
    e: React.KeyboardEvent,
  ) => {
    e.preventDefault();
    // Augment tab-indentation manually since draft-js only supports tabs for nested lists
    const content = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '           ',
    );
    const newEditorState = EditorState.push(editorState, content, 'insert-characters');
    handleOnChangeLocal(newEditorState);
  };

  return (
    <>
      {label && <RichTextLabel>{label}</RichTextLabel>}
      <RichTextWrapper onClick={focusEditor}>
        <RichTextFormatButtonWrapper>
          <RichTextFormatButton
            onMouseDown={onFormatMouseDown('BOLD')}
            onClick={onFormatButtonClick}
          >
            <img
              src={FormatBoldIcon}
              alt="Bold" />
          </RichTextFormatButton>
          <RichTextFormatButton
            onMouseDown={onFormatMouseDown('ITALIC')}
            onClick={onFormatButtonClick}
          >
            <img
              src={FormatItalicIcon}
              alt="Italic" />
          </RichTextFormatButton>
          <RichTextFormatButton
            onMouseDown={onFormatMouseDown('UNDERLINE')}
            onClick={onFormatButtonClick}
          >
            <img
              src={FormatUnderlineIcon}
              alt="Underline" />
          </RichTextFormatButton>
        </RichTextFormatButtonWrapper>
        <Editor
          ref={editor}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={handleOnChangeLocal}
          placeholder={placeholder}
          ariaLabel="Rich Text Editor"
          role="Rich Text Editor"
          stripPastedStyles={stripPastedStyles}
          onTab={onTab}
        />
      </RichTextWrapper>
    </>
  );
};

export default RichTextEditor;
