import type { UserPermission } from '@customer-portal/constants';
import { UserRole } from '@customer-portal/constants';
import { doesUserHavePermissions } from '@customer-portal/utils';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// styles
import * as styles from '../../assets/css/edit_profile/EditProfileCompaniesAndPermissions';
import infoIcon from '../../assets/img/svg/status_icons/info_gray.svg';
// images
import successIcon from '../../assets/img/svg/status_icons/success.svg';
import {
  AccountType,
  AllowedHapoAccounts,
} from '../../constants/account.constants';
import { permissionInfo } from '../../constants/addUserPermissions';
// constants
import {
  COLLAB,
  ENTITY,
  HAPO,
  LICENSE,
  PREMIUMCARE,
  SEND_INVITATION_AND_PERMISSION_EDITING,
  SUPPORT,
} from '../../constants/roles.constants';
// contexts
import { StoreContext } from '../../store';
// components
import Container from '../CustomerPortal-New-Container';

const useStyles = makeStyles(theme => ({ customWidth: { maxWidth: 400 } }));

const UserCompaniesAndPermissions = () => {
  // Translate method
  const { t } = useTranslation('common');
  const { state } = useContext(StoreContext);
  const history = useHistory();
  const classes = useStyles();

  const permissionsList = [
    {
      label: t(
        'user_profile_company_and_permissions_license_rfq_label',
        'License & Request Quote'
      ),
      scope: LICENSE,
    },
    {
      label: t('user_profile_company_and_permissions_support_label', 'Support'),
      scope: SUPPORT,
    },
    {
      label: t(
        'user_profile_company_and_permissions_premium_care_label',
        'Preventive Care'
      ),
      scope: PREMIUMCARE,
    },
    {
      label: t(
        'user_profile_company_and_permissions_collab_space_label',
        'Collaboration Space'
      ),
      scope: COLLAB,
    },
    {
      label: state.isUtoEnabled ? t(
        'user_profile_company_and_permissions_uto_label',
        'UiPath Tailored Offering'
      ) : t(
        'user_profile_company_and_permissions_hapo_label',
        'Enterprise Agreement Licenses'
      ),
      scope: HAPO,
    },
    {
      label: t(
        'user_profile_company_and_permissions_entity_label',
        'Entity Management'
      ),
      scope: ENTITY,
    },
    {
      label: t(
        'user_profile_company_and_permissions_invitations_label',
        'Invitation and permission editing'
      ),
      scope: SEND_INVITATION_AND_PERMISSION_EDITING,
    },
  ];

  const generatePermissionsMatrix = (
    label: string,
    scope: string,
    { userCompany }: typeof state.userCompanies[number],
  ) => {
    const [ viewPermissionInfo, editPermissionInfo ] = permissionInfo(
      scope,
      true,
    );

    return (
      <div
        key={`${userCompany.companyId}-${scope}`}
        className="CompaniesListItem__bodyHeader CompaniesListItem__bodyHeader--content"
      >
        <span className="CompaniesListItem__bodyPermissions">
          <span>{label}</span>
          <Tooltip
            classes={{ tooltip: classes.customWidth }}
            placement="bottom-start"
            title={
              <div className="CompaniesListItem__permissionDescription">
                <p>{t(viewPermissionInfo.keyText, viewPermissionInfo.fallbackText)}</p>
                <p>{t(editPermissionInfo.keyText, editPermissionInfo.fallbackText)}</p>
              </div>
            }
          >
            <div className="CompaniesListItem__infoIcon">
              <img
                src={infoIcon}
                alt="info icon" />
            </div>
          </Tooltip>
        </span>
        <div className="CompaniesListItem__bodyPermissionsType">
          {scope !== SEND_INVITATION_AND_PERMISSION_EDITING && (
            <>
              <span>
                {doesUserHavePermissions({
                  adminUserRoles: [],
                  userCompanyRoles: userCompany.roles,
                  permissions: [ `${scope}:read` as UserPermission ],
                  match: 'any',
                }) ? (
                    <img
                      src={successIcon}
                      alt="Success Icon" />
                  ) : (
                    '-'
                  )}
              </span>
              <span>
                {doesUserHavePermissions({
                  adminUserRoles: [],
                  userCompanyRoles: userCompany.roles,
                  // For external users, the collab `write` permission is labeled as `collab.external:write`
                  permissions: [ `${scope === COLLAB ? `${scope}.external` : scope}:write` as UserPermission ],
                  match: 'any',
                }) ? (
                    <img
                      src={successIcon}
                      alt="Success Icon" />
                  ) : (
                    '-'
                  )}
              </span>
            </>
          )}

          {scope === SEND_INVITATION_AND_PERMISSION_EDITING && (
            <>
              <span>
                {userCompany.roles.map(r => r.name).includes(UserRole.CP_ADMIN) ? (
                  <img
                    src={successIcon}
                    alt="Success Icon" />
                ) : (
                  '-'
                )}
              </span>
              <span>
                {userCompany.roles.map(r => r.name).includes(UserRole.CP_ADMIN) ? (
                  <img
                    src={successIcon}
                    alt="Success Icon" />
                ) : (
                  '-'
                )}
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  const handleCompanyClick = (e: any) => {
    const baseClassName = 'CompaniesListItem';

    if (!e.currentTarget.parentNode.className.includes('active')) {
      e.currentTarget.parentNode.className =
        baseClassName + ` ${baseClassName}--active`;
    } else {
      e.currentTarget.parentNode.className = baseClassName;
    }
  };

  const handleContactAdminClick = (e: any) => {
    e.preventDefault();
    history.push('/company?section=users');
  };

  return (
    <Container>
      <styles.CompaniesAndPermissions data-testid="UserCompaniesAndPermissions__wrapper">
        <h4 className="headerTitle">
          {t(
            'user_profile_company_and_permissions_headline',
            'Company and permissions'
          )}
        </h4>
        <div
          className="CompaniesList"
          data-testid="UserCompaniesAndPermissions__list">
          {state.userCompanies.map(({
            userCompany, additionalCompanyInfo,
          }) => {
            let listItemModifier: string = '';

            if (state.companyId === userCompany.companyId) {
              listItemModifier = ` CompaniesListItem--active`;
            }

            return (
              <div
                key={userCompany.companyId}
                id={userCompany.companyId}
                className={`CompaniesListItem${listItemModifier}`}
                data-testid="ProfilePage__companyItem"
              >
                <div
                  className="CompaniesListItem__header"
                  onClick={e => handleCompanyClick(e)}
                  data-testid="UserCompaniesAndPermissions__header"
                >
                  <span className="CompanyName">{additionalCompanyInfo?.name ?? ''}</span>
                  <span className="CompanyRole">
                    {userCompany.roles.map(r => r.name).includes(UserRole.CP_ADMIN)
                      ? t(
                        'user_profile_company_and_permissions_admin_role_label',
                        'Admin'
                      )
                      : t(
                        'user_profile_company_and_permissions_regular_user_label',
                        'Regular User'
                      )}
                  </span>
                </div>
                <div className="CompaniesListItem__body">
                  <div className="CompaniesListItem__bodyHeader">
                    <span className="CompaniesListItem__bodyPermissions">
                      {t(
                        'user_profile_company_and_permissions_th_permissions',
                        'Permissions'
                      )}
                    </span>
                    <div className="CompaniesListItem__bodyPermissionsType">
                      <span>
                        {t(
                          'user_profile_company_and_permissions_th_can_view',
                          'Can View'
                        )}
                      </span>
                      <span>
                        {t(
                          'user_profile_company_and_permissions_th_can_edit',
                          'Can Edit'
                        )}
                      </span>
                    </div>
                  </div>
                  {permissionsList
                    .filter(({ scope }) => {
                      const isPremiumAccount: boolean = [
                        AccountType.PREMIUM,
                        AccountType.PREMIUM_PLUS,
                        AccountType.ENTERPRISE,
                      ].includes(additionalCompanyInfo?.maintenanceFlag ?? '');
                      const isHAPOAllowed: boolean = AllowedHapoAccounts.includes(
                        userCompany.companyId
                      );

                      if (
                        (!isPremiumAccount && scope === PREMIUMCARE) ||
                          (!isHAPOAllowed && scope === HAPO)
                      ) {
                        return false;
                      }

                      if (additionalCompanyInfo?.isCollabSpaceEnabled === false && scope === COLLAB) {
                        return false;
                      }

                      return true;
                    })
                    .map(({
                      label, scope,
                    }) =>
                      generatePermissionsMatrix(
                        label,
                        scope,
                        {
                          userCompany,
                          additionalCompanyInfo,
                        }
                      )
                    )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="ContactAccountAdmin">
          <span>
            {t(
              'user_profile_company_and_permissions_footer_part_1',
              'Contact your'
            ) + ' '}
            <a
              href="#"
              onClick={handleContactAdminClick}>
              {t(
                'user_profile_company_and_permissions_footer_part_2',
                'account admin'
              )}
            </a>
            {' '}
            {t(
              'user_profile_company_and_permissions_footer_part_3',
              'about any inquiries.'
            )}
              .
          </span>
        </div>
      </styles.CompaniesAndPermissions>
    </Container>
  );
};

export default UserCompaniesAndPermissions;
