import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Constants
import RFQ from './../../../constants/requestForQuote.constants';

interface IBrowseProductTabs {
  productCategory?: string;
  searchText: string;
  licenses: any[];
  licensesTags: string[];
  handleTabChange: (tabName: string, event: any) => void;
}

const BrowseProductsTabs = (props: IBrowseProductTabs) => {
  // Constants
  const productCategory =
    props.productCategory || RFQ.onPremProductCategory.all.fallbackText;
  const searchText = props.searchText || '';
  const licenses = props.licenses || [];
  const licensesTags = props.licensesTags || [];
  const { t } = useTranslation('common');

  // Methods
  const setTabNames = (tag: string) => {
    if (tag === RFQ.productTags.platforms.fallbackText) {
      return 'Platforms';
    }

    return tag;
  };

  const handleTabChange = (tags: string[], e: any) => {
    if (
      e.target.textContent.includes(RFQ.onPremProductCategory.all.fallbackText)
    ) {
      props.handleTabChange(RFQ.onPremProductCategory.all.fallbackText, e);
    } else {
      tags.forEach((tag: string) => {
        if (e.target.textContent.includes(setTabNames(tag))) {
          props.handleTabChange(setTabNames(tag), e);
        }
      });
    }
  };
  return (
    <>
      <div
        className="RequestForQuote__BrowseProducts__Tabs"
        data-testid="RequestForQuote__BrowseProducts__Tabs"
      >
        {/* All Licenses Tab */}
        {licensesTags.length === 0 && (
          <div className="RequestForQuote__BrowseProducts__NoTab">
            <p>
              {t(
                'rfq_hapo_browse_products_no_results_text',
                'No licenses found!'
              )}
            </p>
          </div>
        )}

        {licensesTags.length > 0 && (
          <div
            className={`RequestForQuote__BrowseProducts__Tab ${
              productCategory === RFQ.onPremProductCategory.all.fallbackText
                ? 'RequestForQuote__BrowseProducts__Tab--active'
                : ''
            }`}
            onClick={(e: any) => handleTabChange([ 'all' ], e)}
          >
            <p className="RequestForQuote__BrowseProducts__TabTitle">
              {t(
                RFQ.onPremProductCategory.all.keyText,
                RFQ.onPremProductCategory.all.fallbackText
              )}
              {' '}
              (
              {
                licenses.filter(
                  (el: any) =>
                    el.sku_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    el.long_description
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    el.tag.toLowerCase().includes(searchText.toLowerCase())
                ).length
              }
              )
            </p>
          </div>
        )}

        {licensesTags &&
          licensesTags.length > 0 &&
          licensesTags.map((tag: string) => (
            <div
              aria-label={setTabNames(tag)}
              key={setTabNames(tag)}
              className={`RequestForQuote__BrowseProducts__Tab ${
                productCategory === setTabNames(tag)
                  ? 'RequestForQuote__BrowseProducts__Tab--active'
                  : ''
              }`}
              onClick={(e: any) => handleTabChange(licensesTags, e)}
            >
              <p className="RequestForQuote__BrowseProducts__TabTitle">
                {setTabNames(tag)}
                {' '}
(
                {
                  licenses
                    .filter((item: any) => item.tag === tag)
                    .filter(
                      (el: any) =>
                        el.sku_name
                          .toLowerCase()
                          .includes(searchText.toLowerCase()) ||
                          el.long_description
                            .toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          el.tag
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                    ).length
                }
                  )
              </p>
            </div>
          ))}
      </div>
    </>
  );
};

export default BrowseProductsTabs;
