import Autocomplete from '@mui/lab/Autocomplete';
import styled from 'styled-components';

export const CustomSelect = styled.div`
  .MuiAutocomplete-paper {
    max-height: 250px;
    overflow: auto;
    border-bottom: none;
  }

  .MuiPaper-elevation1 {
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
  }

  .MuiAutocomplete-option {
    font-size: 1.4rem !important;
    font-family: ${p => p.theme.typography.fontFamily};
    padding: 8px 16px;
    cursor: pointer;
    min-height: 24px;

    &:hover {
      background-color: ${p => p.theme.palette.grey[100]};
      color: ${p => p.theme.palette.blue[500]};
    }
  }

  .MuiAutocomplete-option[aria-selected="true"] {
    background: white !important;
    color: ${p => p.theme.palette.blue[500]} !important;
  }
`;
export const CustomSelectManual: typeof Autocomplete = styled(Autocomplete as any)`
  font-family: ${p => p.theme.typography.fontFamily};
  background: white;
  position: relative;
  border-radius: 8px;
  border: none !important;

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-bottom: none;
    transition: none;
  }

  .MuiAutocomplete-root:hover {
    border: 1px solid red !important;
  }

  .MuiAutocomplete-inputRoot {
    padding-left: 14px !important;
    height: 4.5rem;
  }

  .MuiAutocomplete-input {
    cursor: pointer;
    border-radius: 8px;
    font-size: 1.4rem;
    line-height: 2.4rem;
    padding: 2px !important;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline:hover {
    border-bottom: none !important;
  }

  .MuiAutocomplete-tag {
    margin: 5px 5px 5px 0px;
  }

  .MuiAutocomplete-endAdornment {
    right: 38px !important;
    top: auto;
    bottom: auto;
  }

  .Mui-disabled:before {
    border-bottom: none !important;
  }

  .MuiInputBase-root.Mui-disabled input {
    background-color: white;
  }

  .MuiInputBase-root.Mui-disabled {
    background-color: white;
    border-radius: 8px !important;
  }
`;

export const CustomLabel = styled.span`
  display: flex;
`;

export const CustomInput = styled.span`
  display: flex;

  .arrow {
    top: 50%;
    right: 8px;
    padding: 8px;
    transform: translateY(-50%);
    height: 20;
    fill: black;
    position: absolute;
  }

  .arrowUp {
    opacity: 0.3;
  }

  .arrowDisabled {
    opacity: 0.3;
  }
`;

export const CustomOption = styled.span`
  line-height: 2.4rem;
  font-size: 1.4rem;
`;
