import styled from 'styled-components';

export const OrderStatus = styled.span`
  .RequestHistoryListItemWrapper {
    padding-bottom: 16px;

      span {
        display: inline-block;
        padding-bottom: 16px;
        font-size: 14px;
        line-height: 20px;
        color: #273139;
        word-break: break-word;

        &:last-child {
          padding-bottom: 0;
        }
        
        &.RequestHistoryListLabel {
          padding: 4px 0;
          font-size: 10px;
          font-weight: bold;
          line-height: 16px;
          border-radius: 12px;
          background-color: #fff;
          width: 72px;

          &--inProgress {
            background-color: #FFF3DB;
            color: #9E6100;
          }

          &--provisioned {
            background-color: #EEFFE5;
            color: #038108;
            align-self: flex-start;
          }

          &--cancelled {
            background-color: #FFF0F1;
            color: #A6040A;
            align-self: flex-start;
          }
          
        }

      }

    }
  }
`;
