// Info links shown on the bottom of product download page
export const moreInfoLinks = [
  {
    keyText: 'compatibility_matrix',
    fallbackText: 'Compatibility Matrix',
    link:
      'https://docs.uipath.com/overview/other/latest/overview/compatibility-matrix',
  },
  {
    keyText: 'product_lifecycle',
    fallbackText: 'Product lifecycle',
    link: 'https://docs.uipath.com/overview/other/latest/overview/product-lifecycle',
  },
  {
    keyText: 'general_support',
    fallbackText: 'General support',
    link: 'https://docs.uipath.com/overview/other/latest/overview/general-support-terms',
  },

  {
    keyText: 'activities_lifecycle',
    fallbackText: 'Activities lifecycle',
    link: 'https://docs.uipath.com/overview/other/latest/overview/activities-lifecycle',
  },
];
