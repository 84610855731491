import ReactGA from 'react-ga';

export const CustomerPortalGoogleAnalyticsPageView = (nameOfPage: string) => {
  ReactGA.set({ pageName: nameOfPage });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const CustomerPortalGoogleAnalyticsListValues = (listOfValuesToSet: any) => {
  if (Object.keys(listOfValuesToSet).length !== 0) {
    ReactGA.set(listOfValuesToSet);
  }
};

export const CustomerPortalGoogleAnalyticsEvent = (categoryStr: string, actionStr: string, labelStr = '') => {
  ReactGA.event({
    category: categoryStr,
    action: actionStr,
    label: labelStr,
  });
};
