export const TITLE_ACTIVATE_ACCOUNT = {
  keyText: 'activate_account_page_heading_activate_user',
  fallbackText: 'Activate your account',
};
export const TITLE_CREATE_SUPPORT_TICKET = {
  keyText: 'activate_account_page_heading_create_ticket',
  fallbackText: 'Create support ticket',
};
export const DESCRIPTION = {
  keyText: 'activate_account_page_description',
  fallbackText: 'Thank you for accessing the UiPath Customer Portal. From here you can access troubleshooting documentation and knowledge base articles, premium support, contact support, manage licenses, and more. <br /> <strong>Complete the information to activate your account.</strong>',
};
export const DESCRIPTION_FORM_SUBMITTED = {
  keyText: 'activate_account_page_description_form_submitted',
  fallbackText: 'Activating your account... Please wait until the activation process is finished, which can take up to one minute. Once that process is complete, you will be redirected to the Customer Portal home page.',
};
export const DESCRIPTION_FORM_SUBMITTED_REDIRECT = {
  keyText: 'activate_account_page_description_form_submitted_redirect',
  fallbackText: 'Activating your account... Please wait until the activation process is finished, which can take up to one minute. Once that process is complete, you will be redirected to the Customer Portal page you were trying to access.',
};
export const DESCRIPTION_SUPPORT_FORM_SUBMITTED = {
  keyText: 'activate_account_page_description_support_case_submitted',
  fallbackText: 'Creating support ticket... Please wait until the ticket is created.',
};
export const DESCRIPTION_SUPPORT_CASE_INFO = {
  keyText: 'activate_account_page_get_support_ticket_info_text',
  fallbackText: 'Case <strong>#{{caseNumber}}</strong> was submitted on <strong>{{createdDate}}</strong> to activate your account. The Product Support team will respond shortly.',
};
export const DISCLAIMER_FAILED_VERIFICATION = {
  keyText: 'activate_account_page_disclaimer_failed_verification',
  fallbackText: 'The information provided doesn’t match our records. Please confirm your license code or contact your UiPath account representative, the existing Portal admin, or click the below button to create a support ticket.',
};
export const DISCLAIMER_CASE_ALREADY_CREATED = {
  keyText: 'activate_account_page_disclaimer_case_already_created',
  fallbackText: 'The information provided does not match our records. Please contact your UiPath account representative, or the existing Portal admin. Please share with them the support case number, and a screenshot of this page.',
};
export const DESCRIPTION_GENERAL_TERMS_OF_USE_CONSENT = {
  keyText: 'activate_account_page_decription_general_terms_of_use_consent',
  fallbackText: 'I accept the <a href=\'https://www.uipath.com/hubfs/legalspot/Legal%20Terms/UiPath_General_Terms_of_Use.pdf\' target=\'_blank\' rel=\'noreferrer\'>General Terms of Use</a> in my valid authority to execute such terms on behalf of my Company. I agree and acknowledge that UiPath will not be liable for any claims that may be asserted, granted, or imposed against, arising from, or in connection with my authority to register the Customer Portal on behalf of my Company.',
};
export const DESCRIPTION_B2C_TERMS_AND_CONDITIONS_CONSENT = {
  keyText: 'activate_account_page_decription_b2c_terms_and_conditions_consent',
  fallbackText: 'I confirm that I agree to the <a href=\'https://www.uipath.com/legal/trust-and-security/legal-support\' target=\'_blank\' rel=\'noreferrer\'>License Terms</a> and <a href=\'https://www.uipath.com/legal/trust-and-security/legal-support#content-3\' target=\'_blank\' rel=\'noreferrer\'>Acceptable Use</a> policies.',
};
export const LICENSE_CODE_HELP_TEXT = {
  keyText: 'activate_account_page_license_code_help_text',
  fallbackText: 'Automation Cloud Support ID can be <a href=\'https://docs.uipath.com/automation-cloud/docs/managing-organization-settings#finding-your-support-id\' target=\'_blank\' rel=\'noreferrer\'>found in Organization Settings</a> / License code should not contain spaces. The format is 16 digits (xxxx-xxxx-xxxx-6789) or 18 digits (32375675632375632)',
};
export const ACTIVATE_ACCOUNT_BTN_TEXT = {
  keyText: 'activate_account_page_activate_account_btn_text',
  fallbackText: 'Activate Account',
};
export const CREATE_SUPPORT_TICKET_BTN_TEXT = {
  keyText: 'activate_account_page_create_support_ticket_btn_text',
  fallbackText: 'Create Support Ticket',
};
export const EMAIL_DOMAIN_TEXT_FIELD_HELPER_TEXT = {
  keyText: 'activate_account_page_email_domain_text_field_helper_text',
  fallbackText: 'Your logged-in email address.',
};
export const SIGN_IN_WITH_ANOTHER_ACCOUT_LINK_TEXT = {
  keyText: 'activate_account_page_sign_in_with_another_account_link_text',
  fallbackText: 'Sign in with another account?',
};
