import styled from 'styled-components';

export const CustomActionPanel = styled.span`
  display: flex;
  flex-direction: column;
  min-height: 90vh;

  .title {
    font-weight: 600;
    font-size: 18px;
  }

  .button-container {
    margin-top: auto;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .cancelBtn, .submitBtn, .loaderBtn {
    width: 80px;
  }

  .submitBtn:focus {
    box-shadow: none !important;
  }

  .loaderBtn:focus {
    box-shadow: none !important;
  }

  .cancelBtn {
    margin-right: 10px
  }

  .loader {
    color: #ffffff;
  }

  .CaseActions__Case-Field {
    width: 100%;
    margin-bottom: 10px;
  }

  .selectReason {
    padding: 10px;
  }

  .options {
    padding: 0px;
  }

  .businessJustification textarea{
    color: #000000;
    line-height: 20px;
    height: 200px !important;
  }

  .Custom-Select_Reason {
    background: none;
    border-radius: 3px;
    border: 1px solid #a4b1b8;
    .Custom-Select__control {
      background: white;
    }
    .Custom-Select__placeholder {
      color: #000000;
    }
  }

`;
