import {
  CaseRecordType,
  CLOUD_ELEMENTS_PRODUCTS,
} from '@customer-portal/constants';

export const getCaseRecordType = (
  productValue: string,
  isTrial: boolean,
  country: string
) => {
  if (CLOUD_ELEMENTS_PRODUCTS.includes(productValue)) {
    return CaseRecordType.CLOUD_ELEMENTS;
  }

  if (isTrial) {
    return CaseRecordType.SERVICE_REQUEST;
  }

  if (country === 'Japan') {
    return CaseRecordType.JAPAN_INCIDENT;
  }

  return CaseRecordType.INCIDENT;
};
