// Import Download Icons
import {
  CustomFileExtensionToMimeType,
  MAIL_TO_LINK_REGEX,
} from '@customer-portal/constants';

import FileDownloadIconHover from '../assets/img/svg/file_icons/Document_White.svg';
import LinkDownloadIconHover from '../assets/img/svg/file_icons/Export_White.svg';
import FileDownloadIcon from '../assets/img/svg/file_icons/File_Text.svg';
import LinkDownloadIcon from '../assets/img/svg/file_icons/Link_Icon.svg';
import VideoDownloadIcon from '../assets/img/svg/file_icons/Play_Icon.svg';
import VideoDownloadIconHover from '../assets/img/svg/file_icons/Play_Icon_White.svg';

const convertSizeToReadableSize = (fileSizeString?: string) => {
  if (!fileSizeString) {
    return 'Unknown Size';
  }
  const kiloByte = 1024;
  const fileSize = parseInt(fileSizeString);

  if (isNaN(fileSize)) {
    return 'Unknown Size';
  }

  if (fileSize < kiloByte) {
    return `${fileSize.toFixed(0)} B`;
  } else if (fileSize < kiloByte * kiloByte) {
    const fileSizeInKB = (fileSize / kiloByte).toFixed(1);
    return `${fileSizeInKB} KB`;
  } else if (fileSize < kiloByte * kiloByte * kiloByte) {
    const fileSizeInMB = (fileSize / (kiloByte * kiloByte)).toFixed(1);
    return `${fileSizeInMB} MB`;
  } else if (fileSize < kiloByte * kiloByte * kiloByte * kiloByte) {
    const fileSizeInGB = (fileSize / (kiloByte * kiloByte * kiloByte)).toFixed(
      1
    );
    return `${fileSizeInGB} GB`;
  }
  return 'Large file';

};

export const MimeTypeToExtension = new Map<
string,
{ extension: string; internalFileType: string }
>([
  [
    'application/vnd.hzn-3d-crossword',
    {
      extension: '.x3d',
      internalFileType: 'file',
    },
  ],
  [ 'video/3gpp', {
    extension: '.3gp',
    internalFileType: 'video',
  } ],
  [ 'video/3gpp2', {
    extension: '.3g2',
    internalFileType: 'video',
  } ],
  [
    'application/x-7z-compressed',
    {
      extension: '.7z',
      internalFileType: 'file',
    },
  ],
  [ 'application/vnd.rar', {
    extension: '.rar',
    internalFileType: 'file',
  } ],
  [ 'audio/adpcm', {
    extension: '.adp',
    internalFileType: 'video',
  } ],
  [ 'application/pdf', {
    extension: '.pdf',
    internalFileType: 'file',
  } ],
  [ 'image/vnd.dxf', {
    extension: '.dxf',
    internalFileType: 'file',
  } ],
  [ 'image/bmp', {
    extension: '.bmp',
    internalFileType: 'file',
  } ],
  [ 'text/csv', {
    extension: '.csv',
    internalFileType: 'file',
  } ],
  [ 'image/gif', {
    extension: '.gif',
    internalFileType: 'file',
  } ],
  [ 'video/h261', {
    extension: '.h261',
    internalFileType: 'video',
  } ],
  [ 'video/h263', {
    extension: '.h263',
    internalFileType: 'video',
  } ],
  [ 'video/h264', {
    extension: '.h264',
    internalFileType: 'video',
  } ],
  [ 'image/x-icon', {
    extension: '.ico',
    internalFileType: 'file',
  } ],
  [ 'image/jpeg', {
    extension: '.jpg',
    internalFileType: 'file',
  } ],
  [ 'image/x-citrix-jpeg', {
    extension: '.jpg',
    internalFileType: 'file',
  } ],
  [ 'video/x-m4v', {
    extension: '.m4v',
    internalFileType: 'video',
  } ],
  [ 'application/vnd.ms-excel', {
    extension: '.xls',
    internalFileType: 'file',
  } ],
  [
    'application/vnd.ms-excel.addin.macroenabled.12',
    {
      extension: '.xlam',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.ms-excel.sheet.binary.macroenabled.12',
    {
      extension: '.xlsb',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.ms-excel.template.macroenabled.12',
    {
      extension: '.xltm',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.ms-excel.sheet.macroenabled.12',
    {
      extension: '.xlsm',
      internalFileType: 'file',
    },
  ],
  [ 'video/x-flv', {
    extension: '.flv',
    internalFileType: 'video',
  } ],
  [ 'video/x-msvideo', {
    extension: '.avi',
    internalFileType: 'video',
  } ],
  [
    'application/vnd.apple.mpegurl',
    {
      extension: '.m3u8',
      internalFileType: 'video',
    },
  ],
  [
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    {
      extension: '.pptx',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.openxmlformats-officedocument.presentationml.slide',
    {
      extension: '.sldx',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    {
      extension: '.ppsx',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    {
      extension: '.potx',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    {
      extension: '.xlsx',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    {
      extension: '.xltx',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    {
      extension: '.docx',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    {
      extension: '.dotx',
      internalFileType: 'file',
    },
  ],
  [ 'application/onenote', {
    extension: '.onetoc',
    internalFileType: 'file',
  } ],
  [
    'application/vnd.ms-powerpoint',
    {
      extension: '.ppt',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.ms-powerpoint.addin.macroenabled.12',
    {
      extension: '.ppam',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.ms-powerpoint.slide.macroenabled.12',
    {
      extension: '.sldm',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.ms-powerpoint.presentation.macroenabled.12',
    {
      extension: '.pptm',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
    {
      extension: '.ppsm',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.ms-powerpoint.template.macroenabled.12',
    {
      extension: '.potm',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.ms-project',
    {
      extension: '.mpp',
      internalFileType: 'file',
    },
  ],
  [ 'application/vnd.visio', {
    extension: '.vsd',
    internalFileType: 'file',
  } ],
  [
    'application/vnd.visio2013',
    {
      extension: '.vsdx',
      internalFileType: 'file',
    },
  ],
  [ 'video/x-ms-wm', {
    extension: '.wm',
    internalFileType: 'video',
  } ],
  [ 'audio/x-ms-wma', {
    extension: '.wma',
    internalFileType: 'video',
  } ],
  [ 'video/x-ms-wmv', {
    extension: '.wmv',
    internalFileType: 'video',
  } ],
  [ 'application/msword', {
    extension: '.doc',
    internalFileType: 'file',
  } ],
  [
    'application/vnd.ms-word.document.macroenabled.12',
    {
      extension: '.docm',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.ms-word.template.macroenabled.12',
    {
      extension: '.dotm',
      internalFileType: 'file',
    },
  ],
  [ 'application/x-mswrite', {
    extension: '.wri',
    internalFileType: 'file',
  } ],
  [ 'audio/mpeg', {
    extension: '.mpga',
    internalFileType: 'video',
  } ],
  [ 'video/mpeg', {
    extension: '.mpeg',
    internalFileType: 'video',
  } ],
  [ 'audio/mp4', {
    extension: '.mp4a',
    internalFileType: 'video',
  } ],
  [ 'video/mp4', {
    extension: '.mp4',
    internalFileType: 'video',
  } ],
  [ 'audio/ogg', {
    extension: '.oga',
    internalFileType: 'video',
  } ],
  [ 'video/ogg', {
    extension: '.ogv',
    internalFileType: 'video',
  } ],
  [ 'video/webm', {
    extension: '.webm',
    internalFileType: 'video',
  } ],
  [ 'audio/webm', {
    extension: '.webm',
    internalFileType: 'video',
  } ],
  [ 'image/png', {
    extension: '.png',
    internalFileType: 'file',
  } ],
  [ 'image/x-citrix-png', {
    extension: '.png',
    internalFileType: 'file',
  } ],
  [ 'image/x-png', {
    extension: '.png',
    internalFileType: 'file',
  } ],
  [ 'image/x-portable-pixmap', {
    extension: '.ppm',
    internalFileType: 'file',
  } ],
  [ 'video/quicktime', {
    extension: '.mov',
    internalFileType: 'video',
  } ],
  [
    'application/x-rar-compressed',
    {
      extension: '.rar',
      internalFileType: 'file',
    },
  ],
  [ 'application/rtf', {
    extension: '.rtf',
    internalFileType: 'file',
  } ],
  [ 'text/richtext', {
    extension: '.rtx',
    internalFileType: 'file',
  } ],
  [ 'image/svg+xml', {
    extension: '.svg',
    internalFileType: 'file',
  } ],
  [ 'text/plain', {
    extension: '.txt',
    internalFileType: 'file',
  } ],
  [ 'audio/x-wav', {
    extension: '.wav',
    internalFileType: 'video',
  } ],
  [ 'application/wsdl+xml', {
    extension: '.wsdl',
    internalFileType: 'file',
  } ],
  [ 'application/xhtml+xml', {
    extension: '.xhtml',
    internalFileType: 'file',
  } ],
  [ 'application/xml', {
    extension: '.xml',
    internalFileType: 'file',
  } ],
  [ 'text/xml', {
    extension: '.xml',
    internalFileType: 'file',
  } ],
  [ 'application/xslt+xml', {
    extension: '.xslt',
    internalFileType: 'file',
  } ],
  [ 'text/yaml', {
    extension: '.yaml',
    internalFileType: 'file',
  } ],
  [ 'application/zip', {
    extension: '.zip',
    internalFileType: 'file',
  } ],
  [
    'application/vnd.oasis.opendocument.presentation',
    {
      extension: '.odp',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.oasis.opendocument.spreadsheet',
    {
      extension: '.ods',
      internalFileType: 'file',
    },
  ],
  [
    'application/vnd.oasis.opendocument.text',
    {
      extension: '.odt',
      internalFileType: 'file',
    },
  ],
  [
    'application/x-compress',
    {
      extension: '.zip',
      internalFileType: 'file',
    },
  ],
  [
    'application/x-zip-compressed',
    {
      extension: '.zip',
      internalFileType: 'file',
    },
  ],
  [
    'multipart/x-zip',
    {
      extension: '.zip',
      internalFileType: 'file',
    },
  ],
  [
    'audio/mp3',
    {
      extension: '.mp3',
      internalFileType: 'video',
    },
  ],
  [
    'application/x-tar',
    {
      extension: '.tar',
      internalFileType: 'file',
    },
  ],
  [
    'application/x-bzip2',
    {
      extension: '.bz2',
      internalFileType: 'file',
    },
  ],
  ...Object.entries(CustomFileExtensionToMimeType).map(([ extension, mimeType ]) => [
    mimeType,
    {
      extension,
      internalFileType: 'file',
    },
  ] as [string, { extension: string; internalFileType: string }]),
]);

export const getFileExtension = (filename: string) => {
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex !== -1 && lastDotIndex !== 0) {
    return filename.slice(lastDotIndex).toLowerCase();
  }
  return '';

};

export const isFileAttachmentSupported = (file: File, SupportedFileExtensions: string[]) => {
  const fileExtension = getFileExtension(file.name);

  // For uncommon files with missing mime types, use custom mapping
  if (!file.type && CustomFileExtensionToMimeType[fileExtension]) {
    const newFile = new File([ file ], file.name, { type: CustomFileExtensionToMimeType[fileExtension] });
    file = newFile;
  }

  return file.type.length &&
  MimeTypeToExtension.get((file.type as string).toLowerCase()) &&
  SupportedFileExtensions.includes(fileExtension);
};

export const calcFileToReadable = (
  size: string | undefined,
  mimeType?: string,
  hyperlink?: string,
  videoDuration?: number
) => {
  let readableFileSize;
  let readableFileExtension = 'Download';
  let downloadIcon = FileDownloadIcon;
  let downloadIconHover = FileDownloadIconHover;
  let internalFileType = 'file';

  if (hyperlink) {
    return {
      readableFileExtension: MAIL_TO_LINK_REGEX.test(hyperlink) ? 'Open in mail client' : 'Visit Webpage',
      readableFileSize: 'EXTERNAL',
      downloadIcon: LinkDownloadIcon,
      downloadIconHover: LinkDownloadIconHover,
      internalFileType: 'link',
    };
  }

  const mimeTypeData = mimeType ? MimeTypeToExtension.get(mimeType) : undefined;

  if (mimeTypeData) {
    switch (mimeTypeData.internalFileType) {
      case 'video': {
        let videoMin = '0';
        let videoSec = '0';
        if (videoDuration && videoDuration < 60) {
          videoMin = '0';
          videoSec = videoDuration.toString();
        } else if (videoDuration) {
          videoMin = Math.floor(videoDuration / 60).toString();
          videoSec =
            videoDuration % 60 < 10
              ? `0${videoDuration % 60}`
              : (videoDuration % 60).toString();
        }
        downloadIcon = VideoDownloadIcon;
        downloadIconHover = VideoDownloadIconHover;
        readableFileExtension = 'Download Video';
        readableFileSize = `${videoMin}:${videoSec} MIN`;
        internalFileType = mimeTypeData.internalFileType;
        break;
      }
      case 'file':
        downloadIcon = FileDownloadIcon;
        downloadIconHover = FileDownloadIconHover;
        readableFileExtension = `${mimeTypeData.extension
          .toUpperCase()
          .replace(/\./g, '')} Download`;
        readableFileSize = convertSizeToReadableSize(size);
        internalFileType = mimeTypeData.internalFileType;
        break;
      default:
        internalFileType = mimeTypeData.internalFileType;
    }
  }

  return {
    readableFileExtension,
    readableFileSize,
    downloadIcon,
    internalFileType,
    downloadIconHover,
  };
};
