import styled from 'styled-components';

interface Props {
  showBanner: boolean;
}

export const CloudPromptBannerContainer = styled.div<Props>`
  display: ${p => (p.showBanner ? 'block' : 'none')};
  background: ${p => p.theme.palette.yellow[200]};
  padding: 10px 16px;
  font-size: 1.4rem;
  font-weight: 600;
  
  button {
    background: white;
    border: 0px;
    border-radius: 4px;
    margin: 4px 0px 4px 12px;
    cursor: pointer;
    font-weight: 500 !important;
    padding: 4px 6px;
  }

  button:hover {
    background: ${p => p.theme.palette.grey[50]};
    color: ${p => p.theme.palette.blue[500]};
  }
`;
