import styled from 'styled-components';

export const Container = styled.section`
    position: absolute;
    left: 50%;
    bottom: ${p => p.theme.spacing(5)}px;
    transform: translateX(-50%);

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${p => p.theme.breakpoints.down('xs')} {
      position: relative;
      flex-direction: column;
      margin-top: ${p => p.theme.spacing(10)}px; 
    }

    .VerifyEmailPage__BottomOptions__languageSelector {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .SelectLanguage__icon {
        width: ${p => p.theme.spacing(2.5)}px;
        fill: ${p => p.theme.palette.ink[550]};
      }

      .VerifyEmailPage__BottomOptions__languageSelectorBtn {
        span {
          font-size: 1.4rem;
          font-weight: normal;
          line-height: 20px;
        }

        img {
          width: ${p => p.theme.spacing(5)}px;
        }

        &[aria-expanded="true"]{
          img {
            transform: rotate(-180deg);
          }
        }
      }

      .VerifyEmailPage__BottomOptions__languagesDropdown {
        min-width: ${p => p.theme.spacing(20)}px;
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
        
        .VerifyEmailPage__BottomOptions__languageItem {
          font-size: 1.4rem;
          line-height: 20px;

          &--active {
            box-shadow: inset 4px 0px 0px ${p => p.theme.palette.orange[600]};
            background-color: ${p => p.theme.palette.orange[100]};
          }
        }
      }
    }

    .VerifyEmailPage__BottomOptions__termsAndConditions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        padding: 0 ${p => p.theme.spacing(2)}px;
        
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 20px;
      }

      ${p => p.theme.breakpoints.down('xs')} {
        margin: ${p => p.theme.spacing(2.5)}px 0;
        justify-content: right; 
      }
    }
`;
