import type {
  RoleWithMetadata,
  UserRole,
} from '@customer-portal/constants';
import { AdminUserChangeType } from '@customer-portal/constants';

import { ChangeSnapshotState } from '../constants/adminUserHistory';

export const getStatusForChangeType = (
  {
    changeType,
    oldValue,
    newValue,
  }: {
    changeType: AdminUserChangeType;
    oldValue?: {
      roles?: Array<RoleWithMetadata & { companyIds?: string[] }>;
      isActive?: boolean;
    };
    newValue: {
      roles?: Array<RoleWithMetadata & { companyIds?: string[] }>;
      isActive?: boolean;
    };
  },
  snapShotState: ChangeSnapshotState,
): string => {
  switch (changeType) {
    case AdminUserChangeType.ADD_NEW: {
      if (snapShotState === ChangeSnapshotState.OLD) {
        return '';
      }
      return newValue.isActive === true ? 'Active' : 'Inactive';
    }
    case AdminUserChangeType.EDIT_ROLES:
    case AdminUserChangeType.EDIT_STATUS: {
      const isActive = snapShotState === ChangeSnapshotState.OLD
        ? oldValue?.isActive
        : newValue.isActive;
      return isActive === true ? 'Active' : 'Inactive';
    }
    case AdminUserChangeType.UNDEFINED:
    default:
      return '';
  }
};

export const getRolesForChangeType = (
  {
    changeType,
    oldValue,
    newValue,
  }: {
    changeType: AdminUserChangeType;
    oldValue?: {
      roles?: Array<RoleWithMetadata & { companyIds?: string[] }>;
      isActive?: boolean;
    };
    newValue: {
      roles?: Array<RoleWithMetadata & { companyIds?: string[] }>;
      isActive?: boolean;
    };
  },
  snapShotState: ChangeSnapshotState,
): UserRole[] => {
  switch (changeType) {
    case AdminUserChangeType.ADD_NEW: {
      const roles = snapShotState === ChangeSnapshotState.OLD
        ? []
        : newValue.roles ?? [];
      return roles.map((role) => role.name);
    }
    case AdminUserChangeType.EDIT_ROLES:
    case AdminUserChangeType.EDIT_STATUS: {
      const roles = snapShotState === ChangeSnapshotState.OLD
        ? oldValue?.roles ?? []
        : newValue.roles ?? [];
      return roles.map((role) => role.name);
    }
    case AdminUserChangeType.UNDEFINED:
    default:
      return [];
  }
};
