import styled from 'styled-components';

export const ProductsMainWrapper = styled.div`
  background-color: ${p => p.theme.palette.grey[100]};
  padding: ${p => p.theme.spacing(2)}px;
`;

export const BundleCardContainer = styled.div`
  max-width: ${p => p.theme.spacing(50)}px;
  font-size: 1.4rem;
`;

export const BundleCard = styled.div`
  margin-bottom: ${p => p.theme.spacing(2)}px;

  &:last-child {
    margin-bottom: ${p => p.theme.spacing(1)}px;
  }
`;

export const BundleParentContainer = styled.div`
  display: flex;
  align-content: space-between;
  margin-bottom: ${p => p.theme.spacing(0.5)}px;
`;

export const BundleParent = styled.div`
  flex: 1;
  margin-bottom: ${p => p.theme.spacing(1)}px;
`;

export const ProductQty = styled.div`
  flex: 0 0 100px;
  max-width: 100px;
  text-align: right;
`;

export const ProductContainer = styled.div`
  margin-left: ${p => p.theme.spacing(1)}px;
  display: flex;
  flex-direction: column;
  border-left: 2px solid ${p => p.theme.palette.orange[600]};
  padding-left: ${p => p.theme.spacing(1)}px;
`;
