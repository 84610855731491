import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  CustomHelpIcon,
  HelpTextDiv,
} from '../../assets/css/HelpIcon';
import { ReactComponent as HelpIcon } from '../../assets/img/svg/help_outline.svg';

type HelpTextIconProps = {
  text?: string;
  label?: string;
  className?: string;
};

const HelpTextIcon = (props: HelpTextIconProps) => {
  const [ isHovered, setIsHovered ] = useState(false);
  const [ isOpen, setIsOpen ] = useState(false);
  const helpTextRef = useRef<HTMLElement | null>(null);
  const previousActiveElementRef = useRef<HTMLElement | null>(null);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      if (event.shiftKey) {
        if (isOpen) {
          setIsOpen(false);
          helpTextRef.current?.blur();
          previousActiveElementRef.current?.focus();
        } else {
          setIsOpen(true);
          previousActiveElementRef.current = document.activeElement as HTMLElement;
        }
      } else {
        setIsOpen(!isOpen);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      helpTextRef.current?.focus();
    }
  }, [ isOpen ]);

  return (
    <div>
      <CustomHelpIcon className={props.className ? props.className : undefined}>
        <HelpIcon
          className={`help-icon helpIcon-${props.label}`}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        />
      </CustomHelpIcon>
      {
        (isHovered || isOpen) && props.text && (
          <HelpTextDiv
            tabIndex={isOpen ? 0 : -1}
            onKeyDown={handleKeyDown}
            ref={helpTextRef}
          >
            <div
              className={`text text-${props.label}`}
              dangerouslySetInnerHTML={{ __html: props.text }}
            />
          </HelpTextDiv>
        )
      }
    </div>
  );
};

export default HelpTextIcon;
