import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 36px;
  gap: 64px;

  .MuiInputLabel-root {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    position: unset;
    transform: none;
  }

  .MuiFormHelperText-root {
    margin: 0;
    font-size: 10px;
    text-align left;
    font-weight: 400;
    line-height: 16px;
  }

  .Contract-Radio-Buttons {
    margin-left: ${p => p.theme.spacing(0.5)}px;
  }
`;

export const SkuForm = styled.div`
  margin-top: ${p => p.theme.spacing(5)}px;

  .SkuForm__SkuWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: ${p => p.theme.spacing(2)}px;

    .SkuForm__SelectWrapper {
      flex-grow: 1;
    }

    .SkuForm__RemoveSku {
      margin-left: ${p => p.theme.spacing(3)}px;
      max-width: 50px;
    }
  }

  .SkuForm__Add {
    display: flex;
    margin-top: ${p => p.theme.spacing(2)}px;
    cursor: pointer;
  }

  .SkuForm__Plus {
    margin-right: ${p => p.theme.spacing(1)}px;
  }

  .Add-User-Opener {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background: ${p => p.theme.palette.blue[500]};
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    padding-left: ${p => p.theme.spacing(3)}px;
    border-radius: ${p => p.theme.spacing(1)}px;
    margin-bottom: ${p => p.theme.spacing(2)}px;
    h6 {
      color: white;
    }
    .Add-User-Icon {
      width: 24px;
      height: 24px;
      margin-right: ${p => p.theme.spacing(3)}px;
    }
  }

  .Date-Pickers-View {
    display: flex;
    flex-direction: column;
    width: 25%;
  }

  .Date-Picker {
    margin-top: 0;
    margin-bottom: ${p => p.theme.spacing(2)}px;
    .material-icons-outlined {
      color: ${p => p.theme.palette.ink[450]};
      font-size: ${p => p.theme.spacing(3.25)}px;
    }
    input {
      border: none;
      margin-right: -${p => p.theme.spacing(1.25)}px;
    }
    .MuiOutlinedInput-root {
      padding-right: ${p => p.theme.spacing(1.25)}px;
      border: 1px solid ${p => p.theme.palette.grey[400]};
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0);
      }
    }
  }

  .Hapo-Submit {
    overflow-y: auto;
    justify-content: initial;
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    top: 0;
    bottom: 0;
    background-color: ${p => p.theme.palette.grey[100]};
    padding: ${p => p.theme.spacing(2)}px 0px ${p => p.theme.spacing(2)}px 0px;
    flex-direction: row;

    .MuiButtonBase-root {
      margin-right: ${p => p.theme.spacing(2)}px;
    }

    p {
      margin-left: ${p => p.theme.spacing(1)}px;
      align-self: center;
      color: ${p => p.theme.palette.grey[500]};
    }

    button {
      padding: 8px 16px !important;
    }
  }
`;

export const TableHeader = styled.div`
  margin-bottom: ${p => p.theme.spacing(2)}px;
  min-height: 0;
  display: flex;
  flex-direction: row;

  p {
    font-size: 1.4rem;
    line-height: 1.35;
  }

  > :first-child {
    display: flex;
    flex-basis: 45%;
    max-width: 40%;
    padding-left: ${p => p.theme.spacing(6)}px;

  }
  > :nth-child(2),
  > :nth-child(3) {
    flex-basis: 15%;
    margin-right: ${p => p.theme.spacing(6)}px;
    flex-shrink: 0;
    text-align: center;
  }
  > :nth-child(4) {
    margin-right: ${p => p.theme.spacing(5)}px;
    flex: 1;
    text-align: right;
  }
  > :nth-child(5) {
    flex-basis: ${p => p.theme.spacing(3)}px;
    max-width: ${p => p.theme.spacing(3)}px;
    height: ${p => p.theme.spacing(3)}px;
  }

`;

export default styled;
