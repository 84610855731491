export const getActivateAdminUserModalInterval = () => Date.now() + 1000 * 60 * 60 * 72; // Show activate admin user modal after 72H

export const ACTIVATE_ADMIN_USER_MODAL_TITLE = {
  keyText: 'portal_admin_user_activation_modal_heading',
  fallbackText: 'Customer Portal Admin User Activation',
};

export const ACTIVATE_ADMIN_USER_MODAL_DESCRIPTION = {
  keyText: 'portal_admin_user_activation_modal_description',
  fallbackText: 'This portal has been provided to you for <strong>{{accountName}}</strong> with limited access. To unlock all features and functionality of the Customer Portal, including access to Licensing, Collaboration, and site administration please read and acknowledge the below disclaimer.',
};

export const ACTIVATE_ADMIN_USER_MODAL_DESCRIPTION_ACKNOWLEDGE = {
  keyText: 'portal_admin_user_activation_modal_description_acknowledge',
  fallbackText: 'You acknowledge you bear the entire responsibility as concerns the valid authorization issued by the authorized representative of your Company for your appointment as Admin User in the Customer Portal. Upon request, you agree to provide the proof of the valid appointment to UiPath.',
};

export const ACTIVATE_ADMIN_USER_MODAL_DESCRIPTION_CONSENT = {
  keyText: 'portal_admin_user_activation_modal_description_consent',
  fallbackText: 'I have read and understood the role and responsibilities of the Admin User of the Customer Portal. I hereby represent and warrant that I am validly authorized to act as Admin User on behalf of my Company and to perform all of its obligations as set forth the agreed applicable terms for the use of the Customer Portal.',
};

export const ACTIVATE_ADMIN_USER_MODAL_ADD_EMAIL = {
  keyText: 'portal_admin_user_activation_modal_description_add_email',
  fallbackText: 'Specify another user that is authorized to agree with the above terms:',
};

export const ACTIVATE_ADMIN_USER_MODAL_SUBMIT_BUTTON = {
  keyText: 'portal_admin_user_activation_modal_submit_button',
  fallbackText: 'Submit',
};

export const ACTIVATE_ADMIN_USER_MODAL_ASK_ME_LATER_BUTTON = {
  keyText: 'portal_admin_user_activation_modal_ask_me_later_button',
  fallbackText: 'Ask me later',
};

export const ACTIVATE_ADMIN_USER_MODAL_FORM_SUBMITTED_SUBTITLE = {
  keyText: 'portal_admin_user_activation_modal_form_submitted_subtitle',
  fallbackText: 'Activating your admin account...',
};

export const ACTIVATE_ADMIN_USER_MODAL_FORM_SUBMITTED_SUBTITLE_WITH_EMAIL = {
  keyText: 'portal_admin_user_activation_modal_form_submitted_subtitle_with_email',
  fallbackText: 'Sending user registration to: {{email}}!',
};
