import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';

import { SubCategoryText } from '../../../assets/css/Knowledge/Document';
// Styles
import * as styles from '../../../assets/css/Support/SupportKB/SupportKBDocument';
import { getCloudPrefixedPath } from '../../../utils/cloud';

type KBDocumentProps = {
  productComponentTitle?: string;
  documentTitleText: string;
  articleId: string;
  articleViews?: string;
  lastUpdated?: string;
  isProductResults?: boolean;
  articleNumber: string;
};

/**
 *
 * Helper component reused in more than one screen
 */

const SupportKBDocument = ({
  productComponentTitle,
  documentTitleText,
  articleViews,
  lastUpdated,
  isProductResults,
  articleNumber,
}: KBDocumentProps) => {
  // Translate method
  const { t } = useTranslation('common');

  // Event handlers

  const formattedDate = () => {
    const newDate = new Date(`${lastUpdated}`);
    return (
      newDate.getMonth() +
      1 +
      '/' +
      newDate.getDate() +
      '/' +
      newDate.getFullYear()
    );
  };

  const renderInnerContent = () => {
    const articleUrl = getCloudPrefixedPath(`/kb/${articleNumber}`);
    if (isProductResults) {
      return (
        <styles.ProductDocumentInfo>
          <div className="ProductDocument__Meta-Info">
            <SubCategoryText data-testid="CategoryTitle">
              {productComponentTitle}
            </SubCategoryText>
            <SubCategoryText data-testid="ArticleStats">
              {t(
                'support_kb_document_component_is_product_article_stats',
                `${articleViews} views · Last Updated: ${formattedDate()}`,
                {
                  articleViews,
                  lastUpdated: formattedDate(),
                }
              )}
            </SubCategoryText>
          </div>
          <styles.KBTitle
            className="SupportKBDocument__KnowledgeBaseTitle"
            data-testid="ArticleTitle"
            href={articleUrl}
          >
            {documentTitleText}
          </styles.KBTitle>
        </styles.ProductDocumentInfo>
      );
    }
    return (
      <styles.DocumentInfo>
        <SubCategoryText data-testid="CategoryTitle">
          {t(
            'support_kb_document_component_article_stats',
            `${productComponentTitle} · ${articleViews} views`,
            {
              articleViews,
              productComponentTitle,
            }
          )}
        </SubCategoryText>
        <styles.KBTitle
          className="SupportKBDocument__KnowledgeBaseTitle"
          data-testid="ArticleTitle"
          href={articleUrl}
        >
          {documentTitleText}
        </styles.KBTitle>
      </styles.DocumentInfo>
    );

  };

  return (
    <styles.DocumentAdminWrapper
      className="SupportKBDocument"
      data-testid="SupportKBDocument"
    >
      <styles.DocInfoWrapper data-testid="DocInfoWrapper">
        {renderInnerContent()}
      </styles.DocInfoWrapper>
    </styles.DocumentAdminWrapper>
  );
};

export default SupportKBDocument;
