import styled from 'styled-components';

export const generateTypographyStyles = (theme: any) => `
    font-family: 'Inter', sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: ${theme.spacing(1)}px;
    line-height: ${theme.spacing(4)}px;
  `;

// Micro
export const MicroAlt = styled.p`
  text-transform: uppercase;

  ${(p) => generateTypographyStyles(p.theme)}
`;

// Captions
export const Caption = styled.p`
  && {
    ${(p) => {
    const generateStyles = () => {
      const styles = `
        font-size: 1.6rem;
        font-weight: 700;
        letter-spacing: ${p.theme.spacing(2)}px;
        line-height: ${p.theme.spacing(4)}px;
      `;
      return `
        ${styles}
        p {
          ${styles}
        }
      `;
    };
    return generateStyles();
  }}
  }
`;

export const CaptionAlt = styled.p`
  text-transform: uppercase;

  ${(p) => generateTypographyStyles(p.theme)}
`;

// Labels
export const Label = styled.p`
  && {
    ${(p) => generateTypographyStyles(p.theme)}
  }
  color: white;
`;

export const LabelAlt = styled.p`
  color: white;
  text-transform: uppercase;
  ${(p) => generateTypographyStyles(p.theme)}
`;

// Lead
export const Lead = styled.p`
  color: white;
  ${(p) => generateTypographyStyles(p.theme)}
`;

export const LeadXS = styled.p`
  color: white;

  ${(p) => `
      ${generateTypographyStyles(p.theme)}
      p,
      ul,
      li  {
        color: white;
        ${generateTypographyStyles(p.theme)}
      }
    `}
`;

// Body
export const Body = styled.p`
  color: white;

  ${(p) => `
      ${generateTypographyStyles(p.theme)}
      p,
      ul,
      li  {
        color: white;
        ${generateTypographyStyles(p.theme)}
      }
    `}
`;

export const BodyXS = styled.p`
  margin: 0;
  color: white;

  ${(p) => `
      ${generateTypographyStyles(p.theme)}
      p,
      ul,
      li {
        color: white;
        ${generateTypographyStyles(p.theme)}
      }
    `}
`;

export const BodyXSWrapper = styled.div`
  margin: 0;

  color: white;

  ${(p) => `
    ${generateTypographyStyles(p.theme)}
    p, li {
      margin: inherit;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      font-family: inherit;
    }
    `}
`;

// Headers
export const Header1 = styled.h1`
  color: white;

  ${(p) => `
      ${generateTypographyStyles(p.theme)}
    `}
`;
export const Header2 = styled.h2`
  margin: 0;

  color: white;

  ${(p) => `
      ${generateTypographyStyles(p.theme)}
    `}
`;
export const Header3 = styled.h3`
  margin: 0;

  color: white;

  ${(p) => `
      ${generateTypographyStyles(p.theme)}
    `}
`;
export const Header4 = styled.h4`
  margin: 0;

  color: white;

  ${(p) => `
      ${generateTypographyStyles(p.theme)}
    `}
`;
export const Header5 = styled.h5`
  margin: 0;

  color: white;

  ${(p) => `
      ${generateTypographyStyles(p.theme)}
    `}
`;
export const Header6 = styled.h6`
  margin: 0;

  color: white;

  ${(p) => `
      ${generateTypographyStyles(p.theme)}
    `}
`;

// Display
export const Display = styled.p`
  ${(p) => `
      ${generateTypographyStyles(p.theme)}
    `}
`;

export const DisplayXL = styled.p`
  ${(p) => `
      ${generateTypographyStyles(p.theme)}
    `}
`;

// Fine Print
// @ts-ignore
export const FinePrint = styled(BodyXS)`
  color: rgba(0, 0, 0, 0.3);

  p,
  .ContentBlock__Fineprint,
  .ContentBlock__Fineprint > p {
    margin: 0;
    ${(p) => `
      ${generateTypographyStyles(p.theme)}
    `}

    color: rgba(0, 0, 0, 0.3);
  }
`;
