class SessionHelper {
  // User
  getUserInitials = (name: string) => {
    const parts = name.split(' ');
    let initials = '';

    for (let i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0];
      }
    }

    return initials;
  };

}

export default new SessionHelper();
