/**
 * Use this sparingly, and only defer to this when you
 * need the actual string value of the environment
 */
export const getNodeEnv = (
  originName: string = window.location.origin
) => {
  switch (originName) {
    // Development
    case 'https://customerportal-dev.uipath.com':
    case 'https://alpha.uipath.com':
      return 'development';

    // Staging
    case 'https://customerportal-staging.uipath.com':
    case 'https://staging.uipath.com':
      return 'staging';

    // Production
    case 'https://customerportal.uipath.com':
    case 'https://cloud.uipath.com':
      return 'production';

    // Localhost
    default:
      return 'localhost';
  }
};

export const isLocalhost = () => getNodeEnv() === 'localhost';
export const isDevelopment = () => getNodeEnv() === 'development';
export const isStaging = () => getNodeEnv() === 'staging';
export const isProduction = () => getNodeEnv() === 'production';
