import { Header } from '@customer-portal/constants';
import {
  FormControl,
  TextareaAutosize,
  Tooltip,
} from '@mui/material';
// styles
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { Parser } from 'json2csv';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import {
  Redirect,
  useHistory,
} from 'react-router-dom';

import * as styled from '../../../assets/css/CustomerPortalSupportDetails';
import * as requestHistoryStyles from '../../../assets/css/HAPO/RequestHistory/RequestHistoryList';
import heroBg from '../../../assets/img/Hero-PlainGrid.png';
import Button from '../../../components/Button/Button';
import ExportCSVButton from '../../../components/CustomerPortal-Export-CSV-Button';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../../components/CustomerPortal-GoogleAnalytics';
// components
import CustomerPortalLoader from '../../../components/CustomerPortal-Loader';
import Container from '../../../components/CustomerPortal-New-Container';
import Pagination from '../../../components/CustomerPortal-Pagination';
import ScrollToTop from '../../../components/CustomerPortal-ScrollToTop';
import HapoOrderBundles from '../../../components/HAPO/RequestHistory/OrderBundles';
import HapoOrderStatus from '../../../components/HAPO/RequestHistory/OrderStatus';
import Modal from '../../../components/Modal';
import {
  CANCEL_ORDER_TEXT,
  CANCELED,
  CSV_CHANGE_HISTORY_COLUMNS,
  DISPLAY_STATUSES,
  GROUP,
  PENDING,
  PROVISIONED,
} from '../../../constants/hapo.constants';
// Constants
import {
  HAPO_GET_ORDERS_URL,
  HAPO_UPDATE_ORDER_STATUS_URL,
} from '../../../constants/network.constants';
import { ENTERPRISE_AGREEMENT_PAST_REQUESTS } from '../../../constants/telemetry.constants';
import {
  getAuthType,
  useAuth,
} from '../../../contexts/auth';
// contexts
import { useTrackPageViewEvent } from '../../../lib/AppInsights/AppInsights';
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
// store
import { StoreContext } from '../../../store';
// Utils
import { getAvailableQuatity } from './../../../lib/hapo.utils';

const HAPORequestHistory = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');

  const isViewHAPOLicenseAllowed = UserPermissionsHelper.isViewHAPOLicenseAllowed();
  const isCancelHAPOOrderAllowed = UserPermissionsHelper.isCancelHAPOOrderAllowed();
  const isCompleteHAPOOrderAllowed = UserPermissionsHelper.isCompleteHAPOOrderAllowed();
  const isViewHAPOOrderChangesInCSVAllowed = UserPermissionsHelper.isViewHAPOOrderChangesInCSVAllowed();
  const tooltipText: string = t(
    'past_requests_hapo_view_notes_tooltip',
    'View Notes'
  );

  /* Default constants */
  const history = useHistory();
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const [ note, setNote ] = useState('');
  const [ noResults, setNoResults ] = useState(false);
  const [ totalResults, setTotalResults ] = useState(0);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ back, setBack ] = useState(false);
  const [ hapoOrders, setHapoOrders ] = useState<any[]>([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ showCancelModal, setShowCancelModal ] = useState(false);
  const [ editingOrderInfo, setEditingOrderInfo ] = useState({
    orderId: '',
    orderStatus: '',
  });
  const [ orderStatusChanged, setOrderStatusChanged ] = useState(false);
  const [
    orderstatusAnchorElem,
    setOrderStatusAnchorElem,
  ] = useState<HTMLElement | null>(null);
  const isEditHAPOOrderAllowed =
    isCompleteHAPOOrderAllowed &&
    (editingOrderInfo.orderStatus === PENDING ||
      editingOrderInfo.orderStatus === PROVISIONED);
  // Pagination settings
  const limit = 25;
  const start = (pageNumber - 1) * limit;
  const end = pageNumber * limit;

  /* Lifecycle */

  useTrackPageViewEvent(ENTERPRISE_AGREEMENT_PAST_REQUESTS);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Enterprise Agreement Past Requests');
  }, []);

  useEffect(() => {
    getHapoOrdersData();
    return () => {
      setOrderStatusChanged(false);
    };
  }, [ orderStatusChanged ]);

  useEffect(() => {
    if (back) {
      history.goBack();
    }
  }, [ back ]);

  const getHapoOrdersData = async () => {
    try {
      const hapoOrdersResults = await axios.get(`${HAPO_GET_ORDERS_URL}`, {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          [Header.AUTH_TYPE]: getAuthType(),
        },
      });

      if (hapoOrdersResults.data) {
        setNoResults(!hapoOrdersResults.data.length);
        setTotalResults(hapoOrdersResults.data.length);
        setHapoOrders(hapoOrdersResults.data);
      }
    } catch (err) {
      setNoResults(true);
      dispatch({
        type: 'setBannerType',
        payload: `error`,
      });
      dispatch({
        type: 'setBannerMsg',
        payload: state.isUtoEnabled
          ? t(
            'past_requests_uto_error_msg',
            `Error getting UiPath Tailored Offering Past Requests: ${err}`,
            { error: err }
          )
          : t(
            'past_requests_hapo_error_msg',
            `Error getting Enterprise Agreement Past Requests: ${err}`,
            { error: err }
          ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeStatusHapoOrder = async (toStatus: string) => {
    try {
      const updateResult = await axios.patch(
        `${HAPO_UPDATE_ORDER_STATUS_URL}`,
        {
          _id: editingOrderInfo.orderId,
          status: toStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: getAuthType(),
          },
        }
      );

      if (updateResult) {
        setShowCancelModal(false);
        setOrderStatusChanged(true);
        dispatch({
          type: 'setBannerType',
          payload: `success`,
        });
        dispatch({
          type: 'setBannerMsg',
          payload: t(
            'past_requests_hapo_success_status_changed_msg',
            `Request status changed to ${toStatus} successfully!`,
            { toStatus }
          ),
        });
      }
    } catch (err) {
      setShowCancelModal(true);
      setOrderStatusChanged(false);
      dispatch({
        type: 'setBannerType',
        payload: `error`,
      });
      dispatch({
        type: 'setBannerMsg',
        payload: state.isUtoEnabled
          ? t(
            'past_requests_uto_error_status_changed_msg',
            `Error changing UiPath Tailored Offering status: ${err}`,
            { error: err }
          )
          : t(
            'past_requests_hapo_error_status_changed_msg',
            `Error changing Enterprise Agreement status: ${err}`,
            { error: err }
          ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowCancelModal = () => {
    setShowCancelModal(!showCancelModal);
  };

  const handlePaginateClick = (page: number) => {
    let currentScrollOffset =
      window.pageYOffset || document.documentElement.scrollTop;
    // dont scroll if already on top
    if (currentScrollOffset === 0) {
      setPageNumber(page);
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      window.onscroll = (e: any) => {
        currentScrollOffset =
          window.pageYOffset || document.documentElement.scrollTop;
        // Scroll reach the target
        if (currentScrollOffset === 0) {
          setPageNumber(page);
          window.onscroll = null; // remove listener
        }
      };
    }
  };

  const handleExportCSV = (hapoOrders: any) => {
    const formatedHapoOrders: any[] = [];

    for (const order of hapoOrders) {
      const hapoConfig: any = order.hapoRef?.summed_config?.config;
      for (const bundle of order.bundles) {
        if (bundle.products?.length > 0) {
          for (const product of bundle.products) {
            formatedHapoOrders.push({
              requestDate: order.created_on,
              startDate: order.start_date ?? order.created_on,
              id: order._id,
              requester: order.created_by_id?.email || '-',
              type: product.consumed_against
                ? product.consumed_against
                : 'undefined',
              groupName:
                product.consumed_against === GROUP
                  ? product.consumed_against_group_name
                    ? product.consumed_against_group_name
                    : 'undefined'
                  : '',
              remaining:
                product.consumed_against === GROUP
                  ? getAvailableQuatity(product.product_code, hapoConfig)
                    .availableQuantityGroup
                  : getAvailableQuatity(product.product_code, hapoConfig)
                    .availableQuantityIndividual,
              name: product.sku_name ? product.sku_name : 'undefined',
              licenseName: bundle.existing_license_name
                ? bundle.existing_license_name
                : bundle.sku_name,
              licenseCode: bundle.license_code,
              quantity: product.quantity ? product.quantity : 'undefined',
              status: order.status,
              note: order.note ?? ' ',
              ...(isViewHAPOOrderChangesInCSVAllowed ? { changeHistory: order.change_history ?? [] } : {}),
            });
          }
        } else {
          formatedHapoOrders.push({
            requestDate: order.created_on,
            startDate: order.start_date ?? order.created_on,
            id: order._id,
            requester: order.created_by_id?.email || '-',
            type: bundle.consumed_against
              ? bundle.consumed_against
              : 'undefined',
            groupName:
              bundle.consumed_against === GROUP
                ? bundle.consumed_against_group_name
                  ? bundle.consumed_against_group_name
                  : 'undefined'
                : '',
            remaining:
              bundle.consumed_against === GROUP
                ? getAvailableQuatity(bundle.product_code, hapoConfig)
                  .availableQuantityGroup
                : getAvailableQuatity(bundle.product_code, hapoConfig)
                  .availableQuantityIndividual,
            name: bundle.sku_name ? bundle.sku_name : 'undefined',
            licenseName: bundle.existing_license_name
              ? bundle.existing_license_name
              : bundle.sku_name,
            licenseCode: bundle.license_code,
            quantity: bundle.quantity ? bundle.quantity : 'undefined',
            status: order.status,
            note: order.note ?? ' ',
            ...(isViewHAPOOrderChangesInCSVAllowed ? { changeHistory: order.change_history ?? [] } : {}),
          });
        }
      }
    }

    if (isViewHAPOOrderChangesInCSVAllowed) {
      // Split change history into columns, only keeping a certain amount of most recent changes
      for (const order of formatedHapoOrders) {
        for (let i = 0; i < CSV_CHANGE_HISTORY_COLUMNS; i++) {
          const currChange = order.changeHistory[order.changeHistory.length - (i + 1)];
          // Replace escaped quotes (\") with just quotes
          order[`recentChange${i}`] = currChange ? JSON.stringify(currChange).replace(/\\"/g, '"') : '';
        }
      }
    }

    const json2csvParser = new Parser({
      transforms: [
        item => ({
          ...item,
          requestDate: moment(item.requestDate).format('MMM DD, YYYY HH:mm'),
          startDate: moment(item.startDate).format('MMM DD, YYYY HH:mm'),
          id: `#${item.id}`,
          requester: item.requester,
          type: item.type,
          groupName: item.groupName ? item.groupName : '-',
          name: item.name,
          licenseName: item.licenseName,
          licenseCode: item.licenseCode,
          quantity: item.quantity,
          remaining: item.remaining,
          status:
            item.status === PENDING
              ? DISPLAY_STATUSES.pending.toLowerCase()
              : item.status,
        }),
      ],
      fields: [
        {
          label: 'Request Date',
          value: 'requestDate',
        },
        {
          label: 'Start Date',
          value: 'startDate',
        },
        {
          label: 'ID',
          value: 'id',
        },
        {
          label: 'License Type',
          value: 'type',
        },
        {
          label: 'Group Name',
          value: 'groupName',
        },
        {
          label: 'Name',
          value: 'name',
        },
        {
          label: 'License Name',
          value: 'licenseName',
        },
        {
          label: 'License Code',
          value: 'licenseCode',
        },
        {
          label: 'Quantity',
          value: 'quantity',
        },
        {
          label: 'Remaining Quantity',
          value: 'remaining',
        },
        {
          label: 'Requester',
          value: 'requester',
        },
        {
          label: 'Status',
          value: 'status',
        },
        {
          label: 'Note',
          value: 'note',
        },
      ].concat(
        isViewHAPOOrderChangesInCSVAllowed ? (Array.from(Array(CSV_CHANGE_HISTORY_COLUMNS).keys()).map(i => ({
          label: `Most Recent Change ${i + 1}`,
          value: `recentChange${i}`,
        }))) : []
      ),
    });

    const csv = json2csvParser.parse(formatedHapoOrders);
    const element = document.createElement('a');
    const file = new Blob([ '\ufeff' + csv ], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = `Enterprise_License_Agreement_Past_Requests.csv`;
    document.body.appendChild(element);
    element.click();
  };

  const handleStatusMenuClick = (
    htmlElem: HTMLElement,
    orderId: string,
    orderStatus: string
  ) => {
    setOrderStatusAnchorElem(htmlElem);
    setEditingOrderInfo({
      orderId,
      orderStatus,
    });
  };

  const handleStatusMenuClose = () => {
    setOrderStatusAnchorElem(null);
  };

  const handleViewNotesClick = (noteLocal: string) => {
    setNote(noteLocal);
  };

  const title = state.isUtoEnabled
    ? t(
      'uto_past_requests_page_title',
      'UiPath Tailored Offering Past Requests | Customer Portal'
    )
    : t(
      'hapo_past_requests_page_title',
      'Enterprise Agreement Past Requests | Customer Portal'
    );
  const heroTitle = state.isUtoEnabled
    ? t(
      'past_requests_uto_headline',
      'Past Requests - UiPath Tailored Offering'
    )
    : t(
      'past_requests_hapo_headline',
      'Past Requests - Enterprise License Agreement'
    );

  const generateActions = () => {
    const addHistoricOrderButton = (
      <ExportCSVButton
        onClick={() => {
          history.push('/hapo/request-history/add');
        }}
      >
        {t('past_request_add_historic_order', 'Add Historic Order')}
      </ExportCSVButton>
    );

    if (noResults) {
      if (isCompleteHAPOOrderAllowed) {
        // If no past requests && is dealhub
        return (
          <styled.actionsWrapper>
            {addHistoricOrderButton}
          </styled.actionsWrapper>
        );
      }

      return undefined;
    }
    return (
      <styled.actionsWrapper>
        {isCompleteHAPOOrderAllowed && addHistoricOrderButton}
        <ExportCSVButton
          onClick={() => handleExportCSV(hapoOrders)}
          data-testid="HapoRequestHistory__exportCSV"
        >
          {t('past_requests_hapo_export_csv_btn', 'Export CSV')}
        </ExportCSVButton>
      </styled.actionsWrapper>
    );
  };

  if (!isViewHAPOLicenseAllowed) {
    return <Redirect to="/unauthorized" />;
  } else if (isLoading) {
    return (
      <styled.notificationsLoader>
        <div data-testid="HapoRequestHistory__Loading">
          <CustomerPortalLoader />
        </div>
      </styled.notificationsLoader>
    );
  }
  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>

      <styled.PageWrapper>
        <styled.HomeSection>
          <Container cssClass="CustomerPortalPage__container">
            <div
              className="heroBackButton"
              onClick={() => {
                setBack(true);
              }}
              data-testid="HapoRequestHistory__goBackBtn"
            >
              {t('past_requests_hapo_go_back_btn', 'Go Back')}
            </div>

            <styled.heroTitleWrapper>
              <styled.heroTitle>
                {heroTitle}
              </styled.heroTitle>
              <Modal
                modalTitle="Standard Modal"
                modalDescription="View Note"
                modalHeading={t(
                  'past_requests_hapo_view_note_modal_title',
                  'View Note'
                )}
                innerClasses="RequestHistory__cancelRequest"
                handleClose={() => setNote('')}
                open={Boolean(note)}
              >
                <requestHistoryStyles.NameModalInner>
                  <FormControl
                    className="AddCustomLicenseNameModal__Ticket-Field"
                  >
                    <TextareaAutosize
                      className="AddCustomLicenseNameModal__Ticket-Field--Textarea"
                      aria-label="Notes textarea"
                      required
                      minRows={5}
                      defaultValue={note}
                      contentEditable="false"
                      disabled
                    />
                  </FormControl>
                </requestHistoryStyles.NameModalInner>
              </Modal>
              <Modal
                modalTitle="Standard Modal"
                modalDescription="Cancel Request"
                modalHeading={t(
                  'past_requests_hapo_cancel_request_modal_title',
                  'Cancel Request'
                )}
                innerClasses="RequestHistory__cancelRequest"
                handleClose={handleShowCancelModal}
                open={showCancelModal}
                testId="HapoRequestHistory__cancelOrderModal"
              >
                <div className="Modal-Fields-Container">
                  <p className="">{CANCEL_ORDER_TEXT}</p>
                  <div className="Modal-Buttons">
                    <Button
                      className="ConfirmButton"
                      text={t(
                        'past_requests_hapo_cancel_request_modal_confirm_btn',
                        'Confirm'
                      )}
                      onClick={e => {
                        handleChangeStatusHapoOrder(CANCELED);
                      }}
                      data-testid="HapoRequestHistory__cancelOrderButton"
                    />
                    <Button
                      className="CancelButton"
                      text={t(
                        'past_requests_hapo_cancel_request_modal_cancel_btn',
                        'Cancel'
                      )}
                      onClick={handleShowCancelModal}
                      data-testid="HapoRequestHistory__closeModal"
                    />
                  </div>
                </div>
              </Modal>
              {generateActions()}
            </styled.heroTitleWrapper>

            <img
              src={heroBg}
              className="fullWidthHero" />

            {!noResults && (
              <requestHistoryStyles.RequestHistoryList data-testid="HapoRequestHistory__ordersList">
                {/* List Header */}
                <div className="RequestHistoryListItem RequestHistoryListItem--header">
                  <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestDate">
                    <span>
                      {t(
                        'past_requests_hapo_column_request_date',
                        'Request Date'
                      )}
                    </span>
                  </div>
                  <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--startDate">
                    <span>
                      {t(
                        'past_requests_hapo_column_start_date',
                        'Start Date'
                      )}
                    </span>
                  </div>
                  <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestId">
                    <span>ID</span>
                  </div>
                  <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestEmail">
                    <span>
                      {t('past_requests_hapo_column_requester', 'Requester')}
                    </span>
                  </div>
                  <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestName">
                    <div className="RequestHistoryListItem__nameQuantityRemaining">
                      <span className="RequestHistoryListItem__tooltip">
                        {t('past_requests_hapo_column_name', 'Name')}
                      </span>
                      <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestQuantityRequested">
                        <span>
                          {t(
                            'past_requests_hapo_column_quantity_requested',
                            'Quantity Requested'
                          )}
                        </span>
                      </div>
                      <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestRemaining">
                        <span>
                          {t(
                            'past_requests_hapo_column_remaining',
                            'Remaining'
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestStatus">
                    <span>
                      {t('past_requests_hapo_column_status', 'Status')}
                    </span>
                  </div>
                </div>
                {/* List Status Change Menu */}
                <requestHistoryStyles.LicenseCardMenu
                  anchorEl={orderstatusAnchorElem}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(orderstatusAnchorElem)}
                  onClose={handleStatusMenuClose}
                  onClick={handleStatusMenuClose}
                  className="Select__Outer"
                  elevation={0}
                  data-testid="StatusChangeMenu"
                >
                  {/* Show Complete option only if coming from Pending */}
                  {isCompleteHAPOOrderAllowed &&
                      editingOrderInfo.orderStatus === PENDING && (
                    <MenuItem
                      onClick={e => {
                        handleChangeStatusHapoOrder(PROVISIONED);
                      }}
                      className="UserCard__Menu-Option Select__Option"
                      data-testid="CompleteOrderMenuItem"
                    >
                      {t(
                        'past_requests_hapo_menu_item_complete_order',
                        'Complete Order'
                      )}
                    </MenuItem>
                  )}
                  {/* Show Edit past request regardless of status */}
                  {isEditHAPOOrderAllowed && (
                    <MenuItem
                      onClick={e => {
                        const orderInfo = hapoOrders.find(
                          order => order._id === editingOrderInfo.orderId
                        );
                        history.push('/hapo/request-history/edit', { orderInfo });
                      }}
                      className="UserCard__Menu-Option Select__Option"
                      data-testid="EditOrderMenuItem"
                    >
                      {t(
                        'past_requests_hapo_menu_item_edit_order',
                        'Edit Order'
                      )}
                    </MenuItem>
                  )}
                  {isCancelHAPOOrderAllowed && (
                    <MenuItem
                      onClick={e => {
                        handleShowCancelModal();
                      }}
                      className="UserCard__Menu-Option Select__Option"
                      data-testid="CancelOrderMenuItem"
                    >
                      {t(
                        'past_requests_hapo_menu_item_cancel_order',
                        'Cancel Order'
                      )}
                    </MenuItem>
                  )}
                </requestHistoryStyles.LicenseCardMenu>
                {/* List Body */}
                {hapoOrders.slice(start, end).map((item: any) => (
                  <div
                    className="RequestHistoryListItem"
                    data-testid="HapoRequestHistory__item"
                    key={item._id}
                  >
                    <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestDate">
                      <span className="RequestHistoryListItemDesc__mobile">
                        {t(
                          'past_requests_hapo_column_request_date',
                          'Request Date'
                        )}
                          :
                        {' '}
                      </span>
                      <span>
                        <Moment format="MMM DD, YYYY HH:mm">
                          {item.created_on}
                        </Moment>
                      </span>
                    </div>
                    <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--startDate">
                      <span className="RequestHistoryListItemDesc__mobile">
                        {t(
                          'past_requests_hapo_column_start_date',
                          'Start Date'
                        )}
                          :
                        {' '}
                      </span>
                      <span>
                        <Moment format="MMM DD, YYYY HH:mm">
                          {item.start_date || item.created_on}
                        </Moment>
                      </span>
                    </div>
                    <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestId">
                      <span className="RequestHistoryListItemDesc__mobile">
                          ID:
                        {' '}
                      </span>
                      <span>
#
                        {item._id}
                      </span>
                    </div>
                    <div className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestEmail">
                      <span className="RequestHistoryListItemDesc__mobile">
                        {t(
                          'past_requests_hapo_column_requester',
                          'Requester'
                        )}
                          :
                        {' '}
                      </span>
                      <span>{item.created_by_id?.email || '-'}</span>
                    </div>
                    <HapoOrderBundles
                      bundles={item.bundles}
                      hapoRef={item.hapoRef}
                    />
                    <HapoOrderStatus
                      status={item.status}
                      orderId={item._id}
                      handleAttributeParentClick={handleStatusMenuClick}
                    />
                    {item.note && (
                      <Tooltip
                        placement="bottom"
                        title={tooltipText}
                      >
                        <div
                          onClick={e => handleViewNotesClick(item.note)}
                          className="RequestHistoryListItem__License-With-Note"
                          data-parent="note"
                          data-testid="view-note"
                        />
                      </Tooltip>
                    )}
                  </div>
                ))}
                {totalResults > limit && (
                  <Pagination
                    activePage={pageNumber}
                    handleClick={handlePaginateClick}
                    numResults={totalResults}
                    numPagesBeforeEllipses={5}
                    numResultsPerPage={limit}
                    className="CustomerPortalPagination--noLeftMargin"
                  />
                )}
              </requestHistoryStyles.RequestHistoryList>
            )}

            {noResults && (
              <styled.noResultsTest data-testid="HapoRequestHistory__noResults">
                {state.isUtoEnabled
                  ? t(
                    'past_requests_uto_no_results',
                    'We could not find Enterprise Agreement Requests'
                  )
                  : t(
                    'past_requests_hapo_no_results',
                    'We could not find Enterprise Agreement Requests'
                  )}
                <br />
                <br />
                <div className="HapoRequestHistory__noResults__buttonContainer">
                  <Button
                    text={t('past_requests_hapo_no_results_back_btn', 'Back')}
                    onClick={() => setBack(true)}
                  />
                </div>
              </styled.noResultsTest>
            )}
          </Container>
        </styled.HomeSection>
      </styled.PageWrapper>
    </>
  ); // End of return

};

export default HAPORequestHistory;
