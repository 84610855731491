import styled from 'styled-components';

export const LabelContainer = styled.div`
  width: 120px;
  height: 16px;
  text-align: center;
  border-radius: 8px;
  &.Label__Active {
    background: ${p => p.theme.palette.green[100]};
    .Label__Text {
      color: ${p => p.theme.palette.green[700]};
    }
  }
  &.Label__Disabled {
    background: ${p => p.theme.palette.grey[200]};
    .Label__Text {
      color: #858f95;
    }
  }
  &.Label__Expired {
    background: ${p => p.theme.palette.error[50]};
    .Label__Text {
      color: ${p => p.theme.palette.error.main};
    }
  }
  &.Label__Pending {
    background: ${p => p.theme.palette.warning[50]};
    .Label__Text {
      color: ${p => p.theme.palette.warning.main};
    }
  }
  .Label__Text {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
  &.Label--Compact {
    width: 64px;
    height: 24px;
    border-radius: 12px;
    .Label__Text {
      line-height: 24px;
    }
  }
`;
