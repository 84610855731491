import type { SigninArgsWithState } from '@uipath/auth-react';
import { AuthGuard } from '@uipath/auth-react';
import type { ReactNode } from 'react';
import React from 'react';

import {
  getCloudOrgNameFromUrl,
  getUrlWithAllParams,
} from '../../utils/cloud';

interface Props {
  children?: ReactNode;
}

const IdentityAuthGuard = ({ children }: Props) => {
  const returnTo = getUrlWithAllParams();
  const cloudOrgName = getCloudOrgNameFromUrl();

  const extraSigninRequestArgs: SigninArgsWithState = {
    ...(cloudOrgName && { organizationName: cloudOrgName }),
    // Note: returnTo must be a non-empty string, or else the @uipath/auth-react package will default to
    // redirecting to the full path of the current page after sign-in. See https://uipath.atlassian.net/browse/CP-2663
    state: { returnTo },
  };

  return (
    <AuthGuard extraSigninRequestArgs={extraSigninRequestArgs}>
      {children}
    </AuthGuard>
  );
};

export default IdentityAuthGuard;
