import React from 'react';
import {
  Link,
  useHistory,
} from 'react-router-dom';

// Styles
import * as styles from '../../assets/css/Knowledge/CategoryHero';
// Assets
import defaultHeroBg from '../../assets/img/Hero-Header.png';
// Components
import Container from '../CustomerPortal-New-Container';

type BasicHeroProps = {
  cssClass?: string;
  heroBg?: string;
  title: string;
  description?: string;
  linkHref: string; // for back button
  linkText: string; // for back button
  subtitle?: string;
  subtitleLink?: string;
  handleSubTitleLinkClick?: (e: any) => void;
  back?: boolean; // for back button
  backAmount?: number; // for back button
};

const CustomerPortalBasicHero = (props: BasicHeroProps) => {
  const history = useHistory();
  const cssClass = props.cssClass || '';
  const title = props.title || 'Hero title...';
  const description = props.description || 'Hero subtitle...';
  const heroBg = props.heroBg || defaultHeroBg;
  const back = props.back || false;
  const backAmount = props.backAmount || 0;
  const linkHref = props.linkHref || '/';
  const linkText = props.linkText || '';
  const subtitle = props.subtitle;
  const subtitleLink = props.subtitleLink;

  return (
    <styles.CategoryHero
      className={cssClass}
      heroBg={heroBg}>
      <Container cssClass="CustomerPortalClientsSelection__heroContent">
        <Link
          className="CustomerPortalHyperlinkHero__Link"
          to={linkHref}
          {
            // Overwrite props if `back` is true
            ...(back && {
              to: '#',
              onClick: (e) => history.go(-backAmount),
            })
          }
        >
          {linkText}
        </Link>
        <styles.Title>{title}</styles.Title>
        {props.description && (
          <styles.CategoryDescription>{description}</styles.CategoryDescription>
        )}
        {subtitle && (
          <styles.SubTitle onClick={props.handleSubTitleLinkClick}>
            {subtitle}
          </styles.SubTitle>
        )}
        {subtitleLink && (
          <styles.SubTitleLink onClick={props.handleSubTitleLinkClick}>
            {subtitleLink}
          </styles.SubTitleLink>
        )}
      </Container>
    </styles.CategoryHero>
  );
};

export default CustomerPortalBasicHero;
