import type { Browser } from 'detect-browser';
import { detect } from 'detect-browser';

import { UNSUPPORTED_BROWSERS } from '../constants/browser.constants';

const getBrowserName = () => {
  const browser = detect();

  if (!browser) {
    return null;
  }

  return browser.name;
};

export const isBrowserSupported = () => {
  const browserName = getBrowserName();

  if (!browserName) {
    return true;
  }

  return !UNSUPPORTED_BROWSERS.includes(browserName as Browser);
};

export const isChrome = () => getBrowserName() === 'chrome';
export const isFirefox = () => getBrowserName() === 'firefox';
export const isSafari = () => getBrowserName() === 'safari';
export const isEdge = () => getBrowserName() === 'edge-chromium';
