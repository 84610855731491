import type { LocalizedString } from '../utils/localization';

export const EMAIL_DOMAIN_API_ERROR: LocalizedString = {
  textKey: 'email_domain_api_error',
  fallbackText: 'There was an error while validating the email domain!',
};

export const INVALID_EMAIL_DOMAIN: LocalizedString = {
  textKey: 'invalid_email_domain_error',
  fallbackText: 'Invalid email domain for your account!',
};

export const VALID_EMAIL_DOMAIN: LocalizedString = {
  textKey: 'valid_email_domain_message',
  fallbackText: 'The email domain was successfully validated!',
};

export enum EmailAddressValidationResponseEnum {
  VALID_EMAIL_ADDRESS = 'Valid email address!',
  INVALID_EMAIL_ADDRESS = 'Invalid email address!',
  SAME_EMAIL_ADDRESS = 'Please check the above checkbox to authorize yourself!',
}
