import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const ThumbUpOutline = () => (
  <SvgIcon
    width="16px"
    height="14px"
    viewBox="0 0 16 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g
      id="00-Feedback"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="0x.1-—-Feedback"
        transform="translate(-697.000000, -575.000000)">
        <g
          id="Group-7"
          transform="translate(697.000000, 495.000000)">
          <g
            id="Group-4"
            transform="translate(0.000000, 80.000000)">
            <g id="thumb_up-24px-(1)">
              <path
                d="M0,0 L16,0 L16,16 L0,16 L0,0 Z M0,0 L16,0 L16,16 L0,16 L0,0 Z"
                id="Shape" />
              <path
                d="M6,14 L12,14 C12.5533333,14 13.0266667,13.6666667 13.2266667,13.1866667 L15.24,8.48666667 C15.3,8.33333333 15.3333333,8.17333333 15.3333333,8 L15.3333333,6.66666667 C15.3333333,5.93333333 14.7333333,5.33333333 14,5.33333333 L9.79333333,5.33333333 L10.4266667,2.28666667 L10.4466667,2.07333333 C10.4466667,1.8 10.3333333,1.54666667 10.1533333,1.36666667 L9.44666667,0.666666667 L5.05333333,5.06 C4.81333333,5.3 4.66666667,5.63333333 4.66666667,6 L4.66666667,12.6666667 C4.66666667,13.4 5.26666667,14 6,14 Z M6,6 L8.89333333,3.10666667 L8,6.66666667 L14,6.66666667 L14,8 L12,12.6666667 L6,12.6666667 L6,6 Z M0.666666667,6 L3.33333333,6 L3.33333333,14 L0.666666667,14 L0.666666667,6 Z"
                id="Shape"
                fill="#757679"
                fillRule="nonzero" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ThumbUpOutline;
