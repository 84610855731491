import styled from 'styled-components';

export const EditFormWrapper = styled.div`
  .EditProfileDetailsForm__Form-Inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
    position: relative;
    .EditProfileDetailsForm__Loader {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background: white;
      opacity: 0.9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .EditProfileDetailsForm__Form-Input-Outer {
      flex: calc(50% - 24px);
      max-width: 306px;
      margin-bottom: 16px;
      ${p => p.theme.breakpoints.down('sm')} {
        max-width: 295px;
      }
      ${p => p.theme.breakpoints.down('xs')} {
        flex: 100%;
        max-width: 100%;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
    .EditProfileDetailsForm__Input {
      width: 100%;
      .MuiInputBase-root {
        border-radius: 8px;
        overflow: hidden;
        .MuiInputBase-input {
          background: ${p => p.theme.palette.grey[100]};
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: ${p => p.theme.palette.grey[100]};
        }
      }
      .Custom-Select__control {
        background: ${p => p.theme.palette.grey[100]};
      }
    }
    .Select__Trigger {
      background: ${p => p.theme.palette.grey[100]};
      border: none;
    }
  }
  .EditProfileDetailsForm__Button {
    margin-right: 32px;
    font-size: 1.6rem !important;
  }

  input.MuiInputBase-input {
    border: none !important;
  }
`;
