import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Logo from '../assets/img/uipath-logo.svg';

interface Props {
  useLineBreak?: boolean;
  useOnClick?: boolean;
}

const Container = styled.div<Props & React.HTMLAttributes<HTMLDivElement>>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  .BrandedLogoAndText__Container {
    display: flex;
    .BrandedLogoAndText__Container-Img {
      @media (min-width: 1023px) {
        margin: 16px 0;
      }
    }
    .BrandedLogoAndText__Container-Text {
      color: ${p => p.theme.palette.common.black};
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.033em;
      font-family: poppins, noto-sans, "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", "Noto Sans TC", "Noto Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
      margin: auto 8px;
    }
  }
`;

const BrandedLogoAndText = ({
  useLineBreak, useOnClick, ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) => {
  const history = useHistory();
  const handleClickImageAndText = () => {
    history.push('/');
  };
  return (
    <Container {...props}>
      <div
        className="BrandedLogoAndText__Container"
        {...(useOnClick && { onClick: handleClickImageAndText })}
      >
        <img
          src={Logo}
          alt="UiPath Customer Portal Logo"
          className="BrandedLogoAndText__Container-Img"
        />
        {useLineBreak ? (
          <span className="BrandedLogoAndText__Container-Text">
          Customer
            <br />
          Portal
          </span>
        ) : (
          <span className="BrandedLogoAndText__Container-Text">Customer Portal</span>
        )}
      </div>
    </Container>
  );
};
export default BrandedLogoAndText;
