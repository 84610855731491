import PropTypes from 'prop-types';
import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';

import * as styles from '../../../assets/css/Company/IndividualLicense';
import { UNLIMITED_NUM } from '../../../constants/hapo.constants';
import CPLinearProgressBar from '../../CustomerPortal-ProgressBar';

const IndividualLicense = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  const isUnlimited = parseInt(props.maxAllowedQuantity) === UNLIMITED_NUM;
  const maxAllowedQuantity = isUnlimited
    ? t('company_hapo_dashboard_unlimited_quantity', 'Unlimited')
    : props.maxAllowedQuantity;
  const availableQuantity = isUnlimited
    ? t('company_hapo_dashboard_unlimited_quantity', 'Unlimited')
    : props.availableQuantity;

  return (
    <styles.IndividualLicense data-testid="IndividualLicense">
      <p className="IndividualLicense__Name">{props.licenseName}</p>
      <p className="IndividualLicense__Stats">{maxAllowedQuantity}</p>
      <p className="IndividualLicense__Stats">{props.consumedQuantity}</p>
      <p className="IndividualLicense__Stats">{availableQuantity}</p>
      <div className="IndividualLicense__Progress">
        {!isUnlimited && (
          <CPLinearProgressBar
            progress={props.maxAllowedQuantity - props.availableQuantity}
            total={props.maxAllowedQuantity}
          />
        )}
      </div>
    </styles.IndividualLicense>
  );
};

IndividualLicense.propType = {
  licenseName: PropTypes.string.isRequired,
  maxAllowedQuantity: PropTypes.number.isRequired,
  availableQuantity: PropTypes.number.isRequired,
  consumedQuantity: PropTypes.number.isRequired,
};

export default IndividualLicense;
