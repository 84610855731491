import styled from 'styled-components';

import Stars_24 from '../../img/svg/action_icons/Stars_24.svg';
import {
  Hero,
  Subtitle,
  Title,
} from '../CustomerPortalBasicHero.tsx';

export {
  Hero,
  Title,
};

// Override existing Subtitle from Basic hero
export const PremiumSubtitle = styled(Subtitle)`
  width: 100%;

  ${p => p.theme.breakpoints.up('sm')} {
    width: 100%;
  }

  ${p => p.theme.breakpoints.up('lg')} {
    width: 70%;
  }
`;

export const PremiumWrapper = styled.div`
  .CustomerPortalClientsSelection__heroContent {
    display: flex;
    justify-content: space-between;

    ${p => p.theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  }
`;

export const PremiumStickerWrapper = styled.span`
  display: inline-block;
  margin-left: ${p => p.theme.spacing(2)}px;
  padding: 4px;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${p => p.theme.palette.orange[500]};
  vertical-align: middle;
  border: 1px solid ${p => p.theme.palette.orange[300]};
  border-radius: 3px;

  &::before {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    content: '';
    background: url(${Stars_24});
    margin-right: ${p => p.theme.spacing(0.5)}px;
  }
`;
