import validator from 'validator';

export const getStringEnv = (envKey?: string) => String(envKey);
export const getBoolEnv = (envKey?: string) => envKey?.toLowerCase() === 'true';

export const isValidAccentedString = (str: string) => {
  // the following regex will make sure that the string contains at least one letter
  // and can contain spaces, dashes, and accented letters
  const regex = /^\p{L}(?:[\p{L}\s\u00C0-\u017F-]*[\p{L}_-])?$/u;
  return validator.matches(str, regex);
};
