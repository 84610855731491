import React, {
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  useParams,
} from 'react-router-dom';

import * as styled from '../../assets/css/CustomerPortalContentWrapper';
// Components
import CategoryHero from '../../components/category/KB-Category-Hero';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
import Container from '../../components/CustomerPortal-New-Container';
import SupportKBSearchResults from '../../components/support_kb/searchResult/SupportKBSearchResult';
// Constants
import KB_ACTION from '../../constants/kbActions.constants';
import { SUPPORT_KB_DOCS_URL } from '../../constants/network.constants';
// Interfaces
import type { IKBSearchState } from '../../interfaces/knowledgeBase.interface';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
// Utils
import {
  handleSearchCategoryFilterClick,
  handleSearchPaginateClick,
  handleSearchSortClick,
  KBSearchParamBuilder,
  useKBFetcher,
} from '../../lib/knowledgebaseDocument.utils';
import { kbSearchReducer } from '../../reducers/kb_reducers';
import { getCloudPrefixedPath } from '../../utils/cloud';

/* This is the page component for Knowledge Base.
  Component consumed in route level
  Renders all the layout for all things knowledge base
*/
const CustomerPortalPageCategory = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  /* Default constants */
  const { product } = useParams<any>();
  const docsPerPage = 8;
  const screenName = 'SupportKBProduct';
  const searchParam = new URLSearchParams(window.location.search);
  const initialSearchState: IKBSearchState = {
    keyword: searchParam.get('q') || '',
    keywordInputField: searchParam.get('q') || '',
    isSearching: searchParam.get('q') ? true : false,
    isCompleteSearching: false,
    filterCategories: [],
    kbDocuments: [],
    currentPage: parseInt(searchParam.get('page') || '1', 10),
    kbTotalDocs: 0,
    kbTotalPages: 0,
    sortBy: 'views',
    sortDirection: parseInt(searchParam.get('d') || '-1', 10),
    skipResult:
      parseInt(searchParam.get('page') || '1', 10) === 1
        ? 0
        : (parseInt(searchParam.get('page') || '1', 10) - 1) * docsPerPage,
    filterParam: new Set<string>(),
    filterSubcategoriesParam: searchParam.get('sub')
      ? new Set([ ...(searchParam.get('sub') as any).split(',') ])
      : new Set<string>(),
  };

  /* State */
  const [ searchPaginationPage, setSearchPaginationPage ] = useState(1);
  const [ searchState, setSearchURL, dispatchSearchAction ] = useKBFetcher(
    ``,
    docsPerPage,
    kbSearchReducer,
    initialSearchState
  );
  const [ selectedArticle, setSelectedArticle ] = useState('');
  const [ gotoDetails, setGotoDetails ] = useState(false);

  /* Lifecycle */
  useTrackPageViewEvent(screenName);
  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView(screenName);
    if (product) {
      handleCategoryFilterClick(product);
    }
  }, []);

  /* Event Listeners */

  // Event when Search Sort item is clicked
  const handleSearchingSortClick = (
    sortVal: { path: string; direction: number } | null
  ) => {
    handleSearchSortClick(
      docsPerPage,
      SUPPORT_KB_DOCS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      sortVal,
      'category',
      props.history
    );
  };

  const handleSearchingPaginateClick = (val: number) => {
    handleSearchPaginateClick(
      docsPerPage,
      SUPPORT_KB_DOCS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      val,
      'category',
      props.history
    );
  };

  const handleCategoryFilterClick = (elemID: string) => {
    handleSearchCategoryFilterClick(
      docsPerPage,
      SUPPORT_KB_DOCS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      elemID,
      'category',
      props.history
    );
  };

  const handleMiniSearchFormSubmit = (keywords: string) => {
    // Update keyword
    // Remove hash from keywords as breaks search url
    keywords = keywords.replace(/[*#]/g, '');

    // Do not search again if query unchanged
    if (keywords === searchState.keyword) {
      return;
    }
    dispatchSearchAction({
      type: KB_ACTION.FETCH_INIT,
      payload: { keyword: keywords },
    });

    const queryParam = new KBSearchParamBuilder('Support_KB_Documents')
      .setAction('search')
      .setFilterCategories(searchState.filterParam)
      .setKeyword(keywords.split(' '))
      .setSkip(0)
      .setTop(docsPerPage)
      .setShowDescription(true)
      .setFilterTypeCount('subcategory');

    setSearchURL(`${SUPPORT_KB_DOCS_URL}${queryParam.build().toString()}`);

    // Update url params
    props.history.replace({ search: `q=${keywords}&page=1&s=${searchState.sortBy}&d=${searchState.sortDirection}` });

    // Reset page
    setSearchPaginationPage(1);
  };

  if (gotoDetails) {
    return (
      <Redirect
        push
        to={getCloudPrefixedPath(
          `/kb/${selectedArticle}`
        )}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {t(
            'support_kb_category_page_title',
            'Support Knowledge Base Product | Customer Portal'
          )}
        </title>
      </Helmet>

      <styled.SectionContentWrapper>
        <CategoryHero
          title={product || ''}
          linkHref="/support/knowledge-base"
          linkText={t('knowledge_base_category_page_go_back_btn', 'Go Back')}
          back
          backAmount={1}
          cssClass="SupportKB__Category-Hero"
        />

        {!searchState.isCompleteSearching && (
          <Container>
            <CustomerPortalLoader />
          </Container>
        )}

        {searchState.isSearching && (
          <SupportKBSearchResults
            searchState={searchState}
            handleCategoryFilterClick={handleCategoryFilterClick}
            handleSearchSortClick={handleSearchingSortClick}
            searchDocsPerPage={docsPerPage}
            searchPaginationPage={searchPaginationPage}
            searchNumPagesBeforeEllipses={5}
            handleSearchPaginateClick={handleSearchingPaginateClick}
            isProductResults
            handleMiniSearchFormSubmit={handleMiniSearchFormSubmit}
            screenName={screenName}
          />
        )}
      </styled.SectionContentWrapper>
    </>
  ); // End of return
};

export default CustomerPortalPageCategory;
