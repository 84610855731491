export const JIRA_HOST_NAME = 'uipath.atlassian.net';
export const JIRA_SRE_SERVICE_USER_USERNAME = 'jira.sre.serviceuser@gmail.com';

export enum JiraStatus {
  OPEN = 'Open',
  IN_PROGRESS = 'In Progress',
  CLOSED = 'Closed',
  FIXED = 'Fixed',
  WONT_FIX = 'Won\'t Fix',
  BY_DESIGN = 'By Design',
}

export const JIRA_RESOLVED_STATUS = [ 'Fixed', 'Won\'t Fix', 'By Design' ];

export enum JiraAction {
  ADD_OR_UPDATE = 'Add or Update Jira Known Issue',
  REMOVE = 'Remove Jira Known Issue',
}

export const JIRA_MAX_RESULTS = 50;
