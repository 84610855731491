import { AuthType } from '@customer-portal/constants';
import axios from 'axios';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  CP_VERSION_UPDATE_ATTEMPT_KEY,
  CP_VERSION_UPDATE_ATTEMPT_TIMEOUT_SECONDS,
} from '../constants/state.constants';
import { getAuthType } from '../contexts/auth';
import { getStringEnv } from '../lib/index.util';
import useLogout from './useLogout';

/**
 * Checks for a newly deployed frontend version and reloads the page if a new version is detected.
 * It does this by fetching the version.json file, which is generated upon build and contains the package.json version,
 * and comparing it with the current local frontend version.
 * Response headers are used to ensure that requests to version.json and the main HTML file are never cached by the browser (see nginx.conf)
 */
const detectUpdateAndReload = async (logout: () => Promise<void>) => {
  try {
    // The package.json version of the browser's current frontend code
    const currentVersion = require('../../../package.json').version;

    // Fetch the version.json file that is generated upon build. In nginx.conf, we set response headers to ensure that
    // version.json is not cached and instead always fetches from the frontend server, meaning this response will always be up-to-date.
    const versionResult = await axios.get(`${getStringEnv(process.env.PUBLIC_URL)}/version.json`);
    const { version: fetchedVersion } = versionResult.data;

    if (fetchedVersion !== currentVersion) {
      console.log(`New frontend version detected. Current version: ${currentVersion}. New version: ${fetchedVersion}`);
      const lastVersionUpdateAttemptDate = localStorage.getItem(CP_VERSION_UPDATE_ATTEMPT_KEY);

      // Only attempt a reload if no last attempt, or enough time has passed since the last attempt.
      // This safeguard is to ensure infinite refreshes don't occur if somehow, a new version is fetched but
      // a reload does not obtain the new code. This should never occur, but if it does, check network responses and
      // Cache-Control headers, as a configuration may have changed such that the main HTML file is being cached by the browser.
      const shouldAttemptVersionUpdate = !lastVersionUpdateAttemptDate ||
        (new Date().getTime() - new Date(lastVersionUpdateAttemptDate).getTime()) > (CP_VERSION_UPDATE_ATTEMPT_TIMEOUT_SECONDS * 1000);
      if (shouldAttemptVersionUpdate) {
        const authType = getAuthType();
        if (authType === AuthType.Cloud) {
          console.log('Logging out user to obtain updated frontend...');
          localStorage.setItem(CP_VERSION_UPDATE_ATTEMPT_KEY, new Date().toISOString());
          await logout();
        } else {
          console.log('Attempting page reload to obtain updated frontend...');
          localStorage.setItem(CP_VERSION_UPDATE_ATTEMPT_KEY, new Date().toISOString());
          window.location.reload();
        }
      } else {
        console.error(`Error: Previously detected new version, but page reload did not obtain updated frontend. Not attempting another reload for ${CP_VERSION_UPDATE_ATTEMPT_TIMEOUT_SECONDS} seconds since last attempt.`);
      }
    } else {
      console.log(`Current frontend version ${currentVersion} is up to date.`);
      localStorage.removeItem(CP_VERSION_UPDATE_ATTEMPT_KEY);
    }
  } catch (e) {
    console.log('An error occurred while checking the frontend version. Not reloading.', e);
  }
};

/**
 * This hook calls detectUpdateAndReload() whenever the URL path or search changes,
 * which allows us to handle when frontend deploys have occurred during SPA navigation.
 */
const useUpdateDetector = () => {
  const location = useLocation();
  const logout = useLogout();
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      detectUpdateAndReload(logout);
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [ location.pathname, location.search, logout ]);
};

export default useUpdateDetector;
